import React from 'react';
import {
  Icons,
  styled,
  Carousel as SlickCarousel,
} from '@onehope/design-system-v2';
import { SegmentEvents } from './ActionEngineCard/constants/segmentEvents';
import TrackEventV2Fn from './types/TrackEventV2';

export type SettingsType = {
  dots: boolean;
  arrows: boolean;
  infinite: boolean;
  speed: number;
  slidesToShow: number;
  slidesToScroll: number;
  prevArrow?: any;
  nextArrow?: any;
  afterChange?: (position: number) => void;
  onInit?: () => void;
};

const Carousel = styled(SlickCarousel)(() => ({
  '&.slick-slider': {
    // marginBottom: '24px',
    marginBottom: '0px',
  },
  /* Arrows */
  '& .slick-prev, .slick-next': {
    width: '40px',
    height: '40px',
  },
  '& .slick-prev': {
    cursor: 'pointer',
    left: '16px',
    zIndex: 1,
    ['@media (max-width: 600px)']: {
      display: 'none !important',
    },
  },
  '& .slick-next': {
    cursor: 'pointer',
    right: '16px',
    ['@media (max-width: 600px)']: {
      display: 'none !important',
    },
  },
  '& .slick-prev.slick-disabled, .slick-next.slick-disabled': {
    opacity: '0.25',
  },
}));

const baseSettings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToScroll: 1,
  slidesToShow: 1,
  arrows: true,
  touchThreshold: 1000,
  prevArrow: <Icons.SliderLeftArrow fill="#1A1A1A" />,
  nextArrow: <Icons.SliderRightArrow fill="#1A1A1A" />,
};

const getSettings = (
  dots: boolean = false,
  arrows: boolean = false,
  slidesToShow: number = 1,
  slidesToScroll: number = 1,
  trackEventV2?: TrackEventV2Fn,
): SettingsType => {
  const track = (position?: number) => {
    let cardIndex = position || 0;
    if (trackEventV2 && typeof trackEventV2 !== undefined) {
      const event = SegmentEvents.cardViewed;
      trackEventV2({ index: cardIndex, event });
    }
  };
  return {
    ...baseSettings,
    dots,
    arrows,
    slidesToShow,
    slidesToScroll,
    afterChange: (position: number) => track(position),
    onInit: () => track(),
  };
};

interface ImageSliderProps {
  dots?: boolean;
  arrows?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
  children: any;
  trackEventV2?: TrackEventV2Fn;
}

const Slider = ({
  dots,
  arrows,
  slidesToShow,
  slidesToScroll,
  trackEventV2,
  children,
}: ImageSliderProps) => {
  if (!children) return null;
  const settings = getSettings(
    dots,
    arrows,
    slidesToShow,
    slidesToScroll,
    trackEventV2,
  );
  return <Carousel {...settings}>{children}</Carousel>;
};

export default Slider;
