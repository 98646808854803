import { makeStyles } from '@onehope/design-system-v2';

export const useImageStyles = (url: string | null | undefined) =>
  makeStyles(() => ({
    // to correctly display non-square images
    image: {
      background: url ? `url(${url}) center center / cover no-repeat` : '',
      verticalAlign: 'middle',
      marginBottom: 8,
      borderRadius: 4,
      width: 80,
      height: 72,
    },
  }));
