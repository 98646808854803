import { getSeshCache, setSeshCache } from './autocompleteSeshCache';

interface Type {
  name: string;
  description?: string;
  fields: Field[] | null;
}

interface Field {
  name: string;
  description?: string;
  type: Type;
}

interface QueryResult {
  __type: Type;
}

// because of the richness and deeply cyclical nature of our GQL schema,
// let's only include certain fields in our search index
const fieldsToExclude = [
  'accountRole',
  'addressBook',
  'autoCompleteData',
  'autoShips',
  'bestSellers',
  'cart',
  'cartV2',
  'ceo',
  'conciergeUser',
  'customerOrders',
  'customerOrdersV2',
  'customerOrdersV2Count',
  'customers',
  'customersV2',
  'customersV2Count',
  'donations',
  'edges',
  'events',
  'eventsV2',
  'eventsV2Count',
  'hashedEmail',
  'hostRewards',
  'hubspotContactInfo',
  'newArrivals',
  'nextAndPrevProducts',
  'nonProfits',
  'order',
  'orders',
  'paymentDefault',
  'permissions',
  'preferredCustomerCeoUser',
  'products',
  'profileDraft',
  'relatedProducts',
  'shippingAddressBook',
  'shippingAddressDefault',
  'shoppingWithCeoUser',
  'stripePaymentCollection',
  'subscriptions',
  'nonProfitAddress',
  'customerCartFullName',
];

/**
 * Recursively build full string accessors for all fields in the GraphQL schema
 */
function collateFields(
  fields: Field[],
  descriptions: Map<string, string>,
  dataPath: string[] = [],
): string[] {
  return fields
    .map((field) => {
      // naively avoid cycles
      if (field.name === dataPath[dataPath.length - 1]) {
        return [];
      }

      if (fieldsToExclude.includes(field.name)) {
        return [];
      }

      if (!field.type?.fields || field.type.fields === null) {
        const fullyQualifiedFieldName = [...dataPath, field.name].join('.');

        // maintain the map so we can look up descriptions later
        if (field.description) {
          descriptions.set(fullyQualifiedFieldName, field.description);
        }

        return fullyQualifiedFieldName;
      }

      return collateFields(field.type.fields, descriptions, [
        ...dataPath,
        field.name,
      ]);
    })
    .flat();
}

/**
 * Parse GraphQL introspection data and build search corpus of all possible template tags
 * @param keyword
 */
export default function buildAutocompleteCorpus(queryResult: QueryResult): {
  fields: string[];
  descriptions: Map<string, string>;
} {
  // use cache since this request gets heavy
  const seshCache = getSeshCache();
  if (seshCache !== null) {
    console.debug('Returning cached autocomplete corpus');
    return {
      descriptions: seshCache.descriptionMap,
      fields: seshCache.fields,
    };
  }

  const descriptions = new Map<string, string>();

  const { __type } = queryResult;
  if (__type.fields === null) {
    return {
      fields: [],
      descriptions,
    };
  }

  const fields = collateFields(__type.fields, descriptions);

  setSeshCache({
    fields,
    descriptionMap: descriptions,
  });
  return {
    fields,
    descriptions,
  };
}
