import React from 'react';

import myTrainingStyles from './MyTrainingStyles';
import SvgApple from '../../../assets/apple';

type MyTrainingProps = {
  url?: string;
  pathName?: string;
  lastAccessed?: string;
  pathDescription?: string;
  noCoursesInProgress: boolean;
  inFastStart: boolean;
};

const fastStartDescription =
  'Learn how to share the ONEHOPE opportunity and launch your new Cause Entrepreneur business.';
const ceDescription =
  'Head to the Training Center to take courses that will challenge Cause Entrepreneurs at every level and help grow your understanding of ONEHOPE products.';

const fastStartVideoUrl =
  'https://training.onehopewine.com/training/0dfc4768-3dc1-11eb-b208-067360dfb065/overview';
const ceNoCoursesUrl = 'https://training.onehopewine.com/collections/6bb66edc-09ab-11eb-8ae3-06cf503dca07';

export default class MyTraining extends React.Component<MyTrainingProps> {
  getTitle = () => {
    const { inFastStart, noCoursesInProgress } = this.props;
    if (!noCoursesInProgress) {
      return this.props.pathName;
    } else if (inFastStart) {
      return 'Start Here!';
    } else {
      return 'Get Learning';
    }
  };

  getDescription = () => {
    const { inFastStart } = this.props;
    if (!this.props.pathDescription) {
      if (this.props.pathName) {
        return `Continue learning with the "${this.props.pathName}" course.`;
      }
      return inFastStart ? fastStartDescription : ceDescription;
    }
    return this.props.pathDescription;
  };

  getButtonText = () =>
    this.props.noCoursesInProgress ? 'Start Learning' : 'Keep Learning';

  getCourseLink = () => {
    const { noCoursesInProgress, inFastStart } = this.props;
    if (!noCoursesInProgress) {
      return this.props.url;
    } else if (inFastStart) {
      return fastStartVideoUrl;
    } else {
      return ceNoCoursesUrl;
    }
  };

  render() {
    const title = this.getTitle();
    const description = this.getDescription();
    const buttonText = this.getButtonText();
    const courseLink = this.getCourseLink();

    return (
      <div css={myTrainingStyles}>
        <header>
          <div>Training Courses</div>
        </header>
        <div className="content">
          <div className="progress-wrapper">
            <SvgApple />
            <div className="desc">{title}</div>
            <div className="text">{description}</div>
            <a
              href={courseLink}
              target="blank"
              style={{
                textDecoration: 'none',
                color: 'white',
              }}
            >
              <button className="cta-btn">{buttonText}</button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
