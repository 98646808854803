import React from 'react';

const SvgGpsPin = (props: any) => (
  <svg width="13px" height="18px" viewBox="0 0 15 20" {...props}>
    <defs>
      <path
        // d="M19.5 10c-3.773 0-6.825 3.13-6.825 7 0 5.25 6.825 13 6.825 13s6.825-7.75 6.825-13c0-3.87-3.052-7-6.825-7zm0 9.5c-1.346 0-2.438-1.12-2.438-2.5s1.092-2.5 2.438-2.5 2.438 1.12 2.438 2.5-1.092 2.5-2.438 2.5z"
        d="M19.5,10 C15.72675,10 12.675,13.13 12.675,17 C12.675,22.25 19.5,30 19.5,30 C19.5,30 26.325,22.25 26.325,17 C26.325,13.13 23.27325,10 19.5,10 Z M19.5,19.5 C18.1545,19.5 17.0625,18.38 17.0625,17 C17.0625,15.62 18.1545,14.5 19.5,14.5 C20.8455,14.5 21.9375,15.62 21.9375,17 C21.9375,18.38 20.8455,19.5 19.5,19.5 Z"
        id="gps_pin_svg__a"
      />
    </defs>
    <g transform="translate(-12 -10)" fill="none" fillRule="evenodd">
      <mask id="gps_pin_svg__b" fill="#fff">
        <use xlinkHref="#gps_pin_svg__a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#gps_pin_svg__a" />
      <g mask="url(#gps_pin_svg__b)" fill="#1A1A1A">
        <path d="M0 0h39v40H0z" />
      </g>
    </g>
  </svg>
);

export default SvgGpsPin;
