import gql from 'graphql-tag';

const GET_TASTING_KIT_ORDERS = gql`
  query CEDash_GetTastingKitOrdersQuery($byEventId: String!) {
    viewer {
      id
      user {
        id
        orders(first: 10000, byEventId: $byEventId, tastingFlightOnly: true)
          @connection(key: "Orders_orders_TF", filter: []) {
          edges {
            __typename
            node {
              id
              orderId
              orderNumber
              accountFullName
              accountId
              createDate
              orderStatus
              orderTotal
              orderTax
              orderSubTotal
              email
              giftReceipt
              isProcessing
              giftCards
              giftCardSavings
              promoCodes
              promoCodeSavings
              isAutoShipOrder
              availableTastingFlightCode {
                code
                expiresOn
              }
              isPreferredCustomerOrder
              trinityTransmissionStatus
              trinityOrderId
              trinityPartyId
              shippingChosen
              shippingInfo {
                carrier
                tracking
                expectedDeliveryDate
              }
              shippingAddress {
                firstName
                lastName
                specialInstructions
                street
                addressLineTwo
                city
                state
                zip
                phone
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;

export default GET_TASTING_KIT_ORDERS;
