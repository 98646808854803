import gql from 'graphql-tag';

export const mutation = gql`
  mutation CreateConciergeServiceMutation(
    $input: CreateConciergeServiceInput!
  ) {
    createConciergeService(input: $input) {
      user {
        id
        userId
        conciergeCustomerAccountId
      }
      newCustomer {
        id
        ordersCount
      }
    }
  }
`;

export default mutation;
