// @ts-ignore
import * as Yup from 'yup';

const getMaxDate = () => {
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 21);
  return maxDate;
};

export const AddContactFormSchema = Yup.object().shape({
  contactType: Yup.string().required('Required'),
  firstName: Yup.string()
    .matches(/^[a-zA-Z]/, 'Please enter first name')
    .required('Required'),
  lastName: Yup.string()
    .matches(/^[a-zA-Z]/, 'Please enter last name')
    .required('Required'),
  organizationName: Yup.string().when('contactType', {
    is: 'ORGANIZATION',
    then: Yup.string().required('Required'),
  }),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Required'),
  addressLineOne: Yup.string(),
  addressLineTwo: Yup.string(),
  city: Yup.string().when('addressLineOne', {
    is: (addressLineOne: string) => !!addressLineOne,
    then: Yup.string().required('City required for shipping address'),
  }),
  state: Yup.string().when('addressLineOne', {
    is: (addressLineOne: string) => !!addressLineOne,
    then: Yup.string().required('State required for shipping address'),
  }),
  zip: Yup.string().when('addressLineOne', {
    is: (addressLineOne: string) => !!addressLineOne,
    then: Yup.string().required('Zipcode required for shipping address'),
  }),
  phone: Yup.string().matches(
    /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
    'Please enter a valid phone number',
  ),
  dateOfBirth: Yup.string()
    .length(10, 'Invalid date, make sure format is MM/DD/YYYY')
    .test(
      'Must be 21',
      'Invalid date, age must be older than 21',
      (value: string) => {
        if (!value) return true;
        return new Date(value) < getMaxDate();
      },
    ),
  note: Yup.string(),
});
