import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { MuiButton } from '@onehope/design-system-ohw';
import Close from '@material-ui/icons/Close';
import { FormikProps } from 'formik';
import { MyFormValues } from './MyFormTypes';
import GET_CUSTOMERS_QUERY from '../../../queries/Customers';
import SearchOrAddCustomer from './SearchOrAddCustomer';
import { CardInfo, CardTitle, CardLine } from '../AddCustomerForm/index.styles';
import {
  CustomerCard,
  UpdateButton,
  formatPhone,
} from '../EventDetailsForm/EventDetailsForm';

interface AddCustomerProps {
  onSubmit: any;
  values: MyFormValues;
  onClose: () => void;
  handleCustomerSelect: (values: MyFormValues) => void;
  existingCustomer: boolean;
  setExistingCustomer: (bool: boolean) => void;
  existingCustomerSelected: boolean;
  setExistingCustomerSelected: (bool: boolean) => void;
  isCustomerSelected: boolean;
  setIsCustomerSelected: (bool: boolean) => void;
  toggleAddCustomer: (values: MyFormValues) => void;
}

type FormProps = {
  setIsNotEditing: () => void;
  mutationLoading: boolean;
};

interface CustomerNode {
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface Customer {
  node: CustomerNode;
}

interface CustomerData {
  fullName: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  email: string;
  phone: string;
  accountId: string;
}

const Form = styled.div`
  margin: 0 auto;
  width: 100%;
  background-color: #ffffff;
  padding: 16px;
  @media all and (min-width: 968px) {
    padding: 32px;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    max-width: 432px;
    max-height: 725px;
    min-width: 432px;
  }
`;

const Title = styled.div`
  height: 42px;
  /* font-family: PlayfairDisplay; */
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.5px;
  color: #000000;
  margin-bottom: 24px;
`;

const CloseDialog = styled.div`
  width: 48px;
  height: 48px;
  position: absolute;
  padding-top: 21.4px;
  right: 0;
  cursor: pointer;
`;

const Spacer = styled.div`padding-bottom: 16px;`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const CustomerCardWide = styled(CustomerCard)`
  min-width: 288px;
  margin-bottom: 40px;
`;

export default function AddOrder(
  props: FormProps & FormikProps<MyFormValues> & AddCustomerProps,
) {
  const {
    values,
    touched,
    onClose,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    setExistingCustomer,
    isCustomerSelected,
    setIsCustomerSelected,
    setExistingCustomerSelected,
    toggleAddCustomer,
  } = props;
  const { fullName, firstName, lastName, email, phone } = values;
  const [
    getCustomers,
    { data: customersData, loading: customersLoading },
  ] = useLazyQuery(GET_CUSTOMERS_QUERY);
  const [isHostNameMenuOpen, setIsHostNameMenuOpen] = useState(false);
  const handleSetValue = (field: string, selectedItem: string) => {
    setFieldValue(field, selectedItem);
    setFieldTouched(field, true, false);
  };

  const change = (name: any, e: React.SyntheticEvent) => {
    if (name === 'email') {
      setExistingCustomer('');
      setExistingCustomerSelected(false);
      getCustomers();
    }
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const handleSelectChange = (field: string, selectedItem: string) => {
    setIsHostNameMenuOpen(false);
    handleSetValue(field, selectedItem);
  };

  const handleCustomerSelected = (selectedItem: CustomerData) => {
    const {
      firstName,
      lastName,
      fullName,
      organizationName,
      email,
      phone,
      accountId,
    } = selectedItem;
    handleSelectChange('fullName', organizationName || fullName);
    handleSelectChange('firstName', firstName);
    handleSelectChange('lastName', lastName);
    handleSelectChange('email', email);
    handleSelectChange('phone', phone);
    handleSelectChange('accountId', accountId);
    setIsCustomerSelected(true);
  };

  function handleHostReset() {
    handleSelectChange('fullName', '');
    handleSelectChange('firstName', '');
    handleSelectChange('lastName', '');
    handleSelectChange('email', '');
    handleSelectChange('phone', '');
    handleSelectChange('accountId', '');
    setIsCustomerSelected(false);
  }

  const disableSubmit = !isCustomerSelected;

  return (
    <form onSubmit={handleSubmit}>
      <CloseDialog onClick={onClose}>
        <Close />
      </CloseDialog>
      <Form>
        <Title>New Order</Title>
        {isCustomerSelected
          ? <CustomerCardWide>
              <CardInfo>
                <CardTitle>Shopping for:</CardTitle>
                {fullName &&
                  <CardLine>
                    {fullName}
                  </CardLine>}
                {email &&
                  <CardLine>
                    {email}
                  </CardLine>}
                {phone &&
                  phone !== 'null' &&
                  <CardLine>
                    {formatPhone(phone)}
                  </CardLine>}
                <UpdateButton onClick={handleHostReset}>Change</UpdateButton>
              </CardInfo>
            </CustomerCardWide>
          : <SearchOrAddCustomer
              change={change}
              onClose={onClose}
              values={values}
              touched={touched}
              isSubmitting={isSubmitting}
              handleSetValue={handleSetValue}
              handleSubmit={handleSubmit}
              toggleAddCustomer={toggleAddCustomer}
              isCustomerSelected={isCustomerSelected}
              setIsCustomerSelected={setIsCustomerSelected}
              handleCustomerSelected={handleCustomerSelected}
              isHostNameMenuOpen={isHostNameMenuOpen}
              setIsHostNameMenuOpen={setIsHostNameMenuOpen}
            />}
        <ButtonContainer>
          <MuiButton
            type="PRIMARY"
            disableRipple
            disabled={disableSubmit}
            onClick={handleSubmit}>
            start order
          </MuiButton>
          <Spacer />
          <MuiButton type="TERTIARY" disableRipple onClick={onClose}>
            cancel
          </MuiButton>
        </ButtonContainer>
      </Form>
    </form>
  );
}
