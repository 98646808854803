import CeIncentive from './images/VINE-Blog_Category_CE-Incentive_v3.png';
import CallRecording from './images/VINE-Blog_Category_Call-Recording_v2.png';
import EventAnnouncement from './images/VINE-Blog_Category_Event-Announcement_v2.png';
import HomeOfficeUpdate from './images/VINE-Blog_Category_Home-Office-Update.png';
import ImpactUpdate from './images/VINE-Blog_Category_Impact-Update_v2.png';
import MarketingUpdate from './images/VINE-Blog_Category_Marketing-Update_v2.png';
import Promotion from './images/VINE-Blog_Category_Promotion_v2.png';
import SaveTheDate from './images/VINE-Blog_Category_Save-the-Date_v2.png';
import TechUpdate from './images/VINE-Blog_Category_Tech-Update_v2.png';

const CALLS_AND_EVENTS = 'CALLS & EVENTS';
const HOME_OFFICE = 'HOME OFFICE';
const IMPACT = 'IMPACT';
const MARKETING = 'MARKETING';
const TRAINING = 'TRAINING';

export default function(category: string) {
  switch (category.toUpperCase()) {
    case CALLS_AND_EVENTS:
      return CallRecording;
    case HOME_OFFICE:
      return HomeOfficeUpdate;
    case IMPACT:
      return ImpactUpdate;
    case MARKETING:
      return MarketingUpdate;
    case TRAINING:
      return TechUpdate;
    default:
      return HomeOfficeUpdate;
  }
}
