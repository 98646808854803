import * as React from 'react';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { styles as s } from '@onehope/design-system';
import IconMenu from './IconMenu';

const { cssConstants } = s;

const GridItem = styled.div<{ orderCount: number }>`
  position: relative;
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 16px;
  max-width: 100%;
  @media all and (min-width: 768px) {
    padding: 24px;
    max-width: ${({ orderCount }) => (orderCount < 3 ? '356px' : '100%')};
  }
  :hover {
    cursor: pointer;
    background-color: ${cssConstants.neutral2};
  }
`;

const GridBlock = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  padding-bottom: 8px;
`;

const GridText = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
`;

const GridContainer = styled.div``;

const HostContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HostName = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  padding-bottom: 8px;
`;

interface OrderCardProps {
  order: any;
  orderCount: number;
}

const handleCardClick = (order: any) => {
  const { GATSBY_HOPECOMMERCE_URL } = process.env;
  return () => {
    return window.open(
      `${GATSBY_HOPECOMMERCE_URL}/order/${order?.orderId}`,
      '_blank',
    );
  };
};

export default function OrderCard(props: OrderCardProps) {
  const { order, orderCount } = props;
  if (order) {
    const onCardClick = handleCardClick(order);
    return (
      <GridItem orderCount={orderCount}>
        <IconMenu
          orderId={order.orderId}
          accountId={order.accountId}
          isCardView={true}
          carrier={order.shippingInfo?.carrier}
          tracking={order.shippingInfo?.tracking}
        />
        <GridContainer onClick={onCardClick}>
          <HostContainer>
            <HostName>{order.accountFullName}</HostName>
          </HostContainer>
          <GridBlock>
            Order #: <GridText>{order.orderNumber}</GridText>
          </GridBlock>
          <GridBlock>
            Date:{' '}
            {order.createDate && (
              <GridText>
                {DateTime.fromISO(order.createDate)
                  .toUTC()
                  .toLocaleString(DateTime.DATE_FULL)}
              </GridText>
            )}
          </GridBlock>
          <GridBlock>
            Promo Code:{' '}
            <GridText>{order?.availableTastingFlightCode?.code}</GridText>
          </GridBlock>
          <GridBlock>
            Promo Code Exp:{' '}
            {order?.availableTastingFlightCode?.expiresOn && (
              <GridText>
                {DateTime.fromISO(order?.availableTastingFlightCode?.expiresOn)
                  .toUTC()
                  .toLocaleString(DateTime.DATE_FULL)}
              </GridText>
            )}
          </GridBlock>
        </GridContainer>
      </GridItem>
    );
  }
  return null;
}
