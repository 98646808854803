import { Theme, makeStyles } from '@material-ui/core/styles';
import { styles as s } from '@onehope/design-system';

const { cssConstants } = s;

export const tabNavStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    justifyContent: 'flex-start',
    padding: '0 40px 0 40px',
    boxShadow: `0 1px 4px 0 rgba(0, 0, 0, 0),  0 2px 2px 0 rgba(0, 0, 0, 0.1)`,
  },
  tabsIndicator: {
    backgroundColor: cssConstants.textColor,
  },
  tabRoot: {
    textTransform: 'unset',
    fontFamily: cssConstants.font,
    fontSize: 14,
    fontWeight: 'bold',
    color: cssConstants.neutral34,
    padding: 16,
    opacity: 1,
    minWidth: 94,
    lineHeight: 1.29,
    '&:hover': {
      opacity: 1,
    },
  },
  tabSelected: {
    color: cssConstants.textColor,
    fontWeight: 'bold',
  },
}));
