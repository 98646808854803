import * as React from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import { MuiButton } from '@onehope/design-system-ohw';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const { cssConstants } = s;

const Button = styled(MuiButton)`
  && {
    width: 288px;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 16px 16px 16px;
  flex-direction: column;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  @media all and (min-width: 768px) {
    padding: 40px;
    flex-direction: row;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  @media all and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const ButtonContainer = styled.div`padding-bottom: 16px;`;

const HeaderTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: -0.5px;
`;


interface HeaderProps {
    setIsOrderDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function Header(props: HeaderProps) {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const handlePlaceOrder = () => {
        if(props.setIsOrderDialogOpen) {
            props.setIsOrderDialogOpen(true);
        }
    };
    return (
        <React.Fragment>
            <HeaderContainer onClick={handlePlaceOrder}>
                <HeaderTitle>Customer Orders</HeaderTitle>
                <ButtonsWrapper>
                    <ButtonContainer>
                        <Button type="PRIMARY" onClick={handlePlaceOrder}>
                            {isTablet ? 'New Order' : 'Create New Order'}
                        </Button>
                    </ButtonContainer>
                </ButtonsWrapper>
            </HeaderContainer>
        </React.Fragment>
    );
}
