import * as React from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import OrderCard from './OrderCard';

/* import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../queries/generatedTypes/CEDash_EventDetailsPage'; */
/* import { CEDash_GuestOrdersQuery_viewer_user_orders_edges as OrdersEdges } from '../../../queries/generatedTypes/CEDash_GuestOrdersQuery'; */

const { cssConstants } = s;

const Grid = styled.div`
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  padding: 0 48px;
`;

const GridList = styled.div<{ orderCount: number }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-gap: 24px;
  grid-auto-flow: dense;
  max-width: ${({ orderCount }) => (orderCount < 3 ? '756px' : '100%')};
  @media all and (min-width: 400px) {
    grid-template-columns: repeat(auto-fit, minmax(356px, 1fr));
`;

interface TastingKitCardListViewProps {
  orders: (any | null)[] | null;
  page: number;
  rowsPerPage: number;
}

export default function CardListView(props: TastingKitCardListViewProps) {
  const { page, rowsPerPage } = props;
  const orders = props.orders?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const orderCount = orders ? orders.length : 0;
  return (
    <React.Fragment>
      <Grid>
        <GridList orderCount={orderCount}>
          {orders &&
            orders.map(order => {
              if (order?.node?.availableTastingFlightCode?.code) {
                return (
                  <OrderCard
                    key={`${order?.node?.id}`}
                    order={order?.node}
                    orderCount={orderCount}
                  />
                );
              }
              return null;
            })}
        </GridList>
      </Grid>
    </React.Fragment>
  );
}
