import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { styles as s } from '@onehope/design-system';

const { cssConstants } = s;

const Container = styled.div`
  padding: 0 16px;
  font-family: ${cssConstants.font};
  color: ${cssConstants.neutral34};
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  a {
    color: ${cssConstants.neutral34};
  }
  @media only screen and (min-width: 768px) {
    padding: 0 40px 16px 40px;
    margin-top: -24px;
  }
`;

const Current = styled.span`
  color: ${cssConstants.textColor};
  font-weight: bold;
`;

const Carrot = styled.span`padding: 0 8px;`;

const getBreadCrumbs = (path: string, contactId?: string | string[] | null | undefined) => {
  switch (path) {
    case 'events':
      return <Container>Events</Container>;
    case 'event':
      return (
        <Container>
          <Link to="/events">Events</Link>
          <Carrot>></Carrot>
          <Current>Manage Event</Current>
        </Container>
      );
    case 'contact':
      return (
        <Container>
          <Link to="/contacts">Contacts</Link>
          <Carrot>></Carrot>
          <Current>Contact details</Current>
        </Container>
      );
    case 'eventFromContact':
      return (
        <Container>
          <Link to="/contacts">Contacts</Link>
          <Carrot>></Carrot>
          <Link to={`/contact?contactId=${contactId}`}>Contact details</Link>
          <Carrot>></Carrot>
          <Current>Manage Event</Current>
        </Container>
      );
    default:
      return <div />;
  }
};

export default function BreadCrumbs({
  location,
  contactId,
}: {
  location: string;
  contactId?: string | string[] | null | undefined;
}) {
  return getBreadCrumbs(location, contactId);
}
