import { css } from '@emotion/core';

export const splitWidgetsContainer = css`
  display: grid;
  grid-template-columns: repeat(2, minmax(360px, 1fr));
  grid-gap: 24px;
  flex-flow: row wrap;
  margin: 24px;
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    margin: 12px;
  }
  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, minmax(320px, 1fr));
    margin: 0px;
  }
  @media only screen and (max-width: 767px) {
    grid-template-columns: repeat(1, minmax(320px, 1fr));
    margin: 0px;
  }
  margin-bottom: 80px;
`;

export const supportContainer = css`
  margin-top: 24px;
`;

export const widgetContainer = css`
  overflow: hidden;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`;

// widgets that take up two container spaces
export const widgetContainerDouble = css`
  grid-column-start: 1;
  grid-column-end: 3;
  height: 578px;
  @media only screen and (max-width: 1280px) {
    // revert to one space, not enough room for double cards on mobile
    grid-column-end: 2;
  }
`;
