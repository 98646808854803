import React from 'react';
import {
  Dialog,
  makeStyles,
  ThemeType,
  Container,
  Text,
} from '@onehope/design-system-v2';
import styled from '@emotion/styled';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { NotificationBadge } from '../../Event/Notifications/index.styles';
import SatelliteEventsSnackBar from '../../Events/SatelliteEventsSnackBar';
import { Close } from '../../../assets/close';

const IconContainer = styled.div`
  margin: 0 11px;
  float: left;
`;

const styles = makeStyles((theme: ThemeType) => ({
  dialogContainer: {
    padding: '20px',
    fontFamily: theme.bodyFontFamily,
  },
  dialogTitle: {
    fontWeight: 400,
    fontSize: '44px',
    fontFamily: theme.headerFontFamily,
  },
  dialogText: {
    fontSize: '12px',
  },
  headerContainer: {
    width: '100%',
    position: 'relative',
    padding: 0,
    height: '41px',
  },
  headerContainerLeft: {
    marginTop: '10px',
  },
  headerText: {
    fontWeight: 500,
    fontSize: '16px',
  },
  closeButton: {
    float: 'right',
    marginTop: '-33px',
  },
  lightGrayBar: {
    borderBottom: `1px solid ${theme.palette.greyLight.main}`,
    marginBottom: '20px',
  },
}));

export default function IOSAnnouncementBanner({
  openNotificationDialog,
  handleCloseDialog,
  isMobile,
}: {
  openNotificationDialog: boolean;
  handleCloseDialog: () => void;
  isMobile: boolean;
}) {
  const classes = styles();

  return (
    <>
      <Dialog
        open={openNotificationDialog}
        onClose={handleCloseDialog}
        fullScreen
      >
        <Container className={classes.dialogContainer}>
          <Container className={classes.headerContainer}>
            <div className={classes.headerContainerLeft}>
              <IconContainer>
                <NotificationBadge variant="dot">
                  <NotificationsIcon />
                </NotificationBadge>
              </IconContainer>
              <Text className={classes.headerText}># Notifications</Text>
            </div>
            <Close
              className={classes.closeButton}
              onClick={handleCloseDialog}
            />
          </Container>
          <div className={classes.lightGrayBar}></div>
          <SatelliteEventsSnackBar isMobile={isMobile} />
        </Container>
      </Dialog>
    </>
  );
}
