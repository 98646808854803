import React from 'react';
import get from 'lodash/get';
import { useMutation, useQuery } from '@apollo/react-hooks';
import FormContainer from '../../../Event/FormContainer';
import CharityDetailsForm from '../../../Events/CharityDetailsForm/CharityDetailsForm';
import Notification from '../../../Notifcations/DefaultNotifcation';
import EVENT_EDIT_MUTATION from '../../../../mutations/Event/EventEditMutation';
import { getInitialValues } from '../../../Event/TabContent/Charity/helpers';
import { EventCharityFormSchema } from '../../../Event/TabContent/Charity/validationSchema';
import { MyFormValues } from '../../../Event/TabContent/Charity/FormTypes';
import useToggle from '../../../../utils/useToggleHook';
import GET_PRE_APPROVED_NON_PROFITS_QUERY from '../../../../queries/NonProfit';
import Loading from '../../../../common/Loading';
import {
  BaseContainer,
  TabContentContainer,
} from '../../../Event/TabContent/index.styles';

interface CharityProps {
  form: any;
  handleNextStepChange: (
    key: string,
    values: MyFormValues,
  ) => Promise<void> | undefined;
  handlePreviousStepChange: () => void;
}

export default function Charity(props: CharityProps) {
  const { handlePreviousStepChange } = props;
  const {
    value: notificationOpen,
    setTrue: setNotificationOpen,
    setFalse: setNotificationClosed,
  } = useToggle();
  const {
    value: editing,
    setTrue: setIsEditing,
    setFalse: setIsNotEditing,
  } = useToggle();

  const [eventEditMutation, { loading: mutationLoading }] = useMutation(
    EVENT_EDIT_MUTATION,
  );

  const { data, loading, error } = useQuery(GET_PRE_APPROVED_NON_PROFITS_QUERY);
  if (loading) return <Loading />;
  const nonProfits = get(data, 'viewer.user.nonProfits.edges');

  const SUCCESS_MESSAGE = `Your profile has been updated`;
  return (
    <BaseContainer>
      <TabContentContainer>
        <Notification
          open={notificationOpen}
          message={SUCCESS_MESSAGE}
          handleClose={setNotificationClosed}
        />
        <FormContainer
          validationSchema={EventCharityFormSchema}
          initialValues={getInitialValues()}
          /* eslint-disable react/jsx-no-bind */
          formToUse={(props: any) => (
            <CharityDetailsForm
              {...props}
              editing={false}
              mutationLoading={mutationLoading}
              setIsNotEditing={setIsNotEditing}
              nonProfits={nonProfits}
              handlePreviousStepChange={handlePreviousStepChange}
            />
          )}
          onSubmit={async (values: MyFormValues, { setErrors }: any) => {
            props.handleNextStepChange('charity', values);
          }}
        />
      </TabContentContainer>
    </BaseContainer>
  );
}
