import React from 'react';

const SvgUp = (props: any) => (
  <svg width="10px" height="15px" viewBox="0 0 15 20" {...props}>
    <defs>
      <path
        d="M27.118 16.964a.97.97 0 0 1-.262.678l-6.177 6.75a.814.814 0 0 1-1.24 0l-6.177-6.75a.97.97 0 0 1-.262-.678.97.97 0 0 1 .262-.678.814.814 0 0 1 .62-.286h12.353c.24 0 .446.095.62.286a.97.97 0 0 1 .263.678z"
        id="up_svg__a"
      />
    </defs>
    <g
      transform="translate(-12 -6) matrix(1 0 0 -1 0 41)"
      fill="none"
      fillRule="evenodd"
    >
      <mask id="up_svg__b" fill="#fff">
        <use xlinkHref="#up_svg__a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#up_svg__a" />
      <g mask="url(#up_svg__b)" fill="#0091ff">
        <path d="M0 0h39v40H0z" />
      </g>
    </g>
  </svg>
);

export default SvgUp;
