import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { styles as s } from '@onehope/design-system';
import get from 'lodash/get';
import CustomerAddMutation from '../../../../../mutations/Customer/CustomerAddMutation';
import { MyFormValues } from './MyFormTypes';
import { AddCustomerFormSchema } from './validationSchema';
import AddCustomer from './AddCustomer';
import FormContainer from '../../../../Event/FormContainer';
import { useTrackingContextValue } from '../../../../../contexts/TrackingContext';
import { segmentEvents } from '../../../../../utils/segment/constants';

interface AddCustomerFormProps {
  formTitle: string;
  onClose: () => void;
  selectedEventId?: string;
  // isCustomerSelected: boolean;
  defaultValues: MyFormValues;
  addCustomerButtonText: string;
  existingCustomerButtonText?: string;
  // setIsCustomerSelected: (bool: boolean) => void;
  handleSelectedCustomer: (values: MyFormValues) => void;
}

const handleAddCustomer = async ({
  values,
  onClose,
  setErrors,
  selectedEventId,
  existingCustomer,
  shopWithCustomerAdd,
  setExistingCustomer,
  setErrorNotification,
  handleSelectedCustomer,
  existingCustomerSelected,
  trackEventV2,
}: any) => {
  const { firstName, lastName, email, phone } = values;
  if (existingCustomerSelected) {
    setErrors({});
    const {
      email,
      mobilePhone,
      accountId,
      firstName,
      lastName,
    } = existingCustomer;
    return handleSelectedCustomer({
      email,
      phone: mobilePhone,
      accountId,
      firstName,
      lastName,
      fullName: `${firstName} ${lastName}`,
      hostCustomerType: 'Existing Customer',
    });
  }
  await shopWithCustomerAdd({
    variables: {
      input: {
        customerEmail: email,
        firstName,
        lastName,
        eventId: selectedEventId ? selectedEventId : 'null',
        mobilePhone: phone.replace(/[-() ]/g, ''),
        isAddingNewCustomer: true,
      },
    },
  })
    .then(res => {
      if (!res || !res.data || !res.data.shopWithCustomerAdd) return;

      const { data } = res;
      const newCustomer = get(data, 'shopWithCustomerAdd.newCustomer');
      const {
        accountId,
        email,
        firstName,
        lastName,
        mobilePhone,
        ordersCount,
      } = newCustomer;

      // segment tracking

      const isNewCustomer = ordersCount === 0;
      trackEventV2({
        event: segmentEvents.conciergeModeOrderStarted,
        properties: {
          eventId: selectedEventId || null,
          shoppingForId: accountId,
          isNewCustomer,
        },
      });

      if (selectedEventId) {
        window.location.href = `${process.env.GATSBY_TASTING_FLIGHT_HOST}`;
        return;
      }

      const customerValues = {
        email,
        phone: mobilePhone,
        accountId,
        firstName,
        lastName,
        fullName: `${firstName} ${lastName}`,
        hostCustomerType: 'New Customer',
      };
      handleSelectedCustomer(customerValues);
    })
    .catch(async (errors: any) => {
      const errorMessage = errors?.graphQLErrors[0]?.message;
      if (
        errorMessage.includes(
          'To shop for yourself, simply click the event link',
        ) ||
        errorMessage.includes(
          'Sorry, this user is a CE and cannot be added as your customer',
        ) ||
        errorMessage.includes(
          'Customer is already shopping with a different Cause Entrepreneur. Please contact support@onehopewine.com for assistance',
        )
      ) {
        setErrorNotification(errorMessage);
        setErrors({ email: 'Please choose another email' });
      } else {
        const errorMessageParsed = JSON.parse(errorMessage);
        const existingCustomer = JSON.parse(errorMessageParsed.existingUser);
        if (
          errorMessageParsed.error.includes(
            'An account with this email already exists',
          )
        ) {
          const {
            accountId,
            email,
            firstName,
            lastName,
            mobilePhone,
          } = existingCustomer;
          setExistingCustomer({
            accountId,
            email,
            firstName,
            lastName,
            mobilePhone,
          });
          setErrors({ email: 'A customer with this email already exists' });
        }
      }
      return;
    });
};

export default function AddCustomerForm(props: AddCustomerFormProps) {
  const {
    onClose,
    formTitle,
    defaultValues,
    selectedEventId,
    addCustomerButtonText,
    handleSelectedCustomer,
    existingCustomerButtonText,
  } = props;
  const [shopWithCustomerAdd, { loading: mutationLoading }] = useMutation(
    CustomerAddMutation,
  );
  const [existingCustomer, setExistingCustomer] = useState('');
  const [errorNotification, setErrorNotification] = useState(null);
  const [existingCustomerSelected, setExistingCustomerSelected] = useState(
    false,
  );

  const { trackEventV2 } = useTrackingContextValue();

  const validDefaultValues = { ...defaultValues } as MyFormValues;
  // Workaround; cannot get initial validation on mount to work. So make sure initial values are valid or empty
  // Phone mask works for newly type dvalues but does not update the initial value.
  validDefaultValues.phone = defaultValues.phone
    ? defaultValues.phone.replace(/\D/g, '')
    : '';
  return (
    <div>
      <FormContainer
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={validDefaultValues}
        validationSchema={AddCustomerFormSchema}
        formToUse={(props: any) => (
          <AddCustomer
            {...props}
            onClose={onClose}
            formTitle={formTitle}
            mutationLoading={mutationLoading}
            existingCustomer={existingCustomer}
            setExistingCustomer={setExistingCustomer}
            existingCustomerSelected={existingCustomerSelected}
            setExistingCustomerSelected={setExistingCustomerSelected}
            addCustomerButtonText={addCustomerButtonText}
            existingCustomerButtonText={existingCustomerButtonText}
            errorNotification={errorNotification}
            setErrorNotification={setErrorNotification}
          />
        )}
        onSubmit={async (values: MyFormValues, { setErrors }: any) => {
          await handleAddCustomer({
            values,
            onClose,
            setErrors,
            selectedEventId,
            existingCustomer,
            shopWithCustomerAdd,
            setExistingCustomer,
            setErrorNotification,
            handleSelectedCustomer,
            existingCustomerSelected,
            trackEventV2,
          });
        }}
      />
    </div>
  );
}
