import isAlpha from 'validator/lib/isAlpha';
import { LaunchDates, ohwImageUploads } from './constants';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../queries/generatedTypes/CEDash_EventDetailsPage';
import { eventIsClosed } from '../components/Events/EventsTable/helpers';

const imageUploadPath = process.env.GATSBY_IMAGE_UPLOAD_PATH;
const urlProfile = `${process.env.GATSBY_PROFILE_IMAGE_HOST}/profile/`;
const urlProfileV2 = `https://ohw-image-uploads.s3.amazonaws.com/accounts/${imageUploadPath}`;

export function getCurrentUserId(location: any) {
  const { search } = location;
  const hasCorrectParams = search.includes('user=');
  return hasCorrectParams && search !== '' ? search.split('?user=')[1] : false;
}

export function getMonthName(month: number) {
  switch (month) {
    case 1:
      return 'january';
    case 2:
      return 'february';
    case 3:
      return 'march';
    case 4:
      return 'april';
    case 5:
      return 'may';
    case 6:
      return 'june';
    case 7:
      return 'july';
    case 8:
      return 'august';
    case 9:
      return 'september';
    case 10:
      return 'october';
    case 11:
      return 'november';
    default:
      return 'december';
  }
}

export function validInput(value: string) {
  return !isNaN(+value);
}

export const statesAbbrev = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export enum keyCodes {
  leftArrow = 37,
  rightArrow = 39,
}

export function isStateAbbrev(abbrev: string) {
  return statesAbbrev.indexOf(abbrev) !== -1;
}

export function removeSpaces(text: string): string {
  return text.replace(/\s/g, '');
}

function isNotAlpha(text: string): boolean {
  return !isAlpha(removeSpaces(text));
}

export function checkContainsPOBox(line: string): boolean {
  const pattern = /^box[^a-z]|(p[-. ]?o.?[- ]?|post office )b(.|ox)/i;
  const noExtraSpace = line.replace(/\s+/g, ' ').trim();
  if (noExtraSpace.match(pattern)) return true;
  const noPunctuation = noExtraSpace.replace(
    /[.,\/#!$%\^&\*;:{}=\-_`~()]/g,
    '',
  );
  if (noPunctuation.match(pattern)) return true;
  return false;
}

export function isValidUSZip(usZip: string): boolean {
  return /^\d{5}(-\d{4})?$/.test(usZip);
}

export function checkContainsNumber(addressLineOne: string) {
  const containsDigits = /[0123456789]/.test(addressLineOne);
  const containsNumbers = /one/.test(addressLineOne.toLowerCase());
  return containsNumbers || containsDigits;
}

export function checkContainsLetter(addressLineOne: string) {
  return /[abcdefghijklmnopqrstuvwxyz]/.test(addressLineOne.toLowerCase());
}

export function checkCountry(country: string) {
  if (!country) {
    return true;
  }
  const lowerCountry = country.toLowerCase();
  switch (true) {
    case lowerCountry === 'usa':
    case lowerCountry === 'us':
    case lowerCountry === 'united states':
    case lowerCountry === '':
    case lowerCountry === ' ':
      return true;
    default:
      return false;
  }
}

export function evaluateOrderStatus(orderStatus: string | null | undefined) {
  const status = orderStatus || '';

  // object key/value lookups are quicker than arrays
  const processingStates = {
    processing: true,
    'Compliance Success': true,
    'Compliance Issue': true,
    'Payment Auth': true,
    'Payment Success': true,
    'Payment Issue': true,
    'Order Received': true,
    'Sent to ShipCompliant': true,
  };

  const inTransitStates = {
    'In Transit': true,
    inTransit: true,
    'Delivery Exception': true,
    'Missed Attempt': true,
  };

  const deliveredStates = {
    Delivered: true,
    delivered: true,
  };

  const cancelledStates = {
    Cancelled: true,
    cancelled: true,
  };

  const returnedToSenderStates = {
    'Returned to Sender': true,
  };

  const outForDeliveryStates = {
    'Out For Delivery': true,
  };

  const exceptionStates = {
    Exception: true,
  };

  const missedDeliveryFirstStates = {
    'Missed Delivery: First Attempt': true,
  };

  const missedDeliverySecondStates = {
    'Missed Delivery: Second Attempt': true,
  };

  const missedDeliveryThirdStates = {
    'Missed Delivery: Third Attempt': true,
  };

  const returningToSenderStates = {
    'Returning to Sender': true,
  };

  const returnedStates = {
    'Customer Returned': true,
  };

  const serviceDisruptionStates = {
    'Service Disruption': true,
  };

  const damagedPackageStates = {
    'Damaged Package': true,
  };

  const fulfillmentStates = {
    'Back Ordered': true,
    Hold: true,
    Shipped: true,
  };

  const paymentPendingStates = {
    'Payment Pending': true,
  };

  const completeStates = {
    Complete: true,
  };

  if (deliveredStates[status]) {
    return 'complete';
  } else if (processingStates[status]) {
    return 'processing';
  } else if (fulfillmentStates[status]) {
    return 'inTransit';
  } else if (inTransitStates[status]) {
    return 'inTransit';
  } else if (returnedStates[status]) {
    return 'exception';
  } else if (returnedToSenderStates[status]) {
    return 'exception';
  } else if (cancelledStates[status]) {
    return 'cancelled';
  } else if (exceptionStates[status]) {
    return 'exception';
  } else if (missedDeliveryFirstStates[status]) {
    return 'exception';
  } else if (missedDeliverySecondStates[status]) {
    return 'exception';
  } else if (missedDeliveryThirdStates[status]) {
    return 'exception';
  } else if (returningToSenderStates[status]) {
    return 'exception';
  } else if (outForDeliveryStates[status]) {
    return 'inTransit';
  } else if (serviceDisruptionStates[status]) {
    return 'exception';
  } else if (damagedPackageStates[status]) {
    return 'exception';
  } else if (paymentPendingStates[status]) {
    return 'exception';
  } else if (completeStates[status]) {
    return 'complete';
  }
}

export function isDateBeforeHostKitV2Launch(date: string | Date | null) {
  if (!date) return false;
  return new Date(date) < LaunchDates.hostKitV2;
}

export function isDateBeforeRewardsLaunch(date: string | Date | null) {
  if (!date) return false;
  return new Date(date) < LaunchDates.rewards;
}

export function isDateBeforeTastingFlightsLaunch(date: string | Date | null) {
  if (!date) return false;
  return new Date(date) < LaunchDates.tastingKit;
}

export function getHostKitsV2(hostKitsV2Flag: boolean, event: EventType) {
  const eventClosed = eventIsClosed(event);
  const manualCloseDateBeforeLaunch = isDateBeforeHostKitV2Launch(
    event.manualCloseDate,
  );

  // Covers deprecation requirements
  if (eventClosed && (manualCloseDateBeforeLaunch || !event.manualCloseDate)) {
    return false;
  }
  return hostKitsV2Flag;
}

export const isVirtual = (trinityPartyType: string | null) => {
  return trinityPartyType === 'EPARTY';
};

export function getTastingFlights(event: EventType) {
  const isVirtualEvent = isVirtual(event?.trinityPartyType);
  const createDateBeforeLaunch = isDateBeforeTastingFlightsLaunch(
    event?.createDate,
  );
  return isVirtualEvent && !createDateBeforeLaunch;
}

export function getHostRewardPoints(
  rewardPointsFlag: boolean,
  event: EventType,
) {
  const eventClosed = eventIsClosed(event);
  const manualCloseDateBeforeLaunch = isDateBeforeRewardsLaunch(
    event?.manualCloseDate,
  );

  if (eventClosed && manualCloseDateBeforeLaunch) {
    return false;
  }
  return rewardPointsFlag;
}

export const getImageUrl = (image: string, size: 'thumb' | 'medium') => {
  const imageId = image.match(/(.*)\.[^.]+$/)[1];
  const mimeType = image.match(/[^.]+$/)[0];
  return `${urlProfileV2}/${imageId}_${size}.${mimeType}`;
};

export function ceProfileImage(image?: string) {
  return image ? `${urlProfileV2}/${image}` : '';
}

export const getEventImage = (photo: {
  groupName: string;
  fileName: string;
}) => {
  if (!photo) return null;
  return `${ohwImageUploads}/${photo?.groupName}/${photo?.fileName}`;
};

export const redirectToLoginV2 = (redirectUri: string) => {
  window.location.href = `${process.env.GATSBY_MEGALITH_URL}/login?redirect_uri=${redirectUri}`;
};
