import React from 'react';

const NotificationAlert = (props: any) => (
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.88 9V14.82L18 16.94V18H0V16.94L2.12 14.82V9C2.12 5.75 4.37 3.03 7.41 2.31V1.59C7.41 0.71 8.12 0 9 0C9.88 0 10.59 0.71 10.59 1.59V2.31C13.63 3.03 15.88 5.75 15.88 9ZM9 21C7.9 21 7.01 20.11 7.01 19.01H10.99C10.99 20.11 10.1 21 9 21Z"
      fill="#373634"
    />
    <rect x="11" y="2" width="8" height="8" rx="4" fill="white" />
    <rect x="13" y="4" width="4" height="4" rx="2" fill="#DB4437" />
  </svg>
);

export default NotificationAlert;
