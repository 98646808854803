import React, { useCallback } from 'react';
import getRelativeTime from './helpers/getRelativeTime';
import ActivityEntry from './types/ActivityEntry';

interface DeleteContextProps {
  deleteContext: ActivityEntry['deleteContext'];
  time: ActivityEntry['dateDeleted'];
}

export const DeleteContext = ({ deleteContext, time }: DeleteContextProps) => {
  const getAudienceVerb = useCallback((audienceDirection?: string) => {
    if (!audienceDirection) {
      return null;
    }

    if (audienceDirection === 'audience_enter') {
      return 'entering';
    }
    return 'exiting';
  }, []);

  // Removed {card-name} from {user-name} upon {entering/exiting} {audience-name}
  if (deleteContext) {
    switch (deleteContext.triggerType) {
      default:
        return null;

      case 'cardRule':
        return (
          <div className="change-context">
            <p>
              Removed <b>{deleteContext.templateName}</b> from{' '}
              <b>{deleteContext.userName}</b> upon{' '}
              {getAudienceVerb(
                deleteContext.cardRuleContext?.audienceDirection,
              )}{' '}
              audience <b>{deleteContext.cardRuleContext?.audienceName}</b>{' '}
              &mdash; {getRelativeTime(`${time}Z`)}
            </p>
          </div>
        );

      case 'ttlExpired':
        return (
          <div className="change-context">
            <p>
              Removed <b>{deleteContext.templateName}</b> from{' '}
              <b>{deleteContext.userName}</b> upon <b>card expiration</b>
              {time && <>&mdash; {getRelativeTime(time)}</>}
            </p>
          </div>
        );

      case 'API':
        return (
          <div className="change-context">
            <p>
              Removed <b>{deleteContext.templateName}</b> from{' '}
              <b>{deleteContext.userName}</b> via <b>ONEHOPE API</b>
              {time && <> &mdash; {getRelativeTime(time)}</>}
            </p>
          </div>
        );
    }
  }

  return null;
};
