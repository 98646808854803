import React from 'react';
import Media from 'react-media';
import { MutedHorizontalRule } from '@onehope/design-system';

import BlogCard, { BlogCardProps } from './BlogCard/BlogCard';
import BlogCarousel from './BlogCarousel';

import {
  hrStyle,
  headerStyle,
  headerTitleStyle,
  tabbedHeaderStyles,
  tabStyles,
  tabSpanStyles,
  blogMainContainerStyle,
  simpleCarouselCss,
  activeTabStyles,
  activeTabSpanStyles,
  blogLinkStyle,
} from './BlogStyles';

type Tab = {
  title: string;
  cards: BlogCardProps[];
};

type BlogProps = {
  titleText: string;
  linkText?: string;
  linkHref?: string;
  cards?: BlogCardProps[];
  isTabbed?: boolean;
  tabs?: Tab[];
};
type BlogState = {
  activeTabIndex: number;
};

export default class Blog extends React.Component<BlogProps, BlogState> {
  state = {
    activeTabIndex: 0,
  };

  renderLoading() {
    return <>Loading...</>;
  }

  renderHeader() {
    const { titleText, linkText = '', linkHref = '#' } = this.props;
    return (
      <header css={headerStyle}>
        <span css={headerTitleStyle}>{titleText}</span>
        {linkText && (
          <span css={blogLinkStyle}>
            <a href={linkHref} target="_blank" rel="noopener noreferrer">
              {linkText}
            </a>
          </span>
        )}
      </header>
    );
  }

  renderBody() {
    const { cards = [], isTabbed = false } = this.props;

    const noCards = !cards.length;
    const notTabbed = !isTabbed;
    const noData = noCards && notTabbed;

    if (noData) return this.renderLoading();
    else if (isTabbed) {
      // tabbed mode
      return this.renderTabbedMode();
    } else {
      // simple mode
      return this.renderSimpleMode();
    }
  }

  renderTabsHeader() {
    const { tabs = [] } = this.props;
    const { activeTabIndex } = this.state;
    const tabTitles: string[] = tabs.map(tab => tab.title);
    return (
      <header css={tabbedHeaderStyles}>
        {tabTitles.map((title: string, i: number) => {
          const isActiveTab = i === activeTabIndex;
          const [containerStyles, spanStyles] = isActiveTab
            ? [activeTabStyles, activeTabSpanStyles]
            : [tabStyles, tabSpanStyles];
          return (
            <div
              key={i}
              css={containerStyles}
              onClick={() => this.setState({ activeTabIndex: i })}
            >
              <span css={spanStyles}>{title}</span>
            </div>
          );
        })}
      </header>
    );
  }
  renderActiveTab() {
    const { tabs = [] } = this.props;
    const { activeTabIndex } = this.state;
    const { cards } = tabs[activeTabIndex];
    const children = cards.map((card, i) => <BlogCard {...card} key={i} />);
    return <BlogCarousel children={children} />;
  }

  renderTabbedMode() {
    const { tabs = [] } = this.props;
    if (!tabs.length) return this.renderLoading();

    return (
      <>
        {this.renderTabsHeader()}
        {this.renderActiveTab()}
      </>
    );
  }

  renderSimpleMode() {
    const { cards = [] } = this.props;
    const children = cards.map((card, i) => <BlogCard {...card} key={i} />);

    return <BlogCarousel carouselCss={simpleCarouselCss} children={children} />;
  }

  render() {
    return (
      <>
        {this.renderHeader()}
        <MutedHorizontalRule css={hrStyle} />
        <main css={blogMainContainerStyle}>{this.renderBody()}</main>
      </>
    );
  }
}
