import gql from 'graphql-tag';

const GET_CUSTOMERS_V2_QUERY = gql`
  query CEDash_CustomersV2Query(
    $page: Int
    $filterBy: String
    $sortBy: String
    $desc: Boolean
    $rowsPerPage: Int
    $searchBy: String
  ) {
    viewer {
      id
      user {
        id
        customersV2Count(filterBy: $filterBy, searchBy: $searchBy)
        customersV2(
          page: $page
          filterBy: $filterBy
          sortBy: $sortBy
          desc: $desc
          rowsPerPage: $rowsPerPage
          searchBy: $searchBy
        ) {
          edges {
            node {
              id
              isHost
              isSubscriber
              isPreferredCustomer
              impactTotal
              dateOfBirth
              fullName
              accountId
              email
              mobilePhone
              lifeTimeSpend
              lastOrderDate
              contactType
              organizationName
            }
          }
        }
      }
    }
  }
`;

export default GET_CUSTOMERS_V2_QUERY;
