import React from 'react';
import { ReactMuiMask } from '@onehope/design-system-ohw';
import { TextFieldProps } from '@material-ui/core/TextField';

export function PhoneMask(props: TextFieldProps) {
  return (
    <ReactMuiMask
      mask={[
        '(',
        /[2-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      {...props}
    />
  );
}
