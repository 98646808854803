import { css } from '@emotion/core';

import { hrefStyle } from '../BlogStyles';

const topPaddingVertical = css`
  padding-top: 12px;
`;
const bottomPaddingVertical = css`
  padding-bottom: 12px;
`;

export const blogCardContainerStyle = [
  hrefStyle,
  css`
    color: #000000;
  `,
];

export const blogCardTitleStyle = css`
  height: 26px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #000000;
  padding-bottom: 8px;
  width: 288px;
`;

export const linkStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const blogCardImageContainerStyle = bottomPaddingVertical;
export const blogCardTitleContainerStyle = [
  css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 288px;
  `,
  topPaddingVertical,
];

export const blogCardPublishedStyle = css`
  width: 288px;
  height: 16px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 2px;
  color: #000000;
  padding-top: 8px;
`;

export const blogCardImageStyle = css`
  height: 288px;
  width: 288px;
`;
