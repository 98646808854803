import React, { useState } from 'react';
import AddOrder from './AddOrder';
import { MyFormValues } from './MyFormTypes';
import { AddOrderFormSchema } from './validationSchema';
import FormContainer from '../../Event/FormContainer';

interface AddOrderFormProps {
  isCustomerSelected: boolean;
  mutationLoading: boolean;
  defaultValues: MyFormValues;
  onClose: () => void;
  toggleAddCustomer: (values: MyFormValues) => void;
  setIsCustomerSelected: (bool: boolean) => void;
  handleCustomerSelect: (values: MyFormValues) => void;
}

export default function AddOrderForm(props: AddOrderFormProps) {
  const {
    onClose,
    defaultValues,
    mutationLoading,
    toggleAddCustomer,
    isCustomerSelected,
    handleCustomerSelect,
    setIsCustomerSelected,
  } = props;
  const [existingCustomer, setExistingCustomer] = useState('');
  const [existingCustomerSelected, setExistingCustomerSelected] = useState(
    false,
  );
  return (
    <div>
      <FormContainer
        initialValues={defaultValues}
        validationSchema={AddOrderFormSchema}
        formToUse={(props: any) => (
          <AddOrder
            {...props}
            onClose={onClose}
            toggleAddCustomer={toggleAddCustomer}
            mutationLoading={mutationLoading}
            existingCustomer={existingCustomer}
            setExistingCustomer={setExistingCustomer}
            isCustomerSelected={isCustomerSelected}
            setIsCustomerSelected={setIsCustomerSelected}
            handleCustomerSelect={handleCustomerSelect}
            existingCustomerSelected={existingCustomerSelected}
            setExistingCustomerSelected={setExistingCustomerSelected}
          />
        )}
        onSubmit={async (values: MyFormValues, { setErrors }: any) => {
          await handleCustomerSelect(values);
        }}
      />
    </div>
  );
}
