import gql from 'graphql-tag';

const GET_CUSTOMERS_BY_SEARCH_QUERY = gql`
  query CEDash_CustomersBySearchQuery(
    $search: String
    $onlyForSearch: Boolean
  ) {
    viewer {
      id
      user {
        id
        email
        customers(
          first: 10
          sortBy: "any"
          search: $search
          onlyForSearch: $onlyForSearch
        ) {
          edges {
            node {
              accountId
              firstName
              lastName
              email
              mobilePhone
              organizationName
            }
          }
        }
      }
    }
  }
`;

export default GET_CUSTOMERS_BY_SEARCH_QUERY;
