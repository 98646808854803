import React from 'react';
import { Helmet } from 'react-helmet';
import { ApolloConsumer } from 'react-apollo';
import { PageRendererProps } from 'gatsby';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';

import LoginRedirectWrapper from '../components/MainLayout/loginRedirect/loginRedirectWrapper';
import {
  Login,
  GoogleOauthResponse,
  getGoogleInputVariables,
  types,
  getGoogleInputVariablesRegistration,
  FacebookOauthResponse,
  getFacebookInputVariablesRegistration,
  getFacebookInputVariables,
} from '@onehope/login';

import {
  suffix,
  verifyEmail,
  loginMutation,
  registerMutation,
  sendRecoveryEmailMutation,
} from '../utils/authentication';
import { getPageTitle } from '../utils/site';
import { trackEvent } from '../utils/segment/tracking';

import { LoginMutationVariables } from '../mutations/Authentication/LoginMutation';
import { redirectToLoginV2 } from '../utils/utils';
const { TypeKeys, LoginPageDictionary } = types;
export type VerifyEmailPayload = {
  verifyEmail: {
    hasFacebook: boolean;
    hasGoogle: boolean;
    isAccount: boolean;
    hasPassword: boolean;
    isCE: boolean;
    isAdmin: boolean;
    firstName: string | null;
  };
  hasError: boolean;
};

type AppVariables = {
  loginMutation: (
    variables: LoginMutationVariables,
  ) => Promise<void | {
    error: any;
  }>;
  verifyEmail: (email: string) => Promise<VerifyEmailPayload>;
};

const pageInfo = () => (
  <Helmet>
    <title>{getPageTitle('Login')}</title>
  </Helmet>
);

function responseGoogle(
  // App-level concerns
  { verifyEmail, loginMutation }: AppVariables,
) {
  return (
    // Login component-level concerns
    setError: React.Dispatch<React.SetStateAction<string>>,
    dispatch: React.Dispatch<any>,
  ) => {
    return (googleResponse: GoogleOauthResponse) => {
      const email = googleResponse.profileObj.email;
      return verifyEmail(email)
        .then(res => {
          const { isCE, isAccount } = res.verifyEmail;
          // if (!isAccount) {
          //   const { givenName, familyName } = googleResponse.profileObj;
          //   dispatch({
          //     type: TypeKeys.UPDATE_NAME,
          //     firstName: givenName,
          //     lastName: familyName,
          //   });
          //   dispatch({
          //     type: TypeKeys.UPDATE_SSO_REGISTRATION_INFO,
          //     ssoRegistrationInput: getGoogleInputVariablesRegistration(
          //       googleResponse,
          //     ),
          //   });
          //   dispatch({
          //     type: TypeKeys.CHANGE_PAGE,
          //     page: LoginPageDictionary.ENTER_AGE,
          //   });
          //   return;
          // }
          if (!isCE) {
            return setError('You must have CE permissions to login');
          }
          const inputVariables = getGoogleInputVariables(googleResponse);
          return loginMutation(inputVariables);
        })
        .catch(err => console.log('err', err));
    };
  };
}

function responseFacebook(
  // App-level concerns
  { verifyEmail, loginMutation }: AppVariables,
) {
  return (
    // Login component-level concerns
    setError: React.Dispatch<React.SetStateAction<string>>,
    dispatch: React.Dispatch<any>,
  ) => {
    return (facebookResponse: FacebookOauthResponse) => {
      const { email } = facebookResponse;
      return verifyEmail(email)
        .then(res => {
          const { isCE, isAccount } = res.verifyEmail;
          // if (!isAccount) {
          //   const ssoRegistrationInput = getFacebookInputVariablesRegistration(
          //     facebookResponse,
          //   );
          //   const { firstName, lastName } = ssoRegistrationInput.input;
          //   dispatch({
          //     type: TypeKeys.UPDATE_SSO_REGISTRATION_INFO,
          //     ssoRegistrationInput,
          //   });
          //   dispatch({
          //     type: TypeKeys.UPDATE_NAME,
          //     firstName,
          //     lastName,
          //   });
          //   dispatch({
          //     type: TypeKeys.CHANGE_PAGE,
          //     page: LoginPageDictionary.ENTER_AGE,
          //   });
          //   return;
          // }
          if (!isCE) {
            return setError('You must have CE permissions to login');
          }
          const inputVariables = getFacebookInputVariables(facebookResponse);
          return loginMutation(inputVariables);
        })
        .catch(err => console.log('err', err));
    };
  };
}

function LoginPage(props: PageRendererProps) {
  // https://www.gatsbyjs.org/docs/environment-variables/#example
  const { GATSBY_S3_ASSETS } = process.env;
  const { GATSBY_FACEBOOK_APP_ID } = process.env;
  const { GATSBY_GOOGLE_CLIENT_ID } = process.env;

  const flags = useFlags();

  if (flags?.loginEnhancements) {
    const redirectUrl = encodeURI(props.location.href);
    return redirectToLoginV2(redirectUrl);
  }

  if (!GATSBY_S3_ASSETS) return <div>We can't pull images, {suffix}</div>;
  if (!GATSBY_FACEBOOK_APP_ID)
    return <div>We can't login using Facebook, {suffix}</div>;
  if (!GATSBY_GOOGLE_CLIENT_ID)
    return <div>We can't login using Google, {suffix}</div>;
  const redirectUri =
    new URLSearchParams(props.location.search).get('redirect_uri') || '/';
  const originUrl = props.location.origin;

  return (
    <LoginRedirectWrapper>
      {pageInfo()}
      <ApolloConsumer>
        {client => {
          // client.query({ query: GET_USER_QUERY });
          const login = loginMutation(client, redirectUri);
          const verify = verifyEmail(client);
          return (
            <React.Fragment>
              <Login
                originUrl={originUrl}
                assetsUrl={GATSBY_S3_ASSETS}
                facebookAppId={GATSBY_FACEBOOK_APP_ID}
                googleClientId={GATSBY_GOOGLE_CLIENT_ID}
                verifyEmail={verifyEmail(client)}
                loginMutation={loginMutation(client, redirectUri)}
                sendRecoveryEmailMutation={sendRecoveryEmailMutation(client)}
                registerMutation={registerMutation(client)}
                responseGoogle={responseGoogle({
                  verifyEmail: verify,
                  loginMutation: login,
                })}
                responseFacebook={responseFacebook({
                  verifyEmail: verify,
                  loginMutation: login,
                })}
              />
            </React.Fragment>
          );
        }}
      </ApolloConsumer>
    </LoginRedirectWrapper>
  );
}

export default withLDProvider({
  clientSideID: process.env.GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: {
    anonymous: true,
  },
})(LoginPage);
