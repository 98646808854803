import * as React from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';

const {
  cssConstants: { font, primaryColor, textColor, neutral5, neutral34 },
} = s;

const UncheckedIcon = styled(RadioButtonUncheckedIcon)`
  && {
    fill: ${neutral34};
  }
`;

const RadioButtonIcon = styled(Radio)`
  && {
    margin-right: -10px;
    :hover,
    &.Mui-checked:hover {
      background-color: rgba(26, 26, 26, 0.1);
    }
    &.MuiRadio-colorPrimary,
    &.MuiRadio-colorSecondary {
      color: ${primaryColor};
    }
  }
`;

const RadioButtonRowIcon = styled(Radio)`
  && {
    margin-right: -18px;
    :hover,
    &.Mui-checked:hover {
      background-color: rgba(26, 26, 26, 0.1);
    }
    &.MuiRadio-colorPrimary,
    &.MuiRadio-colorSecondary {
      color: ${primaryColor};
    }
  }
`;

const RadioButtonContainer = styled.div<{ isSelected: boolean }>`
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${font};
  color: ${textColor};
  border-radius: 4px;
  border: ${({ isSelected }) =>
    isSelected ? `solid 1px ${neutral5}` : `solid 1px ${primaryColor}`};
  background-color: ${({ isSelected }) => (isSelected ? neutral5 : 'white')};
  padding-left: 16px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  margin-bottom: 8px;
`;

const RadioButtonRowContainer = styled.div<{ isSelected: boolean }>`
  height: 38px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ${font};
  color: ${({ isSelected }) => (isSelected ? neutral34 : textColor)};
  border-radius: 4px;
  background-color: 'white';
  /* padding-left: 16px; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  margin-bottom: 23px;
  margin-right: 24px;
  @media all and (min-width: 968px) {
    margin-right: 40px;
  }
`;

const RadioButtonRowGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  ...props
}) => {
  return (
    <label htmlFor={id}>
      <RadioButtonContainer isSelected={id !== value}>
        <label htmlFor={id}>{label}</label>
        <FormControlLabel
          label=""
          onChange={onChange}
          control={
            <RadioButtonIcon
              name={name}
              id={id}
              value={id}
              color="primary"
              checkedIcon={<CheckCircleIcon />}
              icon={<RadioButtonUncheckedIcon />}
              disabled={props.form.isSubmitting}
              checked={id === value}
              onChange={onChange}
              onBlur={onBlur}
              {...props}
            />
          }
        />
      </RadioButtonContainer>
    </label>
  );
};

export const RadioButtonRow = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  ...props
}) => {
  return (
    <label htmlFor={id}>
      <RadioButtonRowContainer isSelected={id !== value}>
        <FormControlLabel
          label=""
          onChange={onChange}
          control={
            <RadioButtonRowIcon
              name={name}
              id={id}
              value={id}
              color="primary"
              checkedIcon={<RadioButtonCheckedOutlinedIcon />}
              icon={<UncheckedIcon />}
              disabled={props.form.isSubmitting}
              checked={id === value}
              onChange={onChange}
              onBlur={onBlur}
              {...props}
            />
          }
        />
        <label htmlFor={id}>{label}</label>
      </RadioButtonRowContainer>
    </label>
  );
};

export const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  label,
  children,
}) => {
  return (
    <div>
      <fieldset>{children}</fieldset>
    </div>
  );
};

export const RadioButtonRowGroup = ({
  value,
  error,
  touched,
  id,
  label,
  children,
}) => {
  return (
    <fieldset>
      <RadioButtonRowGroupContainer>{children}</RadioButtonRowGroupContainer>
    </fieldset>
  );
};
