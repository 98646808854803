import React from 'react';
import { Layout, Table } from 'antd';
const { Content } = Layout;
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import _get from 'lodash/get';
import Authentication from '../../Authentication/Authentication';
import TrackingProvider from '../../../contexts/TrackingContext';

const GET_CE_DASHBOARD_INFO_QUERY = gql`
  query CeDashboardInfoQuery {
    viewer {
      id
      isLoggedIn
      user {
        id
        userId
        firstName
        lastName
        email
        trinityDealerId
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
      }
    }
  }
`;

interface MainContentProps {
  children: React.ReactNode;
  selectedUserId: string;
  innerWidth: number;
  siderOpen: boolean;
}

const contentStyle = {
  minHeight: '100vh',
};

const displayNone = { display: 'none' };

const MainContent: React.FunctionComponent<MainContentProps> = ({
  children,
  siderOpen,
  innerWidth,
}) => (
  <Query query={GET_CE_DASHBOARD_INFO_QUERY}>
    {({ loading, error, refetch, data }) => {
      if (error) return `Error! ${error.message}`;
      if (loading) return <Content style={contentStyle} />;
      if (!data || !data.viewer) return <Authentication />;

      const isLoggedInAsCeo = _get(
        data.viewer.user,
        'permissions.oneCommerce.ceo.access',
      );

      const style = siderOpen && innerWidth < 600 ? displayNone : contentStyle;
      if (!isLoggedInAsCeo) {
        return (
          <Content style={style}>
            <Authentication />
          </Content>
        );
      }
      return (
        <Content style={style}>
          <TrackingProvider viewer={data.viewer}>{children}</TrackingProvider>
        </Content>
      );
    }}
  </Query>
);

export default MainContent;
