import * as React from 'react';
import styled from '@emotion/styled';
import { Currency } from '@onehope/design-system';
import SvgAlert from '../../../../assets/alert';
import {
  DetailsContainer,
  DetailsTitle,
  StraightLine,
  Line,
  Key,
  Value,
} from '../index.styles';

const SvgContainer = styled.div`
  padding-top: 24px;
`;

interface TrinityHostRewardsRedeemedProps {
  trinityHostRewardsOrderId: string;
  hostRewardsQualifiedSubTotal: number;
}

export default function TrinityHostRewardsRedeemed(
  props: TrinityHostRewardsRedeemedProps,
) {
  const { trinityHostRewardsOrderId, hostRewardsQualifiedSubTotal } = props;
  if (!trinityHostRewardsOrderId) {
    return null;
  }

  return (
    <DetailsContainer>
      <DetailsTitle>Details</DetailsTitle>
      <StraightLine />
      <Line>
        <Key>Status:</Key>
        <Value>Redeemed</Value>
      </Line>
      <Line>
        <Key>Event orders subtotal:</Key>
        <Value>
          <Currency rawValue={hostRewardsQualifiedSubTotal} />
        </Value>
      </Line>
      <SvgContainer>
        <SvgAlert></SvgAlert>
      </SvgContainer>
      <Line>
        <Value>
          The Host Rewards for this event were created and redeemed in your
          backoffice party portal. Please use the order ID # below for your
          reference.
        </Value>
      </Line>
      <Line>
        <Key>Backoffice order ID #:</Key>
        <Value>{trinityHostRewardsOrderId}</Value>
      </Line>
    </DetailsContainer>
  );
}
