import React, { useState, Dispatch, SetStateAction } from 'react';
import { MuiButton } from '@onehope/design-system-ohw';
import DialogRoot from '@material-ui/core/Dialog';
import styled from '@emotion/styled';
import Close from '@material-ui/icons/Close';
import { styles as s } from '@onehope/design-system';
import copy from 'copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';

const { cssConstants } = s;

const Dialog = styled(DialogRoot)`
  &.MuiDialog-root {
    /* z-index: 9000 !important; */
  }
`;

const CloseDialog = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-top: -16px;
  margin-right: -8px;
`;

const Url = styled.div`
  font-size: 15px;
  font-weight: bold;
  line-height: 40px;
  padding-bottom: 10px;
  width: 100%;
  word-wrap: break-word;
  margin: 5% 0;
`;

const Title = styled.div`
  font-size: 40px;
  text-align: center;
  line-height: 50px;
  width: 100%;
  margin: 5% 0;
  font-family: ${cssConstants.tertiaryFont};
`;

const Container = styled.div`
  font-family: ${cssConstants.font};
  padding: 32px;
  margin: 0 auto;
  width: 100%;
  word-wrap: break-word;
`;

const CreateEventButton = styled(MuiButton)`
  && {
    width: 100%;
    margin-bottom: 5%;
  }
`;

type EventCopyUrlDialogProps = {
  setIsCopyUrlDialogOpen: (input: boolean) => void;
  setSelectedTrinityPartyId: Dispatch<SetStateAction<string>>;
  isOpen: boolean;
  selectedTrinityPartyId: string;
  selectedEventId: string;
  isSatellite?: boolean;
  groupFundraiserSlug?: string;
  trinityDealerId?: string;
};

export default function EventCopyUrlDialog(props: EventCopyUrlDialogProps) {
  const [copySuccess, setCopySuccess] = useState(false);

  const {
    setIsCopyUrlDialogOpen,
    setSelectedTrinityPartyId,
    isOpen,
    selectedTrinityPartyId,
    selectedEventId,
    isSatellite,
    groupFundraiserSlug,
    trinityDealerId,
  } = props;

  const urlId = !!selectedTrinityPartyId
    ? selectedTrinityPartyId
    : selectedEventId;
  const url = isSatellite
    ? `${process.env.GATSBY_HOPECOMMERCE_URL}/${groupFundraiserSlug}?dealer=${trinityDealerId}`
    : `${process.env.GATSBY_MEGALITH_URL}/event/${urlId}`;
  function handleClose() {
    setCopySuccess(false);
    setIsCopyUrlDialogOpen(false);

    // wait to clear state until after dialog is closed
    // otherwise it will show the eventId link briefly
    !isOpen && setSelectedTrinityPartyId('');
  }

  function handleCopy() {
    copy(url);
    setCopySuccess(!copySuccess);
  }

  function handleVisitPage() {
    window.open(url, '_blank');
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Container>
        <CloseDialog>
          <IconButton
            aria-label="close"
            aria-controls="close icon"
            aria-haspopup="true"
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </CloseDialog>
        <Title>Event Page URL</Title>
        <Url>{url}</Url>
        {copySuccess && <div>Copy Success</div>}
        <CreateEventButton type="PRIMARY" onClick={handleCopy}>
          Copy Url
        </CreateEventButton>
        <CreateEventButton type="SECONDARY" onClick={handleVisitPage}>
          VIEW EVENT PAGE
        </CreateEventButton>
      </Container>
    </Dialog>
  );
}
