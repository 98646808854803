import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { navigate } from 'gatsby';
import get from 'lodash/get';
import ContactAddMutation from '../../../mutations/Customer/ContactAddMutation';
import NoteAddMutation from '../../../mutations/Note/NoteAddMutation';
import { MyFormValues } from './MyFormTypes';
import { AddContactFormSchema } from './validationSchema';
import AddContact from './AddContact';
import FormContainer from '../../Event/FormContainer';
import { getContactDetailsToSubmit, cannotBeAdded } from './helpers';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';
import { useEffect } from 'react';
import { trackEvent } from '../../../utils/segment/tracking';

interface AddContactFormProps {
  onClose: () => void;
  defaultValues: MyFormValues;
}

const handleAddContact = async ({
  values,
  contactAdd,
  noteAdd,
  trackContacts,
  onClose,
  setErrors,
  existingCustomer,
  setExistingCustomer,
  setErrorNotification,
}: any) => {
  await contactAdd({
    variables: {
      input: { form: getContactDetailsToSubmit(values) },
    },
  })
    .then((data: any) => {
      const contact = get(data, 'data.contactAdd.contact');

      if (values?.note) {
        noteAdd({
          variables: {
            input: {
              text: values?.note,
              contactAccountId: contact.accountId,
            },
          },
        })
          .then(() => {
            trackContacts({
              eventName: 'CE Created Contact',
              contact,
            });
            return navigate(`/contact?contactId=${contact?.accountId}&tab=0`);
          })
          .catch(async (errors: any) => {
            console.log(errors);
          });
      } else {
        trackContacts({
          eventName: 'CE Created Contact',
          contact,
        });
        return navigate(`/contact?contactId=${contact?.accountId}&tab=0`);
      }
    })
    .catch(async (errors: any) => {
      const errorMessage = errors?.graphQLErrors[0]?.message;
      if (cannotBeAdded(errorMessage)) {
        setErrorNotification(errorMessage);
        setErrors({ email: true });
      } else {
        const errorMessageParsed = JSON.parse(errorMessage);
        const existingCustomer = JSON.parse(errorMessageParsed.existingUser);
        if (
          errorMessageParsed.error.includes(
            'An account with this email already exists',
          )
        ) {
          const {
            accountId,
            email,
            firstName,
            lastName,
            mobilePhone,
          } = existingCustomer;
          setExistingCustomer({
            accountId,
            email,
            firstName,
            lastName,
            mobilePhone,
          });
          setErrors({ email: true });
        }
      }
      return;
    });
};

export default function AddContactForm(props: AddContactFormProps) {
  const { onClose, defaultValues } = props;
  const [contactAdd, { loading: contactAddMutationLoading }] = useMutation(
    ContactAddMutation,
  );
  const [noteAdd, { loading: noteAddMutationLoading }] = useMutation(
    NoteAddMutation,
  );

  const { trackContacts } = useTrackingContextValue();

  const [existingCustomer, setExistingCustomer] = useState('');
  const [errorNotification, setErrorNotification] = useState(null);
  const [existingCustomerSelected, setExistingCustomerSelected] = useState(
    false,
  );

  useEffect(() => {
    if (
      process.env.GATSBY_BUILD_ENV === 'staging' ||
      process.env.GATSBY_BUILD_ENV === 'development'
    ) {
      trackContacts({
        eventName: 'Contact Creation Started',
      });
    }
  }, []);

  return (
    <div>
      <FormContainer
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={defaultValues}
        validationSchema={AddContactFormSchema}
        formToUse={(props: any) => (
          <AddContact
            {...props}
            onClose={onClose}
            mutationLoading={
              contactAddMutationLoading || noteAddMutationLoading
            }
            existingCustomer={existingCustomer}
            setExistingCustomer={setExistingCustomer}
            existingCustomerSelected={existingCustomerSelected}
            setExistingCustomerSelected={setExistingCustomerSelected}
            errorNotification={errorNotification}
            setErrorNotification={setErrorNotification}
          />
        )}
        onSubmit={async (values: MyFormValues, { setErrors }: any) => {
          await handleAddContact({
            values,
            onClose,
            setErrors,
            existingCustomer,
            contactAdd,
            noteAdd,
            trackContacts,
            setExistingCustomer,
            setErrorNotification,
            existingCustomerSelected,
          });
        }}
      />
    </div>
  );
}
