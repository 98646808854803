/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import { DateTime } from 'luxon';
import IconMenu from './IconMenu';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system-ohw';
import { MuiButton } from '@onehope/design-system-ohw';
import Tooltip from '@material-ui/core/Tooltip';

import SubscriberSvg from '../../assets/subscriber';
import PCCustomerSvg from '../../assets/pcCustomer';
import HostSvg from '../../assets/host';
import { formatPhone } from '../Events/EventDetailsForm/EventDetailsForm';
const { cssConstants } = s;

const PreferredCustomer = styled.span`
  svg {
    height: 30px;
    width: 30px;
  }
`;

const Host = styled.span`
  svg {
    height: 30px;
    width: 30px;
  }
`;

const Subscriber = styled.span`
  svg {
    height: 30px;
    width: 30px;
  }
`;

const Link = styled.a`
  color: ${cssConstants.primaryColor} !important;
  font-weight: 600;
`;

const OrderStatusIconContainer = styled.div`
  display: flex;
  align-items: center;
  //justify-content: center;
  svg {
    height: 28px;
    width: 28px;
  }
`;

function getCustomerType(customerType: string | null) {
  switch (customerType) {
    case 'subscriber':
      return (
        <Tooltip title="Subscriber" placement="top">
          <Subscriber>
            <SubscriberSvg />
          </Subscriber>
        </Tooltip>
      );
    case 'preferredCustomer':
      return (
        <Tooltip title="Preferred Customer" placement="top">
          <PreferredCustomer>
            <PCCustomerSvg />
          </PreferredCustomer>
        </Tooltip>
      );
    case 'subscriberPreferredCustomer':
      return (
        <>
          <Tooltip title="Subscriber" placement="top">
            <Subscriber>
              <SubscriberSvg />
            </Subscriber>
          </Tooltip>
          <Tooltip title="Preferred Customer" placement="top">
            <PreferredCustomer>
              <PCCustomerSvg />
            </PreferredCustomer>
          </Tooltip>
        </>
      );
    case 'subscriberPreferredCustomerHost':
      return (
        <>
          <Tooltip title="Subscriber" placement="top">
            <Subscriber>
              <SubscriberSvg />
            </Subscriber>
          </Tooltip>
          <Tooltip title="Preferred Customer" placement="top">
            <PreferredCustomer>
              <PCCustomerSvg />
            </PreferredCustomer>
          </Tooltip>
          <Tooltip title="Host" placement="top">
            <Host>
              <HostSvg />
            </Host>
          </Tooltip>
        </>
      );
    case 'subscriberHost':
      return (
        <>
          <Tooltip title="Subscriber" placement="top">
            <Subscriber>
              <SubscriberSvg />
            </Subscriber>
          </Tooltip>
          <Tooltip title="Host" placement="top">
            <Host>
              <HostSvg />
            </Host>
          </Tooltip>
        </>
      );
    case 'preferredCustomerHost':
      return (
        <>
          <Tooltip title="Preferred Customer" placement="top">
            <PreferredCustomer>
              <PCCustomerSvg />
            </PreferredCustomer>
          </Tooltip>
          <Tooltip title="Host" placement="top">
            <Host>
              <HostSvg />
            </Host>
          </Tooltip>
        </>
      );
    case 'host':
      return (
        <Tooltip title="Host" placement="top">
          <Host>
            <HostSvg />
          </Host>
        </Tooltip>
      );
    default:
      return <span>&mdash;</span>;
  }
}

interface RenderProps {
  contactId?: string | null | undefined;
  email?: string | null | undefined;
  handleOrderAdd: (contactId: string) => void;
}

interface Column {
  id:
    | 'selected'
    | 'menu'
    | 'fullName'
    | 'email'
    | 'mobilePhone'
    | 'dateOfBirth'
    | 'customerType'
    | 'lifeTimeSpend'
    | 'lastOrderDate'
    | 'orderAdd';
  label?: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'right' | 'center';
  format?: (value: string, contactId?: string | null | undefined) => void;
  render?: (renderProps: RenderProps) => any;
  customerType?: (props: { customerType: string }) => any;
}

const columns: Column[] = [
  {
    id: 'fullName',
    label: 'Name',
    align: 'left',
    minWidth: 200,
    format: (value: string | null | undefined) =>
      value ? value.toLocaleString() : '\u2014',
    // ? <Link to={`/event?eventId=${eventId}`}>
    //     {value.toLocaleString()}
    //   </Link>
    // : '',
  },
  {
    id: 'customerType',
    label: 'Type',
    align: 'left',
    minWidth: 130,
    // format: formatDonationStatus,
    customerType: customerType => getCustomerType(customerType),
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    minWidth: 130,
    format: (value: string | null | undefined) =>
      value ? (
        <Link
        // href={`mailto:${value}`}
        // target="_blank"
        // rel="noopener noreferrer"
        >
          {value.toLocaleString()}
        </Link>
      ) : (
        '\u2014'
      ),
    // ? <Link to={`/event?eventId=${eventId}`}>
    //     {value.toLocaleString()}
    //   </Link>
    // : '',
  },
  {
    id: 'mobilePhone',
    label: 'Phone #',
    align: 'left',
    minWidth: 150,
    format: (value: string | null | undefined) =>
      value ? formatPhone(value.toLocaleString()) : '\u2014',
    // ? <Link to={`/event?eventId=${eventId}`}>
    //     {value.toLocaleString()}
    //   </Link>
    // : '',
  },
  {
    id: 'lifeTimeSpend',
    label: 'Lifetime spend',
    align: 'left',
    minWidth: 180,
    format: (value: string | null | undefined) =>
      value ? `$${parseFloat(value).toFixed(2)}` : '\u2014',
    // ? <Link to={`/event?eventId=${eventId}`}>
    //     {value.toLocaleString()}
    //   </Link>
    // : '',
  },
  {
    id: 'lastOrderDate',
    label: 'Last order',
    align: 'left',
    minWidth: 150,
    format: (value: string | null | undefined) =>
      value
        ? DateTime.fromISO(value)
            .toUTC()
            .toLocaleString(DateTime.DATE_SHORT)
        : '\u2014',
  },
  // {
  //   id: 'dateOfBirth',
  //   label: 'Birthday',
  //   align: 'left',
  //   format: (value: string | null | undefined) =>
  //     value
  //       ? DateTime.fromFormat(value, 'M/d/yyyy').toLocaleString(
  //           DateTime.DATE_SHORT,
  //         )
  //       : null,
  // },
  {
    id: 'orderAdd',
    minWidth: 0,
    align: 'left',
    render: ({ contactId, email, handleOrderAdd }: RenderProps) => (
      <MuiButton
        type="ROUND"
        onClick={email ? () => handleOrderAdd(email) : () => {}}
      >
        Order
      </MuiButton>
    ),
  },
  // {
  //   id: 'orderStatus',
  //   label: 'Status',
  //   align: 'left',
  //   // format: formatDonationStatus,
  //   format: value => {
  //     const { icon, status } = getOrderStatusIcon(value);
  //     return value
  //       ? <OrderStatusIconContainer>
  //           {icon}
  //         </OrderStatusIconContainer>
  //       : '';
  //   },
  // },
  {
    id: 'menu',
    minWidth: 0,
    align: 'right',
    render: ({
      contactId,
      email,
      handleOrderAdd,
    }: {
      contactId: string;
      email: string;
      handleOrderAdd: (contactId: string) => void;
    }) => (
      <IconMenu
        isCardView={false}
        contactId={contactId}
        email={email}
        handleOrderAdd={handleOrderAdd}
      />
    ),
  },
];

export default columns;
