import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { navigate } from 'gatsby';
import { get } from 'lodash';

// Components
import UploadImage, { ImageValuesType } from './UploadImage';
import Charity from './Charity';
import Details from './Details';
import Wine from './Wine';

// Mutations
import EventCreateMutation from '../../../mutations/Event/EventCreateMutation';
import PRE_APPROVED_DONATION_ADD from '../../../mutations/Donations/PreApprovedDonationAdd';
import CUSTOM_DONATION_ADD from '../../../mutations/Donations/DonationsAddMutation';
import IMAGE_ADD_MUTATION from '../../../mutations/Image/ImageAddMutation';
import GET_IMAGE_PRESIGNED_URL_MUTATION from '../../../mutations/Image/ImagePresignedUrlGetMutation';

// Helpers
import {
  getEventDetailsToSubmit,
  uploadImage,
  getDisplayPreferences,
} from '../../Events/EventDetailsForm/submitHelpers';
import { saveDonation } from '../../Events/EventDetailsForm/submitHelpers';
import { getWineDetailsToSubmit } from '../../Event/TabContent/Wine/helpers';

import { useTrackingContextValue } from '../../../contexts/TrackingContext';
import { clearLocalStorage } from './ClearLocalStorage';

import { getInitialValues as getInitialDetailsValues } from '../../Event/TabContent/Details/helpers';
import { getInitialValues as getInitialCharityValues } from '../../Event/TabContent/Charity/helpers';

// Types
import { MyFormValues as CharityFormValues } from '../../Event/TabContent/Charity/FormTypes';
import { FormValues as WineFormValues } from '../../Event/TabContent/Wine/FormTypes';
import { CEDash_EventsQuery_viewer_user as UserType } from '../../../queries/generatedTypes/CEDash_EventsQuery';

interface EventCreateStepperProps {
  createEventTab: number;
  handelDialogClose: () => void;
  user: UserType;
}

export default function EventCreateStepper(props: EventCreateStepperProps) {
  const [form, setForm] = React.useState({});
  const { createEventTab, handelDialogClose } = props;
  const [detailsFormValues, setDetailsFormValues] = useState(
    getInitialDetailsValues(),
  );
  const [charityFormValues, setCharityFormValues] = useState(
    getInitialCharityValues(),
  );
  const [imageValues, setImageValues] = useState({
    imageCanvas: '',
    mimeType: '',
  } as ImageValuesType);
  const [
    eventCreate,
    { loading: mutationEventCreateMutationLoading },
  ] = useMutation(EventCreateMutation);
  const [
    preApprovedDonationAdd,
    { loading: preApprovedDonationMutationLoading },
  ] = useMutation(PRE_APPROVED_DONATION_ADD);
  const [
    customDonationAdd,
    { loading: customDonationMutationLoading },
  ] = useMutation(CUSTOM_DONATION_ADD);
  const [imageAddMutation] = useMutation(IMAGE_ADD_MUTATION);
  const [getPresignedUrl] = useMutation(GET_IMAGE_PRESIGNED_URL_MUTATION);

  const { trackEventsV2 } = useTrackingContextValue();

  const { hostKitsV2 } = useFlags();

  async function handleBookEvent({
    charityValues,
    wineValues,
    setErrorNotification,
  }: {
    charityValues?: CharityFormValues;
    wineValues?: WineFormValues;
    setErrorNotification?: React.Dispatch<React.SetStateAction<string>>;
  }) {
    if (
      !mutationEventCreateMutationLoading &&
      !preApprovedDonationMutationLoading &&
      !customDonationMutationLoading
    ) {
      const eventDetails = getEventDetailsToSubmit({
        values: detailsFormValues,
      });
      const wineDetails = wineValues
        ? getWineDetailsToSubmit(
            wineValues,
            eventDetails?.trinityPartyType,
            new Date().toString(),
          )
        : {};
      const charityVals = charityValues || charityFormValues;
      const displayPreferences = getDisplayPreferences({
        event: null,
        values: {
          ...detailsFormValues,
          ...charityVals,
        },
      });
      const input = {
        form: {
          ...eventDetails,
          ...wineDetails,
          displayPreferences,
          hostQuote: charityVals?.charityQuote || '',
        },
      };

      let eventId = '';
      await eventCreate({
        variables: { input },
      })
        .then(async data => {
          const {
            data: { eventCreate },
          } = data;
          eventId = eventCreate.eventId;
          const newEvent = get(data, 'data.eventCreate.event');

          // Save Donation separately from event
          const updatedEventData = await saveDonation({
            eventId,
            newEvent,
            values: charityVals,
            customDonationAdd,
            preApprovedDonationAdd,
          });

          if (imageValues?.imageCanvas) {
            await uploadImage({
              imageAddMutation,
              getPresignedUrl,
              values: imageValues,
              groupName: `events/${process.env.GATSBY_IMAGE_UPLOAD_PATH}`,
              authorId: eventId,
              imageType: 'event',
            });
          }

          // Send tracking when it's all complete.
          const eventData = updatedEventData ?? newEvent;
          trackEventsV2({
            eventName: 'Event Create Completed',
            newEvent: eventData,
            formValues: { ...charityVals, ...(wineValues ? wineValues : {}) },
          });
        })
        .then(() => {
          const isManualAddress =
            localStorage.getItem('manualAddress') === 'true';
          const isManualCharityAddress =
            localStorage.getItem('manualCharityAddress') === 'true';
          const isManualNonProfit =
            localStorage.getItem('manualNonProfit') === 'true';
          clearLocalStorage();
          localStorage.setItem('eventCreated', 'true');
          if (isManualAddress)
            localStorage.setItem(`manualAddress${eventId}`, 'true');
          if (isManualCharityAddress)
            localStorage.setItem(`manualCharityAddress${eventId}`, 'true');
          if (isManualNonProfit)
            localStorage.setItem(`manualNonProfit${eventId}`, 'true');
          return navigate(`/event?eventId=${eventId}&tab=0`);
        })
        .catch((errors: any) => {
          const errorMessage = errors?.graphQLErrors[0]?.message;
          if (errorMessage.includes('Not Found') && setErrorNotification) {
            setErrorNotification(
              'These selections are no longer valid - please update your choices and try again.',
            );
          }
          return;
        });
    }
  }

  function handleNextStepChange(
    key: string,
    values: any,
    setErrorNotification?: React.Dispatch<React.SetStateAction<string>>,
  ) {
    switch (key) {
      case 'details': {
        setDetailsFormValues(values);
        return navigate(`/events?createEventStep=${createEventTab + 1}`);
      }
      case 'charity': {
        setCharityFormValues(values);
        if (hostKitsV2) {
          return navigate(`/events?createEventStep=${createEventTab + 1}`);
        }
        return handleBookEvent({ charityValues: values });
      }
      case 'imageUpload': {
        setImageValues(values);
        return navigate(`/events?createEventStep=${createEventTab + 1}`);
      }
      case 'wine': {
        return handleBookEvent({
          wineValues: values,
          setErrorNotification,
        });
      }
      default:
        return;
    }
  }

  function handlePreviousStepChange() {
    if (createEventTab > 0) {
      return navigate(`/events?createEventStep=${createEventTab - 1}`);
    } else {
      clearLocalStorage();
      return handelDialogClose();
    }
  }
  switch (createEventTab) {
    case 1:
      return (
        <UploadImage
          form={form}
          handleNextStepChange={handleNextStepChange}
          handlePreviousStepChange={handlePreviousStepChange}
        />
      );

    case 2:
      return (
        <Charity
          form={form}
          handleNextStepChange={handleNextStepChange}
          handlePreviousStepChange={handlePreviousStepChange}
        />
      );
    case 3:
      if (hostKitsV2) {
        return (
          <Wine
            user={props.user}
            form={form}
            handleNextStepChange={handleNextStepChange}
            handlePreviousStepChange={handlePreviousStepChange}
          />
        );
      }
      break;
    default:
      return (
        <Details
          form={form}
          handleNextStepChange={handleNextStepChange}
          handlePreviousStepChange={handlePreviousStepChange}
        />
      );
  }
}
