import React from 'react';
import styled from '@emotion/styled';
import { MuiButton } from '@onehope/design-system-ohw';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  padding-top: 36px;
  @media all and (min-width: 768px) {
    max-width: 736px;
    flex-direction: row;
  }
`;

export const Spacer = styled.span`
  margin: 8px 0;
  @media all and (min-width: 768px) {
    margin: 0 8px;
  }
`;

interface SaveButtonsProps {
  handleSave: () => void;
  handleCancel: () => void;
  saveButtonText: string;
  cancelButtonText: string;
  disableSave: boolean;
}

export default function SaveButtons(props: SaveButtonsProps) {
  return (
    <Container>
      <MuiButton type="SECONDARY" fullWidth onClick={props.handleCancel}>
        {props.cancelButtonText}
      </MuiButton>
      <Spacer />
      <MuiButton
        fullWidth
        type="PRIMARY"
        disabled={props.disableSave}
        onClick={props.handleSave}
      >
        {props.saveButtonText}
      </MuiButton>
    </Container>
  );
}
