import React, { useEffect, useState } from 'react';
import { handleFilterByTab } from '../../../../common/Orders/helpers';
import OrdersView from '../../../../common/Orders';
import { CEDash_GuestOrdersQuery_viewer_user_orders as Orders } from '../../../../queries/generatedTypes/CEDash_GuestOrdersQuery';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';

interface OrdersPageWrapperProps {
  orders: Orders;
  event: EventType;
}

function OrdersPageWrapper(props: OrdersPageWrapperProps) {
  const ordersEdges = props.orders.edges;
  const openOrders = handleFilterByTab(ordersEdges, 'open');
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);
  const [orders, setOrders] = useState(openOrders);
  const [allOrders, setAllOrders] = useState(ordersEdges);

  // useEffect(
  //   () => {
  //     setOrders(openOrders);
  //     setAllOrders(ordersEdges);
  //   },
  //   [props.orders],
  // );

  return (
    <OrdersView
      setOrders={setOrders}
      orders={orders}
      event={props.event}
      allOrders={allOrders}
      isOrderDialogOpen={isOrderDialogOpen}
      setIsOrderDialogOpen={setIsOrderDialogOpen}
    />
  );
}

export default OrdersPageWrapper;
