import React, { useState, useEffect, useContext, useCallback } from 'react';

import ImpactToDate from './ImpactToDate';
import MyImpactProgress from './MyImpactProgress';
import MyMentor from './MyMentor';
import MyTraining from './MyTraining';
import Blog from './Blog';

import { widgetContainer, widgetContainerDouble } from './DashboardStyles';
import { TrackingContext } from '../../contexts/TrackingContext';
import { CEDash_DashboardQuery_viewer_user } from '../../queries/generatedTypes/CEDash_DashboardQuery';
import Header from './ActionEngine/Header';
import { Dialog, Grid, makeStyles, ThemeType } from '@onehope/design-system-v2';
import ReactPlayer from 'react-player';
import { ActivityEntry, ButtonActionModal } from '@onehope/action-engine';

interface DashboardProps {
  user: CEDash_DashboardQuery_viewer_user;
  workRampInfo: any;
  ceImpactData: any;
  ceImpactGoals: any;
  ceId: any;
  tasks: any;
  noCoursesInProgress: any;
  fastStartCheck: any;
  mentorInfo: any;
  customUrl: any;
  fastStartEndDate: any;
  mostRecentPosts: any;
  featuredPosts: any;
  location: any;
  classes: any;
}

interface DashboardState {
  openDialog: boolean;
  playbackId: string | undefined;
}

const styles = makeStyles((theme: ThemeType) => ({
  cardsMobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      gridRow: 1,
    },
  },
  greetingMobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  splitWidgets: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(360px, 1fr))',
    gridGap: '24px',
    flexFlow: 'row wrap',
    margin: '24px',

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, minmax(320px, 1fr))',
      margin: '0px',
      rowGap: '24px',
    },
    marginBottom: '80px',
  },
  player: ({ ratio }) => ({
    width: ratio === '9:16' ? '360px' : '640px',
    height: ratio === '9:16' ? '640px' : '360px',
    backgroundColor: 'white',
    margin: 0,
    padding: 0,
    overflowY: 'hidden !important',
    overflowX: 'hidden !important',
  }),
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '640px !important',
    },
  },
  buttonActionModal: {
    height: 'auto',
    width: '460px',
  },
}));

const WidgetContainerDouble = ({ Widget }: WidgetType) => (
  <div css={[widgetContainer, widgetContainerDouble]}>
    <Widget />
  </div>
);

const Dashboard = (props: DashboardProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [playbackId, setPlaybackId] = useState('');
  const [buttonAction, setButtonAction] = useState({});
  const [ratio, setRatio] = useState('9:16');
  const context = useContext(TrackingContext);

  useEffect(() => {
    // const { context } = TrackingContext;
    const { trackPageView } = context;
    trackPageView({ page: 'DASHBOARD' });
  });

  const onDialogClose = useCallback(() => {
    setOpenDialog(false);
    setButtonAction({});
    setPlaybackId('');
  }, [setOpenDialog, setButtonAction, setPlaybackId]);

  const onOpenVideoDialog = useCallback(
    (playbackId: string | undefined, ratio: string | undefined) => {
      setOpenDialog(true);
      if (playbackId) setPlaybackId(playbackId);
      if (ratio) setRatio(ratio);
    },
    [playbackId, ratio],
  );

  const onOpenButtonActionDialog = useCallback(
    (button: ActivityEntry['buttonAction']) => {
      if (typeof button !== 'undefined') {
        setButtonAction(button);
        setOpenDialog(true);
      }
    },
    [],
  );

  const {
    user,
    workRampInfo,
    ceImpactData,
    ceImpactGoals,
    ceId,
    tasks,
    noCoursesInProgress,
    fastStartCheck,
    mostRecentPosts,
    featuredPosts,
    location,
  } = props;
  const classes = styles({ ratio, buttonAction });

  return (
    <React.Fragment>
      <>
        <Header
          user={user}
          openVideoDialog={onOpenVideoDialog}
          openButtonActionDialog={onOpenButtonActionDialog}
          openDialog={openDialog}
        />
        <Dialog
          open={openDialog}
          onClose={onDialogClose}
          className={classes.dialog}
        >
          {playbackId && (
            <Grid className={classes.player}>
              <ReactPlayer
                url={`https://stream.mux.com/${playbackId}.m3u8`}
                width="100%"
                height="100%"
                light={`https://image.mux.com/${playbackId}/thumbnail.jpg?fit_mode=smartcrop&width=600&height=200`}
                controls={true}
                playing={true}
                muted={false}
              />
            </Grid>
          )}
          {buttonAction?.action && (
            <Grid className={classes.buttonActionModal}>
              <ButtonActionModal
                button={buttonAction}
                onDialogClose={onDialogClose}
              />
            </Grid>
          )}
        </Dialog>
      </>
      <div className={classes.splitWidgets}>
        <ImpactToDate ceImpactData={ceImpactData} />
        <MyImpactProgress
          ceId={ceId}
          user={user}
          ceImpactData={ceImpactData}
          ceImpactGoals={ceImpactGoals}
        />
        <MyMentor user={user} />
        <MyTraining
          {...workRampInfo}
          inFastStart={fastStartCheck}
          noCoursesInProgress={noCoursesInProgress}
        />
        <WidgetContainerDouble
          Widget={() => (
            <Blog
              titleText="The VINE"
              linkText="View all"
              linkHref={`${process.env.GATSBY_VINE_URL}`}
              isTabbed
              tabs={[
                {
                  title: 'Most recent',
                  cards: mostRecentPosts,
                },
                {
                  title: 'Featured',
                  cards: featuredPosts,
                },
              ]}
            />
          )}
        />
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
