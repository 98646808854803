import React from 'react';

const SvgCalendar = (props: any) => (
  <svg width="18px" height="18px" viewBox="0 0 15 20" {...props}>
    <defs>
      <path
        d="M26.2 10.636h-.9V9h-1.8v1.636h-9V9h-1.8v1.636h-.9c-.99 0-1.8.737-1.8 1.637v13.09c0 .9.81 1.637 1.8 1.637h14.4c.99 0 1.8-.736 1.8-1.636V12.273c0-.9-.81-1.637-1.8-1.637zm0 14.728H11.8V14.727h14.4v10.637zM14.5 18.9a.9.9 0 1 0 0-1.8.9.9 0 0 0 0 1.8zm4.5 0a.9.9 0 1 0 0-1.8.9.9 0 0 0 0 1.8zm4.5 0a.9.9 0 1 0 0-1.8.9.9 0 0 0 0 1.8zm-9 4.5a.9.9 0 1 0 0-1.8.9.9 0 0 0 0 1.8zm4.5 0a.9.9 0 1 0 0-1.8.9.9 0 0 0 0 1.8zm4.5 0a.9.9 0 1 0 0-1.8.9.9 0 0 0 0 1.8z"
        id="calendar_svg__a"
      />
    </defs>
    <g transform="translate(-12 -8)" fill="none" fillRule="evenodd">
      <mask id="calendar_svg__b" fill="#fff">
        <use xlinkHref="#calendar_svg__a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#calendar_svg__a" />
      <g mask="url(#calendar_svg__b)" fill="#1A1A1A">
        <path d="M0 0h39v40H0z" />
      </g>
    </g>
  </svg>
);

export default SvgCalendar;
