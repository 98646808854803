import React from 'react';

const PCCustomerSvg = (props: any) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40" {...props}>
    <defs>
      <path
        id="c"
        d="M19.917 8a.564.564 0 00-.495.577v1.704a.57.57 0 00.275.498.547.547 0 00.561 0 .57.57 0 00.276-.498V8.577a.574.574 0 00-.163-.41A.55.55 0 0019.97 8h-.052zm-7.51 2.556a.558.558 0 00-.47.352.577.577 0 00.096.588l2.504 3.123a.556.556 0 00.96-.162.583.583 0 00-.091-.566l-2.503-3.122a.552.552 0 00-.444-.213h-.052zm15.02 0a.554.554 0 00-.374.213L24.55 13.89a.583.583 0 00-.092.566.556.556 0 10.96.161l2.504-3.122a.577.577 0 00.082-.62.555.555 0 00-.525-.32h-.052zm-7.493 3.415a.553.553 0 01.522.266l2.103 3.487 3.93.798a.56.56 0 01.418.395c.058.2.004.416-.14.563l-2.869 2.928.521 3.983a.57.57 0 01-.215.522.547.547 0 01-.55.073l-3.676-1.606-3.677 1.606a.547.547 0 01-.55-.073.573.573 0 01-.215-.522l.522-3.983-2.869-2.928a.576.576 0 01-.14-.563.56.56 0 01.419-.395l3.928-.798 2.104-3.487a.555.555 0 01.434-.266zm11.474 3.398a.546.546 0 00-.122.018l-1.669.284a.559.559 0 00-.446.36.577.577 0 00.098.573.55.55 0 00.54.184l1.668-.283a.566.566 0 00.522-.604.561.561 0 00-.591-.532zm-22.912.009a.564.564 0 00-.496.555c-.004.287.2.531.478.572l1.67.283a.55.55 0 00.54-.184.577.577 0 00.097-.573.559.559 0 00-.447-.36l-1.668-.284a.545.545 0 00-.174-.01zm18.4 5.669a.562.562 0 00-.53.497.57.57 0 00.33.594l3.617 1.703a.547.547 0 00.564-.039.572.572 0 00-.095-.99l-3.616-1.703a.547.547 0 00-.27-.062zm-13.906.009a.547.547 0 00-.2.053l-3.616 1.703a.572.572 0 00-.095.99.547.547 0 00.564.04l3.616-1.704a.571.571 0 00.306-.656.558.558 0 00-.575-.426zm6.927 4.817a.564.564 0 00-.495.577v3.974a.57.57 0 00.275.499.547.547 0 00.561 0 .57.57 0 00.276-.499V28.45a.574.574 0 00-.163-.41.55.55 0 00-.402-.167h-.052zm-5.537.568a.555.555 0 00-.443.284l-.834 1.42a.58.58 0 00-.01.568c.097.178.279.29.478.293.2.003.385-.102.488-.276l.834-1.42a.578.578 0 00.006-.596.553.553 0 00-.519-.273zm11.126 0a.556.556 0 00-.466.305.578.578 0 00.023.564l.834 1.42c.103.174.288.28.488.276a.556.556 0 00.478-.293.58.58 0 00-.01-.569l-.834-1.42a.554.554 0 00-.513-.283z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="d" fill="#fff">
        <use xlinkHref="#c" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#c" />
      <g fill="#1A1A1A" mask="url(#d)">
        <path d="M0 0h40v40H0z" />
      </g>
    </g>
  </svg>;
export default PCCustomerSvg;
