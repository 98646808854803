import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { handleFilterByTab } from '../../../../common/Orders/helpers';
import OrdersView from '../../../../common/Orders';
import { CEDash_GuestOrdersQuery_viewer_user_orders as Orders } from '../../../../queries/generatedTypes/CEDash_GuestOrdersQuery';
import { CEDash_CustomerDetailsPage_viewer_customer as ContactType } from '../../../../queries/generatedTypes/CEDash_CustomerDetailsPage';
import CreateConciergeServiceMutation from '../../../../mutations/Account/CreateConciergeServiceMutation';
import { useTrackingContextValue } from '../../../../contexts/TrackingContext';
import { segmentEvents } from '../../../../utils/segment/constants';
import { SegmentEventPropsV2 } from '../../../../utils/segment/SegmentTrackingV2';

interface OrderForCustomerProps {
  createConciergeService: any;
  email: string | null;
  productCatalogPageV2: boolean;
  trackEventV2: (params: SegmentEventPropsV2) => void;
}

const handleOrderForCustomer = ({
  email,
  createConciergeService,
  productCatalogPageV2,
  trackEventV2,
}: OrderForCustomerProps) => {
  return () => {
    const variables = {
      input: {
        customerEmail: email,
      },
    };
    return createConciergeService({ variables })
      .then((res: any) => {
        const conciergeService = res?.data?.createConciergeService;
        if (!conciergeService) return;

        // segment tracking
        const isNewCustomer = conciergeService.newCustomer?.ordersCount === 0;
        const customerId = conciergeService.user?.conciergeCustomerAccountId;
        trackEventV2({
          event: segmentEvents.conciergeModeOrderStarted,
          properties: {
            eventId: null,
            shoppingForId: customerId,
            isNewCustomer,
          },
        });

        if (productCatalogPageV2) {
          window.location.href = `${process.env.GATSBY_PRODUCT_CATALOG_PAGE_URL}`;
        } else {
          window.location.href = `${process.env.GATSBY_HOPECOMMERCE_URL}/shop/wine/wine`;
        }

        return;
      })
      .catch(console.error);
  };
};

interface OrdersPageWrapperProps {
  contact: ContactType;
  orders: Orders;
}

function OrdersPageWrapper(props: OrdersPageWrapperProps) {
  const { productCatalogPageV2 } = useFlags();
  const { trackEventV2 } = useTrackingContextValue();

  const ordersEdges = props.orders.edges;
  const openOrders = handleFilterByTab(ordersEdges, 'open');
  const [orders, setOrders] = useState(openOrders);
  const [allOrders, setAllOrders] = useState(ordersEdges);
  const [createConciergeService, { loading: mutationLoading }] = useMutation(
    CreateConciergeServiceMutation,
  );
  // useEffect(
  //   () => {
  //     setOrders(openOrders);
  //     setAllOrders(ordersEdges);
  //   },
  //   [props.orders],
  // );

  const dispatchHandleOrderForCustomer = handleOrderForCustomer({
    email: props.contact.email,
    createConciergeService,
    productCatalogPageV2,
    trackEventV2,
  });

  const contactName =
    props.contact?.fullName || props.contact?.organizationName;
  return (
    <OrdersView
      setOrders={setOrders}
      orders={orders}
      allOrders={allOrders}
      contactName={contactName}
      handleOrderForCustomer={dispatchHandleOrderForCustomer}
    />
  );
}

export default OrdersPageWrapper;
