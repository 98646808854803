import React, { Component } from 'react';
import { navigate } from 'gatsby';

import Layout from '../components/MainLayout/layout/layout';

export default function IndexPage() {
  if (typeof window !== 'undefined') {
    window.location.href = '/dashboard';
  }
  return null;
}
