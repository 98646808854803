import gql from 'graphql-tag';

const mutation = gql`
  mutation EventCreateMutation($input: EventCreateInput!) {
    eventCreate(input: $input) {
      eventId
      user {
        id
        numberOfAvailableCredits
        availableStarterKitCredits
      }
      event {
        id
        eventId
        eventDate
        closeDate
        eventAddress {
          addressLineOne
          addressLineTwo
          city
          state
          zip
          phone
        }
        hostFirstName
        hostLastName
        hostFullName
        hostPhone
        hostEmail
        hostAccountId
        hostQuote
        trinityPartyType
        trinityPartyId
        timeZone
        displayPreferences {
          id
          donationGoal
          supporters
        }
        donationId
        donationInfo {
          id
          ordersSubTotal
          donationAmount
          donationGoal
          hostKitDonation
          donationType
          orderDonationAmount
          status
          lobMemo
          nonProfit {
            id
            nonProfitName
            nonProfitTaxId
            nonProfitId
            nonProfitPayeeName
            isPreApproved
            nonProfitAddress {
              optionalName
              addressLineOne
              addressLineTwo
              city
              state
              zip
              phoneNumber
            }
          }
        }
      }
    }
  }
`;

export default mutation;
