import React from 'react';
import styled from '@emotion/styled';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { tabFilterStyles } from './TabFilter.styles';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 16px 24px 0 24px;
  flex-direction: column;
  @media all and (min-width: 960px) {
    padding: 0 24px 0 24px;
    flex-direction: row;
  }
`;

type TabFilterProps = {
  filterBy: string;
  setFilterBy: (value: string) => void;
  tabValues: Array<string>;
  tabLabels?: Array<string>;
};

function TabFilter(props: TabFilterProps) {
  const { filterBy, setFilterBy, tabValues, tabLabels } = props;
  const classes = tabFilterStyles();
  const {
    root,
    tabSelected,
    tabsIndicator,
    tabRootLeft,
    tabRootRight,
    tabRootMiddleLeft,
    tabRootMiddleRight,
  } = classes;
  const tabClassesRight = { root: tabRootRight, selected: tabSelected };
  const tabClassesLeft = { root: tabRootLeft, selected: tabSelected };
  const tabClassesMiddleLeft = {
    root: tabRootMiddleLeft,
    selected: tabSelected,
  };
  const tabClassesMiddleRight = {
    root: tabRootMiddleRight,
    selected: tabSelected,
  };

  const handleTabChange = (e: React.FormEvent<EventTarget>, value: string) => {
    setFilterBy(value);
  };

  const getClassesForTabs = (index: number, totalTabCount: number) => {
    /**
     * ex. tabPlacement
     * [0, 4] = the first of 4 tabs
     * [1, 4] = the second of 4 tabs
     */
    const tabPlacement = [index, totalTabCount];
    switch (tabPlacement) {
      case [0, 2]:
      case [0, 3]:
      case [0, 4]:
        return tabClassesLeft;
      case [1, 2]:
      case [1, 3]:
      case [1, 4]:
        return tabClassesMiddleLeft;
      case [2, 4]:
        return tabClassesMiddleRight;
      case [2, 3]:
      case [3, 4]:
        return tabClassesRight;
      default:
        return tabClassesLeft;
    }
  };

  const totalTabs = (tabValues && tabValues.length) || 0;

  return (
    <Container>
      <Tabs
        style={{ minHeight: 'unset' }}
        key="TabFilter"
        value={filterBy}
        scrollButtons="off"
        variant="scrollable"
        onChange={handleTabChange}
        classes={{ indicator: tabsIndicator }}
      >
        {tabValues &&
          tabValues.length &&
          tabValues.map((val, index) => {
            return (
              <Tab
                value={val}
                label={(tabLabels && tabLabels[index]) || val}
                classes={getClassesForTabs(index, totalTabs)}
              />
            );
          })}
      </Tabs>
    </Container>
  );
}

export default TabFilter;
