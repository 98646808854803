import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import styled from '@emotion/styled';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { styles as s } from '@onehope/design-system';

const Notification = styled(Snackbar)`
  && {
    &.MuiSnackbar-anchorOriginBottomCenter {
      //position: absolute;
      //bottom: 20%;
      transform: translateX(-15%);
      @media only screen and (max-width: 600px) {
        transform: translateX(0%);
      }
    }
  }
`;

const { cssConstants } = s;

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  success: {
    backgroundColor: cssConstants.successColor,
  },
  error: {
    backgroundColor: cssConstants.errorColor,
  },
  info: {
    backgroundColor: cssConstants.linkColor,
  },
  warning: {
    backgroundColor: cssConstants.warningColor,
  },
}));

export interface Props {
  message?: string;
  open: boolean;
  handleClose?: () => void;
  variant: keyof typeof variantIcon;
}

export default function MySnackbarContentWrapper(props: Props) {
  // @ts-ignore
  const classes = useStyles();
  const { message, handleClose, variant, open, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <Notification open={open} onClose={handleClose} autoHideDuration={6000}>
      <SnackbarContent
        className={classes[variant] || classes.success}
        aria-describedby="client-snackbar"
        message={<span id="client-snackbar">{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
        {...other}
      />
    </Notification>
  );
}
