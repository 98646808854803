import * as React from 'react';
import styled from '@emotion/styled';
import CardListView from '../../TableComponents/CardListView';
import { CEDash_CustomersQuery_viewer_user_customers_edges as ContactsEdges } from '../../../queries/generatedTypes/CEDash_CustomersQuery';
import Checkbox from '@material-ui/core/Checkbox';
import { SvgArchive } from '../../../assets/archive';
import { styles as s } from '@onehope/design-system';
import { useCheckBoxToggleContext } from '../../Contacts/CheckboxToggleContext';
import { useArchiveContactsContext } from '../../Contacts/ArchiveContactsContext';

const { cssConstants } = s;

const ArchiveButton = styled.span`
  font-size: 14px;
  font-family: ${cssConstants.font};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #0091ea;
  padding-left: 12px;
`;
const Selected = styled.div`
  margin-left: 6px;
  height: 36px;
  display: flex;
  align-items: center;
  font-family: ${cssConstants.font};
`;
const ArchiveWarningDiv = styled.div`
  font-family: ${cssConstants.font};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
`;
const ArchiveWarningContainer = styled.div`
  display: flex;
  height: 40px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-family: ${cssConstants.font};
  background-color: #ddd;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  @media all and (max-width: 600px) {
    height: 60px;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const clearSelected = `Clear selection`;
const ArchiveContact = styled.span`
  :hover {
    cursor: pointer;
  }
`;

const CheckBoxHeaderContainer = styled.div`
  display: flex;
  height: 50px;
  padding: 2;
  align-items: center;
`;

interface CardListViewProps {
  contacts: (ContactsEdges | null)[] | null;
  handleOrderAdd: (contactId: string) => void;
}
export default function CardListViewContainer(props: CardListViewProps) {
  const { handleOrderAdd, contacts } = props;
  /* contactIds on the same page */
  const contactIds = contacts?.map(contact => contact?.node?.accountId);
  const { isCheckBoxActivated } = useCheckBoxToggleContext();

  const {
    handleArchiveContact,
    handleArchiveAllContacts,
    handleContacts,
    selectedContacts,
    setAllSelectedTrue,
    setAllSelectedFalse,
    allSelected,
  } = useArchiveContactsContext();
  const contactCount = contacts ? contacts.length : 0;
  const allContacts = props.contacts?.length;
  const archiveWarningMsg = `All ${
    allSelected ? props?.contacts?.length : contactCount
  } contacts ${allSelected ? '' : 'on this page'} are selected`;
  const archiveAllMsg = `Select all ${allContacts} contacts`;
  const selectAllMsg = `Select all (${contactCount})`;
  const selectedMsg = `Selected (${
    allSelected ? props?.contacts?.length : selectedContacts.length
  })`;
  const zeroSelectedMsg = `0 contacts have been selected`;

  const isAllSelectedOnPage =
    selectedContacts.length === contacts?.length ? true : false;
  return (
    <React.Fragment>
      {isCheckBoxActivated && (
        <CheckBoxHeaderContainer>
          <Checkbox
            checked={isAllSelectedOnPage && allContacts}
            onClick={() => handleContacts(contactIds, isAllSelectedOnPage)}
          />
          <Selected>
            {selectedContacts.length >= 1 ? selectedMsg : selectAllMsg}
          </Selected>
          {selectedContacts.length >= 1 && (
            <>
              <ArchiveContact>
                <SvgArchive
                  onClick={
                    allSelected
                      ? () => handleArchiveAllContacts(contactIds, allContacts)
                      : () => {
                          handleArchiveContact(selectedContacts, allContacts);
                        }
                  }
                />
              </ArchiveContact>
              <ArchiveButton
                onClick={
                  allSelected
                    ? () => handleArchiveAllContacts(contactIds, allContacts)
                    : () => {
                        handleArchiveContact(selectedContacts, allContacts);
                      }
                }
              >
                Archive selected
              </ArchiveButton>
            </>
          )}
        </CheckBoxHeaderContainer>
      )}
      {isCheckBoxActivated && isAllSelectedOnPage && (
        <ArchiveWarningContainer>
          <ArchiveWarningDiv>
            {contacts?.length === 0 ? zeroSelectedMsg : archiveWarningMsg}
          </ArchiveWarningDiv>
          {props?.contacts?.length > contactCount ? (
            !allSelected ? (
              <ArchiveButton onClick={setAllSelectedTrue}>
                {archiveAllMsg}
              </ArchiveButton>
            ) : (
              <ArchiveButton onClick={setAllSelectedFalse}>
                {clearSelected}
              </ArchiveButton>
            )
          ) : (
            ''
          )}
        </ArchiveWarningContainer>
      )}
      <CardListView
        callbackFn={handleOrderAdd}
        listItems={contacts}
        baseRoute={'contacts'}
      />
    </React.Fragment>
  );
}
