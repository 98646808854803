import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import get from 'lodash/get';
import queryString from 'query-string';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Loading from '../../common/Loading';
import CredtsPageWrapper from './CreditsWrapper';
import CreditsAvailable from './CreditsAvailable';
import HowItWorks from './HowItWorks';
import GET_CREDITS_QUERY from '../../queries/Credits';

const DisplayFlex = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

function CreditsPage() {
  let { page } = queryString.parse(window.location.search);
  if (!page) page = 1;
  const [pageNumber, setPageNumber] = useState(page);
  const [filterBy, setFilterBy] = useState(null);
  const [sort, setSort] = useState({ sortBy: 'dateCreated', desc: true });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSortCredits = (sortBy: string) => {
    setSort({
      sortBy,
      desc: !sort.desc,
    });
  };

  const { data, loading, error } = useQuery(GET_CREDITS_QUERY, {
    variables: {
      page: Number(page),
      filterBy,
      sortBy: sort.sortBy,
      desc: sort.desc,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (error) return null;
  if (get(data, 'viewer.user.credits')) {
    const {
      credits,
      numberOfAvailableCredits,
      numberOfTotalCredits,
    } = data.viewer.user;

    return (
      <>
        {isMobile ? (
          <>
            <CreditsAvailable
              numberOfAvailableCredits={numberOfAvailableCredits}
              isMobile={isMobile}
            ></CreditsAvailable>
            <HowItWorks></HowItWorks>
          </>
        ) : (
          <DisplayFlex>
            <CreditsAvailable
              numberOfAvailableCredits={numberOfAvailableCredits}
            ></CreditsAvailable>
            <HowItWorks></HowItWorks>
          </DisplayFlex>
        )}
        <CredtsPageWrapper
          credits={credits}
          pageNumber={Number(pageNumber)}
          filterBy={filterBy ? filterBy : 'all'}
          setPageNumber={setPageNumber}
          setFilterBy={setFilterBy}
          handleSortCredits={handleSortCredits}
          numberOfTotalCredits={numberOfTotalCredits}
        />
      </>
    );
  }
  return <Loading />;
}

export default CreditsPage;
