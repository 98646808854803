// @ts-ignore
import * as Yup from 'yup';

export const WineFormSchema = Yup.object().shape({
  wineNeeded: Yup.string().required('Required'),
  hostKitCredits: Yup.string().when('hostKitQuantity', {
    is: (quantity: any) => quantity === '6',
    then: Yup.string().oneOf(['0', '1']),
    otherwise: Yup.string().oneOf(['0', '1', '2']),
  }),
});
