export const config = [
  {
    label: 'Details',
    value: 0,
    cypressData: 'contact-details-tab-button',
  },
  {
    label: 'Notes',
    value: 1,
    cypressData: 'contact-notes-tab-button',
  },
  {
    label: 'Orders',
    value: 2,
    cypressData: 'contact-orders-tab-button',
  },
  {
    label: 'Events',
    value: 3,
    cypressData: 'contact-events-tab-button',
  },
];
