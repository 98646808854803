import React from 'react';
import { formatDistance } from 'date-fns';

import {
  blogCardContainerStyle,
  blogCardImageStyle,
  blogCardTitleStyle,
  blogCardPublishedStyle,
  blogCardImageContainerStyle,
  blogCardTitleContainerStyle,
  linkStyle,
} from './BlogCardStyles';

export type BlogCardProps = {
  title: string;
  postHref: string;
  publishedDate: string | Date;
  previewImgHref: string;
};

export default ({
  title,
  postHref,
  publishedDate,
  previewImgHref,
}: BlogCardProps) => (
  <div css={blogCardContainerStyle}>
    <a
      href={postHref}
      css={linkStyle}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div css={blogCardImageContainerStyle}>
        <img css={blogCardImageStyle} src={previewImgHref} alt="" />
      </div>
      <div css={blogCardTitleContainerStyle}>
        <span css={blogCardTitleStyle}>{title}</span>
      </div>
      <div css={blogCardPublishedStyle}>
        {formatDistance(new Date(publishedDate), new Date(), {
          addSuffix: true,
        })}
      </div>
    </a>
  </div>
);
