import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
const { cssConstants } = s;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 0 auto;
  font-weight: bold;
  font-size: 11px;
  line-height: 1.45;
  padding: 0 16px;
  font-family: ${cssConstants.font};
`;

export const PromoContainer = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  background-color: #f0f9fe;
  color: ${cssConstants.textColor};
  padding: 16px 24px;
  cursor: pointer;
  a {
    padding: 0 8px;
    text-decoration: underline;
    color: ${cssConstants.textColor};
  }
`;

export const SatelliteContainer = styled(Container)`
  padding: 0;
`;

export const SatellitePromoContainer = styled(PromoContainer)`
  margin: 0;
  padding: 8px;
  text-align: left;
  flex-wrap: unset;
  @media only screen and (min-width: 768px) {
    margin: 0 8px 0 27px;
    padding: 16px 24px;
    text-align: center;
    flex-wrap: wrap;
  }
`;

export const SatellitePromoNotificationContainer = styled(PromoContainer)`
  border: 1px solid #46789e;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: left;
  position: relative;
`;
