import React from 'react';
import { DashboardQuery_viewer_user } from '../../../queries/generatedTypes/DashboardQuery';
import myMentorStyles, {
  MyMentorSupportContainer,
  myMentorCardStyles,
  myMentorHeaderStyles,
  myMentorBodyStyles,
  myMentorComingSoonLogo,
  myMentorComingSoonWelcome,
  myMentorComingSoonText,
  myMentorHomeOfficeButton,
  MyMentorHomeOfficeText,
} from './MyMentorStyles';
import { Interval, Duration } from 'luxon';

import myMentorLogo from './onehope_mentor_logo.svg';

import defaultImage from '../empty_profile.svg';
import Envelope from '../../../assets/envelope';
import Phone from '../../../assets/phone';
import GpsPin from '../../../assets/gps_pin';
import Calendar from '../../../assets/calendar';
import { DealerIdDictionary } from '../../../utils/enums';
import { getImageUrl } from '../../../utils/utils';

type MyMentorProps = {
  user: DashboardQuery_viewer_user;
};

const HOME_OFFICE_MENTOR_ID = '813448';
const HOME_OFFICE_NAME = 'Home Office';
const HOME_OFFICE_RANK = 'Cause Entrepreneur';
const HOME_OFFICE_EMAIL = 'support@onehopewine.com';
const HOME_OFFICE_NUMBER = '(949) 329-5576';
const HOME_OFFICE_LOCATION = 'El Segundo, CA';
const HOME_OFFICE_DATE = 'June 1, 2007';

export default class MyMentor extends React.Component<MyMentorProps> {
  getName = (mentor: { fullName: string }, trinityUplineDealerId: string) => {
    if (mentor && mentor.fullName) return mentor.fullName;
    else if (trinityUplineDealerId === HOME_OFFICE_MENTOR_ID)
      return HOME_OFFICE_NAME;
    else return '--';
  };

  getRank = (
    mentor: { trinityCurrentRank: string },
    trinityUplineDealerId: string,
  ) => {
    if (mentor && mentor.trinityCurrentRank)
      return mapAbbreviations(mentor.trinityCurrentRank);
    else if (trinityUplineDealerId === HOME_OFFICE_MENTOR_ID)
      return HOME_OFFICE_RANK;
    else return '--';
  };

  getEmail = (
    mentor: { mentorEmail: string },
    trinityUplineDealerId: string,
  ) => {
    if (mentor && mentor.mentorEmail) return mentor.mentorEmail;
    else if (trinityUplineDealerId === HOME_OFFICE_MENTOR_ID)
      return HOME_OFFICE_EMAIL;
    else return '--';
  };

  getPhone = (
    mentor: { phoneNumber: string },
    trinityUplineDealerId: string,
  ) => {
    if (mentor && mentor.phoneNumber) return mentor.phoneNumber;
    else if (trinityUplineDealerId === HOME_OFFICE_MENTOR_ID)
      return HOME_OFFICE_NUMBER;
    else return '--';
  };

  getTimeAtOneHope = (
    mentor: { trinityEnrollDate: string },
    trinityUplineDealerId: string,
  ) => {
    if (mentor && mentor.trinityEnrollDate) {
      let trinityEnrollDate = new Date(mentor.trinityEnrollDate);
      let beginDay = new Date();
      let time = calculateInterval(trinityEnrollDate, beginDay);
      let { months, years } = time;
      return displayTime(Math.floor(months), Math.floor(years));
    } else if (trinityUplineDealerId === HOME_OFFICE_MENTOR_ID) {
      let trinityEnrollDate = new Date(HOME_OFFICE_DATE);
      let beginDay = new Date();
      let time = calculateInterval(trinityEnrollDate, beginDay);
      let { months, years } = time;
      return displayTime(Math.floor(months), Math.floor(years));
    }
    return '--';
  };

  render() {
    const {
      ce: { mentorInfo },
    } = this.props.user;

    const info = mentorInfo || {};
    const { mentorProfile, trinityUplineDealerId } = info;
    const profile = mentorProfile || {};
    const { image, city, state } = profile;

    if (trinityUplineDealerId === DealerIdDictionary.LEAD_GEN_MENTOR_ID) {
      return <MyMentorComingSoon />;
    }

    const profileImage = image ? getImageUrl(image, 'thumb') : defaultImage;
    const profileImageStyle = {
      background: `url("${profileImage}") center center / cover no-repeat`,
    };

    const mentorFullName = this.getName(info, trinityUplineDealerId);
    const rank = this.getRank(info, trinityUplineDealerId);
    const email = this.getEmail(info, trinityUplineDealerId);
    const phone = this.getPhone(info, trinityUplineDealerId);
    const location =
      city && state
        ? `${city}, ${state}`
        : trinityUplineDealerId === HOME_OFFICE_MENTOR_ID
        ? HOME_OFFICE_LOCATION
        : '--';
    const joinDate = this.getTimeAtOneHope(info, trinityUplineDealerId);
    const showEmailLink = email !== '--';

    return (
      <div css={myMentorStyles}>
        <header>
          <div>My Mentor</div>
        </header>
        <div className="mentor-info">
          <div className="profile-pic-container">
            <div style={profileImageStyle} className="profile-picture" />
          </div>
          <div className="mentor-details">
            <span className="mentor-text">
              <div className="name">{mentorFullName}</div>
              <div className="rank">{rank}</div>
            </span>
          </div>
          <div className="email">
            <Envelope />
            {showEmailLink && (
              <span className="email-info">
                <a href={`mailto:${email}`} rel="noopener noreferrer">
                  {email}
                </a>
              </span>
            )}
            {!showEmailLink && <span className="email-info">{email}</span>}
          </div>
          <div className="phone">
            <Phone />
            <span className="phone-info">{phone}</span>
          </div>
          <div className="city">
            <GpsPin />
            <span className="city-info">{location}</span>
          </div>
          <div className="join-date">
            <Calendar />
            <span className="join-date-info">{joinDate}</span>
          </div>
          <div css={MyMentorSupportContainer}>
            How to reach support?
            <a href="mailto:support@onehopewine.com">
              {' '}
              support@onehopewine.com{' '}
            </a>
            or (949) 329-5576 M-F: 5am to 5pm PST/8am to 8pm EST; Sat: 9am- 12pm
            PST/ 12pm-3pm EST
          </div>
        </div>
      </div>
    );
  }
}

const MyMentorComingSoon = () => (
  <div css={myMentorCardStyles}>
    <header css={myMentorHeaderStyles}>
      <div>My Mentor</div>
    </header>
    <div css={myMentorBodyStyles}>
      <img src={myMentorLogo} alt="" css={myMentorComingSoonLogo} />
      <span css={myMentorComingSoonWelcome}>Welcome!</span>
      <span css={myMentorComingSoonText}>
        We’re working on connecting you with your mentor, but in the meantime,
        please do not hesitate to contact Home Office with any questions or
        needs.
      </span>
      <button css={myMentorHomeOfficeButton}>
        <span css={MyMentorHomeOfficeText}>
          <a
            href="https://helpcenter.onehopewine.com/article/760-when-is-support-available"
            target="_blank"
            rel="noopener noreferrer"
          >
            Message Home Office
          </a>
        </span>
      </button>
    </div>
  </div>
);

function calculateInterval(startDate: Date, endDate: Date): Duration {
  return Interval.fromDateTimes(startDate, endDate).toDuration([
    'years',
    'months',
  ]);
}

function maybePluralize(count: number, noun: string): string {
  let suffix;
  let time;
  suffix = count !== 1 ? 's' : '';
  time = count === 0 ? '' : count + ' ' + noun + suffix;
  return time;
}

function displayTime(months: number, years: number): string {
  if (months === 0) {
    return maybePluralize(years, ' year');
  } else if (years === 0) {
    return maybePluralize(months, 'month');
  } else {
    return `${maybePluralize(years, 'year')}, ${maybePluralize(
      months,
      'month',
    )} at ONEHOPE`;
  }
}

function mapAbbreviations(rank: string): string {
  switch (rank) {
    case 'CE':
      return 'Cause Entrepreneur';
    case 'LCE':
      return 'Lead CE';
    case 'SCE':
      return 'Senior CE';
    case 'ECE':
      return 'Executive CE';
    case 'D':
      return 'Director';
    case 'LD':
      return 'Lead Director';
    case 'SD':
      return 'Senior Director';
    case 'ED':
      return 'Executive Director';
    case 'SED':
      return 'Senior Executive Director';
    case 'NED':
      return 'National Executive Director';
    case '':
      return '';
    default:
      return 'Cause Entrepreneur';
  }
}
