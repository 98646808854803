import gql from 'graphql-tag';

export default gql`
  mutation ShopWithCustomerMutation($input: ShopWithCustomerInput!) {
    shopWithCustomer(input: $input) {
      user {
        id
        email
        role
        firstName
        lastName
        userId
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
        cart {
          id
          taxRate
          taxTotal
          eventId
          totalNumberOfItems
          subTotalPriceOfItems
          promoCodeSavings
          viaQualifiedCartTotal
          subTotalPriceOfItemsWithPromoCodes
          event {
            eventId
            trinityPartyType
            trinityPartyId
            trinityHostId
          }
        }
      }
      newCustomer {
        id
        ordersCount
      }
    }
  }
`;
