import React, { Fragment, Dispatch, SetStateAction, useState } from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system-ohw';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiFormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import IconButton from '@material-ui/core/IconButton';
import MuiAppsIcon from '@material-ui/icons/Apps';
import TabFilter from '../../TableComponents/TabFilter';
import SearchBar from '../../Events/EventsTable/SearchBar';
import TableListView from './TableBody';
import CardListView from '../../TableComponents/CardListView';
import TablePagination from '../../TableComponents/Pagination';
import NoOrders from '../../../common/Orders/NoOrders';
import OrderAddDialog from '../../Events/AddOrderDialog';

import { CEDash_GuestOrdersQuery_viewer_user_orders_edges as OrdersEdges } from '../../../queries/generatedTypes/CEDash_GuestOrdersQuery';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';

const { cssConstants } = s;
const Container = styled.div`
  padding: 24px 16px;
  font-family: ${cssConstants.font};
  a {
    color: ${cssConstants.primaryColor};
  }
  @media all and (min-width: 768px) {
    padding: 40px;
  }
`;

const AppsIcon = styled(MuiAppsIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const FormatListBulletedIcon = styled(MuiFormatListBulletedIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const ToggleView = styled.div`
  width: 54px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  flex-direction: column;
  @media all and (min-width: 960px) {
    flex-direction: row;
    padding-bottom: 40px;
  }
`;

const NoFilteredOrders = styled.div`
  color: ${cssConstants.textColor};
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  padding: 24px 0;
`;

interface OrdersTableProps {
  orders: (OrdersEdges | null)[] | null;
  page: number;
  filterBy: string;
  setPageNumber: Dispatch<SetStateAction<string | string[]>>;
  setFilterBy: Dispatch<SetStateAction<string | null | undefined>>;
  handleSortOrders: (sortBy: string) => void;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  count: number;
  setSearchBy: Dispatch<SetStateAction<string | null | undefined>>;
  isOrderDialogOpen: boolean;
  setIsOrderDialogOpen: Dispatch<SetStateAction<boolean>>;
  event?: EventType;
}

export default function OrdersTable(props: OrdersTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    page,
    setPageNumber,
    orders,
    setFilterBy,
    filterBy,
    handleSortOrders,
    setRowsPerPage,
    rowsPerPage,
    count,
    setSearchBy,
    isOrderDialogOpen,
    setIsOrderDialogOpen,
    event,
  } = props;

  const [isSearchingBy, setIsSearchingBy] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const selectedEventId = event?.eventId;

  const [isOrdersTableView, setIsOrdersTableView] = useState(
    localStorage.getItem('isOrdersTableView')
      ? localStorage.getItem('isOrdersTableView') === 'true'
      : true,
  );
  const toggleView = () => {
    localStorage.setItem('isOrdersTableView', `${!isOrdersTableView}`);
    setIsOrdersTableView(!isOrdersTableView);
  };

  const handleSearchOrders = (search: string) => {
    setSearchBy(search);
    setIsSearchingBy(true);
  };

  const handleFilterOrders = (filter: string) => {
    setIsFiltering(true);
    setFilterBy(filter);
  };

  return (
    <Fragment>
      <OrderAddDialog
        isOpen={isOrderDialogOpen}
        selectedEventId={selectedEventId}
        handleClose={setIsOrderDialogOpen}
      />
      {(orders && orders.length > 0) || isSearchingBy || isFiltering ? (
        <Fragment>
          <Container>
            <Toolbar>
              <SearchBar
                handleSearch={handleSearchOrders}
                placeHolderText="Search orders"
              />
              <TabFilter
                filterBy={filterBy}
                setFilterBy={handleFilterOrders}
                tabValues={['all', 'open', 'cancelled']}
              />
              {!isMobile && (
                <ToggleView>
                  <IconButton onClick={toggleView}>
                    {isOrdersTableView ? (
                      <AppsIcon />
                    ) : (
                        <FormatListBulletedIcon />
                      )}
                  </IconButton>
                </ToggleView>
              )}
            </Toolbar>
            {isOrdersTableView && !isMobile ? (
              <TableListView
                orders={orders}
                handleSortOrders={handleSortOrders}
              />
            ) : (
                <CardListView listItems={orders} baseRoute={'orders'} />
            )}
            {isFiltering && (!orders || (orders && !orders.length)) && (
              <NoFilteredOrders>
                There are no {filterBy} orders
              </NoFilteredOrders>
            )}
            <TablePagination
              page={page}
              count={count}
              rowsOnPage={(orders && orders.length) || 0}
              setPageNumber={setPageNumber}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              baseRoute={'customer-orders'}
            />
          </Container>
        </Fragment>
      ) : (
          <NoOrders
            noOrdersText={'no orders'}
            openForOrders={true}
            contactName={null}
          />
        )}
    </Fragment>
  );
}
