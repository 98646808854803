/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import { DateTime } from 'luxon';
import Tooltip from '@material-ui/core/Tooltip';
import IconMenu from './IconMenu';
import styled from '@emotion/styled';
import { MuiButton } from '@onehope/design-system-ohw';
import SubscriberSvg from '../../assets/subscriber';
import PCCustomerSvg from '../../assets/pcCustomer';
import { getOrderStatusIcon } from './helpers';

const PreferredCustomer = styled.span`
  svg {
    height: 30px;
    width: 30px;
  }
`;
const Subscriber = styled.span`
  svg {
    height: 30px;
    width: 30px;
  }
`;

const OrderStatusIconContainer = styled.span`
  //justify-content: center;
  svg {
    height: 28px;
    width: 28px;
  }
`;

function getCustomerType(customerType: string) {
  switch (customerType) {
    case 'subscriber':
      return (
          <Tooltip title="Subscriber" placement="top">
            <Subscriber>
              <SubscriberSvg />
            </Subscriber>
          </Tooltip>
      );
    case 'preferredCustomer':
      return (
          <Tooltip title="Preferred Customer" placement="top">
            <PreferredCustomer>
              <PCCustomerSvg />
            </PreferredCustomer>
          </Tooltip>
      );
    default:
      return <div />;
  }
}


interface RenderProps {
  orderId?: string | null | undefined;
  handleTrackOrder?: () => void;
  tracking?: (string | null)[] | null | undefined;
  carrier?: string | null | undefined;
  email?: string | null | undefined;
}

interface Column {
  id:
    | 'menu'
    | 'accountFullName'
    | 'createDate'
    | 'orderTotal'
    | 'orderStatus'
    | 'orderNumber'
    | 'customerType'
    | 'trackOrder'
  label?: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'right' | 'center';
  format?: (value: string, orderId?: string | null | undefined) => void;
  render?: (renderProps: RenderProps) => any;
  customerType?: (props: { customerType: string }) => any;
}

const columns: Column[] = [
  {
    id: 'accountFullName',
    label: 'Name',
    align: 'left',
    format: (value, eventId?: string | null | undefined) =>
      value && eventId ? value.toLocaleString() : '',
    // ? <Link to={`/event?eventId=${eventId}`}>
    //     {value.toLocaleString()}
    //   </Link>
    // : '',
  },
  {
    id: 'createDate',
    label: 'Date',
    minWidth: 100,
    align: 'left',
    format: value => DateTime.fromISO(value).toUTC().toFormat('LL/dd/yy'),
  },
  {
    id: 'orderNumber',
    label: 'Order #',
    align: 'left',
    format: (value, orderId?: string | null | undefined) =>
      value && orderId ? value.toLocaleString() : '',
    // ? <Link to={`/event?eventId=${eventId}`}>
    //     {value.toLocaleString()}
    //   </Link>
    // : '',
  },
  {
    id: 'orderTotal',
    label: 'Total',
    minWidth: 70,
    align: 'left',
    format: value =>
      value ? `$${Number(value).toFixed(2).toLocaleString()}` : '$0',
  },
  {
    id: 'orderStatus',
    label: 'Status',
    align: 'left',
    // format: formatDonationStatus,
    format: value => {
      const { icon, status } = getOrderStatusIcon(value);
      return value
          ?  <Tooltip title={status} placement="top">
              <OrderStatusIconContainer>
                {icon}
              </OrderStatusIconContainer>
          </Tooltip>
          : '';
    },
  },
  {
    id: 'customerType',
    label: 'Customer Type',
    align: 'left',
    // format: formatDonationStatus,
    customerType: customerType => getCustomerType(customerType),
  },
  // {
  //   id: 'donationInfo.event.hostKitStatus',
  //   label: 'Host Kit Status',
  //   minWidth: 170,
  //   align: 'left',
  //   format: value => (value ? value.toLocaleString() : 'Not Yet Ordered'),
  // },
  {
    id: 'trackOrder',
    minWidth: 0,
    align: 'left',
    render: ({ handleTrackOrder, tracking }: RenderProps) => (
      <MuiButton
        disabled={!Array.isArray(tracking) || tracking.length === 0}
        type="ROUND"
        onClick={handleTrackOrder ? () => handleTrackOrder() : () => {}}
      >
        Track
      </MuiButton>
    ),
  },
  {
    id: 'menu',
    minWidth: 0,
    align: 'right',
    render: ({ orderId, carrier, tracking, email }:
                 { orderId: string, carrier?: string | undefined | null, email: string | null, tracking: (string | null)[] | null | undefined }) =>
      <IconMenu email={email} isCardView={false} orderId={orderId} carrier={carrier} tracking={tracking} />,
  },
];

export default columns;
