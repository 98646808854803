import React, { Component } from 'react';

import { Query } from 'react-apollo';

import Loading from '../common/Loading';
import Layout from '../components/MainLayout/layout/layout';
import Payments from '../components/Payments';
import { getCurrentUserId } from '../utils/utils';
import GET_PAYMENTS_QUERY from '../queries/Payments';

interface PaymentsPageProps {
  location: any;
}

interface PaymentsPageState {
  collapsed: boolean;
}

class PaymentsPage extends Component<PaymentsPageProps, PaymentsPageState> {
  state = {
    collapsed: false,
  };

  render() {
    const { location } = this.props;
    const currentUser = getCurrentUserId(location);

    return (
      <Layout selectedUserId={currentUser}>
        <Query
          query={GET_PAYMENTS_QUERY}
          variables={{
            accountId: currentUser,
            csPortal: true,
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) return `Error! ${error.message}`;
            const {
              viewer: {
                userInfo,
                userInfo: { firstName, lastName },
              },
            } = data;
            return (
              <React.Fragment>
                <h2>{`${firstName} ${lastName}'s Payments`}</h2>
                <Payments
                  selectedUserId={currentUser}
                  payments={userInfo.stripePaymentCollection.entries.edges}
                />
              </React.Fragment>
            );
          }}
        </Query>
      </Layout>
    );
  }
}

export default PaymentsPage;
