import gql from 'graphql-tag';

const GET_MASTER_EVENTS_QUERY = gql`
  query CEDash_MasterEventsQuery {
    viewer {
      id
      user {
        id
        trinityDealerId
      }
      masterEvents(first: 10) {
        edges {
          node {
            id
            hostNonProfitName
            groupFundraiserSlug
            openDate
          }
        }
      }
    }
  }
`;

export default GET_MASTER_EVENTS_QUERY;
