import React from 'react';
import styled from '@emotion/styled';
import { useCheckBoxToggleContext } from '../CheckboxToggleContext';
import { useArchiveContactsContext } from '../ArchiveContactsContext';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import { SvgPencil } from '../../../assets/pencil';
import { SvgSM } from '../../../assets/sm';
import { styles as s } from '@onehope/design-system';

export const Container = styled.div`
  display: flex;
`;
const { cssConstants } = s;

export const EditButton = styled.div`
  :hover {
    cursor: pointer;
  }
  width: 218px;
  margin-right: 16px;
  display: flex;
  justify-content: flex-end;
  height: 18px;
  font-family: ${cssConstants.font};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #0091ea;
`;

const Pencil = styled.span`
  position: absolute;
  right: 2px;
  top: 2px;
`;

const SM = styled.span`
  position: absolute;
  right: 2px;
  top: 2px;
`;

const EditButtonMobile = styled(Button)`
  && {
    :hover {
      cursor: pointer;
    }
    padding: 0;
    font-family: ${cssConstants.font};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1px;
    position: relative;
    display: flex;
    margin-top: 16px;
    height: 40px;
    width: 316px;
    border-radius: 4px;
    border: 1px solid black;
  }
`;

export const CheckBoxToggle = () => {
  const {
    isCheckBoxActivated,
    setCheckBoxActivated,
    setCheckBoxDeActivated,
  } = useCheckBoxToggleContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { cancelArchive } = useArchiveContactsContext();

  const handleCancle = () => {
    cancelArchive();
    setCheckBoxDeActivated();
  };

  const ButtonSpan = styled.span`
    font-size: 12px;
    margin-left: 2px;
    margin-right: 2px;
  `;
  const ButtonOption = !isMobile ? EditButton : EditButtonMobile;

  return (
    <Container>
      {isCheckBoxActivated ? (
        <ButtonOption onClick={handleCancle}>
          <ButtonSpan>Cancel</ButtonSpan>
          {isMobile && (
            <SM>
              <SvgSM />
            </SM>
          )}
        </ButtonOption>
      ) : (
        <ButtonOption onClick={setCheckBoxActivated}>
          <ButtonSpan>Edit{` `}</ButtonSpan>
          {isMobile && (
            <>
              <ButtonSpan>CONTACTS</ButtonSpan>
              <Pencil>
                <SvgPencil />
              </Pencil>
            </>
          )}
        </ButtonOption>
      )}
    </Container>
  );
};
