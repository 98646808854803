import * as React from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import { MuiButton } from '@onehope/design-system-ohw';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import EventCreateDialog from '../../../Dialog/EventCreateDialog/EventCreateDialog';
import SnackBarHeader from '../../../../common/Snackbar';
import { CEDash_EventsQuery_viewer_user as UserType } from '../../../queries/generatedTypes/CEDash_EventsQuery';

const { cssConstants } = s;

const Button = styled(MuiButton)`
  && {
    width: 288px;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 16px 16px 16px;
  flex-direction: column;
  @media all and (min-width: 768px) {
    padding: 40px;
    flex-direction: row;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  @media all and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  padding-bottom: 16px;
`;

const HeaderTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: -0.5px;
`;

const ImportContactsButton = styled(MuiButton)`
  && {
    width: 288px;
  }
`;

const SnackBarWrapper = styled.div`
  padding-top: 16px;
`;

function isEventPage() {
  if (typeof window === 'undefined') return false;
  return window.location.pathname.includes('event');
}

interface HeaderProps {
  handleEventCreateOpen: () => void;
  handleEventCreateClose: () => void;
  isEventCreateOpen: boolean;
  createEventStep: string | string[] | null | undefined;
  user: UserType;
}
export default function Header(props: HeaderProps) {
  const {
    createEventStep,
    isEventCreateOpen,
    handleEventCreateOpen,
    handleEventCreateClose,
  } = props;

  const showSnackBar = isEventPage();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <React.Fragment>
      {/* {isMobile && showSnackBar && (
        <SnackBarWrapper>
          <SnackBarHeader />
        </SnackBarWrapper>
      )} */}
      <HeaderContainer>
        <HeaderTitle>All Events</HeaderTitle>
        <ButtonsWrapper>
          <ButtonContainer>
            <EventCreateDialog
              user={props?.user}
              createEventStep={createEventStep}
              isEventCreateOpen={isEventCreateOpen}
              handleEventCreateOpen={handleEventCreateOpen}
              handleEventCreateClose={handleEventCreateClose}
            />
          </ButtonContainer>
        </ButtonsWrapper>
      </HeaderContainer>
    </React.Fragment>
  );
}
