import React from 'react';
import {
  Dropzone,
  Grid,
  Icons,
  makeStyles,
  ThemeType,
} from '@onehope/design-system-v2';

const useStyles = makeStyles((theme: ThemeType) => ({
  dragAndDrop: {
    padding: '16px',
    width: '100%',
    height: '289px',
    border: `1px dashed ${theme.palette.primary.main}`,
    marginBottom: '40px',
  },
  textUpload: {
    paddingLeft: '4px',
    fontWeight: 600,
    color: theme.palette.linkColor.main,
    cursor: 'pointer',
  },
  text: { height: '100%' },
  icon: { paddingBottom: '8px', cursor: 'pointer' },
  skip: {
    fontWeight: 700,
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    paddingTop: '24px',
    cursor: 'pointer',
  },
}));

export default function DragAndDrop(props: any) {
  const { handleNextStepChange, onFileDrop } = props;

  const classes = useStyles();

  return (
    <div>
      <Dropzone
        className={classes.dragAndDrop}
        accept="image/jpg, image/jpeg, image/png"
        onDrop={onFileDrop}
        maxFiles={1}
        multiple={false}
        maxSize={16000000} // in bytes (16 MB)
      >
        <Grid
          container
          wrap="nowrap"
          justify="center"
          direction="column"
          alignItems="center"
          className={classes.text}
        >
          <Icons.PhotoUpload className={classes.icon} />
          <div>File Types: JPG, JPEG, or PNG</div>
          <div>Max file size is 16 MB</div>
          <div>Minimum size 360x220 pixels</div>
          <div>Recommended 720x440 pixels</div>
        </Grid>
      </Dropzone>
      <Grid container justify="center">
        Drag and drop your file or{' '}
        <Dropzone
          className={classes.textUpload}
          accept="image/jpg, image/jpeg, image/png"
          onDrop={onFileDrop}
          maxFiles={1}
          multiple={false}
          maxSize={16000000} // in bytes (16 MB)
        >
          click to browse
        </Dropzone>
      </Grid>
      <div className={classes.skip} onClick={handleNextStepChange}>
        skip
      </div>
    </div>
  );
}
