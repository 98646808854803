/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import styled from '@emotion/styled';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import get from 'lodash/get';
import { styles as s } from '@onehope/design-system';
import Columns from '../../Columns';
import {
  CEDash_GuestOrdersQuery_viewer_user_orders_edges as OrdersEdges,
} from "../../../../queries/generatedTypes/CEDash_GuestOrdersQuery";

const { cssConstants } = s;

const TableRow = styled(MuiTableRow)`
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

const TableCell = styled(MuiTableCell)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
  }
`;

interface TableBodyContainerProps {
  orders: (OrdersEdges | null)[] | null;
  page: number;
  rowsPerPage: number;
}

const handleRowClick = (order: OrdersEdges | null) => {
  return () => {
    const { GATSBY_HOPECOMMERCE_URL } = process.env;
    return window.open(
        `${GATSBY_HOPECOMMERCE_URL}/order/${order?.node?.orderId}`,
        '_blank',
    );
  };
};

const onHandleTrackOrder = (tracking: (string | null)[] | null | undefined, carrier: string | null | undefined) => {
  return () => {
    const trackingUrl =
        carrier === 'FedEx'
            ? `https://www.fedex.com/apps/fedextrack/?tracknumbers`
            : `https://wwwapps.ups.com/tracking/tracking.cgi?tracknum`;
      if(tracking) {
        tracking.forEach(trackingNumber => {
          return window.open(
              `${trackingUrl}=${trackingNumber}`,
              '_blank');
        });
    }
    return null;
  };
};

export default function TableBodyContainer(props: TableBodyContainerProps) {
  const {
    orders,
    rowsPerPage,
    page,
  } = props;

  if (orders) {
    return (
      <TableBody>
        {orders
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((order: OrdersEdges | null) => {
            const onRowClick = handleRowClick(order);
            const tracking = order?.node?.shippingInfo?.tracking;
            const carrier = order?.node?.shippingInfo?.carrier;
            const email = order?.node?.email;
            const handleTrackOrder = onHandleTrackOrder(tracking, carrier);
            const customerType = order?.node?.isAutoShipOrder
                ? 'subscriber'
                : order?.node?.isPreferredCustomerOrder ? 'preferredCustomer' : '';
            return (
              <TableRow hover role="checkbox" key={order?.node?.id}>
                {Columns.map(column => {
                  const value = get(order?.node, `${column.id}`);
                  const onCellClick = !column.render ? onRowClick : () => {};

                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      onClick={onCellClick}
                    >
                      {column.format &&
                        column.format(
                          value,
                          order?.node?.orderId,
                        )}
                      {column.customerType &&
                        column.customerType(customerType)
                      }
                      {column.render &&
                      column.render({
                        orderId: order?.node?.orderId,
                        tracking,
                        carrier,
                        email,
                        handleTrackOrder,
                      })}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
      </TableBody>
    );
  }
  return null;
}
