import React from 'react';

export const SvgGlassesClink = (props: any) => (
  <svg
    width="29px"
    height="30px"
    viewBox="0 0 29 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Number-of-tastings" transform="translate(-5.000000, -4.000000)">
        <g id="icon-/-wine-/-glasses-clink">
          <g>
            <g id="Group-8" transform="translate(-5.000000, 5.000000)">
              <g
                id="icon-/-wine-/-glass-rose"
                transform="translate(17.146428, 17.146428) rotate(15.000000) translate(-17.146428, -17.146428) translate(3.146428, 3.146428)"
              >
                <g id="Group-2" transform="translate(9.100000, 2.800000)">
                  <g id="Group">
                    <ellipse
                      id="Oval"
                      stroke="#1A1A1A"
                      strokeWidth="0.5"
                      fill="#FFFFFF"
                      cx="4.82461538"
                      cy="21.8857692"
                      rx="3.82955335"
                      ry="1"
                    ></ellipse>
                    <rect
                      id="Rectangle"
                      stroke="#1A1A1A"
                      strokeWidth="0.5"
                      fill="#FFFFFF"
                      x="4.41898263"
                      y="11.8115385"
                      width="1"
                      height="10.1492308"
                    ></rect>
                    <path
                      d="M0.5,0 L9.14923077,0 C9.42537314,-1.06237683e-16 9.64923077,0.223857625 9.64923077,0.5 L9.64923077,7.55735493 C9.64923077,10.2219164 7.48917689,12.3819703 4.82461538,12.3819703 C2.16005388,12.3819703 1.21449309e-15,10.2219164 0,7.55735493 L0,0.5 C-3.38176876e-17,0.223857625 0.223857625,5.07265313e-17 0.5,0 Z"
                      id="Rectangle"
                      stroke="#1A1A1A"
                      strokeWidth="0.5"
                      fill="#FFFFFF"
                    ></path>
                    <path
                      d="M0.689230769,4.13538462 L8.96,4.13538462 L8.96,7.58153846 C8.96,9.86544832 7.10852524,11.7169231 4.82461538,11.7169231 C2.54070553,11.7169231 0.689230769,9.86544832 0.689230769,7.58153846 L0.689230769,4.13538462 L0.689230769,4.13538462 Z"
                      id="Rectangle"
                      fillOpacity="0.15"
                      fill="#F4564E"
                    ></path>
                  </g>
                  <path
                    d="M4.71879792,5.87629521 C4.56211708,5.76156002 4.37546709,5.7 4.18154158,5.7 C3.84408198,5.7 3.62264005,5.78800033 3.47599285,5.94159039 C3.33266658,6.09170229 3.25,6.31922456 3.25,6.66380845 C3.25,7.20770792 4.31088923,8.28981619 4.75356008,8.60389338 C4.79454712,8.63297389 4.84349957,8.66488194 4.8989985,8.69870202 C4.95421135,8.6646622 5.00292961,8.63251831 5.04380548,8.60323846 C5.48791044,8.28512099 6.55,7.19929804 6.55,6.66380845 C6.55,6.31922456 6.46733342,6.09170229 6.32400715,5.94159039 C6.19079606,5.80207256 6.01605372,5.68237104 5.20664728,5.76854494 L5.19102055,5.77022598 C5.1857327,5.77145204 5.18065325,5.77265194 4.71879792,5.87629521 Z"
                    id="Path"
                    stroke="#1A1A1A"
                    strokeWidth="0.5"
                    fill="#F4564E"
                    fillRule="nonzero"
                  ></path>
                </g>
              </g>
              <g
                id="icon-/-wine-/-glass-white"
                transform="translate(32.146428, 17.146428) rotate(-15.000000) translate(-32.146428, -17.146428) translate(18.146428, 3.146428)"
              >
                <g id="Group" transform="translate(9.100000, 2.800000)">
                  <ellipse
                    id="Oval"
                    stroke="#1A1A1A"
                    strokeWidth="0.5"
                    fill="#FFFFFF"
                    cx="4.82461538"
                    cy="21.8857692"
                    rx="3.82955335"
                    ry="1"
                  ></ellipse>
                  <rect
                    id="Rectangle"
                    stroke="#1A1A1A"
                    strokeWidth="0.5"
                    fill="#FFFFFF"
                    x="4.41898263"
                    y="11.8115385"
                    width="1"
                    height="10.1492308"
                  ></rect>
                  <path
                    d="M0.5,0 L9.14923077,0 C9.42537314,-1.08729248e-15 9.64923077,0.223857625 9.64923077,0.5 L9.64923077,7.55735493 C9.64923077,10.2219164 7.48917689,12.3819703 4.82461538,12.3819703 C2.16005388,12.3819703 2.10267151e-15,10.2219164 0,7.55735493 L0,0.5 C-3.38176876e-17,0.223857625 0.223857625,5.07265313e-17 0.5,0 Z"
                    id="Rectangle"
                    stroke="#1A1A1A"
                    strokeWidth="0.5"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    d="M0.689230769,4.13538462 L8.96,4.13538462 L8.96,7.58153846 C8.96,9.86544832 7.10852524,11.7169231 4.82461538,11.7169231 C2.54070553,11.7169231 0.689230769,9.86544832 0.689230769,7.58153846 L0.689230769,4.13538462 L0.689230769,4.13538462 Z"
                    id="Rectangle"
                    fillOpacity="0.1"
                    fill="#FFB600"
                  ></path>
                  <path
                    d="M4.71879792,5.87629521 C4.56211708,5.76156002 4.37546709,5.7 4.18154158,5.7 C3.84408198,5.7 3.62264005,5.78800033 3.47599285,5.94159039 C3.33266658,6.09170229 3.25,6.31922456 3.25,6.66380845 C3.25,7.20770792 4.31088923,8.28981619 4.75356008,8.60389338 C4.79454712,8.63297389 4.84349957,8.66488194 4.8989985,8.69870202 C4.95421135,8.6646622 5.00292961,8.63251831 5.04380548,8.60323846 C5.48791044,8.28512099 6.55,7.19929804 6.55,6.66380845 C6.55,6.31922456 6.46733342,6.09170229 6.32400715,5.94159039 C6.19079606,5.80207256 6.01605372,5.68237104 5.20664728,5.76854494 L5.19102055,5.77022598 C5.1857327,5.77145204 5.18065325,5.77265194 4.71879792,5.87629521 Z"
                    id="Path"
                    stroke="#1A1A1A"
                    strokeWidth="0.5"
                    fill="#FFB600"
                    fillRule="nonzero"
                  ></path>
                </g>
              </g>
            </g>
            <g
              id="Group-4"
              transform="translate(19.500000, 4.500000)"
              stroke="#1A1A1A"
              strokeLinecap="round"
            >
              <line
                x1="0.709302326"
                y1="3.84496124"
                x2="0.709302326"
                y2="0.289405685"
                id="Line"
                strokeWidth="0.5"
              ></line>
              <line
                x1="0.709302326"
                y1="27.7209302"
                x2="0.709302326"
                y2="24.1653747"
                id="Line"
                strokeWidth="0.5"
              ></line>
            </g>
            <g
              id="Group-4"
              transform="translate(20.000000, 18.500000) rotate(-25.000000) translate(-20.000000, -18.500000) translate(19.500000, 4.500000)"
              stroke="#1A1A1A"
              strokeLinecap="round"
            >
              <line
                x1="0.709302326"
                y1="3.84496124"
                x2="0.709302326"
                y2="0.289405685"
                id="Line"
                strokeWidth="0.5"
              ></line>
              <line
                x1="0.709302326"
                y1="27.7209302"
                x2="0.709302326"
                y2="24.1653747"
                id="Line"
                strokeWidth="0.5"
              ></line>
            </g>
            <g
              id="Group-4"
              transform="translate(20.000000, 18.500000) rotate(25.000000) translate(-20.000000, -18.500000) translate(19.500000, 4.500000)"
              stroke="#1A1A1A"
              strokeLinecap="round"
            >
              <line
                x1="0.709302326"
                y1="3.84496124"
                x2="0.709302326"
                y2="0.289405685"
                id="Line"
                strokeWidth="0.5"
              ></line>
              <line
                x1="0.709302326"
                y1="27.7209302"
                x2="0.709302326"
                y2="24.1653747"
                id="Line"
                strokeWidth="0.5"
              ></line>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
