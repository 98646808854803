import React from 'react';

import { css } from '@emotion/core';
import { TextField } from '@material-ui/core';

const InputStyles = css`
  width: 300px;
  div,
  div::before {
    border: none !important;
  }
  label + div {
    margin-top: 0px;
    ::before,
    ::after {
      border: none;
    }

    input {
      margin: 16px 0;
      border-radius: 4px;
      background-color: #fff;
      height: 32px;
      padding: 0;
      padding-left: 28px;
      border: 1px solid #d9d9d9;
      outline: none;
    }
    input:focus {
      border: 1px solid #40a9ff;
      outline: none;
    }
  }
  label {
    pointer-events: none;
    margin-left: 28px;
    margin-bottom: -15px;
    z-index: 1000;
  }
`;

export default (props: any) => <TextField css={InputStyles} {...props} />;
