// Following is taken from https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c
import React, { createContext, useContext } from 'react';

import { CEDash_DashboardQuery_viewer } from '../queries/generatedTypes/CEDash_DashboardQuery';
import {
  segmentCookie,
  segmentPageView,
  SegmentTrackEventProps,
  trackEventsV2,
  SegmentTrackContactProps,
  trackContacts,
} from '../utils/segment/tracking';
import {
  segmentCookieV2,
  segmentEventV2,
  SegmentEventPropsV2,
} from '../utils/segment/SegmentTrackingV2';

export type TrackingProps = {
  trackPageView: (input: TrackUserPageViewProps) => any;
  trackEventsV2: (props: SegmentTrackEventProps) => void;
  trackContacts: (props: SegmentTrackContactProps) => void;
  trackEventV2: (props: SegmentEventPropsV2) => void;
};

export type TrackUserPageViewProps = {
  page: string;
};

// @ts-ignore: An argument for 'defaultValue' was not provided.
export const TrackingContext = createContext<TrackingProps>();

export const useTrackingContextValue = () =>
  useContext<TrackingProps>(TrackingContext);

// Just sending to Segment for now,
// but we can add other services in here too!
export default function TrackingProvider({
  viewer,
  children,
}: {
  viewer: CEDash_DashboardQuery_viewer;
  children: React.ReactNode;
}) {
  const { user, isLoggedIn } = viewer;
  const { email, userId } = user;

  const trackUserPageView = ({ page }: TrackUserPageViewProps) => {
    // Segment
    // segmentCookie({ email });
    segmentPageView({ page, user });
  };
  const trackUserEventV2 = ({
    eventName,
    newEvent,
    oldEvent,
    formValues,
  }: SegmentTrackEventProps) => {
    trackEventsV2({ eventName, user, newEvent, oldEvent, formValues });
  };
  const trackUserContact = ({
    eventName,
    contact,
    totalArchived,
    contactImportStats,
  }: SegmentTrackContactProps) => {
    trackContacts({
      eventName,
      ceAccountId: user?.userId,
      contact,
      totalArchived,
      contactImportStats,
    });
  };

  const trackEventV2 = ({ event, properties }: SegmentEventPropsV2) => {
    segmentCookieV2({ userId, isLoggedIn });
    segmentEventV2({
      event,
      properties,
    });
  };

  return (
    <TrackingContext.Provider
      value={{
        trackPageView: trackUserPageView,
        trackEventsV2: trackUserEventV2,
        trackContacts: trackUserContact,
        trackEventV2: trackEventV2,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
}
