import React from 'react';
import DialogRoot from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import { MuiButton } from '@onehope/design-system-ohw';

const { cssConstants } = s;

interface AlertDialogProps {
  open: boolean;
  dialogText?: string;
  setClose: (input: boolean) => void;
  saveChange: () => void;
  mutationLoading: boolean;
  errorNotification?: string | null;
}

const Dialog = styled(DialogRoot)`
  &.MuiDialog-root {
    max-width: 460px;
    margin: 0 auto;
    z-index: 9000 !important;
  }
`;

const DialogTitleWrapper = styled(DialogTitle)`
  .MuiTypography-h6 {
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }
`;

const ErrorNotification = styled.div`
  width: 100%;
  font-family: ${cssConstants.font};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  border-radius: 4px;
  border: solid 1px #ff4d4f;
  background-color: #fff5f5;
  padding: 14px 22px;
  margin-top: 16px;
`;

export default function AlertDialog(props: AlertDialogProps) {
  const {
    open,
    setClose,
    saveChange,
    dialogText,
    mutationLoading,
    errorNotification,
  } = props;

  const handleClose = () => {
    setClose(false);
  };

  return (
    <Dialog
      open={open}
      onClose={!mutationLoading ? handleClose : () => { }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitleWrapper>
        {dialogText || `Are you sure you would like to cancel this event?`}
        {errorNotification && (
          <ErrorNotification>{errorNotification}</ErrorNotification>
        )}
      </DialogTitleWrapper>
      <DialogActions>
        <MuiButton
          type="SECONDARY"
          disabled={mutationLoading || !!errorNotification}
          fullWidth
          onClick={handleClose}
        >
          No
        </MuiButton>
        <MuiButton
          type="PRIMARY"
          fullWidth
          disabled={mutationLoading || !!errorNotification}
          onClick={saveChange}
          color="primary"
          autoFocus
        >
          {mutationLoading && (
            <CircularProgress
              size={24}
              style={{ marginRight: 6, color: 'white' }}
            />
          )}
          Yes
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
}
