import gql from 'graphql-tag';

export default gql`
  mutation ContactEditMutation($input: ContactEditInput!) {
    contactEdit(input: $input) {
      contact {
        id
        dateOfBirth
        isPreferredCustomer
        fullName
        firstName
        lastName
        organizationName
        accountId
        email
        mobilePhone
        createDate
        shippingAddress {
          addressId
          addressLineOne
          addressLineTwo
          city
          state
          zip
        }
        contactPreferences {
          id
          contactPreferenceId
          interestedIn {
            hostWineTasting
            joinWineClub
            becomePC
            joinAsCE
            corporateGifting
          }
        }
        preferredCustomerData {
          preferredCustomerStatus
          preferredCustomerJoinDate
        }
        lifeTimeSpend
        contactType
        notes {
          id
          noteId
          text
          subjectId
          dateCreated
          dateUpdated
        }
      }
    }
  }
`;
