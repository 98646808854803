import React from 'react';

const SvgApple = (props: any) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M48.0332 69.8088C50.2535 69.8088 52.5225 68.7041 54.6497 66.9109C56.7831 65.1123 58.8196 62.5837 60.5857 59.6397C64.1154 53.7557 66.6133 46.1303 66.6133 39.1585C66.6133 32.1997 64.6524 27.4275 61.5711 24.3965C58.4933 21.3689 54.3651 20.1452 50.1603 20.1452C48.1371 20.1452 45.5548 20.733 43.5114 21.3051C42.4826 21.5932 41.5773 21.8811 40.929 22.097C40.6048 22.205 40.3445 22.2951 40.1649 22.3584C40.1523 22.3628 40.1402 22.3671 40.1284 22.3713C40.1026 22.3804 40.0787 22.3889 40.0568 22.3967C40.0249 22.3853 39.9888 22.3725 39.9487 22.3584C39.769 22.2951 39.5087 22.205 39.1845 22.097C38.5363 21.8811 37.631 21.5932 36.6022 21.3051C34.5587 20.733 31.9764 20.1452 29.9532 20.1452C25.7484 20.1452 21.6202 21.3689 18.5424 24.3965C15.4611 27.4275 13.5002 32.1997 13.5002 39.1585C13.5002 46.1303 15.9981 53.7557 19.5278 59.6397C21.2939 62.5837 23.3304 65.1123 25.4638 66.9109C27.591 68.7041 29.86 69.8088 32.0803 69.8088C33.8209 69.8088 35.0182 69.5339 36.174 69.2686L36.1804 69.2671C37.3269 69.0039 38.4327 68.7508 40.0568 68.7508C41.6808 68.7508 42.7866 69.0039 43.9331 69.2671L43.9396 69.2686C45.0954 69.5339 46.2927 69.8088 48.0332 69.8088Z"
      fill="white"
      stroke="#1A1A1A"
    />
    <path
      d="M36.5678 37.0968C33.7244 37.0968 31.4194 37.9199 31.4194 41.8151C31.4194 45.7103 40.613 52.5806 40.613 52.5806C40.613 52.5806 49.8065 45.7103 49.8065 41.8151C49.8065 37.9199 47.5015 37.0968 44.6581 37.0968C43.0173 37.0968 41.5557 37.8544 40.613 39.0345C39.6702 37.8544 38.2087 37.0968 36.5678 37.0968Z"
      fill="#F4564E"
      fillOpacity="0.75"
      stroke="#1A1A1A"
    />
    <path
      d="M40.1291 24.5161C39.9494 23.7357 39.9304 17.6461 44.8423 14.3189"
      stroke="#1A1A1A"
    />
    <path
      d="M40.0687 19.5747L39.9183 20.0515L40.4186 20.2093L40.5522 19.7021L40.0687 19.5747ZM40.0687 19.5747C39.9183 20.0515 39.9181 20.0515 39.9178 20.0514L39.9171 20.0511L39.9152 20.0505L39.9095 20.0487L39.8905 20.0425C39.8746 20.0372 39.8522 20.0296 39.8238 20.0196C39.7669 19.9996 39.6858 19.9699 39.5843 19.9299C39.3815 19.85 39.0964 19.7285 38.7594 19.5593C38.0869 19.2218 37.1994 18.6907 36.3462 17.9162C34.6261 16.3546 33.0567 13.8067 33.659 9.94478L33.7179 9.5669L34.098 9.52486L34.153 10.0218C34.098 9.52486 34.0982 9.52483 34.0985 9.52481L34.099 9.52475L34.1003 9.52461L34.1041 9.52421L34.116 9.52301C34.1258 9.52206 34.1393 9.5208 34.1565 9.51936C34.1907 9.51649 34.2393 9.5129 34.3006 9.5096C34.4231 9.503 34.5969 9.4975 34.8088 9.50118C35.2315 9.50853 35.8134 9.55248 36.4474 9.70069C37.7123 9.99639 39.2424 10.7243 40.0624 12.46C40.8428 14.1121 40.9571 15.931 40.8869 17.3097C40.8516 18.0035 40.769 18.5969 40.6951 19.018C40.658 19.2288 40.623 19.3971 40.5969 19.5139C40.5839 19.5724 40.5731 19.618 40.5653 19.6497L40.5561 19.6867L40.5535 19.6972L40.5527 19.7003L40.5524 19.7014L40.5523 19.7018C40.5522 19.7019 40.5522 19.7021 40.0687 19.5747ZM39.5851 19.4477C39.5851 19.4477 39.5851 19.4477 39.5851 19.4477L39.5851 19.4477Z"
      fill="#00BFB2"
      fillOpacity="0.75"
      stroke="#1A1A1A"
    />
  </svg>
);
export default SvgApple;
