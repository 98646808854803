import React from 'react';
import { DateTime } from 'luxon';

import { MyFormValues } from '../../Event/TabContent/Details/FormTypes';
import { DetailsContainer } from '../../Event/TabContent/index.styles';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';

import SummaryLine from '../../Contact/TabContent/SummaryLine';
import EventCancelButton from './EventCancelButton';

import { formatZone } from '../../Event/TabContent/Details/helpers';
import { eventIsAcceptingOrders } from '../EventsTable/helpers';
import { EventStatusDictionary } from '../../../utils/enums';
import { getEventImage } from '../../../utils/utils';

interface EventSummaryProps {
  event: EventType;
  values: MyFormValues;
  editing: boolean;
  satelliteEvent: boolean;
  setIsEditing: () => void;
}

const getHostType = (hostType: string | undefined) => {
  switch (hostType) {
    case 'CUSTOMER':
      return 'A contact';
    case 'NONPROFIT':
      return 'A nonprofit';
    default:
      return 'Self hosted';
  }
};

const getLocationType = (trinityPartyType: string | undefined) => {
  if (trinityPartyType === 'EPARTY') return 'Online';
  return 'In-person';
};

const formatDisplayDate = (
  eventDate: string | null,
  timeZone: string | undefined,
) => {
  if (!eventDate || !timeZone) return '';
  const date = DateTime.fromISO(eventDate, { zone: timeZone });

  if (!date) return '';
  if (!date.c) return date;
  const {
    c: { month, day, year },
  } = date;
  return `${month} / ${day.toString().padStart(2, 0)} / ${year}`.padStart(
    14,
    0,
  );
};

export default function EventSummary(props: EventSummaryProps) {
  const { event, values, satelliteEvent } = props;
  const {
    hostFullName,
    hostNonProfitName,
    eventTime,
    timeZone,
    displayAddress,
    eventTimeLocale,
    title,
  } = values;

  const closedEvent = event?.status === EventStatusDictionary.CLOSED;
  const isAcceptingOrders = eventIsAcceptingOrders(event);
  const ordersPlaced = event?.ordersCount > 0;
  const image = event?.images?.[0];
  const imageUrl = getEventImage(image);

  const hostType = getHostType(values.hostType);
  const locationType = getLocationType(values.trinityPartyType);
  const eventDate = formatDisplayDate(event.eventDate, timeZone);
  const closeDate = formatDisplayDate(event.closeDate, timeZone);
  const manualCloseDate = formatDisplayDate(event.manualCloseDate, timeZone);
  const showSupporters = event?.displayPreferences?.supporters;

  return (
    <DetailsContainer>
      {closedEvent && !satelliteEvent && (
        <div>
          {closeDate && (
            <SummaryLine
              title="Stopped accepting orders on"
              value={closeDate}
            />
          )}
          {manualCloseDate && (
            <SummaryLine title="Event closed on" value={manualCloseDate} />
          )}
        </div>
      )}
      <SummaryLine title="Event ID#" value={event.trinityPartyId} />
      {satelliteEvent ? (
        <div>
          <SummaryLine title="Event location" value="Online" />
          <SummaryLine title="Host name" value={event.hostFullName} />
        </div>
      ) : (
        <div>
          {hostType && <SummaryLine title="Host type" value={hostType} />}
          {title && <SummaryLine title="Event Title" value={title} />}
          {hostFullName && (
            <SummaryLine title="Host name" value={hostFullName} />
          )}
          {hostNonProfitName && (
            <SummaryLine title="Host nonprofit" value={hostNonProfitName} />
          )}
          {locationType && (
            <SummaryLine title="Location type" value={locationType} />
          )}
          {displayAddress && (
            <SummaryLine title="Event address" value={displayAddress} />
          )}
          {eventDate && <SummaryLine title="Date" value={eventDate} />}
          {eventTime && (
            <SummaryLine
              title="Time"
              value={`${eventTime}
                ${eventTimeLocale} ${formatZone(timeZone)}`}
            />
          )}
          <SummaryLine
            title="Event supporters"
            value={showSupporters ? 'Show' : 'Hide'}
          />
          {imageUrl && <SummaryLine title="Event photo" imgSrc={imageUrl} />}
          {isAcceptingOrders && !ordersPlaced && (
            <EventCancelButton event={props.event} />
          )}
        </div>
      )}
    </DetailsContainer>
  );
}
