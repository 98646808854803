import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/react-hooks';
import { get } from 'lodash';

import FormContainer from '../../FormContainer';
import WineForm from '../../../Events/WineForm';
import { FormValues } from './FormTypes';

import { getDefaultValues, getWineDetailsToSubmit } from './helpers';
import { WineFormSchema } from './validationSchema';

import EVENT_UPDATE_MUTATION from '../../../../mutations/Event/EventUpdateMutation';

import {
  CEDash_EventDetailsPage_viewer_event as EventType,
  CEDash_EventDetailsPage_viewer_user as UserType,
} from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';
import WineSummary from './WineSummary';
import OrderSummary from './OrderSummary';
import { useTrackingContextValue } from '../../../../contexts/TrackingContext';
import { getTastingFlights } from '../../../../utils/utils';

const Container = styled.div`
  position: relative;
`;

interface DetailsProps {
  event: EventType;
  user: UserType;
}

const handleUpdateEvent = async ({
  eventId,
  values,
  setErrors,
  toggleEdit,
  eventUpdateMutation,
  setErrorNotification,
  setInitialValues,
  trackEventsV2,
  event,
}: any) => {
  const wineDetails = getWineDetailsToSubmit(
    values,
    event?.trinityPartyType,
    event?.createDate,
  );
  const input = {
    form: {
      eventId,
      ...wineDetails,
    },
  };
  await eventUpdateMutation({
    variables: { input: input },
  })
    .then((data: any) => {
      const newEvent = get(data, 'data.eventUpdate.event');
      setInitialValues(getDefaultValues(newEvent));
      toggleEdit();
      trackEventsV2({
        eventName: 'Event Wine Updated',
        formValues: values,
      });
    })
    .catch((errors: any) => {
      const errorMessage = errors?.graphQLErrors[0]?.message;
      if (errorMessage.includes('Not Found')) {
        setErrorNotification(
          'These selections are no longer valid - please update your choices and try again.',
        );
        setErrors({ wineNeeded: true });
      }
      return;
    });
};

const Wine = ({ event, user }: DetailsProps) => {
  const [eventUpdateMutation, { loading: mutationLoading }] = useMutation(
    EVENT_UPDATE_MUTATION,
  );
  const [errorNotification, setErrorNotification] = useState('');
  const [initialValues, setInitialValues] = useState(getDefaultValues(event));

  const { trackEventsV2 } = useTrackingContextValue();

  const emptyForm = initialValues.wineNeeded === 'SKIP';
  const [editing, setEditing] = useState(emptyForm);

  const numCreditsUsed = event?.hostKitQuantity > 0 ? event?.hostKitCredits : 0;
  const numberOfAvailableCredits = user?.numberOfAvailableCredits ?? 0;
  const orderId = event?.wineTastingOrderIds.length
    ? event?.wineTastingOrderIds[0]
    : null;
  const starterKitCreditsUsed = event?.starterKitCredits ?? 0;
  const availableStarterKitCredits = user?.availableStarterKitCredits ?? 0;

  const showTastingFlights = getTastingFlights(event);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  return (
    <Container>
      {editing || showTastingFlights ? (
        <FormContainer
          enableReinitialize
          validationSchema={WineFormSchema}
          initialValues={initialValues}
          /* eslint-disable react/jsx-no-bind */
          formToUse={(props: any) => (
            <WineForm
              {...props}
              editing={true}
              toggleEdit={toggleEdit}
              mutationLoading={mutationLoading}
              numberOfAvailableCredits={numberOfAvailableCredits}
              numCreditsUsed={numCreditsUsed}
              event={event}
              starterKitCredits={Math.max(
                availableStarterKitCredits,
                starterKitCreditsUsed,
              )}
              errorNotification={errorNotification}
              setErrorNotification={setErrorNotification}
            />
          )}
          onSubmit={async (values: FormValues, { setErrors }) => {
            await handleUpdateEvent({
              eventId: event.eventId,
              values,
              setErrors,
              toggleEdit,
              eventUpdateMutation,
              setErrorNotification,
              setInitialValues,
              trackEventsV2,
              event,
            });
          }}
        />
      ) : orderId ? (
        <OrderSummary orderId={orderId} />
      ) : (
        <WineSummary
          event={event}
          ceEmail={user.email}
          toggleEdit={toggleEdit}
          wineNeeded={initialValues.wineNeeded}
          numberOfAvailableCredits={numberOfAvailableCredits}
          numCreditsUsed={numCreditsUsed}
        />
      )}
    </Container>
  );
};

export default Wine;
