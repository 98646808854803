import React, { Component, ReactNode } from 'react';

import Layout from '../components/MainLayout/layout/layout';
import { getCurrentUserId } from '../utils/utils';
import Subscriptions from '../components/Subscriptions';

interface SubscriptionsPageProps {
  location: any;
}

class SubscriptionsPage extends Component<SubscriptionsPageProps, {}> {
  render() {
    const { location } = this.props;
    const currentUser = getCurrentUserId(location);

    return (
      <Layout selectedUserId={currentUser}>
        <h1>SUBSCRIPTIONS page</h1>
        <Subscriptions selectedUserId={currentUser} />
      </Layout>
    );
  }
}

export default SubscriptionsPage;
