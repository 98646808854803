import React from 'react';

export const SvgImport = (props: any) => (
  <svg width="40" height="40" viewBox="0 0 40 40" {...props}>
    <defs>
      <path
        id="import_svg__a"
        d="M20 10a10 10 0 0 1 10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 3a1 1 0 0 1 .39.08 1 1 0 0 1 .32.21l3 3a1 1 0 0 1 0 1.42 1 1 0 0 1-1.42 0L21 18.41V24a1 1 0 0 1-2 0v-5.66l-1.31 1.23a1 1 0 0 1-1.38-1.45l3-2.86a1 1 0 0 1 .13-.09.82.82 0 0 1 .46-.17z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <mask id="import_svg__b" fill="#fff">
        <use href="#import_svg__a" />
      </mask>
      <use fill="#000" fill-rule="nonzero" href="#import_svg__a" />
      <g fill="#1A1A1A" mask="url(#import_svg__b)">
        <path d="M0 0h40v40H0z" />
      </g>
    </g>
  </svg>
);
