import React, { useState } from 'react';
import { Query } from 'react-apollo';
import styled from '@emotion/styled';
import Login from './Login';
import Logout from './Logout';
import { HOMEPAGE_GET_USER_QUERY } from '../../queries/HomePage';

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Authentication: React.FunctionComponent<any> = (props: any) => {
  return (
    <Query query={HOMEPAGE_GET_USER_QUERY}>
      {({ loading, error, data }) => {
        if (loading || !data || !data.viewer) return null;
        if (error) return `Error! ${error.message}`;
        const { user } = data.viewer;
        const isCeo =
          user &&
          user.permissions &&
          user.permissions.oneCommerce.ceo &&
          user.permissions.oneCommerce.ceo.access;
        if (isCeo) {
          const { firstName, lastName, userId } = user;
          return (
            <UserInfo>
              <div>User Info</div>
              <div>
                {firstName} {lastName} {userId}
              </div>
              <Logout />
            </UserInfo>
          );
        }
        return <Login />;
      }}
    </Query>
  );
};

export default Authentication;
