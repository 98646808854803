import React, { useState, useEffect, memo } from 'react';
import { useLazyQuery } from '@apollo/client';
import FeedProps from './types/FeedProps';
import Activity from './ActionEngineCard/Activity';
import FEED_QUERY from './queries/feedQuery';
import ActivityEntry from './types/ActivityEntry';
import CardsLoading from './CardsLoading';
import { cardButtonClicked, cardArchiveClicked } from './utils/helpers';
import {
  makeStyles,
  useTheme,
  useMediaQuery,
  Grid,
  ThemeType,
} from '@onehope/design-system-v2';
import Slider from './Slider';
import { SegmentEvents } from './ActionEngineCard/constants/segmentEvents';
import EmptyStateCard from './EmptyCard';
import { Waypoint } from 'react-waypoint';

const styles = makeStyles((theme: ThemeType) => ({
  slider: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    [theme.breakpoints.only('md')]: {
      width: '80%',
      minWidth: '288px',
      marginBottom: '0px',
      '& .slick-slide': {
        height: 'auto',
        marginBottom: '0px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: '288px',
    },
  },
}));

const Feed = ({
  admin,
  apolloClient,
  dealerId,
  openDialog,
  openVideoDialog,
  openButtonActionDialog,
  sendToTrackEventV2,
  userId,
}: FeedProps) => {
  const mobileTheme = useTheme();
  const isMobile = useMediaQuery(mobileTheme.breakpoints.down('sm'));
  const { slider } = styles();
  const [offset, setOffset] = useState(0);
  const limit = 30;
  // Fetched feed is read-only and local feed is mutatable.
  // Local feed is the one that we update locally for "opimistic updates".
  // It is set immediately upon fetch completion, but there's a flicker due to the setState delay in onCompleted
  // ..so that's why we are keeping track of both. Yuck. There's probably a better way to handle this.
  const [localFeed, setLocalFeed] = useState([] as ActivityEntry[]);
  const [fetchedFeed, setFetchedFeed] = useState([] as ActivityEntry[]);
  const [useLocalFeed, setUseLocalFeed] = useState(false);
  // Fetched Feed comes from the backend.
  const [fetchFeed, { loading, refetch }] = useLazyQuery(FEED_QUERY, {
    client: apolloClient,
    fetchPolicy: admin ? 'no-cache' : undefined,
    variables: {
      dealerId,
      userId,
      offset,
      limit,
    },
    onCompleted: (data) => {
      setFetchedFeed([...fetchedFeed, ...data?.viewer?.v2?.actionEngineFeed]);
      return setLocalFeed(data?.viewer?.v2?.actionEngineFeed);
    },
  });

  useEffect(() => {
    fetchFeed();
  }, []);

  const onLoadMore = () => {
    refetch &&
      refetch({
        userId,
        dealerId,
        limit,
        offset: offset + limit,
      });

    setOffset(offset + limit);
  };

  // Use localFeed if we make changes to the feed, otherwise use the fetchedFeed.
  const getDisplayFeed = () => (useLocalFeed ? localFeed : fetchedFeed);

  const trackEventV2 = ({
    activity,
    index,
    event,
  }: {
    activity?: ActivityEntry;
    index?: number;
    event: SegmentEvents;
  }): void | undefined => {
    if (sendToTrackEventV2 && typeof sendToTrackEventV2 !== undefined) {
      const feed = getDisplayFeed();
      if (feed === undefined) return;

      const getActivityForTrackEvent = (cardIndex: number | undefined) => {
        if (typeof cardIndex === 'number') {
          return feed[cardIndex];
        }
        return null;
      };

      const activityObj = activity || getActivityForTrackEvent(index);
      if (!activityObj) return;
      sendToTrackEventV2({
        event,
        activity: activityObj,
      });
    }
  };

  const archiveCardMutation = cardArchiveClicked(apolloClient);

  const onButtonClickedMutation = cardButtonClicked(apolloClient);
  const onCardButtonClicked = (id: string) => {
    // Handle special db changes on card click
    // e.g. success triggers, mark read
    onButtonClickedMutation(id);

    const feed = getDisplayFeed();
    const index = feed?.findIndex((item: ActivityEntry) => item.id === id);
    if (index !== -1) {
      const activity = feed[index];
      // Handle track event before modifying the feed
      trackEventV2({ activity, event: SegmentEvents.cardClicked });

      // do an "optimistic update" as well so we don't
      // refetch the feed and interrupt their display order etc.
      if (activity.buttonText === 'Mark Read') {
        const optimisticUpdate = [...feed];
        optimisticUpdate.splice(index, 1);
        setLocalFeed(optimisticUpdate);
        if (!useLocalFeed) {
          setUseLocalFeed(true);
        }
        // When we modify the feed list, the slider doesn't know that we went to the "next card"
        // So we need to manually trigger the card viewed event.
        // If we remove the last card in the list, the slider moves to the previous card,
        // which will actually trigger the card viewed event.
        // (In that case, newDisplayedCard will be undefined and we won't trigger the event here)
        const newDisplayedCard = optimisticUpdate[index];
        trackEventV2({
          activity: newDisplayedCard,
          event: SegmentEvents.cardViewed,
        });
      }
    }
  };

  const removeArchivedCard = (ledgerId: string) => {
    const feeds = getDisplayFeed();
    if (feeds) {
      const filteredActivities = feeds.filter(
        (activity) => activity.id !== ledgerId,
      );
      setLocalFeed(filteredActivities);
      if (!useLocalFeed) {
        setUseLocalFeed(true);
      }
    }
  };

  const activities =
    getDisplayFeed()?.map((feedItem: ActivityEntry) => (
      <Activity
        activity={feedItem}
        admin={admin ?? false}
        key={feedItem.id}
        openDialog={openDialog}
        openVideoDialog={openVideoDialog}
        openButtonActionDialog={openButtonActionDialog}
        sendToTrackEventV2={sendToTrackEventV2}
        onCardButtonClicked={onCardButtonClicked}
        archiveCardMutation={archiveCardMutation}
        removeArchivedCard={removeArchivedCard}
      />
    )) || null;

  if (loading && (!activities || !activities.length)) {
    return <CardsLoading />;
  }

  if (!activities || !activities.length) {
    return <EmptyStateCard />;
  }

  // A populated feed must be passed to the Slider.
  // We trigger the segment track event for "Card Viewed" in the Slider's onInit function.
  // onInit only fires once, so if it doesn't have the feed it will fail and we never get that first Card Viewed event.
  return admin ? (
    <>
      {activities}
      <Waypoint topOffset={'30%'} onEnter={onLoadMore} />
    </>
  ) : (
    <Grid className={slider}>
      <Slider arrows={!isMobile} trackEventV2={trackEventV2}>
        {activities}
      </Slider>
    </Grid>
  );
};

export default memo(
  Feed,
  (prev, current) => prev.openDialog === current.openDialog,
);
