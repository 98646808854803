import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

const {
  cssConstants: { font, textColor },
} = s;

export const containerStyle = `
  font-family: ${font};
  color: ${textColor};
  max-width: 432px;
  margin: 40px auto 40px auto;
  padding: 0 16px;
`;

export const Container = styled.div`
  ${containerStyle}
  display: flex;
  justify-content: center;
  height: 100vh;
`;

export const ContainerBlock = styled.div`
  ${containerStyle}
  display: block;
`;

export const TabContentContainer = styled.div`
  max-width: 432px;
  width: 100%;
  margin: auto;
  padding: 24px 0 156px 0;
  @media all and (min-width: 768px) {
    padding: 40px 0 156px 0;
  }
`;

export const FormWrapper = styled.form`
  max-width: 432px;
  margin: 40px auto 40px auto;
  padding: 0 16px;
  @media all and (min-width: 768px) {
    padding: 0;
  }
`;

export const FieldContainer = styled.div`
  margin-bottom: 24px;
`;

export const DetailsContainer = styled.div`
  margin-bottom: 40px;
`;

export const DetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DetailsTitle = styled.div`
  /* font-family: ProximaNova; */
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

export const StraightLine = styled.div`
  max-width: 432px;
  height: 1px;
  background-color: #dedede;
  margin: 15px 0;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 432px;
  padding-bottom: 16px;
`;

export const Key = styled.div`
  /* font-family: ProximaNova; */
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  margin-right: 8px;
`;

export const EditIcon = styled(CreateOutlinedIcon)`
  cursor: pointer;
`;

export const Value = styled.div`
  /* font-family: ProximaNova; */
  font-size: 16px;
  line-height: 22px;
  margin-right: 8px;
`;

export const ToggleLabel = styled.div`
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
`;
