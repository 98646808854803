import * as React from 'react';
import { navigate } from 'gatsby';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiMoreVertIcon from '@material-ui/icons/MoreVert';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
const { cssConstants } = s;

const options = ['Share Link', 'Manage Event', 'New Order', 'Donate'];

const Container = styled.div<{ isCardView: boolean }>`
  position: ${({ isCardView }) => (isCardView ? 'absolute' : 'relative')};
  right: 0;
  top: ${({ isCardView }) => (isCardView ? '8px' : '0px')};
  z-index: 100;
`;

const MenuItem = styled(MuiMenuItem)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
  }
`;

const MoreVertIcon = styled(MuiMoreVertIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

interface IconMenuProps {
  eventId: string | null | undefined;
  isCardView: boolean;
  setSelectedEventId: (eventId: string) => void;
  handleOrderAdd: (eventId: string) => void;
  toggleEventCopyUrlDialog?: (
    eventId: string | null | undefined,
    trinityPartyId: string | null | undefined,
  ) => void;
  isAcceptingOrders?: boolean | null | undefined;
  trinityPartyId?: string | null | undefined;
}

export default function IconMenu(props: IconMenuProps) {
  const { isCardView, setSelectedEventId } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewEvent = () => {
    return navigate(`/event?eventId=${props.eventId}`);
  };

  const handleDonate = () => {
    return navigate(`/event?eventId=${props.eventId}&tab=2`);
  };

  const handleCancel = () => {
    if (props.eventId) {
      setSelectedEventId(props.eventId);
      handleClose();
    }
  };
  const handleOrderAdd = () => {
    // console.log({ props });
    if (props.eventId) {
      props.handleOrderAdd(props.eventId);
      handleClose();
    }
  };

  const handleCopyEventLink = () => {
    if (props.eventId && props.toggleEventCopyUrlDialog) {
      props.toggleEventCopyUrlDialog(props.eventId, props.trinityPartyId);
      handleClose();
    }
  };

  return (
    <Container isCardView={isCardView}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ horizontal: -75, vertical: 'top' }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem key="Manage Event" onClick={handleViewEvent}>
          Manage event
        </MenuItem>
        {props.isAcceptingOrders && (
          <MenuItem key="Order" onClick={handleOrderAdd}>
            Place order
          </MenuItem>
        )}
        <MenuItem key="Copy" onClick={handleCopyEventLink}>
          Copy event link
        </MenuItem>
        {/*<MenuItem key="Cancel" onClick={handleCancel}>*/}
        {/*  Cancel event*/}
        {/*</MenuItem>*/}
        <MenuItem key="Donate" onClick={handleDonate}>
          Donate
        </MenuItem>
      </Menu>
    </Container>
  );
}
