import React from 'react';
import Cards from './Cards';
import Greeting from './Greeting';
import {
  makeStyles,
  ThemeType,
  Grid,
  useTheme,
  useMediaQuery,
} from '@onehope/design-system-v2';

import { CEDash_DashboardQuery_viewer_user } from '../../../queries/generatedTypes/CEDash_DashboardQuery';
import { ActivityEntry } from '@onehope/action-engine';

type HeaderProps = {
  user: CEDash_DashboardQuery_viewer_user;
  openVideoDialog?: (
    playbackId: string | undefined,
    ratio: string | undefined,
  ) => any;
  openButtonActionDialog?: (button: ActivityEntry['buttonAction']) => void;
  openDialog: boolean;
};

const useStyles = makeStyles((theme: ThemeType) => ({
  wrapper: {
    width: '100%',
    backgroundColor: 'white',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
    flexWrap: 'unset',
    [theme.breakpoints.down('md')]: {
      display: 'grid',
      boxShadow: 'none',
      marginBottom: '24px',
      backgroundColor: '#E9EAE9',
      gridTemplateColumns: 'repeat(1, minmax(320px, 1fr))',
      rowGap: '24px',
    },
  },
  greetingRowWrapper: {
    [theme.breakpoints.down('md')]: {
      gridRow: 2,
    },
  },
  cardsRowWrapper: {
    [theme.breakpoints.down('md')]: {
      gridRow: 1,
    },
  },
}));

function Header(props: HeaderProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { user, openVideoDialog, openButtonActionDialog, openDialog } = props;
  return (
    <Grid
      container
      direction={isDesktop ? 'row' : 'column-reverse'}
      className={classes.wrapper}
    >
      <Grid item xs={12} md={12} lg={6} className={classes.greetingRowWrapper}>
        <Greeting user={user} />
      </Grid>
      <Grid item xs={12} md={12} lg={6} className={classes.cardsRowWrapper}>
        <Cards
          openVideoDialog={openVideoDialog}
          openButtonActionDialog={openButtonActionDialog}
          openDialog={openDialog}
        />
      </Grid>
    </Grid>
  );
}

export default Header;
