import React, { useState } from 'react';
import styled from '@emotion/styled';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import { MuiButton } from '@onehope/design-system-ohw';
import AutoComplete from '../EventDetailsForm/AutoComplete';
import GET_CUSTOMERS_BY_SEARCH_QUERY from '../../../queries/CustomersBySearch';

interface CustomerNode {
  fullName?: string;
  firstName?: string;
  lastName?: string;
  organizationName?: string;
  email: string;
  mobilePhone: string;
  accountId: string;
}

interface Customer {
  node: CustomerNode;
}

const InputContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  @media all and (min-width: 968px) {
    max-width: 368px;
  }
`;

const OrContainer = styled.div`
  height: 26px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const OrText = styled.div`
  width: 32px;
  height: 26px;
  /* font-family: ProximaNova; */
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 3px;
  text-align: center;
  color: #000000;
  flex-direction: row;
  display: flex;
  justify-content: center;
`;

const NewCustomerButton = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 40px;
  color: #1a1a1a;
  cursor: pointer;
`;

const NewCustomerText = styled.div`
  /* font-family: ProximaNova; */
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 8px;
`;

const CustomerTextContainer = styled.div`
  width: 432px;
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HorizontalLine = styled.div`
  width: 148px;
  height: 1px;
  background-color: #dddddd;
`;

const formatCustomers = (data: any) => {
  return (
    get(data, 'viewer.user.customers.edges') &&
    get(data, 'viewer.user.customers.edges').map((customer: Customer) =>
      customer && customer.node
        ? {
            organizationName: customer.node.organizationName || '',
            fullName:
              customer.node.firstName && customer.node.lastName
                ? `${customer.node.firstName} ${customer.node.lastName}`
                : '',
            firstName: customer.node.firstName || '',
            lastName: customer.node.lastName || '',
            email: customer.node.email,
            phone: customer.node.mobilePhone,
            accountId: customer.node.accountId,
          }
        : '',
    )
  );
};

export default function SearchOrAddCustomer(props: any) {
  const {
    change,
    values,
    touched,
    isSubmitting,
    handleCustomerSelected,
    isHostNameMenuOpen,
    setIsHostNameMenuOpen,
    toggleAddCustomer,
  } = props;
  const { refetch: getCustomers } = useQuery(GET_CUSTOMERS_BY_SEARCH_QUERY, {
    variables: { search: '', onlyForSearch: false },
    skip: true,
  });

  const [customers, setCustomers] = useState([]);

  const handleHostNameChange = async (
    event: React.ChangeEvent<{ value: string }>,
  ) => {
    event.preventDefault();
    change('fullName', event);
    const search = event.target.value;
    if (search && search !== '') {
      const onlyForSearch = true;
      setIsHostNameMenuOpen(true);
      await getCustomers({ search, onlyForSearch }).then(results => {
        if (get(results, 'data.viewer')) {
          setCustomers(formatCustomers(results.data));
        }
      });
    } else {
      setIsHostNameMenuOpen(false);
      setCustomers([]);
    }
  };

  const handleOuterClick = (field: string) => {
    setIsHostNameMenuOpen(false);
  };
  return (
    <InputContainer>
      <AutoComplete
        id="nameDropDown"
        inputId="fullName"
        isWithinDialog={true}
        label="Search existing customers"
        placeholder="Search first and last names"
        value={values.fullName}
        touched={touched.fullName}
        isSubmitting={isSubmitting}
        isMenuOpen={isHostNameMenuOpen}
        handleCustomerSelected={handleCustomerSelected}
        handleInputChange={handleHostNameChange}
        handleOuterClick={handleOuterClick}
        objectPredictions={customers}
        handleNewCustomerOpen={toggleAddCustomer}
      />
      <OrContainer>
        <HorizontalLine />
        <OrText>OR</OrText>
        <HorizontalLine />
      </OrContainer>
      <NewCustomerButton>
        <MuiButton
          type="DASHED"
          fullWidth
          disableRipple
          onClick={() => toggleAddCustomer(values)}
        >
          <CustomerTextContainer>
            <AddCircleOutline />
            <NewCustomerText>add new customer</NewCustomerText>
          </CustomerTextContainer>
        </MuiButton>
      </NewCustomerButton>
    </InputContainer>
  );
}
