import ProductV2 from '../types/ProductV2';
import React from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system-ohw';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';

const { cssConstants: { textColor, neutral13 } } = s;

const ItemCount = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${textColor};
`;

type CartHeaderProps = {
  items: {
    quantity: number;
    product: ProductV2;
  }[];
  shouldShow: boolean;
  setShouldShow: Function;
};

export default ({ items, shouldShow, setShouldShow }: CartHeaderProps) => {
  const itemCount = items?.reduce((acc, { quantity }) => acc + quantity, 0);

  const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 10px 0;
    border-bottom: 1px solid ${neutral13};
    margin-bottom: 8px;
  `;

  return (
    <HeaderContainer>
      <ItemCount>
        ({itemCount}) Item{itemCount === 1 ? '' : 's'}
      </ItemCount>
      <IconButton aria-label="close" onClick={() => setShouldShow(!shouldShow)}>
        <RemoveIcon fontSize="small" />
      </IconButton>
    </HeaderContainer>
  );
};
