import gql from 'graphql-tag';

export const CustomerFragment = gql`
  fragment Customer_customer on Customer {
    id
    isHost
    isSubscriber
    isPreferredCustomer
    impactTotal
    dateOfBirth
    fullName
    firstName
    lastName
    accountId
    email
    mobilePhone
    createDate
    shippingAddress {
      addressId
      addressLineOne
      addressLineTwo
      city
      state
      zip
    }
    contactPreferences {
      id
      contactPreferenceId
      interestedIn {
        hostWineTasting
        joinWineClub
        becomePC
        joinAsCE
        corporateGifting
      }
    }
    preferredCustomerData {
      preferredCustomerStatus
      preferredCustomerJoinDate
    }
    lifeTimeSpend
    lastOrderDate
    contactType
    organizationName
    notes {
      id
      noteId
      text
      subjectId
      dateCreated
      dateUpdated
    }
    pointBalance
  }
`;

const CUSTOMER_DETAILS_QUERY = gql`
  query CEDash_CustomerDetailsPage($accountId: String) {
    viewer {
      id
      user {
        id
        userId
        permissions {
          id
          oneCommerce {
            admin {
              access
            }
          }
        }
      }
      customer(accountId: $accountId) {
        ...Customer_customer
      }
    }
  }
  ${CustomerFragment}
`;

export default CUSTOMER_DETAILS_QUERY;
