import gql from 'graphql-tag';

const mutation = gql`
  mutation EventEditMutation($input: EventEditInput!) {
    eventEdit(input: $input) {
      eventId
      entryEdge {
        cursor
        node {
          id
          eventId
          donationId
          eventId
          eventDate
          closeDate
          hostFirstName
          hostLastName
          trinityPartyStatus
          trinityPartyId
          hostKitOrderNumbers
          hostKitQuantity
          donationInfo {
            id
            ordersSubTotal
            donationAmount
            donationType
            status
            lobMemo
          }
        }
      }
      event {
        id
        eventId
        donationId
        eventId
        eventDate
        closeDate
        hostFirstName
        hostLastName
        trinityPartyStatus
        trinityPartyId
        hostKitOrderNumbers
        hostKitQuantity
        hostQuote
        displayPreferences {
          id
          donationGoal
          supporters
        }
        donationInfo {
          id
          ordersSubTotal
          donationAmount
          donationType
          status
          lobMemo
          donationGoal
          nonProfit {
            id
            nonProfitName
            nonProfitTaxId
            nonProfitId
            isPreApproved
            nonProfitAddress {
              optionalName
              addressLineOne
              addressLineTwo
              city
              state
              zip
              phoneNumber
            }
          }
        }
      }
    }
  }
`;

export default mutation;
