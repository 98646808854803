import React from 'react';

export const SvgExport = (props: any) => (
  <svg width="40" height="40" viewBox="0 0 40 40" {...props}>
    <defs>
      <path
        id="export_svg__a"
        d="M19.976 19.02c.55 0 .997.448.997.999v5.628l1.306-1.257a.996.996 0 1 1 1.376 1.437l-2.991 2.894a.997.997 0 0 1-1.396-.01l-2.99-2.994a1.003 1.003 0 0 1 1.415-1.417l1.286 1.297V20.02c0-.551.446-.998.997-.998zm0-8.02a5.982 5.982 0 0 1 5.653 4.03 4.986 4.986 0 0 1 4.062 3.22 4.993 4.993 0 0 1-.992 5.092.997.997 0 0 1-1.746-.6.999.999 0 0 1 .25-.727 2.996 2.996 0 0 0-2.242-4.99h-.06a.997.997 0 0 1-.997-.798 3.989 3.989 0 0 0-3.908-3.198 3.989 3.989 0 0 0-3.908 3.198.997.997 0 0 1-.997.798h-.1a2.996 2.996 0 0 0-2.243 4.99.998.998 0 0 1-.512 1.765.997.997 0 0 1-.983-.498 4.993 4.993 0 0 1-.94-5.049 4.986 4.986 0 0 1 4.01-3.204A5.982 5.982 0 0 1 19.976 11z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <mask id="export_svg__b" fill="#fff">
        <use href="#export_svg__a" />
      </mask>
      <use fill="#000" fill-rule="nonzero" href="#export_svg__a" />
      <g fill="#1A1A1A" mask="url(#export_svg__b)">
        <path d="M0 0h40v40H0z" />
      </g>
    </g>
  </svg>
);
