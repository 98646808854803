import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import Decimal from 'decimal.js';
import { DateTime } from 'luxon';
import { Currency } from '@onehope/design-system';

import {
  DetailsContainer,
  DetailsTitle,
  StraightLine,
  Line,
  Value,
} from '../../Event/TabContent/index.styles';
import SummaryLine from '../../Contact/TabContent/SummaryLine';
import { Address } from '../EventDetailsForm/EventDetailsForm';

import {
  CEDash_EventDetailsPage_viewer_event as EventType,
  CEDash_EventDetailsPage_viewer_event_donationInfo as DonationInfoType,
} from '../../../queries/generatedTypes/CEDash_EventDetailsPage';

const { cssConstants } = s;

interface DonationSummaryProps {
  event: EventType;
  donation: DonationInfoType;
  editable: boolean;
  satelliteEvent: boolean;
  disable: boolean;
}

const SummaryWrapper = styled.div<{ disable: boolean }>`
  color: ${props => (props.disable ? '#cccccc' : cssConstants.textColor)};
`;

const capitalize = (value: string) => {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export default function DonationSummary(props: DonationSummaryProps) {
  const { donation, event, editable, satelliteEvent, disable } = props;
  const { no500DonationThreshold } = useFlags();

  const qualified = donation?.ordersSubTotal > 500;
  const closedEvent = event?.status === 'CLOSED';
  const toolTipText = qualified
    ? 'This event has qualified for a custom charity selection since the orders subtotal is at least $500'
    : 'The event must earn $500 in sales to qualify the Host to select a custom charity';
  const donationSubmitted = donation?.nonProfit?.nonProfitName;
  const { hostKitDonation, donationAmount, ordersSubTotal } = donation || {};
  // Donation sales subtotal is the amount from orders that contributed directly to the donation.
  const donationFromOrders = new Decimal(donationAmount || 0).minus(
    hostKitDonation || 0,
  );
  // The donation is always 10 percent more than the order donation amount.
  const donationSalesSubTotal = donationFromOrders.times(10).toNumber();

  const nonProfitAddress = donation?.nonProfit?.nonProfitAddress
    ? donation.nonProfit.nonProfitAddress
    : null;
  return (
    <SummaryWrapper disable={disable}>
      <DetailsContainer>
        <DetailsTitle>Summary</DetailsTitle>
        <StraightLine />
        {!satelliteEvent && !no500DonationThreshold && (
          <SummaryLine
            title="Status"
            value={qualified ? 'Qualified' : 'Not qualified'}
            valueToolTipText={toolTipText}
          />
        )}
        <SummaryLine
          title="Event sales subtotal"
          value={<Currency rawValue={ordersSubTotal || 0} />}
        />
        <SummaryLine
          title="Donation sales subtotal"
          value={<Currency rawValue={donationSalesSubTotal || 0} />}
        />
        {!satelliteEvent && !!hostKitDonation && (
          <SummaryLine
            title="Host Kit donation"
            value={<Currency rawValue={hostKitDonation} />}
          />
        )}
        <SummaryLine
          title="Donation raised"
          value={<Currency rawValue={donationAmount || 0} />}
        />
      </DetailsContainer>
      {!editable && (
        <DetailsContainer>
          <DetailsTitle>Charity Details</DetailsTitle>
          <StraightLine />
          {donationSubmitted || satelliteEvent ? (
            <>
              {satelliteEvent ? (
                <div>
                  <SummaryLine
                    title="Event sales subtotal"
                    value={<Currency rawValue={ordersSubTotal || 0} />}
                  />
                  <SummaryLine
                    title="Name of charity"
                    value={event?.hostNonProfitName}
                  />
                  {closedEvent && (
                    <SummaryLine
                      title="Donation status"
                      value={capitalize(donation?.status)}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <SummaryLine
                    title="Submission date"
                    value={DateTime.fromISO(event?.eventDate)
                      .toUTC()
                      .toLocaleString(DateTime.DATE_SHORT)}
                  />
                  <SummaryLine
                    title="Submission status"
                    value={donation?.status.replace(/^\w/, (c: string) =>
                      c.toUpperCase(),
                    )}
                  />
                  <SummaryLine
                    title="Nonprofit name"
                    value={donation?.nonProfit?.nonProfitName}
                  />
                  {donation?.nonProfit?.attentionToName && (
                    <SummaryLine
                      title="Attention to"
                      value={donation?.nonProfit?.attentionToName}
                    />
                  )}
                  {nonProfitAddress?.addressLineOne && (
                    <SummaryLine
                      title="Address"
                      address={
                        nonProfitAddress
                          ? ({
                              addressLineOne: nonProfitAddress.addressLineOne,
                              addressLineTwo: nonProfitAddress.addressLineTwo,
                              city: nonProfitAddress.city,
                              state: nonProfitAddress.state,
                              zip: nonProfitAddress.zip,
                            } as Address)
                          : undefined
                      }
                    />
                  )}
                  <SummaryLine
                    title="Tax ID #"
                    value={donation?.nonProfit?.nonProfitTaxId}
                  />
                </div>
              )}
            </>
          ) : (
            <Line>
              <Value>No donation submitted</Value>
            </Line>
          )}
        </DetailsContainer>
      )}
    </SummaryWrapper>
  );
}
