import React from 'react';
import { css } from '@emotion/core';
import Carousel from 'react-slick';
import Media from 'react-media';

import {
  blogDesktopCarouselContainerStyle,
  slickStyle,
  slickThemeStyle,
} from './BlogCarouselStyles';

export type CardCarouselProps = {
  children: any;
  carouselCss?: any;
};

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
};

const MAX_SLIDES = 6;

export default ({ children, carouselCss = null }: CardCarouselProps) => (
  <React.Fragment>
    <div
      css={[
        slickStyle,
        slickThemeStyle,
        carouselCss,
        // css`
        //   grid-template-columns: repeat(1, 100%);
        // `,
      ]}
    >
      <Media query="(max-width: 600px)">
        {(matches: boolean) =>
          matches && (
            <Carousel {...settings}>
              {makeGrid(children.slice(0, MAX_SLIDES), 1)}
            </Carousel>
          )
        }
      </Media>
      <Media query="(min-width: 601px) and (max-width: 1023px)">
        {(matches: boolean) =>
          matches && (
            <Carousel {...settings}>
              {makeGrid(children.slice(0, MAX_SLIDES * 2), 2)}
            </Carousel>
          )
        }
      </Media>
      <Media query="(min-width: 1024px) and (max-width: 1634px)">
        {(matches: boolean) =>
          matches && (
            <Carousel {...settings}>
              {makeGrid(children.slice(0, MAX_SLIDES * 3), 3)}
            </Carousel>
          )
        }
      </Media>
      <Media query="(min-width: 1635px)">
        {(matches: boolean) =>
          matches && (
            <Carousel {...settings}>
              {makeGrid(children.slice(0, MAX_SLIDES * 4), 4)}
            </Carousel>
          )
        }
      </Media>
    </div>
  </React.Fragment>
);

function makeGrid(items: Array<object>, itemsPerRow: number = 1) {
  const gridItems = [];
  const numberOfRows = Math.ceil(items.length / itemsPerRow);

  for (let i = 0; i < numberOfRows; i++) {
    const offset = i * itemsPerRow;
    const rowItems = items.slice(offset, offset + itemsPerRow);
    const rowCount = Math.min(rowItems.length, itemsPerRow);

    gridItems.push(
      <div
        key={i}
        css={css`
          display: grid !important;
          grid-template-columns: repeat(${rowCount}, 288px) !important;
          grid-gap: 48px;
          justify-content: center;
        `}
      >
        {rowItems}
      </div>,
    );
  }

  return gridItems;
}
