import * as React from 'react';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { styles as s } from '@onehope/design-system';
import { CreditsPage_user_credits_edges_node as CreditType } from '../../../../queries/generatedTypes/CreditsPage_user';
import { isCreditExpired } from '../helpers';

const { cssConstants } = s;

const GridItem = styled.div<{ creditCount: number }>`
  position: relative;
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 16px;
  max-width: 100%;
  @media all and (min-width: 768px) {
    padding: 24px;
    max-width: ${({ creditCount }) => (creditCount < 3 ? '356px' : '100%')};
  }
  :hover {
    cursor: pointer;
    background-color: ${cssConstants.neutral2};
  }
`;

const GridBlock = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  padding-bottom: 8px;
`;

const GridText = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
`;

const GridContainer = styled.div``;

interface CreditsCardProps {
  credit: CreditType | null;
  creditCount: number;
}

const capitalizeString = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export default function CreditsCard(props: CreditsCardProps) {
  const { credit, creditCount } = props;

  if (credit) {
    const timeZone = credit?.timeZone ? credit.timeZone : 'America/Chicago';
    return (
      <GridItem creditCount={creditCount}>
        <GridContainer>
          <GridBlock>
            Status:{' '}
            {credit.status && !isCreditExpired(credit) ? (
              <GridText>{capitalizeString(credit.status)}</GridText>
            ) : (
                <GridText>{capitalizeString('expired')}</GridText>
              )}
          </GridBlock>
          <GridBlock>
            Issue date:{' '}
            {credit.dateCreated && (
              <GridText>
                {DateTime.fromISO(credit.dateCreated, {
                  zone: timeZone,
                }).toLocaleString(DateTime.DATE_SHORT)}
              </GridText>
            )}
          </GridBlock>
          <GridBlock>
            Earned from event:{' '}
            {credit?.earnedFromEvent?.trinityPartyId ? (
              <GridText>{credit?.earnedFromEvent?.trinityPartyId}</GridText>
            ) : (
                <GridText>N/A</GridText>
              )}
          </GridBlock>
          <GridBlock>
            Redeemed for:{' '}
            {credit?.event?.hostFullName && (
              <GridText>{credit?.event?.hostFullName}</GridText>
            )}
          </GridBlock>
          <GridBlock>
            Expires:{' '}
            <GridText>
              {credit?.expirationDate &&
                DateTime.fromISO(credit?.expirationDate, {
                  zone: timeZone,
                }).toLocaleString(DateTime.DATE_SHORT)}
            </GridText>
          </GridBlock>
        </GridContainer>
      </GridItem>
    );
  }
  return null;
}
