import { gql } from '@apollo/client';

const FEED_QUERY = gql`
  query ActionEngineFeedQuery(
    $dealerId: String
    $userId: String
    $limit: Int
    $offset: Int
  ) {
    viewer {
      v2 {
        actionEngineFeed(
          dealerId: $dealerId
          userId: $userId
          limit: $limit
          offset: $offset
        ) {
          id
          cardTemplateId
          title
          message
          dateCreated
          dateDeleted
          dateSucceeded
          dateArchived
          dateSaved
          isDeleted
          category
          buttonText
          buttonAction {
            action
            name
            phoneNumber
            body
            emailAddress
            subject
            url
            firstName
            lastName
          }
          image {
            src
            alt
          }
          muxVideo {
            assetId
            playbackId
            ratio
          }
          createContext {
            changeType
            templateName
            triggerType
            userName
            cardRuleContext {
              audienceDirection
              audienceName
            }
          }
          deleteContext {
            changeType
            templateName
            triggerType
            userName
            cardRuleContext {
              audienceDirection
              audienceName
            }
          }
          dynamicDataParams {
            userId
            subjectUserId
          }
        }
      }
    }
  }
`;

export default FEED_QUERY;
