import * as React from 'react';
import { Link } from 'gatsby';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import _get from 'lodash/get';
import { Query, Mutation } from 'react-apollo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from '@emotion/styled';
import Layout from 'antd/lib/layout';
import Menu from 'antd/lib/menu';
import 'antd/lib/menu/style/index.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import UserLogoutMutation from '../../../mutations/Authentication/LogoutMutation';
import LoginTrinitySsoMutation from '../../../mutations/CE/LoginTrinitySsoMutation';
import siderStyle, { siderContainer } from './siderStyles';
import { styles } from '@onehope/design-system';
import { OneHopeLogoWhite } from '@onehope/design-system-ohw';
import { resetSegment, trackEvent } from '../../../utils/segment/tracking';

const {
  antDesignStyles: {
    icon: { Icon },
  },
} = styles;

const { Sider } = Layout;
const { SubMenu } = Menu;

interface SiderNavProps {
  defaultSelectedKeys: Array<string>;
  // defaultOpenKeys: Array<string>;
  collapsed: boolean;
  selectedUserId: string;
  innerWidth: number;
  toggle: () => void;
}

const GET_USER_QUERY = gql`
  query CEDash_UserQueryV1 {
    viewer {
      id
      user {
        id
        email
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
      }
    }
  }
`;

const SiderBarHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  background-color: #1a1a1a;
`;

/**
 * This part is hit in ce dashboard after login.
 * But trackEvent is not sending the event to Segment. Why?
 * Investigation pending...
 */
function logoutSuccess() {
  resetSegment();
  // segmentCookie({ email: undefined });
  window.location.href = process.env.GATSBY_HOPECOMMERCE_URL;
}

function trackEventStaging(eventName: string) {
  if (
    process.env.GATSBY_BUILD_ENV === 'staging' ||
    process.env.GATSBY_BUILD_ENV === 'development'
  ) {
    trackEvent({
      event: eventName,
      properties: {},
    });
  }
}

export default function SiderComponent(props: SiderNavProps) {
  const {
    defaultSelectedKeys,
    defaultOpenKeys,
    collapsed,
    innerWidth,
    toggle,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { trainingCenter } = useFlags();
  const workRampUrl = trainingCenter
    ? process.env.GATSBY_WORKRAMP_URL_V2
    : process.env.GATSBY_WORKRAMP_URL;

  const [loginTrinitySso, { loading: mutationLoading }] = useMutation(
    LoginTrinitySsoMutation,
  );

  const handleOpenBackOffice = async () => {
    await loginTrinitySso({
      variables: { input: {} },
    }).then(response => {
      const ua = navigator.userAgent.toLowerCase();
      let isSafari = null;
      if (ua.indexOf('safari') !== -1) {
        if (ua.indexOf('chrome') > -1) {
          isSafari = false;
        } else {
          isSafari = true;
        }
      }
      const redirectUrl =
        response?.data?.loginTrinitySso?.redirectUrl ||
        `${process.env.GATSBY_CE_BACK_OFFICE}`;
      trackEventStaging('Reports Clicked');
      if (isMobile || isSafari) window.open(`${redirectUrl}`, '_self');
      else window.open(`${redirectUrl}`, '_blank');
    });
  };

  return (
    <Query query={GET_USER_QUERY}>
      {({ loading, error, data }) => {
        if (error) return <div>`Error! ${error.message}`</div>;
        let isLoggedInAsCeo = false;
        let loggedInUserId = null;

        if (!loading) {
          isLoggedInAsCeo =
            !data || !data.viewer || !data.viewer.user
              ? false
              : _get(data.viewer.user, 'permissions.oneCommerce.ceo.access');
          loggedInUserId =
            !data || !data.viewer ? null : _get(data.viewer.user, 'id');
        }

        let menu;
        if (!isLoggedInAsCeo) {
          menu = (
            <Menu
              theme="dark"
              mode="inline"
              // defaultOpenKeys={defaultOpenKeys}
              defaultSelectedKeys={defaultSelectedKeys}
            >
              <Menu.Item key="1">
                <Icon type="login" />
                <span>Login</span>
              </Menu.Item>
            </Menu>
          );
        } else {
          menu = (
            <Menu
              theme="dark"
              mode="inline"
              // defaultOpenKeys={defaultOpenKeys}
              defaultSelectedKeys={defaultSelectedKeys}
              onSelect={() => {
                if (!collapsed && innerWidth < 1080) {
                  toggle();
                }
              }}
            >
              <Menu.Item key="1" disabled={!isLoggedInAsCeo}>
                <Link to={`/dashboard`}>
                  <Icon type="home" />
                  <span>Home</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="2" disabled={!isLoggedInAsCeo}>
                <Link to={`/events`}>
                  <Icon type="calendar" />
                  <span>Events</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="3" disabled={!isLoggedInAsCeo}>
                <Link to={`/credits`}>
                  <Icon type="credit-card" />
                  <span>Credits</span>
                </Link>
              </Menu.Item>

              {/*<Menu.Item key="3" disabled={!isLoggedInAsCeo}>*/}
              {/*  <Link to={`/customers`}>*/}
              {/*    <Icon type="team" />*/}
              {/*    <span data-cypress="customers-link">Customers</span>*/}
              {/*  </Link>*/}
              {/*</Menu.Item>*/}
              <Menu.Item key="4" disabled={!isLoggedInAsCeo}>
                <Link to={`/contacts`}>
                  <Icon type="team" />
                  <span data-cypress="customers-link">Contacts</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="5" disabled={!isLoggedInAsCeo}>
                <Link to={`/customer-orders`}>
                  <Icon type="tag" />
                  <span>Orders</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="6" disabled={!isLoggedInAsCeo}>
                <div onClick={handleOpenBackOffice}>
                  <Icon type="form" />
                  <span>Reports</span>
                </div>
              </Menu.Item>
              <Menu.Item key="7" disabled={!isLoggedInAsCeo}>
                <a
                  href={workRampUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => trackEventStaging('Training Center Clicked')}
                >
                  <Icon type="trophy" />
                  <span>Training Center</span>
                </a>
              </Menu.Item>
              <Menu.Item key="8" disabled={!isLoggedInAsCeo}>
                <a
                  href={`${process.env.GATSBY_HELP_CENTER_URL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackEventStaging('Help Center Clicked');
                  }}
                >
                  <Icon type="compass" />
                  <span>Help Center</span>
                </a>
              </Menu.Item>
              <Menu.Item key="9" disabled={!isLoggedInAsCeo}>
                <a
                  href={`${process.env.GATSBY_VINE_URL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => trackEventStaging('The Vine Clicked')}
                >
                  <Icon type="file-text" />
                  <span>The VINE</span>
                </a>
              </Menu.Item>
              <Menu.Item key="10" disabled={!isLoggedInAsCeo}>
                <a
                  href={`${process.env.GATSBY_HOPECOMMERCE_URL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => trackEventStaging('onehopewine.com Clicked')}
                >
                  <Icon type="home" />
                  <span>onehopewine.com</span>
                </a>
              </Menu.Item>
              <Menu.Item key="11" disabled={!isLoggedInAsCeo}>
                <a
                  href={`${process.env.GATSBY_HOPECOMMERCE_URL}/account`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => trackEventStaging('My Account Clicked')}
                >
                  <Icon type="user" />
                  <span>My Account</span>
                </a>
              </Menu.Item>
              <Menu.Item key="12">
                <Mutation
                  mutation={UserLogoutMutation}
                  onCompleted={logoutSuccess}
                >
                  {(logout: any, { loading, client }) => (
                    <div
                      onClick={() =>
                        logout({ variables: { input: {} } }).then(
                          () =>
                            client &&
                            typeof client.resetStore === 'function' &&
                            client.resetStore(),
                        )
                      }
                    >
                      <Icon type="logout" />
                      <span>Logout</span>
                    </div>
                  )}
                </Mutation>
              </Menu.Item>
            </Menu>
          );
        }

        return (
          <div css={siderContainer}>
            <Sider
              width={256}
              breakpoint="lg"
              trigger={null}
              collapsible
              collapsed={collapsed}
              collapsedWidth={innerWidth < 1200 ? 0 : 80}
              css={siderStyle}
              style={{ height: !collapsed ? '100vh' : undefined }}
            >
              {!collapsed && (
                <SiderBarHeader>
                  <OneHopeLogoWhite />
                </SiderBarHeader>
              )}
              {menu}
            </Sider>
          </div>
        );
      }}
    </Query>
  );
  // }
}
