import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { navigate } from 'gatsby';
import { MuiButton } from '@onehope/design-system-ohw';
import { getCreditOptions, getCreditText } from './helpers';
import SummaryLine from '../../../Contact/TabContent/SummaryLine';
import CircularProgress from '@material-ui/core/CircularProgress';

import EventResendOrderLinkMutation from '../../../../mutations/Event/EventResendOrderLinkMutation';

// Styles
import {
  DetailsHeader,
  DetailsTitle,
} from '../../../Contact/TabContent/index.styles';
import {
  Link,
  ChangeHost,
} from '../../../Contact/TabContent/SummaryLine/index.styles';
import { StraightLine, TabContentContainer } from '../index.styles';
import { SvgPencil } from '../../../../assets/pencil';

import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { eventIsAcceptingOrders } from '../../../Events/EventsTable/helpers';
import { getDays } from '../../Notifications';
import WarningCard from './WarningCard';
import useToggle from '../../../../utils/useToggleHook';

interface WineSummaryProps {
  event: EventType;
  ceEmail: string | null;
  wineNeeded: string;
  toggleEdit: () => void;
  numberOfAvailableCredits: number;
  numCreditsUsed: number;
}

const Button = styled(MuiButton)`
  && {
    margin-top: 24px;
  }
`;

const getButtonContent = (
  hostBuying: boolean,
): { type: string; text: string } => {
  return hostBuying
    ? { type: 'SECONDARY', text: 'Resend order link to host' }
    : { type: 'PRIMARY', text: 'Select wines for tasting' };
};

const WineSummary = ({
  event,
  ceEmail,
  wineNeeded,
  toggleEdit,
  numberOfAvailableCredits,
  numCreditsUsed,
}: WineSummaryProps) => {
  const [resendOrderLink, { loading: mutationLoading }] = useMutation(
    EventResendOrderLinkMutation,
  );

  const hostBuying =
    event.hostKitPurchaser === 'HOST' && ceEmail !== event.hostEmail;
  const purchaser = hostBuying ? `Host (${event.hostEmail})` : 'Myself';
  const dateSent = event?.lastOrderLinkInfo?.dateSent
    ? DateTime.fromISO(event.lastOrderLinkInfo.dateSent)
        .toUTC()
        .toLocaleString(DateTime.DATE_SHORT)
    : '';

  const wineQuantity = event.hostKitQuantity === 1 ? '6' : '12';
  const hostKitQuantity = event.hostKitQuantity
    ? (event.hostKitQuantity * 6).toString()
    : '';
  const hostKitCredits =
    event.hostKitCredits || event.hostKitCredits === 0
      ? event.hostKitCredits.toString()
      : '';
  const creditOptions = getCreditOptions({
    numberOfAvailableCredits,
    numCreditsUsed,
    hostKitQuantity,
  });
  const creditText = getCreditText(creditOptions, hostKitCredits);
  const buttonContent = getButtonContent(hostBuying);

  const isAcceptingOrders = eventIsAcceptingOrders(event);

  const hostKitOrderLink = `${process.env.GATSBY_HOST_KIT_BUILDER_URL}/?hostKitOrderLinkId=${event.hostKitOrderLinkId}`;

  const daysUntilEvent = getDays(event.eventDate);
  const showWineNotification =
    daysUntilEvent < 10 &&
    daysUntilEvent > 0 &&
    ((!event.wineTastingOrderIds?.length && wineNeeded === 'YES') ||
      wineNeeded === 'SKIP');
  const WARNING_MESSAGE = `your event is in ${daysUntilEvent} ${
    daysUntilEvent === 1 ? 'day' : 'days'
  }. If you order your wine today, it may not arrive in time for your event.`;

  const {
    value: notificationOpen,
    setTrue: setNotificationOpen,
    setFalse: setNotificationClosed,
  } = useToggle(showWineNotification);

  const handleHostChange = () => {
    return navigate(`/event?eventId=${event.eventId}&tab=0`);
  };

  const goToHostKitOrderLink = () => {
    return window.open(hostKitOrderLink, '_blank');
  };

  const handleButtonAction = async () => {
    switch (buttonContent.type) {
      case 'PRIMARY': {
        goToHostKitOrderLink();
        break;
      }
      case 'SECONDARY': {
        if (mutationLoading) {
          return;
        }
        await resendOrderLink({
          variables: { input: { eventId: event.eventId } },
        })
          .then((data: any) => {
            console.log({ data });
          })
          .catch((errors: any) => {
            console.log('errors', errors);
            return;
          });
        break;
      }
    }
  };

  return (
    <TabContentContainer>
      {showWineNotification && notificationOpen && (
        <WarningCard
          message={WARNING_MESSAGE}
          setClose={setNotificationClosed}
        />
      )}
      <DetailsHeader>
        <DetailsTitle>Details</DetailsTitle>
        {isAcceptingOrders && (
          <SvgPencil onClick={toggleEdit} cursor="pointer" />
        )}
      </DetailsHeader>
      <StraightLine />
      {wineNeeded === 'YES' && isAcceptingOrders && (
        <div>
          <SummaryLine title="Wine" value="Yes, wine is needed" />
          <SummaryLine
            title="Bottles of wine"
            value={`${wineQuantity} bottles`}
          />
          <SummaryLine title="Credits used" value={creditText} />
          <SummaryLine
            title="Who's ordering"
            value={
              hostBuying ? (
                <div>
                  <div>{purchaser}</div>
                  <ChangeHost onClick={handleHostChange}>
                    Change Host
                  </ChangeHost>
                </div>
              ) : (
                purchaser
              )
            }
          />
          <SummaryLine title="Order status" value="Not yet ordered" />
          <SummaryLine
            title="Order link"
            value={
              <Link onClick={goToHostKitOrderLink}>{hostKitOrderLink}</Link>
            }
          />
          {hostBuying && (
            <div>
              <SummaryLine
                title="Order link sent to"
                value={event?.lastOrderLinkInfo?.recipient}
              />
              <SummaryLine title="Date sent" value={dateSent} />
            </div>
          )}
          <Button
            type={buttonContent.type}
            fullWidth
            disableRipple
            onClick={handleButtonAction}
          >
            {mutationLoading && (
              <CircularProgress
                size={24}
                style={{ marginRight: 6, color: 'black' }}
              />
            )}
            {buttonContent.text}
          </Button>
        </div>
      )}
      {wineNeeded === 'STARTER-KIT' && (
        <SummaryLine title="Wine" value={'Starter Kit wine applied'} />
      )}
      {wineNeeded === 'ALREADY-ORDERED' && (
        <SummaryLine
          title="Wine"
          value={'I already ordered wine for this event'}
        />
      )}
      {(wineNeeded === 'NO' ||
        (wineNeeded === 'YES' && !isAcceptingOrders)) && (
        <SummaryLine title="Wine" value={'Wine is not needed for this event'} />
      )}
    </TabContentContainer>
  );
};

export default WineSummary;
