import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { isEmpty } from 'lodash';
import styled from '@emotion/styled';
import { FormikProps, FastField } from 'formik';
import get from 'lodash/get';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { MuiButton } from '@onehope/design-system-ohw';
import { styles } from '@onehope/design-system';

import { RadioButton, RadioButtonGroup } from '../../../common/RadioButton';
import FooterButtons from '../../Dialog/EventCreateDialog/FooterButtons';

import { GET_EXPIRED_EVENT_CREDITS_QUERY } from '../../../queries/Credits';

import {
  ToggleLabel,
  StraightLine,
  DetailsTitle,
} from '../../Event/TabContent/index.styles';
import { FormValues } from '../../Event/TabContent/Wine/FormTypes';
import { TabContentContainer } from '../../Contact/TabContent/index.styles';
import { BalanceContainer, BalanceHeader, Balance } from './index.styles';
import { getCreditOptions } from '../../Event/TabContent/Wine/helpers';
import { SvgGlassesClink } from '../../../assets/glassesClink';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import useToggle from '../../../utils/useToggleHook';
import ExpiredCreditDialog from '../../Dialog/ExpiredCreditDialog';
import { isVirtual, getTastingFlights } from '../../../utils/utils';

const Padding40 = styled.div`
  padding-bottom: 40px;
`;

const { styled: s } = styles;

interface WineFormProps {
  handlePreviousStepChange: any;
  editing: boolean;
  toggleEdit: () => void;
  numberOfAvailableCredits: number;
  numCreditsUsed: number;
  event: EventType;
  starterKitCredits: number;
  errorNotification: string;
  setErrorNotification: (error: string | null) => void;
}

type FormProps = {
  setIsNotEditing: () => void;
  mutationLoading: boolean;
};

const Container = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 0 16px;
`;

const SaveButtons = styled.div`
  margin-top: 16px;
`;

const Spacer = styled.div`
  margin-bottom: 16px;
`;

const ErrorNotification = styled.div`
  width: 100%;
  /* font-family: ProximaNova; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  border-radius: 4px;
  border: solid 1px #ff4d4f;
  background-color: #fff5f5;
  padding: 14px 22px;
  margin-bottom: 40px;
`;

export default function WineForm(
  props: FormProps & FormikProps<FormValues> & WineFormProps,
) {
  const {
    values,
    errors,
    touched,
    setErrors,
    handleReset,
    handleSubmit,
    mutationLoading,
    setFieldTouched,
    starterKitCredits,
    handlePreviousStepChange,
    editing,
    numberOfAvailableCredits,
    numCreditsUsed,
    errorNotification,
    setErrorNotification,
  } = props;
  const {
    wineNeeded,
    hostKitQuantity,
    hostKitCredits,
    hostKitPurchaser,
  } = values;
  const { refetch: checkExpiredEventCredits } = useQuery(
    GET_EXPIRED_EVENT_CREDITS_QUERY,
    {
      variables: { eventId: props.event?.eventId, numberOfCredits: 0 },
    },
  );

  const { applyStarterKitWine } = useFlags();

  const {
    value: dialogOpen,
    setTrue: setDialogOpen,
    setFalse: setDialogClosed,
  } = useToggle();

  useEffect(() => {
    localStorage.setItem('wineNeeded', wineNeeded ? wineNeeded : '');
    setFieldTouched('wineNeeded', true, false);
    setErrorNotification(null);
    setErrors({});
  }, [wineNeeded, setFieldTouched, setErrorNotification, setErrors]);

  useEffect(() => {
    localStorage.setItem(
      'hostKitQuantity',
      hostKitQuantity ? hostKitQuantity.toString() : '',
    );
    setFieldTouched('hostKitQuantity', true, false);
  }, [hostKitQuantity, setFieldTouched]);

  useEffect(() => {
    localStorage.setItem(
      'hostKitCredits',
      hostKitCredits ? hostKitCredits.toString() : '',
    );
    setFieldTouched('hostKitCredits', true, false);
  }, [hostKitCredits, setFieldTouched]);

  useEffect(() => {
    localStorage.setItem(
      'hostKitPurchaser',
      hostKitPurchaser ? hostKitPurchaser : '',
    );
    setFieldTouched('hostKitPurchaser', true, false);
  }, [hostKitPurchaser, setFieldTouched]);

  const onCancel = () => {
    handleReset();
    if (editing && props.toggleEdit) {
      props.toggleEdit();
    }
  };

  const checkDisable = () => {
    if (orderingWine && !isVirtualEvent)
      return (
        (!hostKitPurchaser && !selfHost) ||
        !hostKitQuantity ||
        !hostKitCreditsSelected ||
        (hostKitQuantity === '6' && hostKitCredits === '2')
      );
    if (editing) return wineNeeded === 'SKIP';
    return wineNeeded === '';
  };

  const onSubmit = async () => {
    const hostKitCreditsInt = parseInt(hostKitCredits, 10);
    // if un-applying credits, check if expired
    if (numCreditsUsed > hostKitCreditsInt) {
      let expired = false;
      const numberOfCredits = numCreditsUsed - hostKitCreditsInt;
      await checkExpiredEventCredits({
        eventId: props.event?.eventId,
        numberOfCredits,
      }).then(response => {
        expired = get(response, 'data.viewer.user.checkExpiredEventCredits');
      });
      if (expired) {
        return setDialogOpen();
      }
    }
    return handleSubmit();
  };

  // check if event is virtual
  const trinityPartyType =
    localStorage.getItem('trinityPartyType') || props?.event?.trinityPartyType;
  const isVirtualEvent = isVirtual(trinityPartyType);

  const event = editing
    ? props.event
    : ({ trinityPartyType, createDate: new Date().toString() } as EventType);
  const showTastingFlights = getTastingFlights(event);

  const orderingWine = wineNeeded === 'YES' && !showTastingFlights;
  const creditOptions = getCreditOptions({
    numberOfAvailableCredits,
    numCreditsUsed,
    hostKitQuantity,
  });
  const hostKitQuantitySelected = orderingWine && !!hostKitQuantity;
  const hostKitCreditsSelected =
    orderingWine &&
    hostKitQuantitySelected &&
    (!!hostKitCredits || !creditOptions);
  const hostType = localStorage.getItem('hostType') || '';
  const selfHost =
    hostType === 'SELFHOST' ||
    (!!props.event && props.event?.accountId === props.event?.hostAccountId);

  const disableNext = checkDisable();

  return (
    <form onSubmit={onSubmit}>
      <Container>
        <ExpiredCreditDialog
          open={dialogOpen}
          setClose={setDialogClosed}
          saveChange={handleSubmit}
          mutationLoading={mutationLoading}
        />
        <TabContentContainer>
          {editing ? (
            <div>
              <DetailsTitle>
                {showTastingFlights
                  ? 'Edit Tasting Flights Details'
                  : 'Edit Wine Details'}
              </DetailsTitle>
              <StraightLine />
            </div>
          ) : (
            <s.LoginHeader css>
              Order the wine{' '}
              <span role="img" aria-label="wine-emoji">
                🍷
              </span>
            </s.LoginHeader>
          )}
          {!showTastingFlights && (
            <BalanceContainer editing={editing}>
              <SvgGlassesClink />
              <BalanceHeader>Balance: </BalanceHeader>
              <Balance>
                {numberOfAvailableCredits} free event kit credits available
              </Balance>
            </BalanceContainer>
          )}
          <ToggleLabel>
            {showTastingFlights
              ? 'Will the guests purchase tasting flights?'
              : 'Do you need to order wine for this event?'}
          </ToggleLabel>
          <RadioButtonGroup
            id="wineNeededGroup"
            label=""
            value={wineNeeded}
            error={errors.wineNeeded}
            touched={touched.wineNeeded}
          >
            <FastField
              component={RadioButton}
              name="wineNeeded"
              id="YES"
              label="Yes"
            />
            <FastField
              component={RadioButton}
              name="wineNeeded"
              id="NO"
              label="No"
            />
            {applyStarterKitWine && starterKitCredits > 0 && (
              <FastField
                component={RadioButton}
                name="wineNeeded"
                id="STARTER-KIT"
                label="Apply my event kit credit"
              />
            )}
            {!editing && (
              <FastField
                component={RadioButton}
                name="wineNeeded"
                id="SKIP"
                label="Skip for now and book event"
              />
            )}
          </RadioButtonGroup>
          <Padding40 />
          {orderingWine && (
            <div>
              <ToggleLabel>
                How many bottles of wine do you need for this event?
              </ToggleLabel>
              <RadioButtonGroup
                id="hostKitQuantityGroup"
                label=""
                value={hostKitQuantity}
                error={errors.hostKitQuantity}
                touched={touched.hostKitQuantity}
              >
                <FastField
                  component={RadioButton}
                  name="hostKitQuantity"
                  id="6"
                  label={
                    numberOfAvailableCredits + numCreditsUsed > 0
                      ? '6 bottles'
                      : '6 bottles (price starts at $99)'
                  }
                />
                <FastField
                  component={RadioButton}
                  name="hostKitQuantity"
                  id="12"
                  label={
                    numberOfAvailableCredits + numCreditsUsed > 0
                      ? '12 bottles'
                      : '12 bottles (price starts at $198)'
                  }
                />
              </RadioButtonGroup>
              <Padding40 />
            </div>
          )}
          {hostKitQuantitySelected && creditOptions && (
            <div>
              <ToggleLabel>How many credits would you like to use?</ToggleLabel>
              <RadioButtonGroup
                id="hostKitCreditsGroup"
                label=""
                value={hostKitCredits}
                error={errors.hostKitCredits}
                touched={touched.hostKitCredits}
              >
                {creditOptions.map(option => (
                  <FastField
                    component={RadioButton}
                    name="hostKitCredits"
                    key={option.key}
                    id={option.id}
                    label={option.label}
                  />
                ))}
              </RadioButtonGroup>
              <Padding40 />
            </div>
          )}
          {hostKitCreditsSelected && !selfHost && (
            <div>
              <ToggleLabel>Who will select the wine?</ToggleLabel>
              <RadioButtonGroup
                id="hostKitPurchaserGroup"
                label=""
                value={hostKitPurchaser}
                error={errors.hostKitPurchaser}
                touched={touched.hostKitPurchaser}
              >
                <FastField
                  component={RadioButton}
                  name="hostKitPurchaser"
                  id="CE"
                  label="I will"
                />
                <FastField
                  component={RadioButton}
                  name="hostKitPurchaser"
                  id="HOST"
                  label="The Host will"
                />
              </RadioButtonGroup>
            </div>
          )}
          {errorNotification && (
            <ErrorNotification>{errorNotification}</ErrorNotification>
          )}
          {editing ? (
            <SaveButtons>
              <MuiButton
                type="PRIMARY"
                fullWidth
                disableRipple
                disabled={disableNext || isEmpty(touched) || !isEmpty(errors)}
                onClick={mutationLoading ? () => {} : onSubmit}
              >
                SAVE CHANGES
              </MuiButton>
              <Spacer />
              <MuiButton
                type="SECONDARY"
                disableRipple
                fullWidth
                onClick={onCancel}
              >
                CANCEL
              </MuiButton>
            </SaveButtons>
          ) : (
            <FooterButtons
              prevStepButtonText="BACK"
              nextStepButtonText="BOOK EVENT"
              handleNextStepChange={onSubmit}
              handlePrevStepChange={handlePreviousStepChange}
              disableNext={disableNext}
            />
          )}
        </TabContentContainer>
      </Container>
    </form>
  );
}
