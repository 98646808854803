// @ts-ignore
import * as Yup from 'yup';

export const AddCustomerFormSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Required'),
  phone: Yup.string().matches(
    /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
    'Please enter a valid phone number',
  ),
});
