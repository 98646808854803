import styled from '@emotion/styled';
import { styles } from '@onehope/design-system-ohw';

const {
  cssConstants: { secondaryTextColor, textColor, italicFont, tertiaryFont  },
} = styles;

export const SummaryHeader = styled.h2`
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.5px;
  color: ${textColor};
  margin-top: 16px;
  margin-bottom: 0;
  font-family: ${italicFont};
`;

export const BreakdownTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  //th {
  //  text-align: left;
  //}
  //
  //td {
  //  text-align: right;
  //}
  //
  //th,
  //td {
  //  padding: 12px 0;
  //  font-weight: normal;
  //  font-size: 14px;
  //  line-height: 1.57;
  //}

  //&.table-primary {
  //  th,
  //  td {
  //    font-weight: bold;
  //    text-transform: uppercase;
  //    letter-spacing: 1px;
  //    font-size: 18px;
  //    line-height: 1.56;
  //  }
  //}
`;

export const MutedText = styled.p`
  font-size: 11px;
  font-weight: normal;

  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  color: ${secondaryTextColor};

  a:link,
  a:visited {
    color: ${secondaryTextColor};
    text-decoration: underline;
  }
`;

export const PromoCodeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const ErrorText = styled.div`
  font-family: ${tertiaryFont};
  font-size: 14px;
  color: red;
  text-align: left;
`;

export const PromoCodes = styled.div`
  justify-content: space-between;
  display: flex;
  margin-top: 20px;
`;


export const TableRowText = styled.div`
  font-family: ${tertiaryFont};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
`;

export const TableRowBigText = styled.div`
  font-family: ${tertiaryFont};
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 3px;
`;
