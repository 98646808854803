import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import NoteEditMutation from '../../../../mutations/Note/NoteEditMutation';
import { MyFormValues } from './FormTypes';
import { NoteFormSchema } from './validationSchema';
import NoteForm from './NoteForm';
import FormContainer from '../../../Event/FormContainer';

interface EditNoteFormProps {
  noteId: string | null | undefined;
  defaultValues: MyFormValues;
  onSave: () => void;
}

const handleUpdateNote = async ({ values, noteId, onSave, noteEdit }: any) => {
  const { text } = values;
  await noteEdit({
    variables: {
      input: {
        text,
        noteId,
      },
    },
  })
    .then((data: any) => {
      console.log(data);
      onSave();
    })
    .catch(async (errors: any) => {
      console.log(errors);
    });
};

export default function EditNoteForm(props: EditNoteFormProps) {
  const { onSave, noteId, defaultValues } = props;
  const [noteEdit, { loading: mutationLoading }] = useMutation(
    NoteEditMutation,
  );

  return (
    <div>
      <FormContainer
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={defaultValues}
        validationSchema={NoteFormSchema}
        formToUse={(props: any) => (
          <NoteForm
            {...props}
            editing={true}
            onClose={onSave}
            mutationLoading={mutationLoading}
          />
        )}
        onSubmit={async (values: MyFormValues) => {
          await handleUpdateNote({
            values,
            noteId,
            onSave,
            noteEdit,
          });
        }}
      />
    </div>
  );
}
