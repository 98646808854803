import CardButtonClickedMutation from '../mutations/CardButtonClickedMutation';
import CardArchiveMutation from '../mutations/CardArchiveMutation';
import { ApolloClient } from '@apollo/client';

export function cardButtonClicked(apolloClient: ApolloClient<any>) {
  return (id: string) => {
    return apolloClient
      .mutate({
        mutation: CardButtonClickedMutation,
        variables: {
          input: {
            id,
          },
        },
      })
      .then((res) => res)
      .catch((err) => {
        console.log(err);
        return err;
      });
  };
}

/**
 * Card Archive Clicked
 * @param apolloClient
 */
export function cardArchiveClicked(apolloClient: ApolloClient<any>) {
  return (ledgerId: string, isArchived: boolean) => {
    return apolloClient
      .mutate({
        mutation: CardArchiveMutation,
        variables: {
          input: {
            ledgerId,
            isArchived,
          },
        },
      })
      .then((res) => res)
      .catch((err) => {
        return err;
      });
  };
}
