import React, { useState } from 'react';
import FormContainer from '../../../Event/FormContainer';
import WineForm from '../../../Events/WineForm';
import { getInitialValues } from '../../../Event/TabContent/Wine/helpers';
import { WineFormSchema } from '../../../Event/TabContent/Wine/validationSchema';
import { FormValues } from '../../../Event/TabContent/Wine/FormTypes';
import { CEDash_EventsQuery_viewer_user as UserType } from '../../../../queries/generatedTypes/CEDash_EventsQuery';

interface WineProps {
  form: any;
  user: UserType;
  handleNextStepChange: (
    key: string,
    values: FormValues,
    setErrorNotification?: React.Dispatch<React.SetStateAction<string>>,
  ) => Promise<void> | undefined;
  handlePreviousStepChange: () => void;
}

export default function Wine(props: WineProps) {
  const { user, handlePreviousStepChange } = props;
  const [errorNotification, setErrorNotification] = useState('');
  return (
    <FormContainer
      validationSchema={WineFormSchema}
      initialValues={getInitialValues()}
      /* eslint-disable react/jsx-no-bind */
      formToUse={(props: any) => (
        <WineForm
          {...props}
          editing={false}
          numberOfAvailableCredits={user?.numberOfAvailableCredits ?? 0}
          numCreditsUsed={0}
          starterKitCredits={user?.availableStarterKitCredits}
          handlePreviousStepChange={handlePreviousStepChange}
          errorNotification={errorNotification}
          setErrorNotification={setErrorNotification}
        />
      )}
      onSubmit={async (values: FormValues) => {
        props.handleNextStepChange('wine', values, setErrorNotification);
      }}
    />
  );
}
