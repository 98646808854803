import React from 'react';

const SubscriberSvg = (props: any) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40" {...props}>
    <defs>
      <path
        id="va"
        d="M6 3.636v2.182L9 2.91 6 0v2.182C2.685 2.182 0 4.785 0 8c0 1.142.345 2.204.93 3.098l1.095-1.062A4.167 4.167 0 011.5 8c0-2.407 2.018-4.364 4.5-4.364zm5.07 1.266L9.975 5.964c.33.61.525 1.301.525 2.036 0 2.407-2.018 4.364-4.5 4.364v-2.182L3 13.09 6 16v-2.182c3.315 0 6-2.603 6-5.818a5.641 5.641 0 00-.93-3.098z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000"
        fillRule="nonzero"
        stroke="#FFF"
        strokeWidth="0.658"
        d="M13.312 4.416c.041-.493.627-.41.627-.41h1.296s.585-.083.627.41v.946l-.21.246v5.138s2.383 1.101 2.383 3.448v15.758c0 .126-.029 1.138-.668 1.138h-5.56c-.598 0-.662-.881-.668-1.102l-.001-15.794c0-2.347 2.383-3.448 2.383-3.448V5.608l-.21-.246zm7.585 0c.042-.493.628-.41.628-.41h1.295s.585-.083.628.41v.946l-.21.246v5.138s2.383 1.101 2.383 3.448v15.758c0 .126-.029 1.138-.668 1.138h-5.56c-.683 0-.67-1.151-.67-1.151V14.194c0-2.347 2.383-3.448 2.383-3.448V5.608l-.209-.246zm7.586 0c.042-.493.627-.41.627-.41h1.296s.585-.083.627.41v.946l-.209.246v5.138s2.383 1.101 2.383 3.448v15.794c-.008.22-.072 1.102-.669 1.102h-5.56c-.682 0-.669-1.151-.669-1.151V14.194c0-2.347 2.383-3.448 2.383-3.448V5.608l-.209-.246z"
      />
      <path
        fill="#000"
        fillRule="nonzero"
        stroke="#FFF"
        strokeWidth="0.658"
        d="M9.863 5.77c.042-.493.628-.41.628-.41h1.295s.585-.083.628.41v.946l-.21.247V12.1s2.383 1.102 2.383 3.448v15.758c0 .127-.029 1.138-.668 1.138h-5.56c-.598 0-.662-.88-.669-1.101V15.548c0-2.346 2.382-3.448 2.382-3.448V6.963l-.209-.247zm7.586 0c.042-.493.627-.41.627-.41h1.296s.585-.083.627.41v.946l-.209.247V12.1s2.383 1.102 2.383 3.448v15.758c-.001.127-.03 1.138-.669 1.138h-5.56c-.682 0-.669-1.15-.669-1.15V15.548c0-2.346 2.383-3.448 2.383-3.448V6.963l-.209-.247zm7.586 0c.041-.493.627-.41.627-.41h1.296s.585-.083.627.41v.946l-.209.247V12.1s2.383 1.102 2.383 3.448l-.001 15.795c-.007.22-.071 1.101-.668 1.101h-5.56c-.683 0-.669-1.15-.669-1.15V15.548c0-2.346 2.383-3.448 2.383-3.448V6.963l-.21-.247z"
      />
      <path
        fill="#1A1A1A"
        stroke="#FFF"
        strokeWidth="0.658"
        d="M33.207 12.127l-3.448 1.925v21.101l3.448-1.924zM29.759 14H7v20.995h22.759z"
      />
      <g transform="matrix(0 1 1 0 10 18)">
        <mask id="vb" fill="#fff">
          <use xlinkHref="#va" />
        </mask>
        <g fill="#FFF" mask="url(#vb)">
          <path d="M0-2h14v18H0z" />
        </g>
      </g>
    </g>
  </svg>;
export default SubscriberSvg;
