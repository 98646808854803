import { Theme, makeStyles } from '@material-ui/core/styles';
import { styles as s } from '@onehope/design-system';

const { cssConstants } = s;

export const tabFilterStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    padding: '0px 24px',
  },
  tabsIndicator: {
    display: 'none',
    backgroundColor: 'transparent',
  },
  tabRootLeft: {
    textTransform: 'unset',
    fontFamily: cssConstants.font,
    fontSize: 14,
    width: 158,
    fontWeight: 'bold',
    color: cssConstants.neutral34,
    opacity: 1,
    minWidth: 94,
    lineHeight: 1.29,
    height: 40,
    minHeight: 40,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    border: `1px solid #c2c2c2`,
    '&:hover': {
      opacity: 1,
    },
  },
  tabRootRight: {
    textTransform: 'unset',
    fontFamily: cssConstants.font,
    fontSize: 14,
    width: 158,
    fontWeight: 'bold',
    color: cssConstants.neutral34,
    opacity: 1,
    minWidth: 94,
    lineHeight: 1.29,
    height: 40,
    minHeight: 40,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    border: `1px solid #c2c2c2`,
    '&:hover': {
      opacity: 1,
    },
  },
  tabRootMiddle: {
    textTransform: 'unset',
    fontFamily: cssConstants.font,
    fontSize: 14,
    width: 114,
    fontWeight: 'bold',
    color: cssConstants.neutral34,
    opacity: 1,
    minWidth: 94,
    lineHeight: 1.29,
    height: 40,
    minHeight: 40,
    borderTop: `1px solid #c2c2c2`,
    borderBottom: `1px solid #c2c2c2`,
    '&:hover': {
      opacity: 1,
    },
  },
  tabSelected: {
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: cssConstants.primaryColor,
    border: `1px solid ${cssConstants.primaryColor}`
  },
}));
