import gql from 'graphql-tag';

const mutation = gql`
  mutation HostRewardsUpdateMutation($input: HostRewardsUpdateInput!) {
    hostRewardsUpdate(input: $input) {
      promoCodeId
      promoCode {
        id
        code
        promoCodeId
        loadedBalance
        storeBalance
        usableByAccountId
        usableByEmails
        expiresOn
      }
    }
  }
`;

export default mutation;
