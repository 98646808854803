import gql from 'graphql-tag';

const GET_USERS_QUERY = gql`
  query CEDash_UsersQuery(
    $searchInput: String!
    $email: Boolean
    $firstName: Boolean
    $lastName: Boolean
    $trinityDealerId: Boolean
    $accountId: Boolean
    $orderId: Boolean
  ) {
    viewer {
      id
      users(
        searchInput: $searchInput
        email: $email
        firstName: $firstName
        lastName: $lastName
        trinityDealerId: $trinityDealerId
        accountId: $accountId
        orderId: $orderId
      ) {
        edges {
          node {
            id
            role
            userId
            firstName
            lastName
            mobilePhone
            email
            permissions {
              id
              oneCommerce {
                ceo {
                  access
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_USERS_QUERY;
