import { styles as s } from '@onehope/design-system';
import styled from '@emotion/styled';
import { Key, Value } from '../../../Event/TabContent/index.styles';

const { cssConstants } = s;

export const WideKey = styled(Key)`
  min-width: 160px;
  margin-right: 8px;
  @media all and (min-width: 768px) {
    display: flex;
    min-width: 225px;
  }
`;

export const Title = styled.div`
  margin-right: 8px;
`;

export const Link = styled(Value)`
  font-weight: bold;
  word-break: break-all;
  text-decoration: underline;
  cursor: pointer;
`;

export const ChangeHost = styled.div`
  /* font-family: ProximaNova; */
  font-size: 11px;
  font-weight: bold;
  line-height: 1.45;
  text-decoration: underline;
  cursor: pointer;
  color: ${cssConstants.primaryColor};
  margin-top: 4px;
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddressValue = styled.div`
  /* font-family: ProximaNova; */
  font-size: 14px;
  line-height: 19px;
  word-break: break-word;
  margin-right: 0;
  text-align: right;
  width: 120px;
  @media all and (min-width: 768px) {
    text-align: left;
    width: 432px;
    margin-left: 0;
  }
`;

export const ToolTipWrapper = styled.div`
  margin: 1px 0 0 10px;
`;
