import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  height: 50%;
  min-height: 400px;
`;
const ProgressContainer = styled.div`margin: auto;`;

export default function Loading() {
  return (
    <Container>
      <ProgressContainer>
        <CircularProgress
        // style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      </ProgressContainer>
    </Container>
  );
}
