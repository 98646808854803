/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import get from 'lodash/get';
import Table from '@material-ui/core/Table';
import { styles as s } from '@onehope/design-system';
import Columns from '../../../common/Orders/Columns';
import { CEDash_GuestOrdersQuery_viewer_user_orders_edges as OrdersEdges } from '../../../queries/generatedTypes/CEDash_GuestOrdersQuery';
import TableHeader from '../../TableComponents/TableListView/TableHeader';

const { cssConstants } = s;

const TableWrapper = styled.div`
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  overflow: auto;
  background-color: white;
  margin-bottom: 24px;
`;

const TableRow = styled(MuiTableRow)`
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

const TableCell = styled(MuiTableCell)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 14px;
  }
`;

interface TableBodyContainerProps {
  orders: (OrdersEdges | null)[] | null;
  handleSortOrders: (sortBy: string) => void;
}

const handleRowClick = (order: OrdersEdges | null) => {
  return () => {
    const { GATSBY_HOPECOMMERCE_URL } = process.env;
    return window.open(
      `${GATSBY_HOPECOMMERCE_URL}/order/${order?.node?.orderId}`,
      '_blank',
    );
  };
};

const onHandleTrackOrder = (
  tracking: (string | null)[] | null | undefined,
  carrier: string | null | undefined,
) => {
  return () => {
    const trackingUrl =
      carrier === 'FedEx'
        ? `https://www.fedex.com/apps/fedextrack/?tracknumbers`
        : `https://wwwapps.ups.com/tracking/tracking.cgi?tracknum`;
    if (tracking) {
      tracking.forEach(trackingNumber => {
        return window.open(`${trackingUrl}=${trackingNumber}`, '_blank');
      });
    }
    return null;
  };
};

const noSortByList = {
  eventDetails: true,
  'event.hostFullName': true,
};

export default function TableBodyContainer(props: TableBodyContainerProps) {
  const { orders, handleSortOrders } = props;
  if (orders) {
    return (
      <TableWrapper>
        <Table stickyHeader aria-label="orders table">
          <TableHeader
            handleSort={handleSortOrders}
            columns={Columns}
            noSortByList={noSortByList}
          />
          <TableBody>
            {orders.map((order: OrdersEdges | null) => {
              const onRowClick = handleRowClick(order);
              const tracking = order?.node?.shippingInfo?.tracking;
              const carrier = order?.node?.shippingInfo?.carrier;
              const email = order?.node?.email;
              const handleTrackOrder = onHandleTrackOrder(tracking, carrier);
              const customerType: string = order?.node?.isAutoShipOrder
                ? 'subscriber'
                : order?.node?.isPreferredCustomerOrder
                  ? 'preferredCustomer'
                  : '';
              return (
                <TableRow hover role="checkbox" key={order?.node?.id}>
                  {Columns.map(column => {
                    const value = get(order?.node, `${column.id}`);
                    const onCellClick = !column.render ? onRowClick : () => { };

                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        onClick={onCellClick}
                      >
                        {column.format &&
                          column.format(value, order?.node?.orderId)}
                        {column.customerType &&
                          column.customerType({ customerType })}
                        {column.render &&
                          column.render({
                            orderId: order?.node?.orderId,
                            tracking,
                            carrier,
                            email,
                            handleTrackOrder,
                          })}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableWrapper>
    );
  }
  return null;
}
