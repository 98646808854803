import { segmentEvents } from './constants';

export type SegmentEventPropsV2 = {
  // Added string 'Card Clicked' bc that event is triggered from the action-engine pckg
  event: segmentEvents | 'Card Clicked';
  properties: CardEventProps | ConciergeModeEventProps;
};

type SegmentCookiePropsV2 = {
  isLoggedIn: boolean;
  userId: string;
};

type CardEventProps = {
  cardName: string | null;
  contentfulEntryId: string | null;
  streamActionId: string | null;
  cardTemplate: string | null;
  cardCategory: string | null;
  cardButtton?: boolean | null;
  subjectUserId: string | null;
};

type ConciergeModeEventProps = {
  eventId: string | null;
  shoppingForId: string;
  isNewCustomer: boolean;
};

export const segmentEventV2 = async ({
  event,
  properties,
}: SegmentEventPropsV2) => {
  if (
    process.env.GATSBY_ENABLE_SEGMENT &&
    typeof window !== 'undefined' &&
    typeof analytics !== 'undefined'
  ) {
    return analytics.track(event, properties);
  }
};

// V2 - will not pass a userId to Segment if the user is anonymous
export const segmentCookieV2 = ({
  isLoggedIn,
  userId,
}: SegmentCookiePropsV2): void => {
  if (
    typeof window !== 'undefined' &&
    typeof analytics !== 'undefined' &&
    analytics._user
  ) {
    if (isLoggedIn) {
      analytics._user.id(userId);
    } else {
      // set to null here in case it has been set to something somwhere else
      analytics._user.id(null);
    }
  }
};
