import gql from 'graphql-tag';

const GET_PAYMENTS_QUERY = gql`
  query CEDash_PaymentsQuery($accountId: String, $csPortal: Boolean!) {
    viewer {
      id
      userInfo(accountId: $accountId, csPortal: $csPortal) {
        role
        id
        userId
        firstName
        lastName
        paymentIdDefault
        stripePaymentCollection {
          id
          entries(first: 1000)
            @connection(key: "StripePaymentCollection_entries", filter: []) {
            edges {
              cursor
              __typename
              node {
                id
                stripePayment {
                  id
                  name
                  last4
                  expYear
                  expMonth
                  billingAddressUuid
                  paymentId
                  brand
                  nickname
                  billingAddress {
                    id
                    firstName
                    lastName
                    street
                    addressLineOne
                    addressLineTwo
                    city
                    state
                    zip
                    recipientBirthDate
                    addressId
                    country
                    phoneNumber
                    emailAddress
                    specialInstructions
                    nickname
                    companyName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_PAYMENTS_QUERY;
