import React, { useState, useEffect, useContext } from 'react';
import { MuiButton } from '@onehope/design-system-ohw';
import DialogRoot from '@material-ui/core/Dialog';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';

import EventCreateStepper from './EventCreateStepper';
import SlideTransition from './SlideTransition';
import { CEDash_EventsQuery_viewer_user as UserType } from '../../../queries/generatedTypes/CEDash_EventsQuery';

const { cssConstants } = s;

const Dialog = styled(DialogRoot)`
  &.MuiDialog-root {
    /* z-index: 9000 !important; */
  }
`;

const CreateEventButton = styled(MuiButton)`
  && {
    width: 288px;
  }
`;

type EventDetailsDialogProps = {
  createEventStep: string | string[] | null | undefined;
  handleEventCreateOpen: () => void;
  handleEventCreateClose: () => void;
  isEventCreateOpen: boolean;
  user: UserType;
};

export default function EventCreateDialog(props: EventDetailsDialogProps) {
  // @ts-ignore
  const {
    createEventStep,
    isEventCreateOpen,
    handleEventCreateOpen,
    handleEventCreateClose,
  } = props;
  const currentStep = createEventStep ? parseInt(createEventStep) : 0;

  return (
    <div>
      <CreateEventButton
        fullWidth
        type="PRIMARY"
        onClick={handleEventCreateOpen}
      >
        Create a new event
      </CreateEventButton>
      <Dialog
        fullScreen
        scroll="body"
        disableEnforceFocus
        open={isEventCreateOpen}
        onClose={handleEventCreateClose}
        TransitionComponent={SlideTransition}
      >
        <EventCreateStepper
          user={props.user}
          createEventTab={currentStep}
          handelDialogClose={handleEventCreateClose}
        />
      </Dialog>
    </div>
  );
}
