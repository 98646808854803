import React, { useState, Fragment } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system-ohw';
import IconButton from '@material-ui/core/IconButton';
import MuiAppsIcon from '@material-ui/icons/Apps';
import MuiFormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, withStyles } from '@material-ui/core/styles';
import TabFilter from './TabFilter';
import TablePagination from '../Events/EventsTable/TablePagination';
import TableListView from './TableListView';
import CardListView from './CardListView';
// import Header from './Header';
import SearchBar from '../Events/EventsTable/SearchBar';
import NoContacts from './NoContacts';
import { SvgSM } from '../../assets/sm';
import { CheckBoxToggleContextProvider } from './CheckboxToggleContext';
import { useArchiveContactsContext } from './ArchiveContactsContext';

import CreateConciergeServiceMutation from '../../mutations/Account/CreateConciergeServiceMutation';

import {
  handleSortByFullName,
  handleFilterByTab,
  handleSortByImpactTotal,
  handleSortByDateOfBirth,
  handleSortByPhoneNumber,
  handleSortByEmail,
  handleSortByLifeTimeSpend,
  handleSortByLastOrderDate,
  handleSearchAllContacts,
  handleSortByCustomerType,
} from './helpers';

import { CEDash_CustomersQuery_viewer_user_customers_edges as Contacts } from '../../queries/generatedTypes/CEDash_CustomersQuery';
import { CheckBoxToggle } from './CheckBoxContainer/index';
import { useTrackingContextValue } from '../../contexts/TrackingContext';
import { segmentEvents } from '../../utils/segment/constants';

const { cssConstants } = s;
const Container = styled.div`
  position: relative;
  padding: 24px 16px 70px 16px;
  font-family: ${cssConstants.font};
  a {
    color: ${cssConstants.primaryColor};
  }
  @media all and (min-width: 768px) {
    padding: 40px;
  }
`;

const AppsIcon = styled(MuiAppsIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const FormatListBulletedIcon = styled(MuiFormatListBulletedIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  flex-direction: column;
  @media all and (min-width: 960px) {
    flex-direction: row;
    padding-bottom: 40px;
  }
`;

const ToggleView = styled.div`
  width: 54px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

interface ContactsViewProps {
  contacts: (Contacts | null)[] | null;
  allContacts: (Contacts | null)[] | null;
  setContacts: (input: any) => void;
}

export const GlobalCss = withStyles({
  '@global': {
    '.MuiCheckbox-root': {
      marginRight: '5px',
      padding: '2px',
    },
    '.MuiCheckbox-colorSecondary': { color: '#c2c2c2' },
    '.MuiCheckbox-colorSecondary.Mui-checked': { color: 'black' },
  },
})(() => null);

export default function ContactsView(props: ContactsViewProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [createConciergeService, { loading: mutationLoading }] = useMutation(
    CreateConciergeServiceMutation,
  );
  const { productCatalogPageV2 } = useFlags();

  const { contacts, setContacts } = props;
  const [page, setPage] = useState(0);
  const [filterBy, setFilterBy] = useState('all');
  const [search, setSearch] = useState('');
  const [nameSortDirection, setNameSortDirection] = useState(false);
  const [customerTypeSortDirection, setCustomerTypeSortDirection] = useState(
    false,
  );
  const [dateOfBirthSortDirection, setDateOfBirthSortDirection] = useState(
    false,
  );
  const [lifeTimeSpendSortDirection, setLifeTimeSpendSortDirection] = useState(
    false,
  );
  const [impactTotlaSortDirection, setImpactTotalSortDirection] = useState(
    false,
  );
  const [lastOrderDateSortDirection, setLastOrderDateSortDirection] = useState(
    false,
  );
  const [contactEmailSortDirection, setContactEmailSortDirection] = useState(
    false,
  );
  const [mobilePhoneSortDirection, setMobilePhoneSortDirection] = useState(
    false,
  );

  const [isContactsTableView, setIsContactsTableView] = useState(
    localStorage.getItem('isContactsTableView')
      ? localStorage.getItem('isContactsTableView') === 'true'
      : true,
  );

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { cancelArchive } = useArchiveContactsContext();
  const { trackEventV2 } = useTrackingContextValue();

  const handleChangePage = (event: unknown, newPage: number) => {
    cancelArchive();
    setPage(newPage);
  };

  const toggleView = () => {
    localStorage.setItem('isContactsTableView', `${!isContactsTableView}`);
    setIsContactsTableView(!isContactsTableView);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSortContacts = (sortBy: string) => {
    switch (sortBy) {
      case 'fullName':
        setContacts(handleSortByFullName(contacts, nameSortDirection));
        return setNameSortDirection(!nameSortDirection);
      case 'email':
        setContacts(handleSortByEmail(contacts, contactEmailSortDirection));
        return setContactEmailSortDirection(!contactEmailSortDirection);
      case 'mobilePhone':
        setContacts(
          handleSortByPhoneNumber(contacts, mobilePhoneSortDirection),
        );
        return setMobilePhoneSortDirection(!mobilePhoneSortDirection);
      case 'dateOfBirth':
        setContacts(
          handleSortByDateOfBirth(contacts, dateOfBirthSortDirection),
        );
        return setDateOfBirthSortDirection(!dateOfBirthSortDirection);
      case 'lifeTimeSpend':
        setContacts(
          handleSortByLifeTimeSpend(contacts, lifeTimeSpendSortDirection),
        );
        return setLifeTimeSpendSortDirection(!lifeTimeSpendSortDirection);
      case 'lastOrderDate':
        setContacts(
          handleSortByLastOrderDate(contacts, lastOrderDateSortDirection),
        );
        return setLastOrderDateSortDirection(!lastOrderDateSortDirection);
      case 'customerType':
        setContacts(
          handleSortByCustomerType(contacts, customerTypeSortDirection),
        );
        return setCustomerTypeSortDirection(!customerTypeSortDirection);
      case 'impactTotal':
        setContacts(
          handleSortByImpactTotal(contacts, impactTotlaSortDirection),
        );
        return setImpactTotalSortDirection(!impactTotlaSortDirection);
      default:
        return setContacts(handleSortByFullName(contacts, nameSortDirection));
    }
  };

  const handleFilterByTabChange = (filterBy: string) => {
    setFilterBy(filterBy);
    setPage(0);
    return setContacts(handleFilterByTab(props.allContacts, filterBy, search));
  };

  const handleSearchContacts = (search: string) => {
    setSearch(search);
    setPage(0);
    return setContacts(
      handleSearchAllContacts(props.allContacts, filterBy, search),
    );
  };

  const handleOrderAdd = (email: string) => {
    const variables = {
      input: {
        customerEmail: email,
      },
    };
    return createConciergeService({ variables })
      .then(res => {
        const conciergeService = res?.data?.createConciergeService;
        if (!conciergeService) return;

        // segment tracking
        const isNewCustomer = conciergeService.newCustomer?.ordersCount === 0;
        const customerId = conciergeService.user?.conciergeCustomerAccountId;
        trackEventV2({
          event: segmentEvents.conciergeModeOrderStarted,
          properties: {
            eventId: null,
            shoppingForId: customerId,
            isNewCustomer,
          },
        });

        if (productCatalogPageV2) {
          window.location.href = `${process.env.GATSBY_PRODUCT_CATALOG_PAGE_URL}`;
        } else {
          window.location.href = `${process.env.GATSBY_HOPECOMMERCE_URL}/shop/wine/wine`;
        }
        return;
      })
      .catch(console.error);
  };

  const contactsCount = (contacts && contacts.length) || 0;

  return (
    <Fragment>
      <GlobalCss />
      {props.allContacts && props.allContacts.length > 0 ? (
        <Fragment>
          <CheckBoxToggleContextProvider>
            <Container>
              <Toolbar>
                <SearchBar
                  handleSearch={handleSearchContacts}
                  placeHolderText="Search contacts"
                />
                <TabFilter
                  filterBy={filterBy}
                  handleFilterByTabChange={handleFilterByTabChange}
                />
                <CheckBoxToggle />
                {!isMobile && (
                  <ToggleView>
                    <IconButton onClick={toggleView}>
                      {isContactsTableView ? (
                        <AppsIcon />
                      ) : (
                        <FormatListBulletedIcon />
                      )}
                    </IconButton>
                  </ToggleView>
                )}
              </Toolbar>
              {isContactsTableView && !isMobile ? (
                <TableListView
                  page={page}
                  contacts={contacts}
                  rowsPerPage={rowsPerPage}
                  handleSortContacts={handleSortContacts}
                  handleOrderAdd={handleOrderAdd}
                />
              ) : (
                <CardListView
                  page={page}
                  contacts={contacts}
                  rowsPerPage={rowsPerPage}
                  handleOrderAdd={handleOrderAdd}
                />
              )}
              <TablePagination
                page={page}
                count={contactsCount}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Container>
          </CheckBoxToggleContextProvider>
        </Fragment>
      ) : (
        <NoContacts openDialog={() => {}} openForContacts={true} />
      )}
    </Fragment>
  );
}
