import { css } from '@emotion/core';
import { styles } from '@onehope/design-system';
const { cssConstants } = styles;

export default css`
  height: 230px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);

  header {
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 24px;
    padding-top: 16px;
    padding-bottom: 15px;
    padding-right: 24px;
    .title {
      height: 23px;
      width: 85px;
      color: ${cssConstants.textColor};
      font-family: ${cssConstants.font};
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
    }
    .ant-btn.edit,
    .ant-btn.cancel {
      padding: 0;
      float: right;
      height: 22px;
      color: ${cssConstants.primaryColor};
      font-family: ${cssConstants.font};
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      border: none;
      span {
        text-decoration: underline;
      }
    }
  }

  .input-label {
    margin-top: -10px;
    padding-bottom: 20px;
    width: 500px;
    height: 18px;
    color: ${cssConstants.neutral34};
    font-family: ${cssConstants.font};
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
  }

  .ant-input {
    width: 100%;
    height: 48px;
    background-color: rgb(24, 144, 255, 0.04);
    color: ${cssConstants.textColor};
    font-family: ${cssConstants.font};
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    border-radius: 0;
    border: 0;
    border-bottom: 2px solid ${cssConstants.primaryColor};
  }

  .my-goal-content {
    display: flex;
    width: 100%;
  }

  .my-goal-progress {
    width: 100%;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    .impact-status-text {
      height: 21px;
      color: ${cssConstants.textColor};
      font-family: ${cssConstants.font};
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
    }
    .impact-status-amnt {
      width: 134px;
      height: 24px;
      margin-top: -5px;
      margin-bottom: 40px;
      font-family: ${cssConstants.font};
      font-size: 32px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.27;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .antd-pro-charts-mini-progress-target {
    color: ${cssConstants.linkColor};
    span {
      background-color: ${cssConstants.linkColor} !important;
    }
  }

  .progress-bar-bg {
    height: 5px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 1px 2px 17px 0 rgba(0, 0, 0, 0.05);
  }

  .progress-bar-prog {
    height: 5px;
    border-radius: 2px;
    background-color: red;
    box-shadow: 1px 2px 17px 0 rgba(0, 0, 0, 0.05);
  }

  .antd-pro-charts-mini-progress-target {
    color: ${cssConstants.linkColor};
  }

  .my-goal-progress-container {
    display: flex;
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 25px;
    .antd-pro-charts-mini-progress-progress {
      background-color: ${cssConstants.linkColor} !important;
    }
  }

  .progress-info {
    width: 100%;
    padding-top: 9px;
    .progress-cash-goal {
      width: 69px;
      height: 22px;
      color: ${cssConstants.textColor};
      font-family: ${cssConstants.font};
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      margin-bottom: 16px;
    }
    .progress-time-left {
      width: 90px;
      height: 22px;
      font-family: ${cssConstants.font};
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: right;
      color: rgba(0, 0, 0, 0.85);
    }
    .progress-cash-goal {
      float: left;
    }
    .progress-time-left {
      float: right;
      text-align: right;
    }
  }

  .ant-menu {
    display: flex;
    padding-top: 5px;
    margin-bottom: -30px;
  }

  .ant-menu
    .ant-menu-change
    .ant-menu-light
    .ant-menu-root
    .ant-menu-horizontal
    .ant-menu-change:hover {
    border-bottom: none !important;
    color: inherit !important;
  }

  .ant-menu-item {
    height: 28px;
    width: 96px;
    border: solid 1px #d9d9d9;
    background-color: white;
    color: ${cssConstants.textColor};
    font-family: ${cssConstants.neutral34};
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: center;
    padding: 5px 2px;
  }

  .ant-menu-item-selected {
    background-color: white;
    border: solid 1px ${cssConstants.primaryColor};
    font-weight: bold;
    color: ${cssConstants.primaryColor};
  }

  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu {
    border: solid 1px ${cssConstants.primaryColor} !important;
    color: inherit !important;
    font-weight: bold;
  }

  .button-container {
    margin-top: -10px;
    button {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }

  .ant-form-explain {
    margin-bottom: 0;
  }
`;
