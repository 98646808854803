import * as React from 'react';
import styled from '@emotion/styled';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { tabFilterStyles } from './TabFilter.styles';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 16px 24px 0 24px;
  flex-direction: column;
  @media all and (min-width: 960px) {
    padding: 0 24px 0 24px;
    flex-direction: row;
  }
`;

type TabFilterProps = {
  filterBy: string;
  handleFilterByTabChange: (value: string) => void;
};

function TabFilter(props: TabFilterProps) {
  const classes = tabFilterStyles();
  const {
    root,
    tabSelected,
    tabsIndicator,
    tabRootLeft,
    tabRootRight,
    tabRootMiddle,
  } = classes;
  const tabClassesRight = { root: tabRootRight, selected: tabSelected };
  const tabClassesLeft = { root: tabRootLeft, selected: tabSelected };
  const tabClassesMiddle = { root: tabRootMiddle, selected: tabSelected };

  const handleTabChange = (e: React.FormEvent<EventTarget>, value: string) => {
    props.handleFilterByTabChange(value);
  };

  return (
    <Container>
      <Tabs
        style={{ minHeight: 'unset' }}
        key="TabFilter"
        value={props.filterBy}
        scrollButtons="off"
        variant="scrollable"
        onChange={handleTabChange}
        classes={{ indicator: tabsIndicator }}>
        <Tab value="all" label="All" classes={tabClassesLeft} />
        <Tab value="open" label="Open" classes={tabClassesMiddle} />
        <Tab value="cancelled" label="Cancelled" classes={tabClassesRight} />
      </Tabs>
    </Container>
  );
}

export default TabFilter;
