import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import get from 'lodash/get';
import Decimal from 'decimal.js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogRoot from '@material-ui/core/Dialog';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { MuiButton } from '@onehope/design-system-ohw';
import { Currency } from '@onehope/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';

// import GIFT_CARD_ADD_MUTATION from '../../../../mutations/GiftCard/GiftCardAdd';
import SHOP_WITH_CUSTOMER_MUTATION from '../../../../mutations/Customer/CustomerShopMutation';
import SHOP_WITH_CUSTOMER_DELETE_MUTATION from '../../../../mutations/Customer/CustomerShopDeleteMutation';
import EVENT_UPDATE_MUTATION from '../../../../mutations/Event/EventUpdateMutation';
import HOST_REWARDS_UPDATE_MUTATION from '../../../../mutations/Event/HostRewardsUpdateMutation';
import { GET_HOST_REWARDS_QUERY } from '../../../../queries/HostRewards';
import GET_USERS_QUERY from '../../../../queries/UserBySearch';

import Loading from '../../../../common/Loading';
import AddCustomerForm from '../../../Events/AddCustomerForm/AddCustomerForm';
import TrinityHostRewardsRedeemed from './TrinityHostRewardsRedeemed';
import HostRewardsRedeemedOrders from './HostRewardsRedeemedOrders';
import PromoCode from './PromoCode';
import useToggle from '../../../../utils/useToggleHook';
import { EventStatusDictionary } from '../../../../utils/enums';

import {
  DetailsContainer,
  DetailsTitle,
  StraightLine,
  TabContentContainer,
} from '../index.styles';
import SummaryLine from '../../../Contact/TabContent/SummaryLine';

import {
  CEDash_EventDetailsPage_viewer_event,
  CEDash_EventDetailsPage_viewer_user,
} from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { useTrackingContextValue } from '../../../../contexts/TrackingContext';
import { getHostRewardPoints } from '../../../../utils/utils';
import { segmentEvents } from '../../../../utils/segment/constants';

const Container = styled.div`
  padding: 0 16px;
`;

const TextLine = styled.div`
  /* font-family: ProximaNova; */
  font-size: 14px;
  line-height: 1.29;
  padding-top: 8px;
`;

const Button = styled(MuiButton)`
  && {
    margin-top: 40px;
  }
`;

function roundUpOrDown(decimal: Decimal | number): number {
  return parseFloat(new Decimal(decimal).toFixed(2, Decimal.ROUND_HALF_UP));
}

const formatRewardsPoints = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

interface HostRewardsProps {
  event: CEDash_EventDetailsPage_viewer_event;
  user: CEDash_EventDetailsPage_viewer_user;
}

export default function HostRewards(props: HostRewardsProps) {
  const { event, user } = props;
  const { userId: loggedInUserId } = user;
  const {
    status,
    eventId,
    accountId,
    hostAccountId,
    hostFullName,
    hostEmail,
    hostPhone,
    trinityHostRewardsOrderId,
    hostRewardsOrderIds,
    hostRewardsQualifiedSubTotal,
  } = event;
  const subtotal = hostRewardsQualifiedSubTotal || 0;
  const [hostRewardsAccount, setHostRewardsAccount] = useState(null);

  // const [GiftCardAddMutation, { loading: giftCardLoading }] = useMutation(
  //   GIFT_CARD_ADD_MUTATION,
  // );
  const [ShopWithCustomerMutation] = useMutation(SHOP_WITH_CUSTOMER_MUTATION);
  const [ShopWithCustomerDeleteMutation] = useMutation(
    SHOP_WITH_CUSTOMER_DELETE_MUTATION,
  );
  const [eventUpdateMutation] = useMutation(EVENT_UPDATE_MUTATION);
  const [hostRewardsUpdateMutation] = useMutation(HOST_REWARDS_UPDATE_MUTATION);

  const { data, loading, error, refetch } = useQuery(GET_HOST_REWARDS_QUERY, {
    fetchPolicy: 'network-only',
    variables: { eventId },
  });
  const { refetch: refetchUsableByHost } = useQuery(GET_USERS_QUERY, {
    variables: { searchInput: accountId, accountId: true },
  });

  const { trackEventsV2, trackEventV2 } = useTrackingContextValue();
  const { productCatalogPageV2, rewardPoints } = useFlags();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { value: open, setTrue: setOpen, setFalse: setClosed } = useToggle();

  /* Return early if we just need to display Trinity Host Rewards */
  if (trinityHostRewardsOrderId) {
    return (
      <Container>
        <TabContentContainer>
          <TrinityHostRewardsRedeemed
            trinityHostRewardsOrderId={trinityHostRewardsOrderId}
            hostRewardsQualifiedSubTotal={subtotal}
          />
        </TabContentContainer>
      </Container>
    );
  }

  /* If the Host Rewards would be from our system */
  if (loading) return <Loading />;
  if (error) return <div>`Error! ${error.message}`</div>;

  if (get(data, 'viewer.event')) {
    const { hostRewards } = data.viewer.event;
    let storeBalance;
    let loadedBalance;
    let expiresOn;
    let promoCodeId = '';
    let usableByAccountId = '';
    const hostReward = hostRewards && hostRewards[0];
    const qualified = subtotal >= 500;
    const showRewardPoints = getHostRewardPoints(
      rewardPoints,
      data.viewer.event,
    );

    if (hostReward) {
      ({
        promoCodeId,
        storeBalance,
        loadedBalance,
        expiresOn,
        usableByAccountId,
      } = hostReward);
      refetchUsableByHost({
        searchInput: usableByAccountId,
        accountId: true,
      }).then(resp => {
        setHostRewardsAccount(resp?.data?.viewer?.users?.edges?.[0].node);
      });
    } else if (qualified) {
      // This amount WILL be loaded to their promocode in the future.
      loadedBalance = new Decimal(subtotal).times(0.1).toNumber();
    }

    const hasPointsIssued = !!data?.viewer?.event?.hostRewardPointsIssued;
    const hostRewardPointsIssued = hasPointsIssued
      ? data?.viewer?.event?.hostRewardPointsIssued
      : roundUpOrDown(loadedBalance * 100);
    const isExpired = expiresOn && new Date(expiresOn) < new Date();
    const hasRemainingCredit = storeBalance && storeBalance !== loadedBalance;
    const isQualifiedOpenEvent =
      qualified && !hostReward && status === EventStatusDictionary.OPEN;
    const hostRewardsExpiredInTrinity =
      qualified &&
      !hostReward &&
      !hasPointsIssued &&
      status !== EventStatusDictionary.OPEN;
    const statusText = qualified ? 'Qualified' : 'Not qualified for rewards';
    const label = 'Host rewards promo code';
    const toolTipText = qualified
      ? showRewardPoints
        ? 'This event has qualified to earn reward points since the subtotal of the orders is at least $500'
        : 'This event has qualified for Host Rewards since the orders subtotal is at least $500'
      : showRewardPoints
      ? 'The event must earn $500 in sales to qualify to earn reward points'
      : 'The event must earn $500 in sales to qualify for Host Rewards';

    const noop = () => {};

    const handleNewCustomerClose = () => {
      setClosed();
    };

    const handleNewCustomerOpen = () => {
      setOpen();
    };

    const redirectToShop = () => {
      // Add promocode to cart automatically and then redirect to shop.
      // On hold; would need to make sure it adds even if no cart/nothing in cart, for both CE and concierge.
      // if (hostReward) {
      //   GiftCardAddMutation({
      //     variables: {
      //       input: { promoCodes: [hostReward.code], noGiftCards: false },
      //     },
      //   }).then(() => {
      // if (productCatalogPageV2) {
      //   return window.open(
      //     `${process.env.GATSBY_PRODUCT_CATALOG_PAGE_URL}`,
      //     '_blank',
      //   );
      // }
      // return window.open(
      //   `${process.env.GATSBY_HOPECOMMERCE_URL}/shop/wine/wine`,
      //   '_blank',
      // );
      //   });

      if (productCatalogPageV2) {
        return window.open(
          `${process.env.GATSBY_PRODUCT_CATALOG_PAGE_URL}`,
          '_blank',
        );
      }
      return window.open(
        `${process.env.GATSBY_HOPECOMMERCE_URL}/shop/wine/wine`,
        '_blank',
      );
    };

    const conciergeShop = (customerId: string) => {
      return ShopWithCustomerMutation({
        variables: { input: { customerId } },
      }).then(res => {
        const shopWithCustomerData = res?.data?.shopWithCustomer;

        // segment tracking
        const isNewCustomer =
          shopWithCustomerData.newCustomer?.ordersCount === 0;
        trackEventV2({
          event: segmentEvents.conciergeModeOrderStarted,
          properties: {
            eventId: event.eventId || null,
            shoppingForId: customerId,
            isNewCustomer,
          },
        });
        redirectToShop();
      });
    };

    const shopForSelf = () => {
      return ShopWithCustomerDeleteMutation({ variables: { input: {} } }).then(
        redirectToShop,
      );
    };

    const handleSelectedCustomer = ({
      email,
      phone,
      accountId,
      firstName,
      lastName,
      hostCustomerType,
    }: any) => {
      eventUpdateMutation({
        variables: {
          input: {
            form: {
              eventId,
              hostAccountId: accountId,
              hostFirstName: firstName,
              hostLastName: lastName,
              hostEmail: email,
              hostPhone: phone,
            },
          },
        },
      })
        .then(data => {
          const newEvent = get(data, 'data.eventUpdate.event');
          trackEventsV2({
            eventName: 'Event Host Updated',
            newEvent,
            formValues: { hostCustomerType },
          });
          // Update the promocode to belong to the new host.
          return hostRewardsUpdateMutation({
            variables: {
              input: {
                promoCodeId,
                promoCodeData: {
                  reAssignHost: true,
                },
              },
            },
          });
        })
        .then(() => {
          // Refetch the host rewards to get latest. should improve the above to update via fragment.
          refetch();
        })
        .then(() => {
          return handleNewCustomerClose();
        })
        .catch((errors: any) => {
          // console.log({ setErrors });
          console.log('errors', errors);
          return;
        });
    };

    const getShopOptions = () => {
      const shopOptions = { buttonText: 'SHOP FOR HOST', onClick: noop };
      if (!hostReward && !hasPointsIssued) {
        return shopOptions;
      }
      const usableByAccountId = showRewardPoints
        ? hostAccountId
        : hostReward
        ? hostReward?.usableByAccountId
        : null;

      switch (true) {
        case !hostAccountId:
        case hostAccountId !== usableByAccountId:
          // There should always be a hostAccountId in order to shop concierge
          // We need to create a host account and assign them to this event first
          // Once the customer is created, handleSelectedCustomer() will be called as callback.
          shopOptions.buttonText = 'ASSIGN TO HOST';
          shopOptions.onClick = handleNewCustomerOpen;
          break;
        case hostAccountId === accountId:
          // Shop as self, not in concierge mode.
          shopOptions.buttonText = 'SHOP';
          shopOptions.onClick = shopForSelf;
          break;
        default:
          shopOptions.buttonText = 'SHOP FOR HOST';
          shopOptions.onClick = () => conciergeShop(hostAccountId);
          break;
      }

      // Dont allow any admins to perform these actions since the mutations arent built for it yet.
      if (loggedInUserId !== accountId) {
        shopOptions.onClick = () =>
          alert(
            'You are not the CE for this customer so you cannot perform this action',
          );
      }
      return shopOptions;
    };

    const shopOptions = getShopOptions();
    return (
      <Container>
        <TabContentContainer>
          <DialogRoot
            fullScreen={fullScreen}
            disableEnforceFocus
            open={open}
            onClose={handleNewCustomerClose}
          >
            <AddCustomerForm
              formTitle={'Host account'}
              addCustomerButtonText={'create & assign'}
              existingCustomerButtonText={'continue with existing customer'}
              onClose={handleNewCustomerClose}
              handleSelectedCustomer={handleSelectedCustomer}
              defaultValues={{
                firstName: hostFullName || '',
                lastName: '',
                email: hostEmail || '',
                phone: hostPhone || '',
              }}
            />
          </DialogRoot>
          <DetailsContainer>
            <DetailsTitle>Details</DetailsTitle>
            <StraightLine />
            <SummaryLine
              title="Status"
              value={statusText}
              valueToolTipText={toolTipText}
            />
            <SummaryLine
              title="Event orders subtotal"
              value={<Currency rawValue={subtotal} />}
            />
            {showRewardPoints ? (
              <div>
                <SummaryLine
                  title="Reward points earned"
                  value={`${
                    hostRewardPointsIssued
                      ? formatRewardsPoints(hostRewardPointsIssued)
                      : 0
                  } ($${(hostRewardPointsIssued / 100 || 0).toFixed(2)})`}
                />
                <SummaryLine title="Redeemable by" value={hostEmail} />
              </div>
            ) : (
              <div>
                <SummaryLine
                  title="Credit earned"
                  value={<Currency rawValue={loadedBalance || 0} />}
                />
                {!!hasRemainingCredit && (
                  <SummaryLine
                    title="Remaining credit"
                    value={<Currency rawValue={storeBalance} />}
                  />
                )}
                {expiresOn && (
                  <SummaryLine
                    title={isExpired ? 'Expired on' : 'Redeem by'}
                    value={new Date(expiresOn).toLocaleDateString()}
                  />
                )}
              </div>
            )}
            {!!hostRewardsAccount && !showRewardPoints && (
              <SummaryLine
                title="Redeemable by"
                value={hostRewardsAccount?.email}
              />
            )}

            {showRewardPoints && hasPointsIssued ? (
              <Button
                type="PRIMARY"
                disableRipple
                fullWidth
                onClick={shopOptions.onClick}
              >
                {shopOptions.buttonText}
              </Button>
            ) : (
              !!storeBalance && (
                <PromoCode
                  label={label}
                  value={hostReward.code}
                  onChange={noop}
                  buttonText={shopOptions.buttonText}
                  onButtonClick={shopOptions.onClick}
                ></PromoCode>
              )
            )}

            {isQualifiedOpenEvent && (
              <>
                <TextLine>
                  {showRewardPoints
                    ? 'Once this event has closed, the points earned from the event may be used as a credit towards the Host’s next purchase.'
                    : 'Once the event has closed, a Host Rewards promo code will be generated:'}
                </TextLine>
                {!showRewardPoints && (
                  <PromoCode
                    disabled
                    label={label}
                    value={''}
                    onChange={noop}
                    buttonText="SHOP FOR HOST"
                    onButtonClick={noop}
                  />
                )}
              </>
            )}

            {hostRewardsExpiredInTrinity && (
              <TextLine>
                Your host rewards credit expired in the Back Office. For
                questions, contact support.
              </TextLine>
            )}
          </DetailsContainer>
          <HostRewardsRedeemedOrders
            hostRewardsOrderIds={hostRewardsOrderIds}
          ></HostRewardsRedeemedOrders>
        </TabContentContainer>
      </Container>
    );
  }
  return null;
}
