import React, { useEffect, useState, Fragment } from 'react';
import { handleFilterByTab } from '../../common/Orders/helpers';
import Header from './Header';
import OrdersView from '../../common/Orders';
import { CEDash_GuestOrdersQuery_viewer_user_orders as Orders } from '../../queries/generatedTypes/CEDash_GuestOrdersQuery';

interface OrdersPageWrapperProps {
  orders: Orders;
}

function OrdersPageWrapper(props: OrdersPageWrapperProps) {
  const ordersEdges = props.orders.edges;
  const openOrders = handleFilterByTab(ordersEdges, 'open');
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);
  const [orders, setOrders] = useState(openOrders);
  const [allOrders, setAllOrders] = useState(ordersEdges);

  useEffect(
    () => {
      setOrders(openOrders);
      setAllOrders(ordersEdges);
    },
    [props.orders],
  );

  return (
    <Fragment>
      <Header setIsOrderDialogOpen={setIsOrderDialogOpen} />
      <OrdersView
        setOrders={setOrders}
        orders={orders}
        allOrders={allOrders}
        isOrderDialogOpen={isOrderDialogOpen}
        setIsOrderDialogOpen={setIsOrderDialogOpen}
      />
    </Fragment>
  );
}

export default OrdersPageWrapper;
