import React from 'react';
import { navigate } from 'gatsby';
import { useFlags } from 'launchdarkly-react-client-sdk';
import NotificationsIcon from '@material-ui/icons/Notifications';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { LaunchDates } from '../../../utils/constants';
import { CEDash_EventDetailsPage_viewer_event } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import {
  Container,
  NotificationBadge,
  Notification,
  TextContainer,
  Link,
  Spacer,
  ClosedEvent,
} from './index.styles';
import { getHostKitsV2, getTastingFlights } from '../../../utils/utils';
import { EventStatusDictionary } from '../../../utils/enums';
import { getWineNeeded } from '../TabContent/Wine/helpers';
import { isSatelliteEvent } from '../../Events/EventsTable/helpers';

interface HeaderProps {
  event: CEDash_EventDetailsPage_viewer_event;
}

export function getDays(date: string | null) {
  if (!date) return 0;
  let oneDay = 24 * 60 * 60 * 1000;
  const today = new Date();
  const eventDateAsDate = new Date(date);
  return Math.round((eventDateAsDate.getTime() - today.getTime()) / oneDay);
}

export default function Notifications(props: HeaderProps) {
  const { event } = props;

  const handleRouteChange = (
    e: React.FormEvent<EventTarget>,
    value: string,
  ) => {
    e.preventDefault();
    return navigate(`/event?eventId=${event.eventId}&tab=${value}`);
  };

  const getEventDescription = () => {
    if (daysUntilEvent > 0) {
      if (daysUntilEvent === 1) {
        return `${daysUntilEvent} day left`;
      }
      return `${daysUntilEvent} days left`;
    }
    if (daysUntilClose > 0) return `Event will close in ${daysUntilClose} days`;
    if (isMobile) return `Charity details incomplete`;
    return 'Charity details needed before this event can close';
  };

  const getWineDescription = () => {
    if (daysUntilEvent <= 15) {
      if (daysUntilEvent === 1) {
        return `${daysUntilEvent} day left -`;
      }
      return `${daysUntilEvent} days left -`;
    }
  };

  const getCharityDetailsLink = () => {
    if (daysUntilEvent > 0 || daysUntilClose > 0) {
      if (isMobile) return 'Select a charity';
      return 'Enter charity details';
    }
    return 'Enter now';
  };

  const { eventDate, trinityPartyStatus, donationInfo, closeDate } = event;
  const daysUntilEvent = getDays(eventDate);
  const daysUntilClose = getDays(closeDate);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const notAcceptingOrders =
    trinityPartyStatus === EventStatusDictionary.CLOSED;
  const openEvent = event?.status === EventStatusDictionary.OPEN;
  const showEventStatusChangeText = notAcceptingOrders || !openEvent;
  let closedEventText =
    notAcceptingOrders && !openEvent
      ? 'This event has closed'
      : 'The event is no longer accepting orders';
  const qualified = event?.donationInfo?.ordersSubTotal > 500;
  const ordersPlaced = event?.ordersCount > 0;
  const charitySelected = !!donationInfo?.nonProfit;
  const preApprovedNotSelected = !event?.donationInfo?.nonProfit?.isPreApproved;

  // Temporary for launch
  const isMigrating = !event?.status;
  if (isMigrating) {
    closedEventText = 'Please have patience while this event is migrated';
  }

  const { hostKitsV2, no500DonationThreshold } = useFlags();
  const showHostKitsV2 = getHostKitsV2(hostKitsV2, event);
  const satelliteEvent = isSatelliteEvent(event);

  const showTastingFlights = getTastingFlights(event);
  const wineNeeded = getWineNeeded(
    event.hostKitQuantity,
    event.starterKitCredits,
    event.hostKitPurchaser,
    event.isPurchasingTastingFlights,
    showTastingFlights,
  );

  const pendingWineInformation = showTastingFlights
    ? wineNeeded === 'YES'
    : (!event.wineTastingOrderIds?.length && wineNeeded === 'YES') ||
      wineNeeded === 'SKIP';
  const showWineNotification =
    showHostKitsV2 &&
    pendingWineInformation &&
    daysUntilEvent <= 15 &&
    daysUntilEvent > 0;
  const showCausePartnerRequiredNotification =
    ordersPlaced &&
    !qualified &&
    preApprovedNotSelected &&
    !satelliteEvent &&
    !no500DonationThreshold;
  const showDaysUntilEventNotification =
    !charitySelected && daysUntilEvent <= 5 && daysUntilEvent > 0;
  const showDaysUntilCloseNotification =
    !charitySelected && daysUntilClose <= 5 && daysUntilClose > 0;
  const showNotifications =
    (showDaysUntilEventNotification ||
      showDaysUntilCloseNotification ||
      showWineNotification ||
      showCausePartnerRequiredNotification) &&
    openEvent;

  const eventDescription = getEventDescription();
  const wineDescription = getWineDescription();

  const charityDetailsLink = getCharityDetailsLink();
  // calculate wine link text based on
  // trinityPartyStatus for virtual / non
  const wineLink = showTastingFlights
    ? 'remind guests to order their flights'
    : 'Order your wine';

  return (
    <>
      {showEventStatusChangeText && (
        <ClosedEvent>{closedEventText}</ClosedEvent>
      )}
      {showNotifications && (
        <Container>
          <NotificationBadge variant="dot">
            <NotificationsIcon />
          </NotificationBadge>
          <Spacer />
          <Notification
            causePartnerRequired={showCausePartnerRequiredNotification}
          >
            {showCausePartnerRequiredNotification && (
              <TextContainer>
                Event has not qualified -
                {/* eslint-disable react/jsx-no-bind */}
                <Link onClick={e => handleRouteChange(e, '2')}>
                  select a ONEHOPE Cause Partner to close event
                </Link>
              </TextContainer>
            )}
            {showWineNotification && (
              <TextContainer>
                {wineDescription}
                {/* eslint-disable react/jsx-no-bind */}
                <Link onClick={e => handleRouteChange(e, '1')}>{wineLink}</Link>
              </TextContainer>
            )}
            {(showDaysUntilEventNotification ||
              showDaysUntilCloseNotification) &&
              !showCausePartnerRequiredNotification && (
                <TextContainer>
                  {eventDescription} - {/* eslint-disable react/jsx-no-bind */}
                  <Link onClick={e => handleRouteChange(e, '2')}>
                    {charityDetailsLink}
                  </Link>
                </TextContainer>
              )}
          </Notification>
        </Container>
      )}
    </>
  );
}
