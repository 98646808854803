import gql from 'graphql-tag';

export const ADDRESS_AUTOCOMPLETE = gql`
  mutation AddressAutoCompleteMutation($input: AddressAutoCompleteInput!) {
    addressAutoComplete(input: $input) {
      addressPredictions {
        id
        placeId
        addressLineOne
        addressLineTwo
        city
        state
        zip
        addressId
        country
      }
    }
  }
`;

export default ADDRESS_AUTOCOMPLETE;
