import gql from 'graphql-tag';

const mutation = gql`
  mutation ContactsAddBulkMutation($input: ContactsAddBulkInput!) {
    contactsAddBulk(input: $input) {
      user {
        id
        customers(first: 10000, sortBy: "any") {
          edges {
            node {
              id
              createDate
              dateOfBirth
              isSubscriber
              impactTotal
              isPreferredCustomer
              fullName
              accountId
              email
              mobilePhone
              lifeTimeSpend
              lastOrderDate
              contactType
              organizationName
            }
          }
        }
      }
      failedImports {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export default mutation;
