import * as React from 'react';
import styled from '@emotion/styled';
import Tooltip from '@material-ui/core/Tooltip';
import { useArchiveContactsContext } from '../../Contacts/ArchiveContactsContext';

import PCCustomerSvg from '../../../assets/pcCustomer';
import SubscriberSvg from '../../../assets/subscriber';
import HostSvg from '../../../assets/host';
import { SvgArchive } from '../../../assets/archive';

import { CEDash_CustomerDetailsPage_viewer_customer as Contact } from '../../../queries/generatedTypes/CEDash_CustomerDetailsPage';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  flex-direction: column;
  @media all and (min-width: 768px) {
    padding: 0 40px 0 40px;
    flex-direction: row;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HostName = styled.div`
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: -0.5px;
  margin-bottom: 12px;
  margin-right: 24px;
`;

const PreferredCustomer = styled.span`
  svg {
    height: 40px;
    width: 40px;
  }
  :hover {
    cursor: pointer;
  }
`;
const Subscriber = styled.span`
  svg {
    height: 40px;
    width: 40px;
  }
  :hover {
    cursor: pointer;
  }
`;

const ArchiveContact = styled.span`
  /* svg {
    height: 40px;
    width: 40px;
  } */
  :hover {
    cursor: pointer;
  }
`;

const Host = styled.span`
  svg {
    height: 40px;
    width: 40px;
  }
  :hover {
    cursor: pointer;
  }
`;

const ArchiveContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

interface HeaderProps {
  contact?: Contact;
}

function handleArchive(
  handleSingleArchiveContact: (input: string) => void,
  contactId: string | null | undefined,
) {
  return () => {
    if (contactId) {
      return handleSingleArchiveContact(contactId);
    }
  };
}

export default function Header(props: HeaderProps) {
  const { contact } = props;

  const { handleSingleArchiveContact } = useArchiveContactsContext();

  const dispatchHandleArchive = handleArchive(
    handleSingleArchiveContact,
    contact?.accountId,
  );
  return (
    <HeaderContainer>
      <TitleContainer>
        <HostName>
          {contact?.organizationName || contact?.fullName || ''}
        </HostName>
        {contact?.isSubscriber && (
          <Tooltip title="Subscriber" placement="top">
            <Subscriber>
              <SubscriberSvg />
            </Subscriber>
          </Tooltip>
        )}
        {contact?.isHost && (
          <Tooltip title="Host" placement="top">
            <Host>
              <HostSvg />
            </Host>
          </Tooltip>
        )}
      </TitleContainer>
      {contact?.accountId && (
        <Tooltip title="Archive" placement="top">
          <ArchiveContainer onClick={dispatchHandleArchive}>
            <SvgArchive />
          </ArchiveContainer>
        </Tooltip>
      )}
    </HeaderContainer>
  );
}
