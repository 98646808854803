import * as React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import styled from '@emotion/styled';

import { config } from './config';
import { tabNavStyles } from './TabNavigation.styles';

type EventsTabNavigationProps = {
  handleChange: (event: React.FormEvent<EventTarget>, value: number) => void;
  tabValue: number;
};

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-start;
  padding: 0 16px 0 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  @media only screen and (min-width: 768px) {
    padding: 0 40px 0 40px;
  }
`;

function EventsTabNavigation(props: EventsTabNavigationProps) {
  const { handleChange, tabValue } = props;
  const classes = tabNavStyles();
  const { root, tabRoot, tabSelected, tabsIndicator } = classes;
  const tabClasses = { root: tabRoot, selected: tabSelected };

  return (
    <Container>
      <Tabs
        key="EventsTabNavigation"
        value={tabValue}
        scrollButtons="off"
        variant="scrollable"
        onChange={handleChange}
        classes={{ indicator: tabsIndicator }}>
        {config.map(tab =>
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            classes={tabClasses}
            disabled={tab.disabled}
            data-cypress={tab.cypressData}
          />,
        )}
      </Tabs>
    </Container>
  );
}

export default EventsTabNavigation;
