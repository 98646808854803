import * as React from 'react';
import CartItemProps from '../types/CartItemProps';
import ItemQuantity from './ItemQuantity';
import PriceTag from './PriceTag';
import ShopImage from './ShopImage';
import styled from '@emotion/styled';

const leftWidthPercent = 60;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemRow = styled.div`
  display: flex;

  &:first-of-type {
    border-top: 0;
  }

  .item-name {
    flex-grow: 1;
    width: ${leftWidthPercent}%;
  }

  .item-price {
    width: ${100 - leftWidthPercent}%;
    text-align: right;
    flex-shrink: 1;
    padding-top: 5px;
  }

  img {
    width: auto;
    height: 96px;
    margin-top: 10px;
  }

  input {
    width: 3em;
    text-align: center;
  }
`;

const ProductName = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  padding: 16px 0;
  width: 100%;
`;

export default function CartItem({
  product,
  priceBought,
  quantity,
}: CartItemProps) {
  let productImage = null;

  if (product.images && product.images.productCatalog) {
    productImage = <ShopImage url={`${product.images.productCatalog}`} />;
  }

  return (
    <ItemContainer>
      <ItemRow key={product.id}>
        <div className="item-name">
          {productImage}
        </div>
        <div className="item-price">
          <ItemQuantity quantity={quantity} />
          <br />
          <PriceTag product={product} priceBought={priceBought} />
        </div>
      </ItemRow>
      <ProductName>
        {product.name}
      </ProductName>
    </ItemContainer>
  );
}
