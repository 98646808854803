import React, { Component } from 'react';
import {
  Table,
  Input,
  Button,
  InputNumber,
  Popconfirm,
  Form,
  Icon,
  Tooltip,
  Spin,
} from 'antd';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { Mutation } from 'react-apollo';

import PaymentEditMutation from '../../../mutations/Payments/PaymentEdit';
import PaymentDeleteMutation from '../../../mutations/Payments/PaymentDelete';

const { TextArea } = Input;
import InputValidations from './InputValidations';
import {
  addressToStripeAddress,
  expirationDateToStripeExpiration,
} from './utils';

const FormItem = Form.Item;
const EditableContext = React.createContext({});

interface EditableCellProps {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: string;
  index: number;
  record: any;
}

class EditableCell extends React.Component<EditableCellProps, {}> {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    } else if (this.props.inputType === 'textArea') {
      return <TextArea rows={4} />;
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      index,
      ...restProps
    } = this.props;
    const record = this.props.record
      ? this.props.record.node.stripePayment
      : undefined;
    let initialValue = null;
    if (
      record &&
      record.billingAddress &&
      dataIndex &&
      dataIndex.split('.')[2] === 'billingAddress'
    ) {
      const dataKey = dataIndex.split('.')[3];
      initialValue = record.billingAddress[dataKey];
    } else if (record && dataIndex) {
      const dataKey = dataIndex.split('.')[2];
      initialValue = record[dataKey];
    }
    const rules = InputValidations[dataIndex]
      ? InputValidations[dataIndex].rules
      : undefined;
    return (
      <EditableContext.Consumer>
        {(form: any) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules,
                    initialValue,
                  })(this.getInput())}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

interface PaymentsTableProps {
  payments: any;
  form: any;
  selectedUserId: string;
}

interface PaymentsTableState {
  isUpdating: boolean;
  editingKey: string;
  cancellingKey: string;
  data: Array<any>;
  record: any;
  row: any;
  searchText: string;
  isCancelling: boolean;
}

class PaymentsTable extends Component<PaymentsTableProps, PaymentsTableState> {
  private columns: Array<any>;
  private searchInput: any;
  constructor(props: PaymentsTableProps) {
    super(props);
    this.state = {
      data: props.payments,
      editingKey: '',
      cancellingKey: '',
      isUpdating: false,
      record: null,
      row: null,
      searchText: '',
      isCancelling: false,
    };
    this.columns = [
      {
        title: 'Card #',
        dataIndex: 'node.stripePayment.last4',
        editable: false,
        key: 'cardNum',
        sorter: (a: any, b: any) =>
          a.node.stripePayment.last4.localeCompare(b.node.stripePayment.last4),
        ...this.getColumnSearchProps('node.stripePayment.last4'),
        render: (text: string) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={`************${text.toString()}`}
          />
        ),
      },
      {
        title: 'Brand',
        dataIndex: 'node.stripePayment.brand',
        editable: false,
        key: 'Brand',
        sorter: (a: any, b: any) =>
          a.node.stripePayment.brand.localeCompare(b.node.stripePayment.brand),
      },
      {
        title: 'Exp. Month',
        dataIndex: 'node.stripePayment.expMonth',
        editable: true,
        key: 'expMonth',
        render: (text: string) => {
          return moment.months(parseInt(text) - 1);
        },
      },
      {
        title: 'Exp. Year',
        dataIndex: 'node.stripePayment.expYear',
        key: 'expYear',
        editable: true,
      },
      {
        title: 'Card Nickname',
        dataIndex: 'node.stripePayment.nickname',
        key: 'nickname',
        editable: true,
      },
      {
        title: () => (
          <React.Fragment>
            <div>Card Name</div>
          </React.Fragment>
        ),
        dataIndex: 'node.stripePayment.name',
        editable: true,
        key: 'name',
      },
      {
        title: () => (
          <React.Fragment>
            <div>Billing Address</div>
            <div>Address Line 1</div>
          </React.Fragment>
        ),
        dataIndex: 'node.stripePayment.billingAddress.addressLineOne',
        key: 'billingAddress.street',
        editable: true,
      },
      {
        title: () => (
          <React.Fragment>
            <div>Billing Address</div>
            <div>Address Line 2</div>
          </React.Fragment>
        ),
        dataIndex: 'node.stripePayment.billingAddress.addressLineTwo',
        key: 'billingAddress.addressLineTwo',
        editable: true,
      },
      {
        title: () => (
          <React.Fragment>
            <div>Billing Address</div>
            <div>City</div>
          </React.Fragment>
        ),
        dataIndex: 'node.stripePayment.billingAddress.city',
        key: 'billingAddress.city',
        editable: true,
      },
      {
        title: () => (
          <React.Fragment>
            <div>Billing Address</div>
            <div>State</div>
          </React.Fragment>
        ),
        dataIndex: 'node.stripePayment.billingAddress.state',
        key: 'billingAddress.state',
        editable: true,
      },
      {
        title: () => (
          <React.Fragment>
            <div>Billing Address</div>
            <div>Zip</div>
          </React.Fragment>
        ),
        dataIndex: 'node.stripePayment.billingAddress.zip',
        key: 'billingAddress.zip',
        editable: true,
      },
      {
        dataIndex: 'operation',
        key: 'operation',
        width: '125px',
        fixed: 'right',
        render: (text: string, record: any) => {
          const editable = this.isEditing(record);
          const { editingKey } = this.state;
          return (
            <div>
              <Mutation
                mutation={PaymentEditMutation}
                onError={this.onMutationError}
                onCompleted={this.onPaymentUpdated}
              >
                {(editPayment, { data }) => (
                  <div>
                    {editable ? (
                      <span>
                        <EditableContext.Consumer>
                          {form => (
                            <Popconfirm
                              title="Sure to save?"
                              cancelText="Close"
                              okText="Yes"
                              onConfirm={() =>
                                this.save(form, record, editPayment)
                              }
                            >
                              <Button
                                htmlType="button"
                                style={{ marginRight: 8 }}
                                type="primary"
                                shape="circle"
                                icon="save"
                                size="small"
                              />
                            </Popconfirm>
                          )}
                        </EditableContext.Consumer>
                        <React.Fragment>
                          <Button
                            onClick={this.cancel}
                            htmlType="button"
                            type="default"
                            shape="circle"
                            icon="close"
                            size="small"
                          />
                          {this.state.isUpdating && this.isEditing(record) && (
                            <Spin style={{ marginLeft: 8 }} size="small" />
                          )}
                        </React.Fragment>
                      </span>
                    ) : (
                      <span>
                        <Tooltip title="Edit">
                          <Button
                            onClick={() => this.edit(record)}
                            htmlType="button"
                            type="primary"
                            shape="circle"
                            icon="edit"
                            size="small"
                            disabled={editingKey !== ''}
                          />
                        </Tooltip>
                        <Mutation
                          mutation={PaymentDeleteMutation}
                          onError={this.onMutationError}
                          onCompleted={this.onPaymentUpdated}
                        >
                          {(deletePayment, { data }) => (
                            <Popconfirm
                              title="Delete Payment?"
                              cancelText="Close"
                              okText="Delete"
                              onConfirm={() =>
                                this.deletePayment(record, deletePayment)
                              }
                            >
                              <span>
                                <Button
                                  htmlType="button"
                                  style={{ marginLeft: 8 }}
                                  type="danger"
                                  shape="circle"
                                  icon="delete"
                                  size="default"
                                />
                                {this.state.isCancelling &&
                                  this.isCancelling(record) && (
                                    <Spin
                                      style={{ marginLeft: 8 }}
                                      size="small"
                                    />
                                  )}
                              </span>
                            </Popconfirm>
                          )}
                        </Mutation>
                      </span>
                    )}
                  </div>
                )}
              </Mutation>
            </div>
          );
        },
      },
    ];
  }

  getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex.split('.')[2]}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) => {
      return record.node.stripePayment[dataIndex.split('.')[2]]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys: Array<string>, confirm: () => void) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters: () => void) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  isEditing = (record: any) => {
    return record.node.id === this.state.editingKey;
  };

  isCancelling = (record: any) => {
    return record.node.id === this.state.cancellingKey;
  };

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  onPaymentUpdated = () => {
    return this.setState({
      cancellingKey: '',
      editingKey: '',
      isUpdating: false,
      isCancelling: false,
    });
  };

  onMutationError = () => {
    return this.setState({
      isUpdating: false,
      editingKey: '',
      isCancelling: false,
      cancellingKey: '',
    });
  };

  save = (form: any, record: any, editPayment: (input: object) => void) => {
    if (!form || this.state.isUpdating) return null;
    form.validateFields((error: Object, row: any) => {
      if (error) {
        return;
      }
      const addressObject = {
        ...row.node.stripePayment.billingAddress,
      };
      addressObject.name = row.node.stripePayment.name;
      const stripeBillingAddress = addressToStripeAddress(addressObject);
      const stripeExpiration = expirationDateToStripeExpiration(
        row.node.stripePayment,
      );
      const { selectedUserId } = this.props;

      this.setState({ record, row, isUpdating: true });

      editPayment({
        variables: {
          input: {
            selectedUserId,
            nickname: row.node.stripePayment.nickname,
            stripeTokenId: record.node.stripePayment.paymentId,
            billingAddressUuid: record.node.stripePayment.billingAddressUuid,
            data: {
              ...stripeBillingAddress,
              ...stripeExpiration,
            },
          },
        },
      });
    });
  };

  deletePayment = (record: any, deletePayment: (input: object) => void) => {
    if (this.state.isUpdating || this.state.isCancelling) return null;
    this.setState({ isCancelling: true, cancellingKey: record.node.id });
    const { selectedUserId } = this.props;

    deletePayment({
      variables: {
        input: {
          selectedUserId,
          stripeTokenId: record.node.stripePayment.paymentId,
        },
      },
    });
  };

  edit = (key: any) => {
    if (this.state.isUpdating) return null;
    this.setState({ editingKey: key.node.id });
  };

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          key: col.key,
          title: col.title,
          inputType:
            col.key === 'expMonth' || col.key === 'expYear' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          editing: this.isEditing(record),
        }),
      };
    });

    const { payments, form } = this.props;
    const scroll = { x: 1600 };
    return (
      <EditableContext.Provider value={form}>
        <Table
          bordered
          scroll={scroll}
          columns={columns}
          dataSource={payments}
          components={components}
          rowKey={(row: any) => row.node.id}
          rowClassName="editable-row"
        />
      </EditableContext.Provider>
    );
  }
}
const EditableFormTable = Form.create()(PaymentsTable);
export default EditableFormTable;
