import * as React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import styled from '@emotion/styled';
import { MuiBadge } from '@onehope/design-system-ohw';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { config, satelliteEventConfig, oldConfig } from './config';
import { tabNavStyles } from './TabNavigation.styles';
import { CEDash_EventDetailsPage_viewer_event } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { LaunchDates } from '../../../utils/constants';
import {
  getHostKitsV2,
  isDateBeforeTastingFlightsLaunch,
} from '../../../utils/utils';
import { isSatelliteEvent } from '../../Events/EventsTable/helpers';
import { EventStatusDictionary } from '../../../utils/enums';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-start;
  padding: 0 16px 0 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  @media only screen and (min-width: 768px) {
    padding: 0 40px 0 40px;
  }
`;

type TabNavigationProps = {
  handleChange: (event: React.FormEvent<EventTarget>, value: string) => void;
  tabValue: number;
  event: CEDash_EventDetailsPage_viewer_event;
};

const TabLabel = styled.div`
  margin-left: -8px;
`;

function TabNavigation(props: TabNavigationProps) {
  const { handleChange, tabValue, event } = props;

  const isNotVirtual = event?.trinityPartyType !== 'EPARTY';
  const createDateBeforeLaunch = isDateBeforeTastingFlightsLaunch(
    event?.createDate,
  );
  const { hostKitsV2 } = useFlags();
  const showHostKitsV2 = getHostKitsV2(hostKitsV2, event);
  const satelliteEvent = isSatelliteEvent(event);

  const classes = tabNavStyles();
  const { root, tabRoot, tabSelected, tabsIndicator } = classes;
  const tabClasses = { root: tabRoot, selected: tabSelected };

  const showNotification = (tabLabel: string) => {
    if (event?.status === EventStatusDictionary.CLOSED) return false;
    switch (tabLabel) {
      case 'Charity': {
        return satelliteEvent ? false : !event?.donationInfo?.nonProfit;
      }
      case 'Host Kit': {
        // Host kit quantity can be zero, but if it's more than 0 order numbers must be provided
        return (
          !Number.isInteger(event?.hostKitQuantity) ||
          (event?.hostKitQuantity && !event?.hostKitOrderNumbers)
        );
      }
      case 'Wine': {
        return (
          !Number.isInteger(event?.hostKitQuantity) ||
          (event?.hostKitQuantity && !event?.wineTastingOrderIds)
        );
      }
      case 'Tasting Flight': {
        return event?.isPurchasingTastingFlights === null;
      }
      default:
        return false;
    }
  };

  const getConfig = () => {
    if (satelliteEvent) {
      return satelliteEventConfig;
    }
    if (showHostKitsV2) {
      // hide the Tasting Kit tab if not a virtual
      // event or if the order is older than Tasting Kit ff
      return config(isNotVirtual || createDateBeforeLaunch);
    }
    return oldConfig;
  };

  const configToUse = getConfig();
  return (
    <Container>
      <Tabs
        key="TabNavigation"
        value={tabValue}
        scrollButtons="off"
        variant="scrollable"
        onChange={handleChange}
        classes={{ indicator: tabsIndicator }}
      >
        {configToUse.map(tab => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={
              showNotification(tab.label) ? (
                <MuiBadge variant="dot">
                  <TabLabel>{tab.label}</TabLabel>
                </MuiBadge>
              ) : (
                tab.label
              )
            }
            classes={tabClasses}
            data-cypress={tab.cypressData}
          />
        ))}
      </Tabs>
    </Container>
  );
}

export default TabNavigation;
