import React from 'react';

const SvgWarning = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 16L8 0L0 16H16ZM8.72727 13.4737H7.27272V11.7895H8.72727V13.4737ZM7.27272 10.1053H8.72727V6.73687H7.27272V10.1053Z"
      fill="#FAAD14"
    />
  </svg>
);

export default SvgWarning;
