import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system-ohw';
import Button from '@material-ui/core/Button';

import MuiAppsIcon from '@material-ui/icons/Apps';
import MuiFormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import {
  CEDash_EventDetailsPage_viewer_event as EventType,
  CEDash_EventDetailsPage_viewer_user as UserType,
} from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';

import GET_TASTING_KIT_ORDERS from '../../../../queries/TastingKitOrdersByEventId';
import OrderLink from './OrderLink';
import TableListView from './TableListView';
import CardListView from './CardListView';
import OrderAddDialog from './OrderAddDialog';
import TablePagination from '../../../../components/Events/EventsTable/TablePagination';
import Loading from '../../../../common/Loading';

const { cssConstants } = s;

interface DetailsProps {
  event: EventType;
  user: UserType;
}

const Container = styled.div`
  position: relative;
  margin-top: 24px;
  padding-bottom: 40px;
`;

const PaginationContainer = styled.div`
  padding: 0 48px;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 48px 24px 48px;
  flex-direction: column;
  @media all and (min-width: 960px) {
    flex-direction: row;
    padding-bottom: 40px;
  }
`;

const AppsIcon = styled(MuiAppsIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const FormatListBulletedIcon = styled(MuiFormatListBulletedIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const ToggleView = styled.div`
  width: 288px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: center;
`;

const OrderDialogButton = styled(Button)`
  display: flex !important;
  width: 230px;
  margin: 0 auto !important;
  text-transform: none !important;
  text-decoration: underline !important;
`;

const TastingKit = ({ event }: DetailsProps) => {
  const [orders, setOrders] = useState([] as any);

  const { data, loading, error } = useQuery(GET_TASTING_KIT_ORDERS, {
    fetchPolicy: 'network-only',
    variables: {
      byEventId: event.eventId,
    },
    onCompleted: d => {
      const orderData = d?.viewer?.user?.orders?.edges;
      return setOrders(orderData);
    },
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);
  const [isTableView, setIsTableView] = useState(
    localStorage.getItem('isTableView')
      ? localStorage.getItem('isTableView') === 'true'
      : true,
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  if (error) return null;
  if (loading) return <Loading />;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const toggleView = () => {
    localStorage.setItem('isTableView', `${!isTableView}`);
    setIsTableView(!isTableView);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleTrackOrder = (shipping: {
    carrier?: string;
    tracking: string[];
  }) => {
    const { carrier, tracking } = shipping;
    const trackingUrl =
      carrier === 'FedEx'
        ? `https://www.fedex.com/apps/fedextrack/?tracknumbers`
        : `https://wwwapps.ups.com/tracking/tracking.cgi?tracknum`;
    if (tracking) {
      tracking.forEach(trackingNumber => {
        return window.open(`${trackingUrl}=${trackingNumber}`, '_blank');
      });
    }
    return null;
  };

  const handleToggleOrderDialog = () => {
    setIsOrderDialogOpen(!isOrderDialogOpen);
  };

  const ordersCount = (orders && orders.length) || 0;
  return (
    <Container>
      <OrderAddDialog
        isOpen={isOrderDialogOpen}
        selectedEventId={event?.eventId}
        handleClose={handleToggleOrderDialog}
      />
      {orders && orders.length ? (
        <>
          <Toolbar>
            <OrderLink condensed event={event} />
            <OrderDialogButton onClick={handleToggleOrderDialog}>
              Order for guest
            </OrderDialogButton>
            {!isMobile && (
              <ToggleView>
                <IconButton onClick={toggleView}>
                  {isTableView ? <AppsIcon /> : <FormatListBulletedIcon />}
                </IconButton>
              </ToggleView>
            )}
          </Toolbar>
          {!isMobile && isTableView ? (
            <TableListView
              page={page}
              orders={orders}
              rowsPerPage={rowsPerPage}
              handleTrackOrder={handleTrackOrder}
            />
          ) : (
            <CardListView
              page={page}
              orders={orders}
              rowsPerPage={rowsPerPage}
            />
          )}
          <PaginationContainer>
            <TablePagination
              page={page}
              count={ordersCount}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </PaginationContainer>
        </>
      ) : (
        <>
          <OrderLink event={event} />
          <OrderDialogButton onClick={handleToggleOrderDialog}>
            Order for guest
          </OrderDialogButton>
        </>
      )}
    </Container>
  );
};

export default TastingKit;
