/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import get from 'lodash/get';
import { styles as s } from '@onehope/design-system';
import Columns from '../../Columns';
import { CEDash_CustomersQuery_viewer_user_customers_edges as ContactsEdges } from '../../../../queries/generatedTypes/CEDash_CustomersQuery';
import { useCheckBoxToggleContext } from '../../CheckboxToggleContext';
import { useArchiveContactsContext } from '../../ArchiveContactsContext';

const { cssConstants } = s;

const TableRow = styled(MuiTableRow)`
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

const TableCell = styled(MuiTableCell)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 14px;
  }
`;

interface TableBodyContainerProps {
  contacts: (ContactsEdges | null)[] | null;
  page: number;
  rowsPerPage: number;
  handleOrderAdd: (contactId: string) => void;
}

const handleRowClick = (contact: ContactsEdges | null) => {
  return () => {
    return navigate(`/contact?contactId=${contact?.node?.accountId}&tab=0`);
    // const { GATSBY_HOPECOMMERCE_URL } = process.env;
    // return window.open(
    //     `${GATSBY_HOPECOMMERCE_URL}/contact/${contact?.node?.userId}`,
    //     '_blank',
    // );
  };
};

const getCustomerType = (contact: ContactsEdges | null) => {
  switch (true) {
    case contact?.node?.isHost &&
      contact?.node?.isSubscriber &&
      contact?.node?.isPreferredCustomer:
      return 'subscriberPreferredCustomerHost';
    case contact?.node?.isSubscriber && contact?.node?.isPreferredCustomer:
      return 'subscriberPreferredCustomer';
    case contact?.node?.isHost && contact?.node?.isSubscriber:
      return 'subscriberHost';
    case contact?.node?.isHost && contact?.node?.isPreferredCustomer:
      return 'preferredCustomerHost';
    case contact?.node?.isSubscriber:
      return 'subscriber';
    case contact?.node?.isHost:
      return 'host';
    case contact?.node?.isPreferredCustomer:
      return 'preferredCustomer';
    default:
      return '';
  }
};

export default function TableBodyContainer(props: TableBodyContainerProps) {
  const { contacts, rowsPerPage, page, handleOrderAdd } = props;
  const { isCheckBoxActivated } = useCheckBoxToggleContext();
  const { handleContact, selectedContacts } = useArchiveContactsContext();

  if (contacts) {
    return (
      <TableBody>
        {contacts
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((contact: ContactsEdges | null) => {
            const onRowClick = handleRowClick(contact);
            const customerType = getCustomerType(contact);
            return (
              <TableRow hover role="checkbox" key={contact?.node?.id}>
                {isCheckBoxActivated && (
                  <TableCell>
                    <Checkbox
                      checked={selectedContacts.includes(
                        contact?.node?.accountId,
                      )}
                      onClick={() =>
                        handleContact(
                          contact?.node?.accountId,
                          rowsPerPage,
                          contacts.length,
                        )
                      }
                    />
                  </TableCell>
                )}
                {Columns.map(column => {
                  let value: string;
                  if (column.id === 'fullName') {
                    value =
                      get(contact?.node, `organizationName`) ||
                      get(contact?.node, `fullName`);
                  } else {
                    value = get(contact?.node, `${column.id}`);
                  }
                  const onCellClick = !column.render ? onRowClick : () => {};

                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      onClick={onCellClick}
                    >
                      {column.format &&
                        column.format(value, contact?.node?.accountId)}
                      {column.customerType && column.customerType(customerType)}
                      {column.render &&
                        column.render({
                          contactId: contact?.node?.accountId,
                          email: contact?.node?.email,
                          handleOrderAdd,
                        })}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
      </TableBody>
    );
  }
  return null;
}
