import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  Dropzone,
  Grid,
  Icons,
  LoaderDark,
  makeStyles,
  ThemeType,
  useTheme,
} from '@onehope/design-system-v2';
import { getEventImage } from '../../../utils/utils';
import { useImageStyles } from '../commonStyles';
import IMAGE_DELETE_MUTATION from '../../../mutations/Image/ImageDeleteMutation';

const useStyles = makeStyles((theme: ThemeType) => ({
  header: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px',
    padding: '16px 0',
  },
  icon: {
    cursor: 'pointer',
    margin: '4px 8px',
  },
  dropzone: {
    height: 72,
    border: `1px dashed ${theme.palette.greyDark.main}`,
    marginRight: '16px',
  },
  loader: {
    padding: '24px',
    width: 40,
    height: 36,
    ['@media (min-width: 768px)']: {
      width: 80,
      height: 72,
    },
  },
  edit: {
    position: 'absolute',
    margin: '-10px 0px 0 164px',
    cursor: 'pointer',
  },
}));

export default function UpdateImage(props: any) {
  const { onFileDrop, event, loading } = props;

  const eventImage = event?.images?.[0];
  const imageUrl = getEventImage(eventImage);

  const classes = useStyles();
  const imageClasses = useImageStyles(imageUrl)();

  const theme = useTheme();

  const [imageDeleteMutation, { loading: imageDeleteLoading }] = useMutation(
    IMAGE_DELETE_MUTATION,
  );

  const deleteImage = () => {
    return imageDeleteMutation({
      variables: {
        input: {
          imageId: eventImage.imageId,
        },
      },
    })
      .then((data: any) => {
        console.log({ data });
      })
      .catch((error: any) => {
        console.log({ error });
      });
  };

  return (
    <div>
      <div className={classes.header}>Event photo:</div>
      <Grid container>
        <Dropzone
          className={classes.dropzone}
          accept="image/jpg, image/jpeg, image/png"
          onDrop={onFileDrop}
          maxFiles={1}
          multiple={false}
          maxSize={16000000} // in bytes (16 MB)
        >
          <Icons.PhotoUpload
            className={classes.icon}
            height={64}
            width={64}
            fill={theme.palette.greyDark.main}
          />
        </Dropzone>
        {loading || imageDeleteLoading ? (
          <div className={classes.loader}>
            <LoaderDark />
          </div>
        ) : (
          imageUrl && (
            <>
              <div className={classes.edit}>
                <Icons.MuiCancel onClick={deleteImage} />
              </div>
              <div className={imageClasses.image} />
            </>
          )
        )}
      </Grid>
    </div>
  );
}
