import * as React from 'react';
import { LaunchDates } from '../../../utils/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Details from './Details';
import TastingKit from './TastingKit';
import Wine from './Wine';
import GuestOrders from './GuestOrders';
import HostRewards from './HostRewards';
import Charity from './Charity';
import {
  CEDash_EventDetailsPage_viewer_event,
  CEDash_EventDetailsPage_viewer_user,
} from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';
import { isDateBeforeTastingFlightsLaunch } from '../../../utils/utils';

interface TabContentProps {
  tabValue: number;
  event: CEDash_EventDetailsPage_viewer_event;
  user: CEDash_EventDetailsPage_viewer_user;
}

const getPage = (tab: number) => {
  switch (tab) {
    case 1:
      return 'EVENT HOST KIT';
    case 2:
      return 'EVENT CHARITY';
    case 3:
      return 'EVENT HOST REWARDS';
    case 4:
      return 'EVENT GUEST ORDERS';
    default:
      return 'EVENT DETAILS';
  }
};

export default function TabContent(props: TabContentProps) {
  const { tabValue, event, user } = props;

  const { trackPageView } = useTrackingContextValue();
  const isNotVirtual = event?.trinityPartyType !== 'EPARTY';
  const createDateBeforeLaunch = isDateBeforeTastingFlightsLaunch(
    event?.createDate,
  );
  // In this case, if the CE skipped or selected no, show
  // the wine form so they can change their selection
  const notPurchasingTastingFlights = !event?.isPurchasingTastingFlights;

  const page = getPage(tabValue);
  React.useEffect(() => {
    trackPageView({ page });
  }, [page, trackPageView]);

  switch (tabValue) {
    case 1:
      return isNotVirtual ||
        createDateBeforeLaunch ||
        notPurchasingTastingFlights ? (
        <Wine event={event} user={user} />
      ) : (
        <TastingKit event={event} user={user} />
      );
    case 2:
      return <Charity event={event} />;
    case 3:
      return <HostRewards event={event} user={user} />;
    case 4:
      return <GuestOrders event={event} />;
    default:
      return <Details event={event} />;
  }
}
