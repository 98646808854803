import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import EventsTable from '../../../Events/EventsTable';
import EVENT_CANCEL_MUTATION from '../../../../mutations/Event/EventCancelMutation';
import { handleFilterByTab } from '../../../Events/EventsTable/helpers';
import { CEDash_EventsQuery_viewer_user_events as EventsType } from '../../../../queries/generatedTypes/CEDash_EventsQuery';
import { CEDash_CustomerDetailsPage_viewer_customer as ContactType } from '../../../../queries/generatedTypes/CEDash_CustomerDetailsPage';

interface EventsPageWrapperProps {
  events: EventsType;
  contact: ContactType;
}

function EventsPageWrapper(props: EventsPageWrapperProps) {
  const [eventCancelMutation, { loading: eventCanceling }] = useMutation(
    EVENT_CANCEL_MUTATION,
  );
  const eventEdges = props.events.edges;
  const openEvents = handleFilterByTab(eventEdges, 'open');
  const [events, setEvents] = useState(openEvents);
  const [allEvents, setAllEvents] = useState(eventEdges);

  // useEffect(
  //   () => {
  //     setEvents(openEvents);
  //     setAllEvents(eventEdges);
  //   },
  //   [props.events],
  // );

  return (
    <EventsTable
      contact={props.contact}
      showHeader={false}
      events={events}
      setEvents={setEvents}
      allEvents={allEvents}
      eventCanceling={eventCanceling}
      eventCancelMutation={eventCancelMutation}
    />
  );
}

export default EventsPageWrapper;
