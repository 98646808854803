import React, { forwardRef, useRef, RefObject, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useTheme, useMediaQuery, Icons } from '@onehope/design-system-v2';
import { CardContentProps } from './CardContent';
import useStyles from './helpers/styles';
import { isMobile, isIOS } from 'react-device-detect';

interface Props extends CardContentProps {
  onVideoClickPreview: () => void;
  playing: boolean;
  videoPause: () => void;
}

export default forwardRef<ReactPlayer, Props>((props, playerRef) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const {
    activity,
    openDialog,
    onVideoClickPreview,
    playing,
    videoPause,
  } = props;

  const buttonRef = useRef() as RefObject<HTMLButtonElement>;
  useEffect(() => {
    if (isMobile && isIOS) {
      setTimeout(() => buttonRef.current?.click(), 100);
    }
  }, []);

  // if there's a video and an image on this card, use the image as the video's thumbnail.
  // if there's no image, let Mux try making a thumbnail with "smart crop"
  const thumbnail = activity.image
    ? activity.image.src
    : `https://image.mux.com/${activity.muxVideo?.playbackId}/thumbnail.jpg?fit_mode=smartcrop&width=600&height=200`;

  if (!activity.muxVideo) {
    return null;
  }

  if (isDesktop && openDialog) {
    return (
      <img
        src={thumbnail}
        className={classes.player}
        style={{ objectFit: 'cover' }}
      />
    );
  }

  return (
    <ReactPlayer
      url={`https://stream.mux.com/${activity.muxVideo.playbackId}.m3u8`}
      light={thumbnail}
      controls={!isDesktop || !openDialog}
      onClickPreview={onVideoClickPreview}
      className={classes.player}
      ref={playerRef}
      playing={playing}
      onPause={videoPause}
      playIcon={
        <button className={classes.playIcon} ref={buttonRef}>
          <Icons.CardPlayIcon />
        </button>
      }
    />
  );
});
