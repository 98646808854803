import { DateTime } from 'luxon';
import parser from 'parse-address';
import { FormikErrors } from 'formik';
import Resizer from 'react-image-file-resizer';
import { v4 } from 'uuid';

import { MyFormValues as DetailsFormTypes } from '../../Event/TabContent/Details/FormTypes';
import { MyFormValues as CharityFormTypes } from '../../Event/TabContent/Charity/FormTypes';

interface HostName {
  firstName: string;
  lastName: string;
}

const isAddressField = (field: string) => {
  if (!field) return false;
  switch (field) {
    case 'addressLineOne': {
      return true;
    }
    case 'city': {
      return true;
    }
    case 'state': {
      return true;
    }
    case 'zip': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const focusErrors = ({
  errors,
  isSubmitting,
  manualAddress,
}: {
  errors: FormikErrors<DetailsFormTypes> | FormikErrors<CharityFormTypes>;
  isSubmitting: boolean;
  manualAddress?: boolean;
}) => {
  if (!isSubmitting) return;
  const firstErrorKey = Object.keys(errors);
  if (firstErrorKey.length) {
    let field = firstErrorKey[0];
    if (!manualAddress && isAddressField(field)) {
      field = 'displayAddress';
    }
    const firstErrorInput = document.getElementById(field);
    if (firstErrorInput) firstErrorInput.focus();
  }
};

function formatDate(
  eventDate: string | undefined,
  eventTime: string | undefined,
  eventTimeLocale: string | undefined,
  timeZone: string | undefined,
) {
  if (eventDate && eventTime && eventTimeLocale && timeZone) {
    const full = DateTime.fromFormat(
      `${eventDate} ${eventTime}${eventTimeLocale}`,
      'L/d/yyyy hh:mma',
      { zone: timeZone },
    );
    return full.toUTC();
  }
  return '';
}

function getHostInfo(values: DetailsFormTypes, ceAccountId?: string) {
  const { hostType } = values;
  if (hostType !== 'SELFHOST') {
    return {
      hostAccountId: values.hostAccountId || null,
      hostFullName: values.hostFullName || '',
      hostFirstName: values.hostFirstName || '',
      hostLastName: values.hostLastName || '',
      hostEmail: values.hostEmail || null,
      hostPhone: values.hostPhone || '',
      hostNonProfitName:
        hostType === 'NONPROFIT' ? values.hostNonProfitName : '',
    };
  }
  return {
    hostAccountId: ceAccountId || null,
    hostFullName: null,
    hostFirstName: null,
    hostLastName: null,
    hostEmail: null,
    hostPhone: null,
    hostNonProfitName: null,
  };
}

export function getDisplayPreferences({
  event,
  values,
}: {
  event: any;
  values: DetailsFormTypes & CharityFormTypes;
}) {
  // Default values to match DB at launch
  const preferences = {
    donationGoal: event?.displayPreferences
      ? event.displayPreferences.donationGoal
      : false,
    supporters: event?.displayPreferences
      ? event.displayPreferences.supporters
      : true,
  } as {
    supporters?: boolean;
    donationGoal?: boolean;
  };

  if (values?.showSupporters) {
    preferences.supporters = values?.showSupporters === 'true' ? true : false;
  }

  if (values?.showDonationGoal) {
    preferences.donationGoal =
      values?.showDonationGoal === 'true' ? true : false;
  }

  return preferences;
}

export function formatAddress(
  address: string | undefined,
  addressLineTwo: string | undefined,
) {
  if (!address)
    return {
      addressLineOne: null,
      addressLineTwo: null,
      city: null,
      state: null,
      zip: null,
    };
  const { city, number, state, street, zip, type } = parser.parseLocation(
    address,
  );
  const addressLineOne = `${number || ''} ${street || ''} ${type || ''}`.trim();
  return {
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zip,
  };
}

export function getEventDetailsToSubmit({
  values,
  ceAccountId,
}: {
  values: DetailsFormTypes;
  ceAccountId?: string;
}): any {
  const {
    trinityPartyType,
    eventDate,
    eventTime,
    eventTimeLocale,
    timeZone,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zip,
    title,
  } = values;
  const hostInfo = getHostInfo(values, ceAccountId);
  const eventDateFormatted = formatDate(
    eventDate,
    eventTime,
    eventTimeLocale,
    timeZone,
  );
  const eventAddress =
    trinityPartyType === 'EVENT'
      ? { addressLineOne, addressLineTwo, city, state, zip }
      : null;
  return {
    trinityPartyType,
    timeZone,
    eventAddress,
    eventDate: eventDateFormatted,
    ...hostInfo,
    title,
  };
}

export const saveDonation = async ({
  values,
  eventId,
  preApprovedDonationAdd,
  customDonationAdd,
}: any) => {
  const {
    preApprovedNonProfit,
    customNonProfit,
    nonProfitId,
    charityType,
    addressLineOne,
    addressLineTwo,
    state,
    city,
    zip,
    nonProfitTaxId,
    attentionToName,
    lobMemo,
    charityDonationGoal = 0,
  } = values;
  const donationGoal =
    typeof charityDonationGoal == 'string'
      ? Number(charityDonationGoal.replace(/,/g, ''))
      : charityDonationGoal;
  if (charityType === 'OHW-PARTNER') {
    const input = {
      eventId,
      npoId: preApprovedNonProfit,
      donationGoal,
      lobMemo,
    };
    return await preApprovedDonationAdd({
      variables: { input },
    })
      .then(data => {
        const updatedEvent = data?.preApprovedDonationAdd?.event;
        return updatedEvent;
      })
      .catch((errors: any) => {
        console.log('errors', errors);
        return;
      });
  } else if (charityType === 'CUSTOM') {
    let input = {
      eventId,
      nonProfitUuid: null,
      lobMemo,
      form: {
        nonProfitTaxId,
        nonProfitName: customNonProfit,
        addressLineOne,
        addressLineTwo,
        city,
        state,
        zip,
        nonProfitPayeeName: attentionToName,
        donationGoal,
      },
    };
    // ALEX: do we want to check if the customNonProfit has an npoId? if it does,
    // do we want to send that in so we can update the nonProfit instead of creating a new nonProfit
    // every we time we update? The backend currently supports this
    if (nonProfitId !== '') {
      input.nonProfitUuid = nonProfitId;
    }

    return customDonationAdd({
      variables: { input },
    })
      .then(data => {
        const updatedEvent = data?.donationAdd?.event;
        return updatedEvent;
      })
      .catch((errors: any) => {
        console.log('errors', errors);
        return;
      });
  } else if (charityType === 'SKIP') {
    const input = {
      eventId,
      npoId: null,
      donationGoal,
      lobMemo,
    };
    return await preApprovedDonationAdd({
      variables: { input },
    })
      .then(data => {
        const updatedEvent = data?.preApprovedDonationAdd?.event;
        return updatedEvent;
      })
      .catch((errors: any) => {
        console.log('errors', errors);
        return;
      });
  }
  return null;
};

const getImageFileNames = (
  groupName: string,
  imageId: string,
  mimeType: string,
) => {
  const config = {} as { [key: string]: string };

  ['original', 'medium'].forEach((size: string) => {
    const key = `${groupName}/${imageId}_${size}.${mimeType}`;
    config[size] = key;
  });

  return config;
};

const resizeFile = (file: Blob, width: number, height: number) =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      'JPEG',
      100,
      0,
      uri => {
        resolve(uri);
      },
      'blob',
    );
  });

const BUCKET_NAME = 'ohw-image-uploads';

export const uploadImage = async ({
  values,
  authorId,
  getPresignedUrl,
  imageAddMutation,
  groupName,
  imageType,
  setLoading,
}: any) => {
  const { imageCanvas, mimeType } = values;

  if (setLoading) setLoading(true);

  const imageId = v4();
  // to keep with the current naming convention
  const key = `${groupName}/${imageId}.${mimeType}`;

  const imageFileNames = getImageFileNames(groupName, imageId, mimeType);
  const keys = Object.values(imageFileNames);

  return await getPresignedUrl({
    variables: { input: { keys, bucketName: BUCKET_NAME } },
  }).then((response: any) => {
    const urls = response?.data?.imagePresignedUrlGet?.urls;

    if (window && imageCanvas) {
      fetch(imageCanvas)
        .then(res => res.blob())
        .then(async (blob: Blob) => {
          const original = blob;
          const medium = await resizeFile(blob, 718, 480);

          Promise.all(
            // upload image(s) to S3
            urls.map((url: string) =>
              fetch(url, {
                method: 'PUT',
                body: url?.includes('medium') ? medium : original,
              }),
            ),
          )
            .then(() => {
              return imageAddMutation({
                variables: {
                  input: {
                    imageId,
                    authorId,
                    path: key,
                    groupName,
                    bucketName: BUCKET_NAME,
                    imageType,
                    mimeType,
                    thumbnails: {
                      medium: `${imageId}_medium.${mimeType}`,
                    },
                  },
                },
              }).then((data: any) => {
                if (setLoading) setLoading(false);
                const updatedEvent = data?.imageAdd?.event;
                return updatedEvent;
              });
            })
            .catch((errors: any) => {
              console.log('errors', errors);
              return;
            });
        });
    }
  });
};
