import _orderBy from 'lodash/orderBy';
import * as React from 'react';

import { CEDash_CustomersQuery_viewer_user_customers_edges as ContactsType } from '../../queries/generatedTypes/CEDash_CustomersQuery';

export function handleSortByFullName(
  contacts: (ContactsType | null)[] | null,
  nameSortDirection: boolean,
) {
  if (!nameSortDirection) {
    return _orderBy(
      contacts,
      [e => e?.node?.organizationName || e?.node?.fullName],
      ['desc'],
    );
  } else {
    return _orderBy(contacts, [
      e => e?.node?.organizationName || e?.node?.fullName,
    ]);
  }
}

export function handleSortByCustomerType(
  contacts: (ContactsType | null)[] | null,
  nameSortDirection: boolean,
) {
  if (!nameSortDirection) {
    return _orderBy(
      contacts,
      [
        e =>
          e?.node?.isPreferredCustomer ||
          e?.node?.isSubscriber ||
          e?.node?.isHost,
      ],
      ['desc'],
    );
  } else {
    return _orderBy(contacts, [
      e =>
        e?.node?.isPreferredCustomer ||
        e?.node?.isSubscriber ||
        e?.node?.isHost,
    ]);
  }
}

export function handleSortByEmail(
  contacts: (ContactsType | null)[] | null,
  statusSortDirection: boolean,
) {
  if (!statusSortDirection) {
    return _orderBy(contacts, [e => e?.node?.email], ['desc']);
  } else {
    return _orderBy(contacts, [e => e?.node?.email]);
  }
}

export function handleSortByDateOfBirth(
  contacts: (ContactsType | null)[] | null,
  dateSortDirection: boolean,
) {
  if (dateSortDirection) {
    return _orderBy(
      contacts,
      [e => !!e?.node?.dateOfBirth, e => e?.node?.dateOfBirth],
      ['desc', 'asc'],
    );
  } else {
    return _orderBy(
      contacts,
      [e => !!!e?.node?.dateOfBirth, e => e?.node?.dateOfBirth],
      ['asc', 'desc'],
    );
  }
}

export function handleSortByLastOrderDate(
  contacts: (ContactsType | null)[] | null,
  dateSortDirection: boolean,
) {
  if (dateSortDirection) {
    return _orderBy(
      contacts,
      [e => !!e?.node?.lastOrderDate, e => e?.node?.lastOrderDate],
      ['desc', 'asc'],
    );
  } else {
    return _orderBy(
      contacts,
      [e => !!!e?.node?.lastOrderDate, e => e?.node?.lastOrderDate],
      ['asc', 'desc'],
    );
  }
}

export function handleSortByPhoneNumber(
  contacts: (ContactsType | null)[] | null,
  subTotalSortDirection: boolean,
) {
  if (subTotalSortDirection) {
    return _orderBy(
      contacts,
      [
        e => !!e?.node?.mobilePhone,
        e => {
          return e?.node?.mobilePhone ? Number(e?.node?.mobilePhone) : '';
        },
      ],
      ['desc', 'asc'],
    );
  } else {
    return _orderBy(
      contacts,
      [
        e => !!!e?.node?.mobilePhone,
        e => {
          return e?.node?.mobilePhone ? Number(e?.node?.mobilePhone) : '';
        },
      ],
      ['asc', 'desc'],
    );
  }
}

export function handleSortByLifeTimeSpend(
  contacts: (ContactsType | null)[] | null,
  sortDirection: boolean,
) {
  if (sortDirection) {
    return _orderBy(
      contacts,
      [
        e => !!e?.node?.lifeTimeSpend,
        e => {
          return e?.node?.lifeTimeSpend ? Number(e?.node?.lifeTimeSpend) : '';
        },
      ],
      ['desc', 'asc'],
    );
  } else {
    return _orderBy(
      contacts,
      [
        e => !!!e?.node?.lifeTimeSpend,
        e => {
          return e?.node?.lifeTimeSpend ? Number(e?.node?.lifeTimeSpend) : '';
        },
      ],
      ['asc', 'desc'],
    );
  }
}

export function handleSortByImpactTotal(
  contacts: (ContactsType | null)[] | null,
  sortDirection: boolean,
) {
  if (sortDirection) {
    return _orderBy(
      contacts,
      [
        e => !!e?.node?.impactTotal,
        e => {
          return e?.node?.impactTotal ? Number(e?.node?.impactTotal) : '';
        },
      ],
      ['desc', 'asc'],
    );
  } else {
    return _orderBy(
      contacts,
      [
        e => !!!e?.node?.impactTotal,
        e => {
          return e?.node?.impactTotal ? Number(e?.node?.impactTotal) : '';
        },
      ],
      ['asc', 'desc'],
    );
  }
}

export function handleSearchAllContacts(
  contacts: (ContactsType | null)[] | null,
  filterBy: string,
  search: string,
) {
  if (search && search !== '' && contacts) {
    const filteredContacts = getFilteredContacts(search, contacts);
    return handleFilterByTab(filteredContacts, filterBy);
  }
  return handleFilterByTab(contacts, filterBy);
}

function getFilteredContacts(
  search: string,
  contacts: (ContactsType | null)[] | null,
) {
  const searchText = search.toLowerCase().replace(/\s/g, '');
  return (
    contacts &&
    contacts.filter(contact => {
      const contactName =
        contact && contact.node && contact.node.fullName
          ? contact.node.fullName.toLowerCase().replace(/\s/g, '')
          : '';
      const orgName =
        contact && contact.node && contact.node.organizationName
          ? contact.node.organizationName.toLowerCase().replace(/\s/g, '')
          : '';
      const email =
        contact && contact.node && contact.node.email
          ? contact.node.email.toString()
          : '';
      return (
        contactName.includes(searchText) ||
        orgName.includes(searchText) ||
        email.includes(searchText)
      );
    })
  );
}

export function handleFilterByTab(
  contacts: (ContactsType | null)[] | null,
  filterBy: string,
  search?: string,
) {
  if (contacts) {
    let filteredContacts = contacts;
    switch (filterBy) {
      case 'preferredCustomers':
        filteredContacts = contacts.filter(e => e?.node?.isPreferredCustomer);
        break;
      default:
        filteredContacts = contacts;
        break;
    }
    return search
      ? handleTabFilterSearch(filteredContacts, search)
      : filteredContacts;
  }
  return contacts;
}

export function handleTabFilterSearch(
  contacts: (ContactsType | null)[] | null,
  search: string,
) {
  if (search && search !== '' && contacts) {
    return getFilteredContacts(search, contacts);
  }
  return contacts;
}
