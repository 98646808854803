import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiMoreVertIcon from '@material-ui/icons/MoreVert';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
const { cssConstants } = s;

const options = ['View Order', 'Track Order', 'View Contact'];

const Container = styled.div<{ isCardView: boolean }>`
  position: ${({ isCardView }) => (isCardView ? 'absolute' : 'relative')};
  right: 0;
  top: ${({ isCardView }) => (isCardView ? '8px' : '0px')};
  z-index: 100;
`;

const MenuItem = styled(MuiMenuItem)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
  }
`;

const MoreVertIcon = styled(MuiMoreVertIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

interface IconMenuProps {
  orderId: string | null | undefined;
  accountId: string | null | undefined;
  isCardView: boolean;
  tracking: (string | null)[] | null | undefined;
  carrier?: string | null | undefined;
}

export default function IconMenu(props: IconMenuProps) {
  const { isCardView, tracking, carrier } = props;
  const { GATSBY_HOPECOMMERCE_URL } = process.env;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewOrder = () => {
    return window.open(
      `${GATSBY_HOPECOMMERCE_URL}/order/${props.orderId}`,
      '_blank',
    );
  };

  const handleTrackOrder = () => {
    const trackingUrl =
      carrier === 'FedEx'
        ? `https://www.fedex.com/apps/fedextrack/?tracknumbers`
        : `https://wwwapps.ups.com/tracking/tracking.cgi?tracknum`;
    if (tracking) {
      tracking.forEach(trackingNumber => {
        return window.open(`${trackingUrl}=${trackingNumber}`, '_blank');
      });
    }
    return null;
  };

  const handleViewContact = () => {
    return window.open(`/contact?contactId=${props?.accountId}`);
  };

  return (
    <Container isCardView={isCardView}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ horizontal: -75, vertical: 'top' }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem key="Manage Event" onClick={handleViewOrder}>
          View Order
        </MenuItem>
        <MenuItem key="TrackOrder" onClick={handleTrackOrder}>
          Track Order
        </MenuItem>
        <MenuItem key="ViewContact" onClick={handleViewContact}>
          View Contact
        </MenuItem>
      </Menu>
    </Container>
  );
}
