import gql from 'graphql-tag';

export const HomePage_viewer = gql`
  fragment HomePage_viewer on Viewer {
    id
    isLoggedIn
    user {
      id
      role
      userId
      firstName
      lastName
      permissions {
        id
        oneCommerce {
          ceo {
            access
          }
        }
      }
    }
  }
`;

export const HOMEPAGE_GET_USER_QUERY = gql`
  query CEDash_HomePageUserQuery {
    viewer {
      ...HomePage_viewer
    }
  }
  ${HomePage_viewer}
`;
