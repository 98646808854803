import React, { useState } from 'react';
import styled from '@emotion/styled';
import { MuiButton, MuiInput } from '@onehope/design-system-ohw';
import { TabContentContainer } from '../index.styles';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';

// order link styled blocks
const OrderLinkHeader = styled.div``;

const OrderLinkHeaderDetail = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const OrderLinkHeaderText = styled.div`
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 1rem;
`;

const OrderLinkInput = styled(MuiInput)`
  margin-bottom: 1rem !important;
`;

const CondensedOrderLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  @media all and (min-width: 960px) {
    flex-direction: row;
    max-width: 600px;
  }
`;

const CondensedOrderLinkHeaderText = styled.div`
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 1rem;
`;

const CondensedOrderLinkInput = styled(MuiInput)`
  width: 100%;
  @media all and (min-width: 960px) {
    max-width: 250px;
  }
`;

const OrderLinkCopyButton = styled(MuiButton)``;

const CondensedLinkCopyButton = styled(MuiButton)`
  margin-bottom: 1rem;
  margin-left: 1rem;
  @media all and (min-width: 960px) {
    max-width: 200px;
    margin-left: 24px !important;
  }
`;

interface OrderLinkProps {
  event: EventType;
  condensed?: boolean;
}

const OrderLink = ({ event, condensed }: OrderLinkProps) => {
  const [copied, setCopied] = useState(false);
  const tastingKitOrderLink = `${process.env.GATSBY_TASTING_FLIGHT_HOST}?event=${event?.eventId}`;

  const copyToClipboard = () => {
    // using this method because of struggles
    // with refs on the mui input
    const el = document.createElement('textarea');
    el.value = tastingKitOrderLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  };

  return condensed ? (
    <>
      <CondensedOrderLinkWrapper>
        <CondensedOrderLinkInput
          disabled
          fullWidth
          id="tastingflightorderlink"
          name="tastingflightorderlink"
          value={tastingKitOrderLink}
        />
        <CondensedLinkCopyButton
          type="button"
          disableRipple
          fullWidth
          onClick={copyToClipboard}
        >
          {copied ? 'Copied to Clipboard!' : 'Copy Order Link'}
        </CondensedLinkCopyButton>
      </CondensedOrderLinkWrapper>
      <CondensedOrderLinkHeaderText>
        Share this purchasing link with your event guests in order for them to
        order a tasting flight for their upcoming virtual event.
      </CondensedOrderLinkHeaderText>
    </>
  ) : (
    <TabContentContainer>
      <>
        <OrderLinkHeader>
          <OrderLinkHeaderDetail>
            Tasting Flight Purchase Link:
          </OrderLinkHeaderDetail>
        </OrderLinkHeader>
        <OrderLinkHeaderText>
          Share this purchasing link with your event guests in order for them to
          order a tasting flight for their upcoming virtual event.
        </OrderLinkHeaderText>
      </>
      <OrderLinkInput
        disabled
        fullWidth
        id="tastingflightorderlink"
        name="tastingflightorderlink"
        value={tastingKitOrderLink}
      />
      <OrderLinkCopyButton
        type="button"
        disableRipple
        fullWidth
        onClick={copyToClipboard}
      >
        {copied ? 'Copied to Clipboard!' : 'Copy Order Link'}
      </OrderLinkCopyButton>
    </TabContentContainer>
  );
};

export default OrderLink;
