import * as React from 'react';
import { navigate, Link } from 'gatsby';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { styles as s } from '@onehope/design-system';
import { MuiBadge } from '@onehope/design-system-ohw';
import IconMenu from '../IconMenu';
import {
  CEDash_EventsQuery_viewer_user_events_edges as EventEdge,
  CEDash_EventsQuery_viewer_user_events_edges_node as EventType,
} from '../../../../queries/generatedTypes/CEDash_EventsQuery';
import { formatDonationStatus } from '../Columns';
import { EventStatusDictionary } from '../../../../utils/enums';
import { isSatelliteEvent } from '../helpers';

const { cssConstants } = s;

const GridItem = styled.div<{ eventCount: number }>`
  position: relative;
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 16px;
  max-width: 100%;
  @media all and (min-width: 768px) {
    padding: 24px;
    max-width: ${({ eventCount }) => (eventCount < 3 ? '356px' : '100%')};
  }
  :hover {
    cursor: pointer;
    background-color: ${cssConstants.neutral2};
  }
`;

const HostName = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  padding-bottom: 8px;
`;

const GridBlock = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  padding-bottom: 8px;
`;

const GridText = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
`;

const GridContainer = styled.div``;

const eventIsAcceptingOrders = (e: EventType | null | undefined) =>
  e?.trinityPartyStatus === EventStatusDictionary.OPEN;

const eventClosed = (e: EventType | null | undefined) =>
  e?.status !== EventStatusDictionary.OPEN;

interface EventCardProps {
  event: EventType | null | undefined;
  eventCount: number;
  contactId?: string;
  setSelectedEventId: (eventId: string) => void;
  handleOrderAdd: (eventId: string) => void;
  toggleEventCopyUrlDialog: (eventId: string | null | undefined) => void;
}

const handleRowClick = (
  event: EventType | null | undefined,
  contactId?: string,
) => {
  return () => {
    let link = !event?.donationInfo?.nonProfit
      ? `/event?eventId=${event?.eventId}&tab=2`
      : `/event?eventId=${event?.eventId}&tab=0`;
    if (contactId) {
      link = `${link}&contactId=${contactId}`;
    }
    return navigate(link);
  };
};

export default function EventCard(props: EventCardProps) {
  const {
    event,
    eventCount,
    contactId,
    setSelectedEventId,
    handleOrderAdd,
    toggleEventCopyUrlDialog,
  } = props;

  if (event) {
    const onRowClick = handleRowClick(event, contactId);
    const isAcceptingOrders = eventIsAcceptingOrders(event);
    const eventIsClosed = eventClosed(event);
    const timeZone = event?.timeZone ? event.timeZone : 'America/Chicago';
    const satelliteEvent = isSatelliteEvent(event);
    return (
      <GridItem eventCount={eventCount}>
        <IconMenu
          isCardView
          eventId={event.eventId}
          trinityPartyId={event.trinityPartyId}
          handleOrderAdd={handleOrderAdd}
          setSelectedEventId={setSelectedEventId}
          isAcceptingOrders={isAcceptingOrders}
          toggleEventCopyUrlDialog={toggleEventCopyUrlDialog}
        />
        <GridContainer onClick={onRowClick}>
          <HostName>
            {event.title || event.hostNonProfitName || event.hostFullName}
          </HostName>

          {/*{order.isPreferredCustomerOrder &&*/}
          {/*  <PreferredCustomer>Preferred Customer</PreferredCustomer>}*/}
          <GridBlock>
            Event Date:{' '}
            {event.eventDate && (
              <GridText>
                {DateTime.fromISO(event.eventDate, {
                  zone: timeZone,
                }).toLocaleString(DateTime.DATE_SHORT)}
              </GridText>
            )}
          </GridBlock>
          <GridBlock>
            Close Date:{' '}
            {event.closeDate && (
              <GridText>
                {DateTime.fromISO(event.closeDate, {
                  zone: timeZone,
                }).toLocaleString(DateTime.DATE_SHORT)}
              </GridText>
            )}
          </GridBlock>
          <GridBlock>
            Event orders subtotal:{' '}
            <GridText>${event?.donationInfo?.ordersSubTotal || 0}</GridText>
          </GridBlock>
          <GridBlock>
            Donation amount:{' '}
            <GridText>${event?.donationInfo?.donationAmount || 0}</GridText>
          </GridBlock>
          <GridBlock>
            Donation status:{' '}
            <GridText>
              {formatDonationStatus({
                value: event?.donationInfo?.status,
                eventId: event.eventId,
                eventClosed: eventIsClosed,
                donationInfo: event.donationInfo,
                satelliteEvent,
              })}
            </GridText>
          </GridBlock>
        </GridContainer>
      </GridItem>
    );
  }
  return null;
}
