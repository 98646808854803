import * as React from 'react';
import { PageRendererProps } from 'gatsby';
import styled from '@emotion/styled';

import Layout from '../components/MainLayout/layout/layout';
import Credits from '../components/Credits';

import { getCurrentUserId } from '../utils/utils';

const Container = styled.div`
  background-color: white;
  height: 100vh;
`;

function CreditsPage(props: PageRendererProps) {
  const { location } = props;
  const currentUser = getCurrentUserId(location);

  return (
    <Layout selectedUserId={currentUser}>
      <Container>
        <Credits />
      </Container>
    </Layout>
  );
}

export default CreditsPage;
