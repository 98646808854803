import React from 'react';
import styled from '@emotion/styled';
import { PageRendererProps } from 'gatsby';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';

import Layout from '../components/MainLayout/layout/layout';
import { getCurrentUserId } from '../utils/utils';

import OrdersV2 from '../components/OrdersV2';
import Orders from '../components/Orders';

const Container = styled.div`
  background-color: white;
  height: 100vh;
`;

function CustomerOrdersPage(props: PageRendererProps) {
  const { location } = props;
  const currentUser = getCurrentUserId(location);

  const { ordersPaginationV2 } = useFlags();

  return (
    <Layout selectedUserId={currentUser}>
      <Container>{ordersPaginationV2 ? <OrdersV2 /> : <Orders />}</Container>
    </Layout>
  );
}

export default withLDProvider({
  clientSideID: process.env.GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: {
    anonymous: true,
  },
})(CustomerOrdersPage);
