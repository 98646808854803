import * as React from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import NoOrdersSvg from '../../assets/noOrders';
import { MuiButton } from '@onehope/design-system-ohw';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

const { cssConstants } = s;
const NoOrdersContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 16px;
  @media all and (min-width: 768px) {
    padding: 40px 16px;
  }
`;

const NoOrdersText = styled.div`
  color: ${cssConstants.textColor};
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  padding: 24px 0;
`;

const NewOrderButton = styled.div`
  max-width: 432px;
  width: 100%;
  height: 100px;
  color: ${cssConstants.primaryColor};
  cursor: pointer;
`;

const NewOrderText = styled.div`
  /* font-family: ProximaNova; */
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 8px;
`;

const OrderTextContainer = styled.div`
  max-width: 432px;
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SvgContainer = styled.div``;

export default function NoOrders({
  openDialog,
  openForOrders,
  contactName,
  noOrdersText,
  handleOrderForCustomer,
}: {
  openDialog?: React.Dispatch<React.SetStateAction<boolean>>;
  openForOrders: boolean;
  contactName: string | null | undefined;
  handleOrderForCustomer?: () => void;
  noOrdersText?: string;
}) {
  const openOrderDialog = () => {
    if (openDialog) {
      openDialog(true);
    }
    if (handleOrderForCustomer) {
      handleOrderForCustomer();
    }
  };
  const text = noOrdersText ? noOrdersText : contactName
    ? `There are no orders yet for ${contactName}.`
    : 'There are no orders yet for this event.';

  return (
    <NoOrdersContainer>
      <SvgContainer>
        <NoOrdersSvg />
      </SvgContainer>
      <NoOrdersText>{text}</NoOrdersText>
      {openForOrders && (
        <NewOrderButton>
          <MuiButton
            type="DASHED"
            fullWidth
            disableRipple
            onClick={openOrderDialog}
          >
            <OrderTextContainer>
              <AddCircleOutline />
              <NewOrderText>add new order</NewOrderText>
            </OrderTextContainer>
          </MuiButton>
        </NewOrderButton>
      )}
    </NoOrdersContainer>
  );
}
