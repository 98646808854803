import React from 'react';
import { styles } from '@onehope/design-system';
import MenuItem from '@material-ui/core/MenuItem';
import styled from '@emotion/styled';

const { styled: s, cssConstants } = styles;

const TextOverFlowContainer = styled.span`
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type SuggestionContainerProps = {
  height: string;
};

const SuggestionContainer = styled.div<SuggestionContainerProps>`
  height: ${({ height }: SuggestionContainerProps) => `${height}px`};
  width: 432px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${cssConstants.mutedAccentColor};
`;

const CustomerName = styled.div`
  width: 432px;
  height: 18px;
  /* font-family: ProximaNova; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${cssConstants.textColor};
`;

const Address = styled.div`
  width: 432px;
  height: 18px;
  /* font-family: ProximaNova; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${cssConstants.textColor};
`;

const Padding14 = styled.div`
  padding-bottom: 14px;
`;

const Padding8 = styled.div`
  padding-bottom: 8px;
`;

const CustomerEmail = styled.div`
  max-width: 432px;
  height: 16px;
  /* font-family: ProximaNova; */
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: ${cssConstants.textColor};
`;

const HeaderAndSubMenuItem = styled(MenuItem)`
  && {
    height: 67px;
    border-left: ${props =>
      props?.selected
        ? `4px solid ${cssConstants.textColor}`
        : `4px solid ${cssConstants.neutral5}`};
  }
`;

const AddressMenuItem = styled(MenuItem)`
  && {
    height: 46px;
    border-left: ${props =>
      props?.selected
        ? `4px solid ${cssConstants.textColor}`
        : `4px solid ${cssConstants.neutral5}`};
  }
`;

export interface GetItemPropsOptions<Item>
  extends React.HTMLProps<HTMLElement> {
  index?: number;
  item: Item;
  isSelected?: boolean;
  disabled?: boolean;
}

interface SuggestionProps {
  highlightedIndex: number | null;
  index: number;
  itemProps: GetItemPropsOptions<string>;
  selectedItem: string;
  suggestion?: string;
  objSuggestion?: any;
  id: string;
}

export function renderSuggestion(
  suggestionProps: SuggestionProps,
): JSX.Element {
  const {
    suggestion,
    objSuggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
    id,
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  if (typeof objSuggestion !== 'undefined') {
    if (id === 'hostFullNameDropDown' || id === 'nameDropDown') {
      const isSelected =
        (selectedItem || '') ===
        (objSuggestion.fullName || objSuggestion.organizationName);
      return (
        <HeaderAndSubMenuItem
          {...itemProps}
          key={`${objSuggestion.fullName ||
            objSuggestion.organizationName}${index}`}
          selected={isHighlighted}
          component="div"
          style={{
            fontWeight: isSelected ? 500 : 400,
          }}
        >
          <SuggestionContainer height={'67'}>
            <Padding14 />
            <CustomerName>
              {objSuggestion.organizationName || objSuggestion.fullName}
            </CustomerName>
            <Padding8 />
            <CustomerEmail>{objSuggestion.email}</CustomerEmail>
            <Padding8 />
          </SuggestionContainer>
        </HeaderAndSubMenuItem>
      );
    }
    if (id === 'customNonProfitDropDown') {
      const isSelected = (selectedItem || '') === objSuggestion.name;
      return (
        <HeaderAndSubMenuItem
          {...itemProps}
          key={`${objSuggestion.name}${index}`}
          selected={isHighlighted}
          component="div"
          style={{
            fontWeight: isSelected ? 500 : 400,
          }}
        >
          <SuggestionContainer height={'67'}>
            <Padding14 />
            <CustomerName>{objSuggestion?.name}</CustomerName>
            <Padding8 />
            <CustomerEmail>
              {objSuggestion?.city}, {objSuggestion?.state}
            </CustomerEmail>
            <Padding8 />
          </SuggestionContainer>
        </HeaderAndSubMenuItem>
      );
    }
    if (id === 'displayAddressDropDown' || id === 'shippingAddressDropDown') {
      const address = `${objSuggestion.addressLineOne}, ${objSuggestion.city}, ${objSuggestion.state}`;
      const isSelected = (selectedItem || '') === address;
      return (
        <AddressMenuItem
          {...itemProps}
          key={`${address}${index}`}
          selected={isHighlighted}
          component="div"
          style={{
            fontWeight: isSelected ? 500 : 400,
          }}
        >
          <SuggestionContainer height={'46'}>
            <Padding14 />
            <Address>
              <TextOverFlowContainer>{address}</TextOverFlowContainer>
            </Address>
            <Padding14 />
          </SuggestionContainer>
        </AddressMenuItem>
      );
    }
    if (id === 'eventAddressDropDown') {
      const address = `${objSuggestion.addressLineOne}, ${objSuggestion.city}, ${objSuggestion.state}`;
      const isSelected = (selectedItem || '') === address;
      return (
        <AddressMenuItem
          {...itemProps}
          key={`${address}${index}`}
          selected={isHighlighted}
          component="div"
          style={{
            fontWeight: isSelected ? 500 : 400,
          }}
        >
          <SuggestionContainer height={'46'}>
            <Padding14 />
            <Address>
              <TextOverFlowContainer>{address}</TextOverFlowContainer>
            </Address>
            <Padding14 />
          </SuggestionContainer>
        </AddressMenuItem>
      );
    }
  }
  if (typeof suggestion !== 'undefined') {
    const isSelected = (selectedItem || '').indexOf(suggestion) > -1;
    return (
      <MenuItem
        {...itemProps}
        key={`${suggestion}${index}`}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        <TextOverFlowContainer>{suggestion}</TextOverFlowContainer>
      </MenuItem>
    );
  }
}
