import React, { useRef, RefObject, useState } from 'react';
import {
  Grid,
  useTheme,
  useMediaQuery,
  Icons,
  Menu,
  MenuItem,
} from '@onehope/design-system-v2';
import ReactPlayer from 'react-player';

import Message from './Message';
import Title from './Title';
import ActivityEntry from '../types/ActivityEntry';
import sendToTrackEventV2Fn from '../types/sendToTrackEventV2';
import OpenVideoDialogFn from '../types/openVideoDialog';
import OpenButtonActionDialogFn from '../types/OpenButtonActionDialog';
import OnCardButtonClickedFn from '../types/OnCardButtonClicked';
import { SegmentEvents } from './constants/segmentEvents';
import CardButton from './CardButton';
import Video from './Video';
import useStyles from './helpers/styles';

/**
 * Renders the actual content of an Action Engine card given the Stream activity and the result of a GraphQL query for
 * any included dynamic data.
 */

export interface CardContentProps {
  activity: ActivityEntry;
  openVideoDialog?: OpenVideoDialogFn;
  openButtonActionDialog?: OpenButtonActionDialogFn;
  openDialog?: boolean;
  isContentfulSideBar?: boolean;
  sendToTrackEventV2?: sendToTrackEventV2Fn;
  onCardButtonClicked?: OnCardButtonClickedFn;
  archiveCardMutation?: (ledgerId: string, isArchived: boolean) => Promise<any>;
  removeArchivedCard?: (ledgerId: string) => void;
}

export default function CardContent(props: CardContentProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const playerRef = useRef() as RefObject<ReactPlayer>;
  const [playing, setPlaying] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownButtonAnchor, setDropdownButtonAnchor] =
    useState<Element | null>(null);

  const {
    activity,
    openDialog,
    openVideoDialog,
    openButtonActionDialog,
    sendToTrackEventV2,
    onCardButtonClicked,
    archiveCardMutation,
    removeArchivedCard,
  } = props;

  const wrapperClasses: Array<string> = [];
  if (activity.isDeleted) {
    wrapperClasses.push('is-deleted');
  }

  const classes = useStyles();

  const onVideoClickPreview = () => {
    if (isDesktop) {
      openVideoDialog &&
        openVideoDialog(
          activity.muxVideo ? activity.muxVideo.playbackId : 'undefined',
          activity.muxVideo ? activity.muxVideo.ratio : 'undefined',
        );
    } else {
      setPlaying(true);
    }
    sendToTrackEventV2 &&
      sendToTrackEventV2({ event: SegmentEvents.cardClicked, activity });
  };

  const watchNow = () => {
    setPlaying(true);
    playerRef.current?.getInternalPlayer().play();
  };

  const videoPause = () => {
    setPlaying(false);
  };

  const archiveClicked = async () => {
    if (archiveCardMutation) {
      const result = await archiveCardMutation(activity.id, true);
      if (result?.data?.actionEngineArchiveCard?.status === 'done') {
        removeArchivedCard && removeArchivedCard(activity.id);
        sendToTrackEventV2 &&
          sendToTrackEventV2({ event: SegmentEvents.cardArchived, activity });
        onDropdownClose();
      }
    }
  };

  const onDropdownClick = (event: React.MouseEvent) => {
    setDropdownButtonAnchor(event.currentTarget);
    setDropdownOpen(true);
  };

  const onDropdownClose = () => {
    setDropdownOpen(false);
    setDropdownButtonAnchor(null);
  };

  return (
    <Grid className={classes.wrapper}>
      <Grid className={wrapperClasses.join(' ')}>
        {/* don't render the image if there's also a video - it will be rendered in <Video /> as the thumbnail */}
        {activity.image && !activity.muxVideo?.playbackId && (
          <img
            className={classes.image}
            alt={activity.image.alt}
            src={activity.image.src}
          />
        )}

        {activity.muxVideo?.playbackId && (
          <Video
            {...props}
            onVideoClickPreview={onVideoClickPreview}
            ref={playerRef}
            playing={playing}
            openDialog={openDialog}
            videoPause={videoPause}
          />
        )}

        <Grid className={classes.textWrapper}>
          <Grid className={classes.titleWrapper}>
            <Title title={activity.title} />
            {!!archiveCardMutation ? (
              <>
                <Grid className={classes.dropdown} onClick={onDropdownClick}>
                  <Icons.CardDropdown />
                </Grid>
                <Menu
                  open={dropdownOpen}
                  anchorEl={dropdownButtonAnchor}
                  onClose={onDropdownClose}
                >
                  <MenuItem
                    onClick={archiveClicked}
                    className={classes.menuItem}
                  >
                    Archive
                  </MenuItem>
                </Menu>
              </>
            ) : null}
          </Grid>
          <Grid className={classes.bodyWrapper}>
            <Message message={activity.message} />
          </Grid>
          {activity.buttonText && (
            <CardButton
              activity={activity}
              onVideoClickPreview={onVideoClickPreview}
              openButtonActionDialog={openButtonActionDialog}
              onCardButtonClicked={onCardButtonClicked}
              watchNow={watchNow}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
