import { EventStatusDictionary } from '../../../utils/enums';

import _orderBy from 'lodash/orderBy';
import {
  CEDash_EventsQuery_viewer_user_events_edges as EventsType,
  CEDash_EventsQuery_viewer_user_events_edges_node as EventType,
} from '../../../queries/generatedTypes/CEDash_EventsQuery';

import { CEDash_EventDetailsPage_viewer_event as EventViewerType } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';

export function handleSortByFullName(
  events: (EventsType | null)[] | null,
  nameSortDirection: boolean,
) {
  if (!nameSortDirection) {
    return _orderBy(events, [e => e?.node?.hostFullName], ['desc']);
  } else {
    return _orderBy(events, [e => e?.node?.hostFullName]);
  }
}

export function handleSortByStatus(
  events: (EventsType | null)[] | null,
  statusSortDirection: boolean,
) {
  if (!statusSortDirection) {
    return _orderBy(events, [e => e?.node?.donationInfo?.status], ['desc']);
  } else {
    return _orderBy(events, [e => e?.node?.donationInfo?.status]);
  }
}

export function handleSortByEventDate(
  events: (EventsType | null)[] | null,
  dateSortDirection: boolean,
) {
  if (dateSortDirection) {
    return _orderBy(events, [e => e?.node?.eventDate]);
  } else {
    return _orderBy(events, [e => e?.node?.eventDate], ['desc']);
  }
}

export function handleSortByCloseDate(
  events: (EventsType | null)[] | null,
  dateSortDirection: boolean,
) {
  if (dateSortDirection) {
    return _orderBy(events, [e => e?.node?.closeDate]);
  } else {
    return _orderBy(events, [e => e?.node?.closeDate], ['desc']);
  }
}

export function handleSortBySubTotal(
  events: (EventsType | null)[] | null,
  subTotalSortDirection: boolean,
) {
  if (subTotalSortDirection) {
    return _orderBy(
      events,
      [
        e => {
          return e.node?.donationInfo?.ordersSubTotal
            ? Number(e.node?.donationInfo?.ordersSubTotal)
            : 0;
        },
      ],
      ['desc'],
    );
  } else {
    return _orderBy(events, [
      e => {
        return e.node?.donationInfo?.ordersSubTotal
          ? Number(e.node?.donationInfo?.ordersSubTotal)
          : 0;
      },
    ]);
  }
}

export function handleSortByDonationAmount(
  events: (EventsType | null)[] | null,
  donationAmountDirection: boolean,
) {
  if (donationAmountDirection) {
    return _orderBy(
      events,
      [
        e => {
          return e.node?.donationInfo?.donationAmount
            ? Number(e.node?.donationInfo?.donationAmount)
            : 0;
        },
      ],
      ['desc'],
    );
  } else {
    return _orderBy(events, [
      e => {
        return e.node?.donationInfo?.donationAmount
          ? Number(e.node?.donationInfo?.donationAmount)
          : 0;
      },
    ]);
  }
}

export function handleSearchAllEvents(
  events: (EventsType | null)[] | null,
  filterBy: string,
  search: string,
) {
  if (search && search !== '' && events) {
    const filteredEvents = getFilteredEvents(search, events);
    return handleFilterByTab(filteredEvents, filterBy);
  }
  return handleFilterByTab(events, filterBy);
}

export const eventIsAcceptingOrders = (e: EventType | EventViewerType | null) =>
  e?.trinityPartyStatus === EventStatusDictionary.OPEN;

export function eventIsClosed(e: EventType | EventViewerType | null) {
  return e?.status === EventStatusDictionary.CLOSED;
}

export function eventIsOpen(e: EventType | EventViewerType | null) {
  return e?.status === 'OPEN';
}

export function isSatelliteEvent(e: EventType | EventViewerType | null | undefined) {
  return !!e?.parentEventId;
}

function getFilteredEvents(
  search: string,
  events: (EventsType | null)[] | null,
) {
  const searchText = search.toLowerCase().replace(/\s/g, '');
  return (
    events &&
    events.filter(event => {
      const eventTitle =
        event?.node?.title?.toLowerCase().replace(/\s/g, '') ?? '';
      const eventOwnerName =
        event && event.node && event.node.hostFullName
          ? event.node.hostFullName.toLowerCase().replace(/\s/g, '')
          : '';
      const eventNonProfitName =
        event && event.node && event.node.hostNonProfitName
          ? event.node.hostNonProfitName.toLowerCase().replace(/\s/g, '')
          : '';
      const trinityPartyId =
        event && event.node && event.node.trinityPartyId
          ? event.node.trinityPartyId.toString()
          : '';
      return (
        eventTitle.includes(searchText) ||
        eventOwnerName.includes(searchText) ||
        eventNonProfitName.includes(searchText) ||
        trinityPartyId.includes(searchText)
      );
    })
  );
}

export function handleFilterByTab(
  events: (EventsType | null)[] | null,
  filterBy: string,
  search?: string,
) {
  if (events) {
    let filteredEvents = events;
    switch (filterBy) {
      case 'open':
        filteredEvents = events.filter(e => eventIsOpen(e.node));
        break;
      case 'closed':
        filteredEvents = events.filter(e => eventIsClosed(e.node));
        filteredEvents = _orderBy(
          filteredEvents,
          [e => e?.node?.closeDate],
          ['desc'],
        );
        break;
      default:
        filteredEvents = _orderBy(events, [e => e?.node?.closeDate], ['desc']);
    }
    return search
      ? handleTabFilterSearch(filteredEvents, search)
      : filteredEvents;
  }
  return events;
}

export function handleTabFilterSearch(
  events: (EventsType | null)[] | null,
  search: string,
) {
  if (search && search !== '' && events) {
    return getFilteredEvents(search, events);
  }
  return events;
}
