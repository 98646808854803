import { css } from '@emotion/core';

export const hrefStyle = css`
  a,
  a:visited {
    color: inherit;
  }
`;

export const hrStyle = css`
  margin: 0;
`;

export const headerStyle = css`
  height: 56px;
  padding: 16px;
`;

export const headerTitleStyle = css`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
`;

export const blogLinkStyle = [
  css`
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: right;
    float: right;
    text-decoration: underline;
    color: #1a1a1a;
  `,
  hrefStyle,
];

export const tabbedHeaderStyles = css`
  display: flex;
  justify-content: center;
  margin: 12px 0 24px;
`;

export const tabStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  :last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  width: 96px;
  height: 28px;
  border: solid 1px #d9d9d9;
  background-color: #ffffff;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: center;
  color: #a9a9a9;
`;

export const tabSpanStyles = css`
  height: 18px;
  color: #a9a9a9;
`;
export const activeTabSpanStyles = [
  tabSpanStyles,
  css`
    color: #1a1a1a;
  `,
];

export const blogMainContainerStyle = css`
  padding: 12px;
`;

export const simpleCarouselCss = css`
  margin-top: 24px;
`;
export const activeTabStyles = [
  tabStyles,
  css`
    border: solid 1px #1a1a1a;
    background-color: #ffffff;
    font-weight: bold;
    color: #1a1a1a;
  `,
];
