import React from 'react';
import styled from '@emotion/styled';
import { styles } from '@onehope/design-system';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { SvgExport } from '../../../assets/export';

const { cssConstants } = styles;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 288px;
`;

const ExportContactsLink = styled.div`
  height: 18px;
  /* font-family: ProximaNova; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${cssConstants.linkColor};
  :hover {
    cursor: pointer;
  }
`;

interface ExportContactsProps {
  handleContactsCSVExport: () => void;
}

export default function ExportContacts(props: ExportContactsProps) {
  const { handleContactsCSVExport } = props;
  return (
    <Container>
      <SvgExport />
      <ExportContactsLink onClick={handleContactsCSVExport}>
        Export contacts
      </ExportContactsLink>
    </Container>
  );
}
