import * as React from 'react';
import { PageRendererProps } from 'gatsby';
import styled from '@emotion/styled';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';

import Layout from '../components/MainLayout/layout/layout';
import ContactsV2 from '../components/ContactsV2';
import Contacts from '../components/Contacts';

import { getCurrentUserId } from '../utils/utils';

const Container = styled.div`
  background-color: white;
  height: 100vh;
`;

function ContactsPage(props: PageRendererProps) {
  const { location } = props;
  const currentUser = getCurrentUserId(location);

  const { customersPaginationV2 } = useFlags();

  return (
    <Layout selectedUserId={currentUser}>
      <Container>
        {customersPaginationV2 ? <ContactsV2 /> : <Contacts />}
      </Container>
    </Layout>
  );
}

export default withLDProvider({
  clientSideID: process.env.GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: {
    anonymous: true,
  },
})(ContactsPage);
