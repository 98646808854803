import gql from 'graphql-tag';

export const CreditsPage_user = gql`
  fragment CreditsPage_user on User {
    credits {
      edges {
        node {
          creditId
          dateCreated
          expirationDate
          appliedDate
          status
          ceAccountId
          eventId
          earnedFromEventId
          event {
            hostFullName
          }
        }
      }
    }
  }
`;

export const GET_EXPIRED_EVENT_CREDITS_QUERY = gql`
  query CEDash_ExpiredEventCreditsQuery(
    $eventId: String
    $numberOfCredits: Int
  ) {
    viewer {
      id
      user {
        id
        checkExpiredEventCredits(
          eventId: $eventId
          numberOfCredits: $numberOfCredits
        )
      }
    }
  }
`;

const GET_CREDITS_QUERY = gql`
  query CEDash_CreditsQuery(
    $page: Int
    $filterBy: String
    $sortBy: String
    $desc: Boolean
  ) {
    viewer {
      id
      user {
        credits(
          page: $page
          filterBy: $filterBy
          sortBy: $sortBy
          desc: $desc
        ) {
          edges {
            node {
              creditId
              dateCreated
              expirationDate
              appliedDate
              status
              ceAccountId
              eventId
              earnedFromEventId
              event {
                hostFullName
              }
              earnedFromEvent {
                trinityPartyId
              }
            }
          }
        }
        numberOfAvailableCredits
        numberOfTotalCredits(filterBy: $filterBy)
        id
        email
      }
    }
  }
`;

export default GET_CREDITS_QUERY;
