import React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import css from '@emotion/css';
import { MuiButton } from '@onehope/design-system-ohw';

import glassesClink from './assets/glasses-clink.svg';

const CreditsAvailableContainer = styled.div`
  /* text-align: center; */
  padding-left: 63px;
  padding-right: 62px;
  padding-top: 50px;
  width: 50%;
  margin-bottom: 30px;
  @media only screen and (min-width: 1680px) {
    width: 500px;
  }
`;

const mobileWidth = css`
  width: 100% !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
`;

const ComponentTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #000000;
`;

const glassesClinkStyle = css`
  height: 80px;
  margin-top: 10px;
`;

const DisplayFlex = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const DisplayBlock = styled.div`
  display: block;
  margin-left: 40px;
`;

const CreditBalanceTitle = styled.div`
  font-weight: bold;
  margin-top: 20px;
  font-size: 10px;
  letter-spacing: 2px;
`;

const CreditBalance = styled.div`
  font-size: 60px;
  margin-top: -10px;
`;

const MaxWidth300 = styled.div`
  max-width: 300px;
`;

const goToCreateNewEvent = () => {
  navigate(`/events/?createEventStep=0`);
};

export default function CreditsAvailable(props) {
  const { numberOfAvailableCredits, isMobile } = props;
  return (
    <CreditsAvailableContainer css={isMobile && mobileWidth}>
      <ComponentTitle>Event Kit Credits</ComponentTitle>
      <DisplayFlex>
        <img src={glassesClink} css={glassesClinkStyle}></img>
        <DisplayBlock>
          <CreditBalanceTitle>BALANCE:</CreditBalanceTitle>
          <CreditBalance>{numberOfAvailableCredits}</CreditBalance>
        </DisplayBlock>
      </DisplayFlex>
      <MaxWidth300>
        <MuiButton
          // disabled={eventId ? false : true}
          type="default"
          onClick={goToCreateNewEvent}
        >
          Redeem at a new event
        </MuiButton>
      </MaxWidth300>
    </CreditsAvailableContainer>
  );
}
