import { css } from '@emotion/core';

export default css`
  background-color: #1a1a1a;
  .ant-menu {
    background-color: #1a1a1a;
  }
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  .logo {
    text-align: center;
    height: 20px;
    width: 160px;
    font-size: 42px;
    margin: 25px 48px;
    font-weight: 600;
  }
  .hope-text {
    font-weight: 300;
  }
  .logo-subheader {
    text-align: center;
    color: white;
    letter-spacing: 1.2px;
    font-size: 18px;
    font-weight: 300;
    margin-top: -10px;
  }
`;

export const siderContainer = css`
  background-color: #1a1a1a;
`;
