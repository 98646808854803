/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import styled from '@emotion/styled';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import get from 'lodash/get';
import { styles as s } from '@onehope/design-system';
import { navigate } from 'gatsby';
import Columns from '../../Columns';
import {
  CEDash_EventsQuery_viewer_user_events_edges as EventsType,
  CEDash_EventsQuery_viewer_user_events_edges as EventEdge,
  CEDash_EventsQuery_viewer_user_events_edges_node as EventType,
} from '../../../../../queries/generatedTypes/CEDash_EventsQuery';
import { EventStatusDictionary } from '../../../../../utils/enums';
import { isSatelliteEvent } from '../../helpers';

const { cssConstants } = s;

const TableRow = styled(MuiTableRow)`
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

const TableCell = styled(MuiTableCell)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
  }
`;

interface TableBodyContainerProps {
  events: (EventsType | null)[] | null;
  page: number;
  rowsPerPage: number;
  contactId?: string | null | undefined;
  setSelectedEventId: (eventId: string | null | undefined) => void;
  handleOrderAdd: (eventId: string | null | undefined) => void;
  toggleEventCopyUrlDialog: (eventId: string | null | undefined) => void;
}

const eventIsClosed = (e: EventType | null | undefined) =>
  e?.status !== EventStatusDictionary.OPEN;

const eventIsAcceptingOrders = (e: EventType | null | undefined) =>
  e?.trinityPartyStatus === EventStatusDictionary.OPEN;

const handleRowClick = (
  event: EventEdge | null,
  contactId?: string | null | undefined,
) => {
  return () => {
    let link = !event?.node?.donationInfo?.nonProfit
      ? `/event?eventId=${event?.node?.eventId}&tab=2`
      : `/event?eventId=${event?.node?.eventId}&tab=0`;
    if (contactId) {
      link = `${link}&contactId=${contactId}`;
    }
    return navigate(link);
  };
};

export default function TableBodyContainer(props: TableBodyContainerProps) {
  const {
    events,
    rowsPerPage,
    page,
    contactId,
    setSelectedEventId,
    handleOrderAdd,
    toggleEventCopyUrlDialog,
  } = props;

  if (events) {
    return (
      <TableBody>
        {events
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((event: EventEdge | null) => {
            const onRowClick = handleRowClick(event, contactId);
            return (
              <TableRow hover role="checkbox" key={event?.node?.id}>
                {Columns.map(column => {
                  const value = get(event?.node, `${column.id}`);
                  const donationInfo = event?.node?.donationInfo;
                  const eventId = event?.node?.eventId;
                  const eventClosed = eventIsClosed(event?.node);
                  const title = event?.node?.title;
                  const hostName =
                    event?.node?.hostNonProfitName || event?.node?.hostFullName;
                  const timeZone = event?.node?.timeZone
                    ? event.timeZone
                    : 'America/Chicago'; // Fall back for trinity events
                  const isAcceptingOrders = eventIsAcceptingOrders(event?.node);
                  const onCellClick = !column.render ? onRowClick : () => {};
                  const satelliteEvent = isSatelliteEvent(event?.node);
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      onClick={onCellClick}
                    >
                      {column.format &&
                        column.format(
                          value,
                          event?.node?.eventId,
                          eventClosed,
                          donationInfo,
                          title,
                          hostName,
                          timeZone,
                          satelliteEvent,
                        )}
                      {column.render &&
                        column.render({
                          eventId,
                          setSelectedEventId,
                          handleOrderAdd,
                          toggleEventCopyUrlDialog,
                          isAcceptingOrders,
                        })}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
      </TableBody>
    );
  }
  return null;
}
