import React, { useState, useEffect } from 'react';
import Header from './Header';
import ContactsView from './ContactsView';
import {
  CEDash_CustomersQuery_viewer_user_customers as Contacts,
  CEDash_CustomersQuery_viewer_user_customers_edges as ContactsEdges,
} from '../../queries/generatedTypes/CEDash_CustomersQuery';

import { ArchiveContactsContextProvider } from './ArchiveContactsContext';
interface ContactsWrapperProps {
  contacts: Contacts;
}

function ContactsWrapper(props: ContactsWrapperProps) {
  const contactEdges = props.contacts.edges;
  const [contacts, setContacts] = useState(contactEdges);
  const [allContacts, setAllContacts] = useState(contactEdges);
  const resetContacts = (importedContacts: (ContactsEdges | null)[] | null) => {
    setContacts(importedContacts);
    setAllContacts(importedContacts);
  };

  useEffect(() => {
    setContacts(props.contacts.edges);
    setAllContacts(props.contacts.edges);
  }, [props.contacts]);

  return (
    <React.Fragment>
      <Header resetContacts={resetContacts} />
      <ArchiveContactsContextProvider>
        <ContactsView
          contacts={contacts}
          setContacts={setContacts}
          allContacts={allContacts}
        />
      </ArchiveContactsContextProvider>
    </React.Fragment>
  );
}

export default ContactsWrapper;
