import React, { useCallback } from 'react';
import getClient from '../../../apollo-client';
import { segmentEvents } from '../../../utils/segment/constants';
import { getCardTrackingProps } from '../../../utils/segment/helpers';

import { ActivityEntry, Feed } from '@onehope/action-engine';
import {
  makeStyles,
  ThemeType,
  Grid,
  Text,
  useTheme,
  useMediaQuery,
  Icons,
} from '@onehope/design-system-v2';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';

const useStyles = makeStyles((theme: ThemeType) => ({
  inbox: {
    fontFamily: theme.bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.01em',
    color: theme.palette.primary.main,
  },
  swipe: {
    fontFamily: `${theme.bodyFontFamily}`,
    fontStyle: 'italic',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.01em',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  button: {
    height: '30px',
    width: '30px',
    border: 'none',
    backgroundColor: 'white',
    zIndex: 99,
  },
  cardsHeader: {
    width: '100%',
    height: '40px',
    padding: '0 16px',
    borderBottom: `1px solid ${theme.palette.veryLightGrey.main}`,
  },
  cardsWrapper: {
    backgroundColor: 'white',
    boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.1)',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'white',
    },
  },
  slider: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    [theme.breakpoints.only('md')]: {
      width: '80%',
      minWidth: '288px',
      marginBottom: '0px',
      '& .slick-slide': {
        height: 'auto',
        marginBottom: '0px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: '288px',
    },
  },
}));

type CardsProps = {
  openVideoDialog?: (
    playbackId: string | undefined,
    ratio: string | undefined,
  ) => any;
  openButtonActionDialog?: (
    buttonAction: ActivityEntry['buttonAction'],
  ) => void;
  openDialog: boolean;
};

export type ActivityProps = Omit<
  ActivityEntry,
  'createContext' | 'deleteContext'
>;

export default function Cards(props: CardsProps) {
  const { openVideoDialog, openButtonActionDialog, openDialog } = props;
  const classes = useStyles({ hasFeeds: true });

  const { trackEventV2 } = useTrackingContextValue();
  const sendToTrackEventV2 = useCallback(
    ({
      event,
      activity,
    }: {
      event: segmentEvents | 'Card Clicked';
      activity: ActivityProps;
    }) => {
      if (!activity) {
        return;
      }
      const properties = getCardTrackingProps(activity);
      trackEventV2({ event, properties });
    },
    [trackEventV2],
  );

  // TODO: refactor empty state, etc into react-components since it controls the GraphQL query
  // one possible implementation would be allowing it to accept an empty state component as a prop
  const feeds = [null];

  return (
    <Grid
      className={`${classes.cardsWrapper} ${
        feeds && !feeds.length ? '' : classes.wrapperEmptyActivity
      }`}
    >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.cardsHeader}
      >
        <Grid className={classes.inbox}>Your Playbook</Grid>
        <Grid className={classes.swipe}>Swipe for more cards</Grid>
      </Grid>

      <Feed
        apolloClient={getClient()}
        openVideoDialog={openVideoDialog}
        openButtonActionDialog={openButtonActionDialog}
        openDialog={openDialog}
        sendToTrackEventV2={sendToTrackEventV2}
      />
    </Grid>
  );
}
