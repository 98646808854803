import styled from '@emotion/styled';

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const UnderlineText = styled.div`
  text-decoration: underline;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const CancelContainerItems = styled.div`
  text-align: left;
  margin-bottom: 30px;
`;

export const BoldText = styled.div`
  font-weight: bold;
`;
