import React, { useState } from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import { MuiButton } from '@onehope/design-system-ohw';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogRoot from '@material-ui/core/Dialog';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import AddNoteForm from './AddNoteForm';

import {
  FormWrapper,
  DetailsContainer,
  DetailsHeader,
  DetailsTitle,
  StraightLine,
} from '../index.styles';
import { CEDash_CustomerDetailsPage_viewer_customer as ContactType } from '../../../../queries/generatedTypes/CEDash_CustomerDetailsPage';
import { Customer_customer_notes as NoteType } from '../../../../queries/generatedTypes/Customer_customer';

import Note from './Note';

interface NotesPageProps {
  contact: ContactType;
}

const Spacer = styled.div`
  padding-bottom: 8px;
`;

const { cssConstants } = s;
const NewNoteButton = styled.div`
  max-width: 432px;
  width: 100%;
  height: 100px;
  color: ${cssConstants.primaryColor};
  cursor: pointer;
`;

const NewNoteText = styled.div`
  /* font-family: ProximaNova; */
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 8px;
  color: inherit;
`;

const NoteTextContainer = styled.div`
  max-width: 432px;
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
`;

function NotesPage(props: NotesPageProps) {
  const { contact } = props;
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);
  const [noteBeingEdited, setNoteBeingEdited] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const editing = false;
  const notes = contact?.notes;

  const toggleNoteDialog = () => {
    setIsNoteDialogOpen(!isNoteDialogOpen);
  };

  return (
    <React.Fragment>
      <FormWrapper>
        {isNoteDialogOpen && (
          <DialogRoot
            fullScreen={fullScreen}
            disableEnforceFocus
            open={isNoteDialogOpen}
            onClose={toggleNoteDialog}
          >
            <AddNoteForm
              contactAccountId={contact?.accountId}
              onClose={toggleNoteDialog}
              defaultValues={{
                text: '',
              }}
            />
          </DialogRoot>
        )}
        <DetailsContainer>
          <DetailsHeader>
            <DetailsTitle>Contact notes</DetailsTitle>
          </DetailsHeader>
          <StraightLine />
          {notes && (
            <>
              <Spacer />
              {notes.map((note: NoteType | null) => (
                <Note
                  note={note}
                  key={note?.id}
                  noteBeingEdited={noteBeingEdited}
                  setNoteBeingEdited={setNoteBeingEdited}
                />
              ))}
            </>
          )}
        </DetailsContainer>
        <NewNoteButton>
          <MuiButton
            type="DASHED"
            fullWidth
            disableRipple
            onClick={toggleNoteDialog}
            disabled={!!noteBeingEdited}
          >
            <NoteTextContainer>
              <AddCircleOutline />
              <NewNoteText>add new note</NewNoteText>
            </NoteTextContainer>
          </MuiButton>
        </NewNoteButton>
      </FormWrapper>
    </React.Fragment>
  );
}

export default NotesPage;
