// @ts-ignore
import * as Yup from 'yup';

export const EventDetailsFormSchema = Yup.object().shape({
  trinityPartyType: Yup.string().required('Required'),
  hostType: Yup.string().required('Required'),
  eventDate: Yup.date()
    .typeError('Invalid date, make sure format is MM/DD/YYYY')
    .min(new Date(), 'Invalid time, date must be in the future')
    .required('Required'),
  eventTime: Yup.string()
    .matches(
      /^\b((1[0-2]|0?[1-9]):([0-5][0-9]))$/,
      'Invalid time, make sure format is 06:00',
    )
    .required('Required'),
  eventTimeLocale: Yup.string().required('Required'),
  timeZone: Yup.string().required('Required'),
  hostFullName: Yup.string()
    .when('hostType', {
      is: 'CUSTOMER',
      then: Yup.string().required('Required'),
    })
    .when('hostType', {
      is: 'NONPROFIT',
      then: Yup.string().required('Required'),
    }),
  hostNonProfitName: Yup.string().when('hostType', {
    is: 'NONPROFIT',
    then: Yup.string().required('Required'),
  }),
  displayAddress: Yup.string(),
  addressLineOne: Yup.string().when('trinityPartyType', {
    is: 'EVENT',
    then: Yup.string().required('Required'),
  }),
  state: Yup.string().when('trinityPartyType', {
    is: 'EVENT',
    then: Yup.string().required('Required'),
  }),
  city: Yup.string().when('trinityPartyType', {
    is: 'EVENT',
    then: Yup.string().required('Required'),
  }),
  zip: Yup.string().when('trinityPartyType', {
    is: 'EVENT',
    then: Yup.string().required('Required'),
  }),
});
