import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled';

import Notification from '../../../Notifcations/DefaultNotifcation';
import { MyFormValues } from './FormTypes';
import ContactEditMutation from '../../../../mutations/Customer/ContactEditMutation';

import { ContactDetailsFormSchema } from './validationSchema';
import FormContainer from '../../../Event/FormContainer';
import { ContactDetailsForm } from './Form';
import { getDefaultValues, getContactDetailsToSubmit } from './helpers';
import Loading from '../../../../common/Loading';
import { CEDash_CustomerDetailsPage_viewer_customer as ContactType } from '../../../../queries/generatedTypes/CEDash_CustomerDetailsPage';

const Container = styled.div`
  position: relative;
`;

interface DetailsProps {
  contact: ContactType;
}

const handleUpdateContact = async ({ values, contact, contactEdit }: any) => {
  await contactEdit({
    variables: {
      input: {
        form: getContactDetailsToSubmit(values, contact),
      },
    },
  })
    .then((data: any) => {
      console.log(data);
    })
    .catch(async (errors: any) => {
      console.log(errors);
    });
};

const Details = ({ contact }: DetailsProps) => {
  const [contactEdit, { loading: mutationLoading }] = useMutation(
    ContactEditMutation,
  );
  if (mutationLoading) return <Loading />;

  return (
    <Container>
      {/* <Notification
        variant="success"
        open={notificationOpen}
        message={SUCCESS_MESSAGE}
        handleClose={handleNotificationClose}
      /> */}
      <FormContainer
        enableReinitialize
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={ContactDetailsFormSchema}
        initialValues={getDefaultValues(contact)}
        formToUse={(props: any) => (
          <ContactDetailsForm
            {...props}
            contact={contact}
            mutationLoading={mutationLoading}
            // setIsNotEditing={setIsNotEditing}
          />
        )}
        onSubmit={async (values: MyFormValues) => {
          await handleUpdateContact({
            values,
            contact,
            contactEdit,
          });
        }}
      />
    </Container>
  );
};

export default Details;
