import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';

type CustomerCardProps = {
  selected?: boolean;
};

export const CustomerCard = styled.div<CustomerCardProps>`
  max-height: 150px;
  border-radius: 4px;
  background-color: ${({ selected }: CustomerCardProps) =>
    selected ? '#ffffff' : '#f2f2f2'};
  border: ${({ selected }: CustomerCardProps) =>
    selected ? 'solid 1px #1a1a1a' : 'solid 1px #f2f2f2'};
  margin-bottom: 40px;
`;

export const CardTitle = styled.div`
  /* font-family: ProximaNova; */
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 8px;
`;

export const CardInfo = styled.div`
  position: relative;
  padding: 24px;
  padding-right: 60px;
`;

export const Icon = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;
`;

export const CardLine = styled.div`
  /* font-family: ProximaNova; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #000000;
  word-break: break-word;
`;
