import React from 'react';

function NoOrdersSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="80"
      height="80"
      viewBox="0 0 80 80">
      <defs>
        <path
          id="a"
          d="M1.96 0c1.08 0 1.96.897 1.96 2 0 1.122-.861 2-1.96 2C.86 4 0 3.122 0 2 0 .897.88 0 1.96 0zm19.571 0c1.04 0 1.92.892 1.92 1.948s-.88 1.948-1.92 1.948-1.92-.892-1.92-1.948S20.491 0 21.531 0zM8.779 0v3.796h-.814L6.221 1.263h-.01v2.542h-.763V.008h.809l1.75 2.539h.01V0h.762zm4.574 0v.702h-1.955v.813h1.795v.649h-1.795v.93h1.997v.702h-2.811V0h2.77zm2.097 0v1.648h2.25V0h.352v3.796h-.353V1.957h-2.25v1.84h-.351V0h.352zm11.253 0c.166 0 .317.024.451.072a.95.95 0 01.348.213c.097.093.17.208.223.342.052.135.078.288.078.458 0 .17-.026.322-.078.457a.944.944 0 01-.223.343.937.937 0 01-.348.212 1.34 1.34 0 01-.45.072h-1.292v1.627h-.353V0h1.644zm5.271 0v.308H29.77V1.68h2.064v.308H29.77v1.5H32v.308h-2.583V0h2.557zM21.531.34c-.853 0-1.574.736-1.574 1.608 0 .872.72 1.609 1.574 1.609.853 0 1.574-.737 1.574-1.61 0-.871-.721-1.607-1.574-1.607zM1.96.71C1.253.71.72 1.265.72 2c0 .711.556 1.29 1.24 1.29.684 0 1.24-.579 1.24-1.29 0-.735-.533-1.29-1.24-1.29zM26.626.308h-1.214v1.553h1.214c.245 0 .444-.064.596-.191.152-.128.228-.323.228-.585 0-.262-.076-.458-.228-.585-.152-.127-.351-.192-.596-.192z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M0 0h80v80H0z" />
        <path fill="#FFF" d="M0 0h80v80H0z" />
        <path
          fill="#FFF"
          stroke="#1A1A1A"
          d="M18 14.5h44l9.5 8V65L8 64.5l.5-42z"
        />
        <path
          fill="#0091EA"
          fillRule="nonzero"
          stroke="#1A1A1A"
          d="M36.98 14.5l-1.386 8h8.812l-1.385-8h-6.042z"
        />
        <g transform="translate(35 56)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#0A0A0A" fillRule="nonzero" xlinkHref="#a" />
          <g fill="#1A1A1A" mask="url(#b)">
            <path d="M0 0h32v4H0z" />
          </g>
        </g>
        <path
          fill="#0091EA"
          fillRule="nonzero"
          stroke="#1A1A1A"
          d="M35.5 22.5v10h9v-10h-9z"
        />
        <path
          stroke="#1A1A1A"
          strokeLinecap="square"
          d="M40 31.5v-17m-31.5 8h63"
        />
      </g>
    </svg>
  );
}

export default NoOrdersSvg;
