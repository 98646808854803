/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import { DateTime } from 'luxon';
import IconMenu from './IconMenu';
import { MuiButton, MuiBadge } from '@onehope/design-system-ohw';

import { Link } from 'gatsby';

const formatStatusDisplayValue = (value: string) =>
  `${value
    .toLocaleString()
    .charAt(0)
    .toUpperCase()}${value.substring(1).toLowerCase()}`;

export const formatDonationStatus = ({
  value,
  eventId,
  eventClosed,
  donationInfo,
  satelliteEvent,
}: {
  value: string;
  eventId?: string | null | undefined;
  eventClosed?: boolean;
  donationInfo?: any;
  satelliteEvent?: boolean | undefined;
}) => {
  if (satelliteEvent) {
    if (eventClosed) {
      return value && value.toLowerCase() !== 'pending' ? 'Open' : 'Closed';
    }
    return 'Open';
  }
  if (eventClosed) {
    return value && value.toLowerCase() !== 'pending'
      ? formatStatusDisplayValue(value)
      : 'Closed';
  }
  if (!eventId) {
    return null;
  }
  return value && donationInfo?.nonProfit ? (
    formatStatusDisplayValue(value)
  ) : (
    <Link to={`/event?eventId=${eventId}&tab=2`}>
      <MuiBadge variant="dot">Select a charity</MuiBadge>
    </Link>
  );
};

interface RenderProps {
  eventId: string | null | undefined;
  setSelectedEventId: React.Dispatch<React.SetStateAction<string>>;
  handleOrderAdd: (eventId: string | null | undefined) => void;
  toggleEventCopyUrlDialog?: (
    eventId: string | null | undefined,
    trinityPartyId: string | null | undefined,
  ) => void;
  isAcceptingOrders?: boolean | null | undefined;
  trinityPartyId?: string | null | undefined;
}

interface Column {
  id:
    | 'menu'
    | 'title'
    | 'eventDate'
    | 'closeDate'
    | 'orderAdd'
    | 'donationInfo.ordersSubTotal'
    | 'donationInfo.status'
    | 'donationInfo.donationAmount';
  label?: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'right';
  format?: (
    value: string,
    eventId?: string | null | undefined,
    eventClosed?: boolean,
    donationInfo?: any,
    title?: string | null | undefined,
    hostFullName?: string | null | undefined,
    timeZone?: string | undefined,
    satelliteEvent?: boolean,
  ) => void;
  render?: (renderProps: RenderProps) => any;
}

const columns: Column[] = [
  {
    id: 'title',
    label: 'Name',
    minWidth: 170,
    align: 'left',
    format: (
      value,
      eventId?: string | null | undefined,
      eventClosed?: boolean,
      donationInfo?: any,
      title?: string | null | undefined,
      hostFullName?: string | null | undefined,
    ) =>
      title ? title : value && eventId ? value.toLocaleString() : hostFullName,
    // ? <Link to={`/event?eventId=${eventId}`}>
    //     {value.toLocaleString()}
    //   </Link>
    // : '',
  },
  {
    id: 'eventDate',
    label: 'Event Date',
    minWidth: 140,
    align: 'left',
    format: (value: string | null | undefined) =>
      value
        ? DateTime.fromISO(value)
            .toUTC()
            .toLocaleString(DateTime.DATE_SHORT)
        : '\u2014',
  },
  {
    id: 'closeDate',
    label: ' Close Date',
    minWidth: 140,
    align: 'left',
    format: (value: string | null | undefined) =>
      value
        ? DateTime.fromISO(value)
            .toUTC()
            .toLocaleString(DateTime.DATE_SHORT)
        : '\u2014',
  },
  {
    id: 'donationInfo.ordersSubTotal',
    label: 'Subtotal',
    minWidth: 70,
    align: 'left',
    format: value => (value ? `$${value.toLocaleString()}` : '$0'),
  },
  {
    id: 'donationInfo.status',
    label: 'Donation Status',
    minWidth: 170,
    align: 'left',
    format: (
      value,
      eventId?: string | null | undefined,
      eventClosed?: boolean,
      donationInfo?: any,
      title?: string | null | undefined,
      hostFullName?: string | null | undefined,
      timeZone: string | undefined,
      satelliteEvent: boolean | undefined,
    ) =>
      formatDonationStatus({
        value,
        eventId,
        eventClosed,
        donationInfo,
        satelliteEvent,
      }),
  },
  {
    id: 'donationInfo.donationAmount',
    label: 'Donation Amount',
    minWidth: 170,
    align: 'left',
    format: value => (value ? `$${value.toLocaleString()}` : '$0'),
  },
  // {
  //   id: 'donationInfo.event.hostKitStatus',
  //   label: 'Host Kit Status',
  //   minWidth: 170,
  //   align: 'left',
  //   format: value => (value ? value.toLocaleString() : 'Not Yet Ordered'),
  // },
  {
    id: 'orderAdd',
    minWidth: 0,
    align: 'left',
    render: ({ eventId, handleOrderAdd, isAcceptingOrders }: RenderProps) => (
      <MuiButton
        disabled={!isAcceptingOrders}
        type="ROUND"
        onClick={() => handleOrderAdd(eventId)}
      >
        Order
      </MuiButton>
    ),
  },
  {
    id: 'menu',
    minWidth: 0,
    align: 'right',
    render: ({
      eventId,
      setSelectedEventId,
      handleOrderAdd,
      toggleEventCopyUrlDialog,
      isAcceptingOrders,
      trinityPartyId,
    }: RenderProps) => (
      <IconMenu
        isCardView={false}
        eventId={eventId}
        trinityPartyId={trinityPartyId}
        isAcceptingOrders={isAcceptingOrders}
        toggleEventCopyUrlDialog={toggleEventCopyUrlDialog}
        setSelectedEventId={setSelectedEventId}
        handleOrderAdd={handleOrderAdd}
      />
    ),
  },
];

export default columns;
