import React, { Component } from 'react';

import Layout from '../components/MainLayout/layout/layout';
import AccountInfo from '../components/Account/AccountInfo';
import { getCurrentUserId } from '../utils/utils';

interface AccountInfoPageProps {
  location: any;
}

interface AccountInfoPageState {
  collapsed: boolean;
}

class AccountInfoPage extends Component<
  AccountInfoPageProps,
  AccountInfoPageState
> {
  state = {
    collapsed: false,
  };

  render() {
    const { location } = this.props;
    const currentUser = getCurrentUserId(location);

    return (
      <Layout selectedUserId={currentUser}>
        <AccountInfo
          selectedUserId={currentUser.userId}
          userInfo={currentUser}
        />
      </Layout>
    );
  }
}

export default AccountInfoPage;
