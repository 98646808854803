import { css } from '@emotion/core';

export const myMentorCardStyles = css`
  background-color: #ffffff;
  font-style: normal;
  font-stretch: normal;
  position: relative;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 600px) {
    height: 423px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
`;

export const myMentorHeaderStyles = css`
  height: 56px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  div {
    padding: 16px 0 17px 24px;
    height: 23px;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    line-height: 23px;
  }
`;

export const myMentorBodyStyles = css`
  display: flex;
  flex-direction: column;
  margin: 40px 40px 47px;
  @media only screen and (max-width: 600px) {
    margin: 16px 24px;
  }
`;

export const myMentorComingSoonLogo = css`
  width: 79px;
  height: 79px;
  object-fit: contain;
  @media only screen and (max-width: 600px) {
    width: 60px;
    height: 60px;
  }
`;

export const myMentorComingSoonWelcome = css`
  margin: 24px 0;
  width: 475px;
  height: 26px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #000000;
  @media only screen and (max-width: 600px) {
    width: 288px;
    height: 26px;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #000000;
  }
`;

export const myMentorComingSoonText = css`
  width: auto;
  height: inherit;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  @media only screen and (max-width: 320px) {
    width: 288px;
    height: 120px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;
  }
`;

export const myMentorHomeOfficeButton = css`
  :hover {
    background: rgba(0, 0, 0, 0.75);
  }
  width: 288px;
  height: 40px;
  border-radius: 4px;
  background-color: #1a1a1a;
  margin-top: 24px;
`;

export const MyMentorHomeOfficeText = css`
  width: 171px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  a {
    color: #ffffff;
    :hover {
      outline: none;
    }
  }
`;

export const MyMentorSupportContainer = css`
  margin-top: 24px;
`;

export default css`
  background-color: #ffffff;
  font-style: normal;
  font-stretch: normal;
  position: relative;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 531px) {
    width: 100%;
  }

  header {
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    div {
      padding: 16px 0 17px 24px;
      height: 23px;
      color: #000000;
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
    }
  }

  .mentor-info {
    padding: 0 0 17px 24px;
    .my-mentor {
      height: 25px;
      width: 97px;
      color: #000000;
      font-size: 18px;
      font-weight: bold;
      line-height: 25px;
    }
    .mentor-details {
      span {
        color: #000000;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
      }
      .anticon {
        margin-right: 16px;
      }
    }
  }

  .mentor-details {
    display: flex;
    margin-top: 24px;
    margin-bottom: 35px;
  }
  .mentor-info {
    margin-top: 24px;
    .name {
      height: 26px;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.44;
      letter-spacing: normal;
      color: #000000;
    }
    .email-info,
    .join-date-info {
      position: relative;
      top: -3.5px !important;
    }
    .phone-info {
      position: relative;
      top: -5px !important;
    }

    .city-info {
      position: relative;
      top: -4px !important;
    }

    .email,
    .phone,
    .city,
    .join-date {
      span {
        margin-left: 16px;
        height: 22px;
        color: #000000;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.57;
        letter-spacing: normal;
      }
    }
    .rank {
      padding-top: 5px;
      height: 16px;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 2px;
      color: #000000;
      text-transform: uppercase;
    }

    .email,
    .phone,
    .city {
      margin: 23px 0;
    }
    .phone,
    .city {
      padding-left: 4px;
    }

    .join-date {
      padding-left: 2px;
    }
  }

  .profile-pic-container {
    display: flex;
  }

  .profile-picture {
    width: 79px;
    height: 79px;
    border-radius: 50%;
  }

  .profile-url {
    display: flex;
    margin: 18px 0;
  }

  .anticon-more {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    height: 30px;
    svg {
      font-size: 30px;
    }
  }
`;
