import { css } from '@emotion/core';
import { styles } from '@onehope/design-system';
const { cssConstants } = styles;

export default css`
  overflow: hidden;
  font-family: ${cssConstants.font};
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 600px) {
    height: inherit;
  }
  background-color: #ffffff;

  header {
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    div {
      padding: 16px 0 17px 24px;
      height: 23px;
      color: ${cssConstants.textColor};
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
    }
  }

  .content {
    .logo {
      width: 90px;
      height: 90px;
      margin-left: -12px;
      @media only screen and (max-width: 600px) {
        width: 52px;
        height: 52px;
      }
    }
    .title {
      padding-top: 24px;
      padding-bottom: 25px;
      color: ${cssConstants.textColor};
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
    }
    .desc {
      margin-top: 16px;
      width: 456px;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: -0.5px;
      text-align: left;
      color: ${cssConstants.textColor};
      @media only screen and (max-width: 1000px) {
        margin-top: 16px;
        width: 233px;
        height: inherit;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.44;
        letter-spacing: normal;
        color: ${cssConstants.textColor};
      }
    }
    .text {
      display: flex;
      align-items: center;
      width: auto;
      height: inherit;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: ${cssConstants.textColor};
      margin-top: 24px;
      @media only screen and (max-width: 768px) {
        width: auto;
        margin-top: 16px;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: ${cssConstants.textColor};
      }
    }
    .cta-btn {
      border: none;
      width: 288px;
      height: 40px;
      border-radius: 4px;
      background-color: ${cssConstants.primaryColor};
      margin-top: 24px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: 1px;

      :hover {
        background: rgba(0, 0, 0, 0.75);
      }

      @media only screen and (max-width: 600px) {
        margin-top: 16px;
      }
    }

    .progress-wrapper {
      display: flex;
      flex-direction: column;
      margin: 40px 40px 57px;
      @media only screen and (max-width: 600px) {
        margin: 24px 16px;
      }
    }
    .progress-info {
      width: 318.53px;
      .progress-details {
        display: flex;
        margin-top: 3px;
      }
      span {
        height: 16px;
        width: 141px;
        color: #000000;
        font-size: 10px;
        font-style: italic;
        font-weight: 300;
        letter-spacing: 0.5px;
        line-height: 16px;
      }
      span:first-of-type {
        margin-right: auto;
      }
      span:last-of-type {
        text-align: right;
      }
    }
    .antd-pro-charts-mini-progress-miniProgress {
      height: 5px;
      width: 318.53px;
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.1);
      box-shadow: 1px 2px 17px 0 rgba(0, 0, 0, 0.05);
    }
    .antd-pro-charts-mini-progress-progress {
      height: 5px;
      width: 195.02px;
      border-radius: 2px;
      background-color: #00bfb2;
      box-shadow: 1px 2px 17px 0 rgba(0, 0, 0, 0.05);
    }
    .anticon {
      margin-top: -3px;
      svg {
        transform: scale(0.5);
      }
    }
    .check-icon {
      margin-left: 8px;
      color: #00bfb2;
    }
    .complete-btn {
      margin-top: 25px;
      margin-bottom: 32px;
      display: block;
      height: 37px;
      width: 100px;
      border-radius: 100px;
      background-color: #1a1a1a;
      span {
        height: 21px;
        width: 68px;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        line-height: 21px;
        text-align: center;
      }
    }
  }
`;
