import gql from 'graphql-tag';
export const mutation = gql`
  mutation ImagePresignedUrlGetMutation($input: ImagePresignedUrlGetInput!) {
    imagePresignedUrlGet(input: $input) {
      urls
    }
  }
`;

export default mutation;
