import * as React from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import Table from '@material-ui/core/Table';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

import { CEDash_GuestOrdersQuery_viewer_user_orders_edges as OrdersEdges } from '../../../queries/generatedTypes/CEDash_GuestOrdersQuery';

const { cssConstants } = s;
const TableWrapper = styled.div`
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  //max-height: 600px;
  overflow: auto;
  background-color: white;
  margin-bottom: 24px;
`;

interface OrdersListViewProps {
  orders: (OrdersEdges | null)[] | null;
  page: number;
  rowsPerPage: number;
  handleSortOrders: (sortBy: string) => void;
}

export default function OrdersListViewTable(props: OrdersListViewProps) {
  const {
    handleSortOrders,
    orders,
    page,
    rowsPerPage,
  } = props;
  return (
    <TableWrapper>
      <Table stickyHeader aria-label="events table" >
        <TableHeader handleSortOrders={handleSortOrders} />
        <TableBody
          page={page}
          orders={orders}
          rowsPerPage={rowsPerPage}
        />
      </Table>
    </TableWrapper>
  );
}
