import gql from 'graphql-tag';

export const EventsPage_user = gql`
  fragment EventsPage_user on User {
    events(first: 10000, sortBy: "any") {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          status
          trinityPartyStatus
          trinityPartyId
          eventId
          eventDate
          closeDate
          hostFirstName
          hostLastName
          hostFullName
          hostNonProfitName
          trinityPartyType
          hostKitOrderNumbers
          hostKitQuantity
          timeZone
          hostQuote
          eventAddress {
            city
            state
          }
          parentEventId
          donationId
          donationInfo {
            id
            ordersSubTotal
            donationAmount
            hostKitDonation
            donationType
            donationGoal
            status
            lobMemo
            nonProfit {
              id
              nonProfitName
              nonProfitTaxId
              nonProfitId
              isPreApproved
              nonProfitAddress {
                optionalName
                addressLineOne
                addressLineTwo
                city
                state
                zip
                phoneNumber
              }
            }
          }
          title
          displayPreferences {
            id
            donationGoal
            supporters
          }
        }
      }
    }
  }
`;

const GET_EVENTS_QUERY = gql`
  query CEDash_EventsQuery {
    viewer {
      id
      user {
        ...EventsPage_user
        id
        userId
        email
        numberOfAvailableCredits
        availableStarterKitCredits
      }
    }
  }

  ${EventsPage_user}
`;

export default GET_EVENTS_QUERY;
