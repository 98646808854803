import _orderBy from 'lodash/orderBy';
import * as React from 'react';

import { CEDash_GuestOrdersQuery_viewer_user_orders_edges as OrdersType } from '../../queries/generatedTypes/CEDash_GuestOrdersQuery';
import { evaluateOrderStatus } from '../../utils/utils';

import OrderProcessingSvg from '../../assets/orderProcessing';
import OrderShippedSvg from '../../assets/orderShipped';
import OrderErrorSvg from '../../assets/orderError';
import OrderSuccessSvg from '../../assets/orderSuccess';

export function handleSortByFullName(
  orders: (OrdersType | null)[] | null,
  nameSortDirection: boolean,
) {
  if (!nameSortDirection) {
    return _orderBy(orders, [e => e?.node?.accountFullName], ['desc']);
  } else {
    return _orderBy(orders, [e => e?.node?.accountFullName]);
  }
}

export function handleSortByStatus(
  orders: (OrdersType | null)[] | null,
  statusSortDirection: boolean,
) {
  if (!statusSortDirection) {
    return _orderBy(orders, [e => e?.node?.orderStatus], ['desc']);
  } else {
    return _orderBy(orders, [e => e?.node?.orderStatus]);
  }
}

export function handleSortByCreateDate(
  orders: (OrdersType | null)[] | null,
  dateSortDirection: boolean,
) {
  if (dateSortDirection) {
    return _orderBy(orders, [e => e?.node?.createDate]);
  } else {
    return _orderBy(orders, [e => e?.node?.createDate], ['desc']);
  }
}

export function handleSortBySubTotal(
  orders: (OrdersType | null)[] | null,
  subTotalSortDirection: boolean,
) {
  if (subTotalSortDirection) {
    return _orderBy(
      orders,
      [
        e => {
          return e?.node?.orderTotal ? Number(e?.node?.orderTotal) : 0;
        },
      ],
      ['desc'],
    );
  } else {
    return _orderBy(orders, [
      e => {
        return e?.node?.orderTotal ? Number(e?.node?.orderTotal) : 0;
      },
    ]);
  }
}

export function handleSortByOrderNumber(
  orders: (OrdersType | null)[] | null,
  subTotalSortDirection: boolean,
) {
  if (subTotalSortDirection) {
    return _orderBy(
      orders,
      [
        e => {
          return e?.node?.orderNumber
            ? Number(e?.node?.orderNumber.replace(/-/g, ''))
            : 0;
        },
      ],
      ['desc'],
    );
  } else {
    return _orderBy(orders, [
      e => {
        return e?.node?.orderNumber
          ? Number(e?.node?.orderNumber.replace(/-/g, ''))
          : 0;
      },
    ]);
  }
}

export function handleSearchAllOrders(
  orders: (OrdersType | null)[] | null,
  filterBy: string,
  search: string,
) {
  if (search && search !== '' && orders) {
    const filteredOrders = getFilteredOrders(search, orders);
    return handleFilterByTab(filteredOrders, filterBy);
  }
  return handleFilterByTab(orders, filterBy);
}

function getFilteredOrders(
  search: string,
  orders: (OrdersType | null)[] | null,
) {
  const searchText = search.toLowerCase().replace(/\s/g, '');
  return (
    orders &&
    orders.filter(order => {
      const eventOwnerName =
        order && order.node && order.node.accountFullName
          ? order.node.accountFullName.toLowerCase().replace(/\s/g, '')
          : '';
      const orderNumber =
        order && order.node && order.node.orderNumber
          ? order.node.orderNumber.toString().replace(/-/g, '')
          : '';
      return (
        eventOwnerName.includes(searchText) || orderNumber.includes(searchText)
      );
    })
  );
}

export function handleFilterByTab(
  orders: (OrdersType | null)[] | null,
  filterBy: string,
  search?: string,
) {
  if (orders) {
    let filteredOrders = orders;
    switch (filterBy) {
      case 'open':
        filteredOrders = orders.filter(
          e => evaluateOrderStatus(e?.node?.orderStatus) !== 'cancelled',
        );
        break;
      case 'cancelled':
        filteredOrders = orders.filter(
          e => evaluateOrderStatus(e?.node?.orderStatus) === 'cancelled',
        );
        break;
    }
    return search
      ? handleTabFilterSearch(filteredOrders, search)
      : filteredOrders;
  }
  return orders;
}

export function handleTabFilterSearch(
  orders: (OrdersType | null)[] | null,
  search: string,
) {
  if (search && search !== '' && orders) {
    return getFilteredOrders(search, orders);
  }
  return orders;
}

export function getOrderStatusIcon(status: string | null) {
  if (status) {
    switch (evaluateOrderStatus(status)) {
      case 'processing':
        return { icon: <OrderProcessingSvg />, status: 'Processing' };
      case 'inTransit':
        return { icon: <OrderShippedSvg />, status: 'In Transit' };
      case 'cancelled':
        return { icon: <OrderErrorSvg />, status: 'Cancelled' };
      case 'exception':
        return { icon: <OrderErrorSvg />, status };
      case 'complete':
        return { icon: <OrderSuccessSvg />, status: 'Delivered' };
      default:
        return { icon: <OrderProcessingSvg />, status: 'Processing' };
    }
  }
  return { icon: <OrderErrorSvg />, status: '' };
}

export function isStatusValidForCancel(orderStatus: string | null) {
  switch (orderStatus) {
    case 'paymentPending':
    case 'processing': {
      return true;
    }
    case 'cancelled':
    case 'complete':
    case 'damagedPackage':
    case 'delivered':
    case 'exception':
    case 'fulfillment':
    case 'inTransit':
    case 'missedDeliveryFirst':
    case 'missedDeliverySecond':
    case 'missedDeliveryThird':
    case 'outForDelivery':
    case 'returned':
    case 'returningToSender':
    case 'serviceDisruption': {
      return false;
    }
    default: {
      return true;
    }
  }
}
