import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { styles } from '@onehope/design-system';
import {
  ThemeProvider,
  theme,
  makeStyles,
  ThemeType,
} from '@onehope/design-system-v2';
import styled from '@emotion/styled';
import NotificationsIcon from '@material-ui/icons/Notifications';

import headerStyles, { menuStyles } from './headerStyles';
import SatelliteEventsSnackBar from '../../Events/SatelliteEventsSnackBar';
import { NotificationBadge } from '../../Event/Notifications/index.styles';
import NotificationAlert from '../../../assets/notificationAlert';
import NotificationModal from '../NotificationModal';

const pagesToShowNotification = ['/dashboard', '/events'];

const {
  antDesignStyles: {
    icon: { Icon },
    layout: { Header },
  },
} = styles;

interface NavHeaderProps {
  collapsed: boolean;
  firstName: string;
  toggle: () => void;
  location: any;
}

const IconContainer = styled.div`
  margin: 0 11px;
  float: right;
`;

const MobileHeaderContainer = styled.div`
  position: relative;
  width: 100%;
`;

function isEventPage() {
  if (typeof window === 'undefined') return false;
  return window.location.pathname.includes('event');
}

function showSnackBar() {
  if (typeof window === 'undefined') return false;
  const { pathname } = window.location;
  return pagesToShowNotification.includes(pathname);
}

const getStyles = makeStyles((theme: ThemeType) => ({
  greeting: {
    fontFamily: theme.headerFontFamily,
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '18px',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.04em',
    marginLeft: '18px',
    color: '#373634',
    float: 'left',
    marginTop: '18px',
  },
}));

const NavHeader: React.FunctionComponent<NavHeaderProps> = ({
  collapsed,
  firstName,
  toggle,
  location,
}) => {
  const showSatelliteEventsSnackBar = showSnackBar();
  const search = location?.search;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = getStyles();

  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenNotificationDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenNotificationDialog(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Header css={headerStyles}>
        <div css={menuStyles} onClick={toggle}>
          <Icon
            className="trigger"
            type={collapsed ? 'menu-unfold' : 'menu-fold'}
          />
        </div>
        {isMobile && (
          <MobileHeaderContainer>
            <div className={classes.greeting}>Hi, {firstName}!</div>
            <IconContainer onClick={handleOpenDialog}>
              <NotificationAlert />
            </IconContainer>
            <NotificationModal
              openNotificationDialog={openNotificationDialog}
              handleCloseDialog={handleCloseDialog}
              isMobile={isMobile}
            />
          </MobileHeaderContainer>
        )}
        {!isMobile && showSatelliteEventsSnackBar && (
          <SatelliteEventsSnackBar />
        )}
      </Header>
    </ThemeProvider>
  );
};
export default NavHeader;
