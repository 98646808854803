import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { MuiButton, MuiInput } from '@onehope/design-system-ohw';
// import { makeStyles } from '@material-ui/core/styles';

const Container = styled.div`
  max-width: 432px;
  justify-content: center;
  margin: 0;
  padding: 0;
  @media all and (min-width: 450px) {
    display: flex;
  }
`;

const Button = styled(MuiButton)`
  && {
    height: 48px !important;
  }
`;

interface PromoCodeProps {
  label?: string;
  disabled?: boolean;
  value: any;
  onChange: any;
  buttonText?: string;
  onButtonClick: any;
}

const InputStyle = css`
  /* border-radius: 0 !important;
  outline: none !important;
  font-weight: 300 !important;
  line-height: 1.57 !important;
  color: #000000 !important;
  font-size: 14px !important;
  height: 40px !important;
  background-color: rgba(250, 250, 250, 0.04) !important;
  box-shadow: inset 0 -1px 0 0 #1a1a1a !important;
  border: none !important;

  ::placeholder {
    color: black;
    font-style: italic;
  } */

  margin: 24px 0 16px 0;
  width: 100%;
  @media all and (min-width: 450px) {
    width: 255px;
    margin: 24px 16px 0 0;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  @media all and (min-width: 450px) {
    padding-top: 45px;
    width: 161px;
  }
`;

// const useStyles = makeStyles({
//   root: {
//     height: 52,
//     backgroundColor: '#0000ff',
//     color: '#ff0000',
//   },
// });

const PromoCode = (props: PromoCodeProps) => {
  const { onChange, onButtonClick, buttonText, label, disabled, value } = props;
  // const classes = useStyles();
  return (
    <Container>
      <div css={InputStyle}>
        <MuiInput
          label={label}
          disabled={disabled}
          fullWidth
          id="promocode"
          name="promocode"
          value={value}
          onChange={onChange}
        />
      </div>

      <ButtonContainer>
        <Button
          type="PRIMARY"
          disableRipple
          fullWidth
          disabled={disabled}
          onClick={onButtonClick}
          // classes={{ root: classes.root }}
        >
          {buttonText}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default PromoCode;
