import React, { Dispatch, SetStateAction, useState } from 'react';
import Header from '../Contacts/Header';
import ContactsTable from './ContactsTable';
import {
  CEDash_CustomersQuery_viewer_user_customers as Contacts,
  CEDash_CustomersQuery_viewer_user_customers_edges as ContactsEdges,
} from '../../queries/generatedTypes/CEDash_CustomersQuery';

import { ArchiveContactsContextProvider } from '../Contacts/ArchiveContactsContext';

interface ContactsWrapperProps {
  contacts: Contacts;
  pageNumber: number;
  filterBy: string | null;
  setPageNumber: Dispatch<SetStateAction<string | string[] | null | undefined>>;
  handleSortContacts: (sortBy: string) => void;
  setFilterBy: any;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  count: number;
  setSearchBy: any;
}

function ContactsWrapper(props: ContactsWrapperProps) {
  const contactEdges = props?.contacts?.edges;
  const [contacts, setContacts] = useState(contactEdges);
  const {
    pageNumber,
    filterBy,
    setPageNumber,
    handleSortContacts,
    setFilterBy,
    rowsPerPage,
    setRowsPerPage,
    count,
    setSearchBy,
  } = props;
  const resetContacts = (importedContacts: (ContactsEdges | null)[] | null) => {
    setContacts(importedContacts);
  };

  return (
    <React.Fragment>
      <Header resetContacts={resetContacts} />
      <ArchiveContactsContextProvider>
        <ContactsTable
          contacts={contactEdges}
          page={Number(pageNumber)}
          filterBy={filterBy ? filterBy : 'all'}
          setPageNumber={setPageNumber}
          rowsPerPage={rowsPerPage}
          setFilterBy={setFilterBy}
          setRowsPerPage={setRowsPerPage}
          handleSortContacts={handleSortContacts}
          count={count}
          setSearchBy={setSearchBy}
        />
      </ArchiveContactsContextProvider>
    </React.Fragment>
  );
}

export default ContactsWrapper;
