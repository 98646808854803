import * as React from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import AppBar from '@material-ui/core/AppBar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MuiButton } from '@onehope/design-system-ohw';

const { cssConstants } = s;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  @media all and (min-width: 768px) {
    max-width: 736px;
  }
`;
export const Spacer = styled.span`
  width: 32px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'fixed',
      top: 'auto',
      bottom: 0,
      backgroundColor: 'white',
      padding: 16,
    },
  }),
);

interface FooterButtonsProps {
  handleNextStepChange: any;
  handlePrevStepChange: any;
  nextStepButtonText: string;
  prevStepButtonText: string;
  disableNext: boolean;
}

export default function FooterButtons(props: FooterButtonsProps) {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar}>
      <Container>
        <MuiButton
          type="SECONDARY"
          fullWidth
          onClick={props.handlePrevStepChange}
        >
          {props.prevStepButtonText}
        </MuiButton>
        <Spacer />
        <MuiButton
          fullWidth
          type="PRIMARY"
          disabled={props.disableNext}
          onClick={props.handleNextStepChange}
        >
          {props.nextStepButtonText}
        </MuiButton>
      </Container>
    </AppBar>
  );
}
