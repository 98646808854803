import React, { Fragment, Dispatch, SetStateAction, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system-ohw';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiFormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import IconButton from '@material-ui/core/IconButton';
import MuiAppsIcon from '@material-ui/icons/Apps';

import TabFilter from '../../TableComponents/TabFilter';
import CardListViewContainer from '../CardListViewContainer';
import SearchBar from '../../Events/EventsTable/SearchBar';
import TableListView from './TableBody';
// import CardListView from './CardListView';
import TablePagination from '../../TableComponents/Pagination';
import NoContacts from '../../Contacts/NoContacts';
import { CheckBoxToggleContextProvider } from '../../Contacts/CheckboxToggleContext';
import { CheckBoxToggle } from '../../Contacts/CheckBoxContainer/index';
import CreateConciergeServiceMutation from '../../../mutations/Account/CreateConciergeServiceMutation';

import { CEDash_CustomersQuery_viewer_user_customers_edges as Contacts } from '../../../queries/generatedTypes/CEDash_CustomersQuery';
import { segmentEvents } from '../../../utils/segment/constants';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';

const { cssConstants } = s;
const Container = styled.div`
  padding: 24px 16px;
  font-family: ${cssConstants.font};
  a {
    color: ${cssConstants.primaryColor};
  }
  @media all and (min-width: 768px) {
    padding: 40px;
  }
`;

const AppsIcon = styled(MuiAppsIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const FormatListBulletedIcon = styled(MuiFormatListBulletedIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const ToggleView = styled.div`
  width: 54px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  flex-direction: column;
  @media all and (min-width: 960px) {
    flex-direction: row;
    padding-bottom: 40px;
  }
`;

export const GlobalCss = withStyles({
  '@global': {
    '.MuiCheckbox-root': {
      marginRight: '5px',
      padding: '2px',
    },
    '.MuiCheckbox-colorSecondary': { color: '#c2c2c2' },
    '.MuiCheckbox-colorSecondary.Mui-checked': { color: 'black' },
  },
})(() => null);

interface ContactsTableProps {
  contacts: (Contacts | null)[] | null;
  page: number;
  filterBy: string;
  setPageNumber: Dispatch<SetStateAction<number | null | undefined>>;
  setFilterBy: () => void;
  handleSortContacts: () => void;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number | null | undefined>>;
  count: number;
  setSearchBy: Dispatch<SetStateAction<string | null | undefined>>;
}

export default function ContactsTable(props: ContactsTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    page,
    setPageNumber,
    contacts,
    setFilterBy,
    filterBy,
    handleSortContacts,
    setRowsPerPage,
    rowsPerPage,
    count,
    setSearchBy,
  } = props;

  const [isSearchingBy, setIsSearchingBy] = useState(false);
  const [isContactsTableView, setIsContactsTableView] = useState(
    localStorage.getItem('isContactsTableView')
      ? localStorage.getItem('isContactsTableView') === 'true'
      : true,
  );

  const { trackEventV2 } = useTrackingContextValue();

  const toggleView = () => {
    localStorage.setItem('isContactsTableView', `${!isContactsTableView}`);
    setIsContactsTableView(!isContactsTableView);
  };

  const [createConciergeService, { loading: mutationLoading }] = useMutation(
    CreateConciergeServiceMutation,
  );

  const handleOrderAdd = (email: string) => {
    const variables = {
      input: {
        customerEmail: email,
      },
    };
    return createConciergeService({ variables })
      .then(res => {
        const conciergeService = res?.data?.createConciergeService;
        if (!conciergeService) return;

        // segment tracking
        const isNewCustomer = conciergeService.newCustomer?.ordersCount === 0;
        const customerId = conciergeService.user?.conciergeCustomerAccountId;
        trackEventV2({
          event: segmentEvents.conciergeModeOrderStarted,
          properties: {
            eventId: null,
            shoppingForId: customerId,
            isNewCustomer,
          },
        });

        window.location.href = `${process.env.GATSBY_HOPECOMMERCE_URL}/shop/wine/wine`;

        return;
      })
      .catch(console.error);
  };

  const handleSearchContacts = (search: string) => {
    setSearchBy(search);
    setIsSearchingBy(true);
  };

  return (
    <Fragment>
      <GlobalCss />
      {(contacts && contacts.length > 0) || isSearchingBy ? (
        <Fragment>
          <CheckBoxToggleContextProvider>
            <Container>
              <Toolbar>
                <SearchBar
                  handleSearch={handleSearchContacts}
                  placeHolderText="Search contacts"
                />
                <TabFilter
                  filterBy={filterBy}
                  setFilterBy={setFilterBy}
                  tabValues={[]}
                />
                <CheckBoxToggle />
                {!isMobile && (
                  <ToggleView>
                    <IconButton onClick={toggleView}>
                      {isContactsTableView ? (
                        <AppsIcon />
                      ) : (
                        <FormatListBulletedIcon />
                      )}
                    </IconButton>
                  </ToggleView>
                )}
              </Toolbar>
              {isContactsTableView && !isMobile ? (
                <TableListView
                  page={page}
                  contacts={contacts}
                  handleOrderAdd={handleOrderAdd}
                  handleSortContacts={handleSortContacts}
                  rowsPerPage={rowsPerPage}
                  count={count}
                />
              ) : (
                <CardListViewContainer
                  contacts={contacts}
                  handleOrderAdd={handleOrderAdd}
                />
              )}
              <TablePagination
                page={page}
                count={count}
                rowsOnPage={(contacts && contacts.length) || 0}
                setPageNumber={setPageNumber}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                baseRoute={'contacts'}
              />
            </Container>
          </CheckBoxToggleContextProvider>
        </Fragment>
      ) : (
        <NoContacts openDialog={() => {}} openForContacts={true} />
      )}
    </Fragment>
  );
}
