import React, { Component } from 'react';

import { Query } from 'react-apollo';

import Loading from '../common/Loading';
import Layout from '../components/MainLayout/layout/layout';
import Addresses from '../components/Addresses';
import { getCurrentUserId } from '../utils/utils';
import GET_ADDRESSES_QUERY from '../queries/Addresses';

interface AddressesPageProps {
  location: any;
}

interface AddressesPageState {
  collapsed: boolean;
}

class AddressesPage extends Component<AddressesPageProps, AddressesPageState> {
  state = {
    collapsed: false,
  };

  render() {
    const { location } = this.props;
    const currentUser = getCurrentUserId(location);

    return (
      <Layout selectedUserId={currentUser}>
        <Query
          query={GET_ADDRESSES_QUERY}
          variables={{
            accountId: currentUser,
            csPortal: true,
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) return `Error! ${error.message}`;
            const {
              viewer: {
                userInfo,
                userInfo: { firstName, lastName },
              },
            } = data;
            return (
              <React.Fragment>
                <h2>{`${firstName} ${lastName}'s Addresses`}</h2>
                <Addresses
                  selectedUserId={currentUser}
                  addresses={userInfo.addressBook.entries.edges}
                />
              </React.Fragment>
            );
          }}
        </Query>
      </Layout>
    );
  }
}

export default AddressesPage;
