import React from 'react';
import PaymentsTable from './PaymentsTable/PaymentsTable';

interface PaymentsProps {
  payments: any;
  selectedUserId: string;
}

const Payments: React.FunctionComponent<PaymentsProps> = ({
  payments,
  selectedUserId,
}) => <PaymentsTable payments={payments} selectedUserId={selectedUserId} />;
export default Payments;
