import SelectCore from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import styled from '@emotion/styled';
import { MuiInputSelect } from '@onehope/design-system-ohw';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { styles } from '@onehope/design-system';

const { styled: s, cssConstants } = styles;

export type ButtonProps = {
  disabled?: boolean;
  width?: string;
  hasError?: string;
  showDefault?: boolean;
  hasSuccess?: boolean | undefined;
};

export const MiniSelect = withStyles({
  icon: {
    width: 50,
    height: 50,
  },
})(styled(SelectCore)<{ hasSuccess: boolean }>`
  && {
    background-color: ${({ hasSuccess }: { hasSuccess: boolean }) =>
      hasSuccess ? 'white ' : `${cssConstants.neutral2}`};
    :focus {
      background-color: ${({ hasSuccess }: { hasSuccess: boolean }) =>
        hasSuccess ? 'white ' : `${cssConstants.neutral2}`};
    }
  }
`);

export const SelectInput = styled(MuiInputSelect)<{
  width: string;
  hasError: boolean;
  title: string;
}>`
  && {
    width: ${({ width }: ButtonProps) => `${width}px`};
    height: 48px;
    font-family: ${cssConstants.font};
    font-size: 14px;
    line-height: 18px;
    color: ${cssConstants.textColor};
    border-bottom: ${({ hasError }: ButtonProps) =>
      hasError
        ? `2px solid ${cssConstants.errorColor}`
        : `1px solid ${cssConstants.primaryColor}`};
    background-color: ${({ disabled }: ButtonProps) =>
      disabled ? 'white ' : `${cssConstants.neutral2}`};
    :hover,
    :focus {
      background-color: ${({ hasSuccess }: { hasSuccess: boolean }) =>
        hasSuccess ? 'white ' : `${cssConstants.neutral2}`};
    }
    & .MuiSelect-select:focus {
      background-color: ${({ hasSuccess }: { hasSuccess: boolean }) =>
        hasSuccess ? 'white ' : `${cssConstants.neutral2}`};
    }
  }
`;

export const ArrowDropDown = styled(ArrowDropDownIcon)`
  && {
    fill: ${cssConstants.primaryColor};
    margin-top: -2px;
    margin-right: -3px;
    height: 25px;
  }
`;

export const OptionLabel = styled.div`
  padding-left: 16px;
`;

export const DefaultOption = styled.div`
  width: 103.7px;
  /* height: 18px; */
  opacity: 0.5;
  /* font-family: ProximaNova-It; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #1a1a1a;
  padding-left: 16px;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

export const Label = styled.label`
  font-family: ${cssConstants.font};
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: ${cssConstants.neutral34};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding-right: 9px;
  @media all and (min-width: 768px) {
    padding-right: 16px;
    margin-bottom: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  @media all and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
