import { makeStyles, ThemeType } from '@onehope/design-system-v2';

const useStyles = makeStyles((theme: ThemeType) => ({
  wrapper: {
    boxShadow: '0 0 20px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px 12px 12px 12px',
    width: '342px',
    height: '439px',
    margin: '24px auto',
    padding: '0px',
    [theme.breakpoints.down('sm')]: {
      margin: '38px auto',
      boxShadow: '0 0 10px 5px rgba(0, 0, 0, 0.1)',
      width: '288px',
    },
  },
  image: {
    borderRadius: '12px 12px 0px 0px',
    height: '193px !important',
    width: '342px',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '288px',
      height: '163px !important',
    },
  },
  player: {
    borderRadius: '12px 12px 0px 0px !important',
    height: '193px !important',
    width: '342px !important',
    [theme.breakpoints.down('sm')]: {
      width: '288px !important',
      height: '163px !important',
    },
    '& .react-player__preview': {
      borderRadius: '12px 12px 0px 0px !important',
    },
  },
  dropdown: {
    display: 'inline-block',
    float: 'right',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuItem: {
    fontFamily: theme.bodyFontFamily,
    fontSize: '14px',
    fontWeight: 300,
    backgroundColor: 'white !important',
    '&:hover': {
      fontWeight: 500,
    },
  },
  bodyWrapper: {
    width: '310px',
    padding: '0px',
    margin: '0 auto',
    height: '104px',
    borderRadius: '0px 0px 12px 12px',
    [theme.breakpoints.down('sm')]: {
      width: '256px',
      fontSize: '16px',
      lineHeight: '24px',
      height: '128px',
    },
  },
  titleWrapper: {
    width: '310px',
    margin: '16px 16px 8px 16px',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      width: '256px',
    },
  },
  textWrapper: {
    height: '246px',
    width: '342px',
    textAlign: 'left',
    borderRadius: '0px 0px 12px 12px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      width: '288px',
    },
  },
  playIcon: {
    height: '48px',
    width: '51px',
    backgroundColor: 'Transparent',
    padding: 0,
    border: 0,
  },
}));

export default useStyles;
