import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import styled from '@emotion/styled';
import DialogRoot from '@material-ui/core/Dialog';
import AddCustomerForm from './AddCustomerForm/AddCustomerForm';
import AddOrderForm from '../../../Events/AddOrderForm/AddOrderForm';
import ShopWithCustomerMutation from '../../../../mutations/Customer/CustomerShopMutation';
import { MyFormValues } from '../../../Events/AddOrderForm/MyFormTypes';
import { useTrackingContextValue } from '../../../../contexts/TrackingContext';
import { segmentEvents } from '../../../../utils/segment/constants';

interface AddOrderDialogProps {
  selectedEventId?: string | null;
  isOpen?: boolean;
  handleClose?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddOrderDialogContainer = styled(DialogRoot)`
  &.MuiDialog-root {
    /* z-index: 9000 !important; */
    min-height: 520px;
  }
`;

const FormContainer = styled.div`
  margin: auto;
  padding: 0 16px;
`;

export default function AddOrderDialog(props: AddOrderDialogProps) {
  const { isOpen, selectedEventId } = props;
  const [defaultValues, setDefaultValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [isCustomerSelected, setIsCustomerSelected] = useState(false);
  const [addNewCustomer, setAddNewCustomer] = useState(false);
  const [shopWithCustomer, { loading: mutationLoading }] = useMutation(
    ShopWithCustomerMutation,
  );

  const { trackEventV2 } = useTrackingContextValue();
  /* FUNCTIONS */
  const handleCustomerSelect = (values: any) => {
    const { accountId } = values;
    const variables = {
      input: {
        customerId: accountId,
        eventId: props.selectedEventId,
      },
    };
    return shopWithCustomer({ variables })
      .then(res => {
        const shopWithCustomer = res?.data?.shopWithCustomer;
        if (!shopWithCustomer) return;

        // segment tracking
        const isNewCustomer = shopWithCustomer.newCustomer?.ordersCount === 0;
        trackEventV2({
          event: segmentEvents.conciergeModeOrderStarted,
          properties: {
            eventId: props.selectedEventId || null,
            shoppingForId: accountId,
            isNewCustomer,
          },
        });

        window.location.href = `${process.env.GATSBY_TASTING_FLIGHT_HOST}`;
        return;
      })
      .catch(console.error);
  };

  const toggleAddCustomer = (values: MyFormValues) => {
    setDefaultValues(values);
    setAddNewCustomer(!addNewCustomer);
  };

  function handleNewCustomerClose() {
    props.handleClose();
    setTimeout(() => {
      setIsCustomerSelected(false);
      setAddNewCustomer(false);
    }, 500);
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <FormContainer>
      <AddOrderDialogContainer
        disableEnforceFocus
        open={isOpen}
        fullScreen={fullScreen}
        onClose={handleNewCustomerClose}
      >
        <div>
          {addNewCustomer ? (
            <AddCustomerForm
              formTitle={'New Customer'}
              addCustomerButtonText={'add & start order'}
              existingCustomerButtonText={'add & start order'}
              onClose={handleNewCustomerClose}
              handleSelectedCustomer={handleCustomerSelect}
              selectedEventId={selectedEventId}
              defaultValues={defaultValues}
            />
          ) : (
            <AddOrderForm
              onClose={handleNewCustomerClose}
              handleCustomerSelect={handleCustomerSelect}
              isCustomerSelected={isCustomerSelected}
              setIsCustomerSelected={setIsCustomerSelected}
              mutationLoading={mutationLoading}
              toggleAddCustomer={toggleAddCustomer}
              defaultValues={{
                fullName: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                accountId: '',
              }}
            />
          )}
        </div>
      </AddOrderDialogContainer>
    </FormContainer>
  );
}
