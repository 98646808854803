/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import styled from '@emotion/styled';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import get from 'lodash/get';
import { styles as s } from '@onehope/design-system';

import Columns from '../Columns';
import TableHeader from '../../../TableComponents/TableListView/TableHeader';
import { isCreditExpired } from '../helpers';
import {
  CreditsPage_user_credits_edges as CreditsEdge,
} from '../../../../queries/generatedTypes/CreditsPage_user';

const { cssConstants } = s;

const TableRow = styled(MuiTableRow)`
  height: 68px !important;
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

const TableCell = styled(MuiTableCell)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
  }
`;

const noSortByList = {
  eventDetails: true,
  'event.hostFullName': true,
};

interface TableBodyContainerProps {
  credits: (CreditsEdge | null)[] | null;
  handleSortCredits: (sortBy: string) => void;
}

export default function TableBodyContainer(props: TableBodyContainerProps) {
  const { credits, handleSortCredits } = props;

  return (
    <>
      <TableHeader
        handleSort={handleSortCredits}
        columns={Columns}
        noSortByList={noSortByList}
      />
      <TableBody>
        {credits?.map((credit: CreditsEdge | null, i: number) => {
          return (
            <TableRow hover role="checkbox" key={`${credit?.node?.creditId}-${i}`}>
              {Columns.map(column => {
                const value =
                  column.id === 'status' && isCreditExpired(credit)
                    ? 'expired'
                    : column.id === 'earnedFromEventId'
                      ? credit?.node?.earnedFromEvent?.trinityPartyId
                      : get(credit?.node, `${column.id}`);
                const timeZone = 'America/Chicago';
                const eventId = credit?.node?.eventId;
                const earnedFromEventId = credit?.node?.earnedFromEventId;
                return (
                  <TableCell key={column.id} align={column.align}>
                    {column.format &&
                      column.format(value, timeZone, earnedFromEventId)}
                    {column.render && column.render({ eventId })}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
}
