import * as React from 'react';
import { MuiToolTip } from '@onehope/design-system-ohw';

import { Line, Value } from '../../../Event/TabContent/index.styles';
import { Address } from '../../../Events/EventDetailsForm/EventDetailsForm';
import {
  WideKey,
  Title,
  AddressContainer,
  AddressValue,
  ToolTipWrapper,
} from './index.styles';
import { useImageStyles } from '../../../Events/commonStyles';

interface SummaryLineProps {
  title: string;
  value?: string | JSX.Element | null;
  address?: Address;
  valueToolTipText?: string;
  imgSrc?: string;
}

const getToolTipText = (title: string) => {
  switch (title) {
    case 'Total impact': {
      return 'Begins on their Preferred Customer sign-up date and restarts at $0 on their renewal date. ';
    }
    default: {
      return null;
    }
  }
};

export default function SummaryLine(props: SummaryLineProps) {
  const { title, value, address, valueToolTipText, imgSrc } = props;
  const toolTipText = getToolTipText(title);

  const imageClasses = useImageStyles(imgSrc)();

  return (
    <Line>
      <WideKey>
        <Title>{title}:</Title>
        {toolTipText && (
          <MuiToolTip
            title={toolTipText}
            placement="top"
            enterDelay={100}
            leaveDelay={200}
            style={{ fontSize: 17 }}
          />
        )}
      </WideKey>
      {address && (
        <AddressContainer>
          <AddressValue>{address?.addressLineOne}</AddressValue>
          <AddressValue>{address?.addressLineTwo}</AddressValue>
          <AddressValue>
            {address?.city}, {address?.state} {address?.zip}
          </AddressValue>
        </AddressContainer>
      )}
      {imgSrc && <div className={imageClasses.image} />}
      {value && <Value>{value}</Value>}
      {valueToolTipText && (
        <ToolTipWrapper>
          <MuiToolTip
            title={valueToolTipText}
            arrow
            placement="top"
            enterDelay={500}
            leaveDelay={200}
            style={{ fontSize: 17 }}
          />
        </ToolTipWrapper>
      )}
    </Line>
  );
}
