import React, { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { DateTime } from 'luxon';
import styled from '@emotion/styled';
import { styles } from '@onehope/design-system';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CONTACTS_ADD_BULK from '../../../mutations/Customer/ContactsAddBulkMutation';
import Loading from '../../../common/Loading';
import _orderBy from 'lodash/orderBy';
import { SegmentTrackContactProps } from '../../../utils/segment/tracking';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';
import { SvgImport } from '../../../assets/import';
const { cssConstants } = styles;

interface Email {
  address: string;
  type: string;
}

interface Address {
  formatted: string;
  street: string;
  city: string;
  region: string;
  country: string;
  postal_code: string;
  type: string;
}

interface Contact {
  first_name: string;
  last_name: string;
  email: Email[];
  phone: string[];
  address: Address[] | undefined;
  companies: string[];
  dob: string; // YYYY-MM-DD
  selectedEmail: () => string;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 288px;
`;

const ImportContactsLink = styled.div`
  height: 18px;
  margin-right: 10px;
  /* font-family: ProximaNova; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${cssConstants.linkColor};
  :hover {
    cursor: pointer;
  }
`;

function formatContacts(contacts: Contact[]) {
  return contacts.map(contact => {
    if (contact?.email) {
      return {
        firstName: contact.first_name,
        lastName: contact.last_name,
        email: contact.selectedEmail(),
        shippingAddress: contact?.address.length
          ? {
              addressLineOne: contact?.address[0]?.street,
              state: contact?.address[0]?.region,
              city: contact?.address[0]?.city,
              zip: contact?.address[0]?.postal_code,
            }
          : null,
        companies: contact.companies,
        dob: contact?.dob
          ? DateTime.fromFormat(contact?.dob, 'yyyy-MM-dd').toLocaleString(
              DateTime.DATE_SHORT,
            )
          : null,
      };
    }
  });
}

const handleUploadContacts = async (
  contacts: Contact[],
  contactsAddBulk: any,
  resetContacts: (input: any) => void,
  trackContacts: (props: SegmentTrackContactProps) => void,
  // source: string,
  // owner: any,
) => {
  trackContacts({
    eventName: 'CE Started Contact Import',
  });
  await contactsAddBulk({
    variables: {
      input: { contacts: formatContacts(contacts) },
    },
  })
    .then((data: any) => {
      console.log('data', data);
      const {
        data: {
          contactsAddBulk: {
            user: { customers },
          },
        },
      } = data;
      trackContacts({
        eventName: 'CE Completed Contact Import',
        // TODO (monica): update to use real values
        contactImportStats: {
          totalImported: 0,
          successfulImports: 0,
          failedImports: 0,
        },
      });
      const sortedCustomers = _orderBy(
        customers.edges,
        [e => e?.node?.createDate],
        ['desc'],
      );
      return resetContacts(sortedCustomers);
    })
    .catch((errors: any) => {
      console.log('errors', errors);
      return;
    });
};

interface ImportContactsProps {
  resetContacts: (input: any) => void;
}

export default function ImportContacts(props: ImportContactsProps) {
  const { resetContacts } = props;
  const assetUrl = process.env.GATSBY_S3_ASSETS;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [
    contactsAddBulk,
    { loading: contactsAddBulkMutationLoading },
  ] = useMutation(CONTACTS_ADD_BULK);

  const { trackContacts } = useTrackingContextValue();

  useEffect(() => {
    return cloudsponge.init({
      css: isMobile
        ? `${assetUrl}/cloudSpongeMobile.css`
        : `${assetUrl}/cloudSponge.css`,
      sources: [
        'gmail',
        'icloud',
        'yahoo',
        'aol',
        'addressbook',
        'windowslive',
        'outlook',
        'office365',
        'csv',
      ],
      include: ['mailing_address', 'name', 'email'],
      afterImport: function(source: string, success: string) {
        // use this for failed import logging
        if (!success) {
          alert(
            'There was a problem importing your contacts from ' + source + '.',
          );
        }
      },
      afterSubmitContacts: (contacts: Contact[]) =>
        handleUploadContacts(
          contacts,
          contactsAddBulk,
          resetContacts,
          trackContacts,
        ),
      filter: function(contact: Contact) {
        return !!contact.email.length;
      },
    });
  }, [contactsAddBulk, resetContacts, trackContacts, assetUrl, isMobile]);

  if (contactsAddBulkMutationLoading) return <Loading />;

  const openWidget = () => {
    return cloudsponge.launch();
  };

  return (
    <Container>
      <SvgImport />
      <ImportContactsLink onClick={openWidget}>
        Import contacts
      </ImportContactsLink>
    </Container>
  );
}
