import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormikProps, FastField } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';
import get from 'lodash/get';
import { isEmpty } from 'lodash';

/* Components */
import AutoComplete from '../EventDetailsForm/AutoComplete';
import { RadioButton, RadioButtonGroup } from '../../../common/RadioButton';
import SaveButtons from '../../Dialog/EventCreateDialog/SaveButtons';
import FooterButtons from '../../Dialog/EventCreateDialog/FooterButtons';
import CausePartnerDropDown from './CausePartnerDropDown';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/* Mutations & Queries */
import AddressAutoCompleteAddressMutation from '../../../mutations/Event/AddressFormAddressAutoCompleteMutation';
import AddressFormGooglePlacesZipMutation from '../../../mutations/Event/AddressFormGooglePlacesZipMutation';
import NONPROFIT_AUTOCOMPLETE_QUERY from '../../../queries/NonProfitIRS';

/* Types */
import { MyFormValues } from '../../Event/TabContent/Charity/FormTypes';
import {
  CEDash_EventDetailsPage_viewer_event as EventType,
  CEDash_EventDetailsPage_viewer_event_donationInfo as DonationInfoType,
} from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { CEDash_NonProfitsQuery_viewer_user_nonProfits as NonProfitsType } from '../../../queries/generatedTypes/CEDash_NonProfitsQuery';
import {
  CEDash_NonProfitIRSQuery as NonProfitsIRSData,
  CEDash_NonProfitIRSQuery_viewer_nonProfitsIRS_edges as NonProfitsIRSEdges,
} from '../../../queries/generatedTypes/CEDash_NonProfitIRSQuery';

/* Helpers & Functions */
import useToggle from '../../../utils/useToggleHook';
import { allowedStates, statesAbbrev } from '../../../utils/statesWeCanShipTo';
import { formatAddress } from '../../Event/TabContent/Charity/helpers';
import { focusErrors } from '../EventDetailsForm/submitHelpers';
import {
  EventStatusDictionary,
  CharityTypeDictionary,
} from '../../../utils/enums';

/* Styles */
import { styles } from '@onehope/design-system';
import { MuiInputAutosize } from '@onehope/design-system-ohw';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { ToggleLabel } from '../../Event/TabContent/index.styles';
import {
  MiniSelect,
  SelectInput,
  DefaultOption,
  OptionLabel,
  ArrowDropDown,
  MenuProps,
  Col,
} from '../EventDetailsForm/index.styles';
import {
  MedInput,
  SearchLink,
  getManualAddress,
} from '../EventDetailsForm/EventDetailsForm';
import {
  Title,
  CustomNonProfitWrapper,
  LongInput,
  WrapContainer,
  StateContainer,
  DonationContainer,
  DonationAmount,
  DonationSlider,
  DonationBaselineContainer,
  IconContainer,
  DonationBaseline,
  Details,
} from './index.styles';
import CurrencyMask from './CurrencyMask';
import {
  CheckBoxIcon,
  StyledTypography,
} from '../../Contact/TabContent/Details/Form';

const { styled: s } = styles;

interface Address {
  addressId: string;
  addressLineOne: string;
  addressLineTwo?: string;
  city: string;
  country: string;
  id: string;
  placeId: string;
  state: string;
  zip?: string;
}

interface CharityDetailsFormProps {
  event: EventType;
  donation: DonationInfoType;
  nonProfits: NonProfitsType;
  editing: boolean;
  setIsNotEditing: () => void;
  handlePreviousStepChange: () => void;
}

type FormProps = {
  setIsNotEditing: () => void;
  mutationLoading: boolean;
};

const formatIRSNonProfits = (data: NonProfitsIRSData) => {
  return (
    get(data, 'viewer.nonProfitsIRS.edges') &&
    get(data, 'viewer.nonProfitsIRS.edges').map(
      (nonProfit: NonProfitsIRSEdges) => nonProfit.node,
    )
  );
};

export default function CharityDetailsForm(
  props: FormProps & FormikProps<MyFormValues> & CharityDetailsFormProps,
) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleReset,
    handleSubmit,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    handlePreviousStepChange,
    editing,
    mutationLoading,
    donation,
    event,
    setIsNotEditing,
  } = props;
  const {
    charityType,
    preApprovedNonProfit,
    customNonProfit,
    charityDonationGoal,
    charityQuote,
    attentionToName,
    displayAddress,
    addressLineOne,
    addressLineTwo,
    state,
    city,
    zip,
    nonProfitTaxId,
    lobMemo,
    showDonationGoal,
  } = values;

  // Not using for loop to avoid warning
  useEffect(() => {
    localStorage.setItem('charityType', charityType ? charityType : '');
    setFieldTouched('charityType', true, false);
  }, [charityType, setFieldTouched]);

  useEffect(() => {
    localStorage.setItem(
      'preApprovedNonProfit',
      preApprovedNonProfit ? preApprovedNonProfit : '',
    );
  }, [preApprovedNonProfit]);

  useEffect(() => {
    localStorage.setItem(
      'customNonProfit',
      customNonProfit ? customNonProfit : '',
    );
  }, [customNonProfit]);

  useEffect(() => {
    localStorage.setItem(
      'charityDonationGoal',
      charityDonationGoal ? charityDonationGoal : '',
    );
  }, [charityDonationGoal]);

  useEffect(() => {
    localStorage.setItem(
      'showDonationGoal',
      showDonationGoal ? showDonationGoal : '',
    );
  }, [showDonationGoal]);

  useEffect(() => {
    localStorage.setItem('charityQuote', charityQuote ? charityQuote : '');
  }, [charityQuote]);

  useEffect(() => {
    localStorage.setItem(
      'attentionToName',
      attentionToName ? attentionToName : '',
    );
  }, [attentionToName]);

  useEffect(() => {
    localStorage.setItem(
      'charityDisplayAddress',
      displayAddress ? displayAddress : '',
    );
  }, [displayAddress]);

  useEffect(() => {
    localStorage.setItem(
      'charityAddressLineOne',
      addressLineOne ? addressLineOne : '',
    );
  }, [addressLineOne]);

  useEffect(() => {
    localStorage.setItem(
      'charityAddressLineTwo',
      addressLineTwo ? addressLineTwo : '',
    );
  }, [addressLineTwo]);

  useEffect(() => {
    localStorage.setItem('charityCity', city ? city : '');
  }, [city]);

  useEffect(() => {
    localStorage.setItem('charityState', state ? state : '');
  }, [state]);

  useEffect(() => {
    localStorage.setItem('charityZip', zip ? zip : '');
  }, [zip]);

  useEffect(() => {
    localStorage.setItem(
      'nonProfitTaxId',
      nonProfitTaxId ? nonProfitTaxId : '',
    );
  }, [nonProfitTaxId]);
  // /* STATE */
  const [isAddressMenuOpen, setIsAddressMenuOpen] = useState(false);
  const [isNonProfitMenuOpen, setIsNonProfitMenuOpen] = useState(false);
  const [addressPredictions, setAddressPredictions] = useState([]);
  const [manualAddress, setManualAddress] = useState(
    getManualAddress(editing, 'manualCharityAddress', event?.eventId),
  );
  const [manualNonProfit, setManualNonProfit] = useState(
    editing && localStorage.getItem(`manualNonProfit${event.eventId}`)
      ? localStorage.getItem(`manualNonProfit${event.eventId}`) === 'true'
      : false,
  );
  const {
    value: showDefault,
    setTrue: setShowDefaultTrue,
    setFalse: setShowDefaultFalse,
  } = useToggle(!preApprovedNonProfit);
  const {
    value: showStartAdornment,
    setTrue: setShowStartAdornmentTrue,
    setFalse: setShowStartAdornmentFalse,
  } = useToggle(!!charityDonationGoal);

  useEffect(() => {
    editing
      ? localStorage.setItem(
          `manualCharityAddress${event.eventId}`,
          manualAddress ? 'true' : 'false',
        )
      : localStorage.setItem(
          `manualCharityAddress`,
          manualAddress ? 'true' : 'false',
        );
  }, [manualAddress]);

  useEffect(() => {
    editing
      ? localStorage.setItem(
          `manualNonProfit${event.eventId}`,
          manualNonProfit ? 'true' : 'false',
        )
      : localStorage.setItem(
          `manualNonProfit`,
          manualNonProfit ? 'true' : 'false',
        );
  }, [manualNonProfit]);

  useEffect(() => {
    focusErrors({ errors, isSubmitting, manualAddress });
  }, [errors, isSubmitting, manualAddress]);

  const [nonProfitsIRS, setNonProfitsIRS] = useState([]);

  // /*  QUERIES */
  const { refetch: getIRSNonProfits } = useQuery(NONPROFIT_AUTOCOMPLETE_QUERY, {
    variables: { search: '' },
    skip: true,
  });

  /* MUTATIONS */
  const [
    addressAutoComplete,
    { loading: mutationAddressAutoCompleteLoading },
  ] = useMutation(AddressAutoCompleteAddressMutation);

  const [
    googlePlacesZip,
    { loading: mutationGooglePlacesZipLoading },
  ] = useMutation(AddressFormGooglePlacesZipMutation);

  const { hostKitsV2, no500DonationThreshold } = useFlags();

  /* FUNCTIONS */
  const change = (name: any, e: React.SyntheticEvent) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const handleNonProfitAddressManual = (selectedItem: any) => {
    closeMenu('displayAddress');
    const { addressLineOne, city, state, zip, placeId } = selectedItem;
    setFieldValue('addressLineOne', addressLineOne);
    setFieldValue('city', city);
    setFieldValue('zip', zip);
    const validState = statesAbbrev.filter(
      approvedState => state === approvedState,
    );
    if (validState.length || state === '') {
      setFieldValue('state', state);
    }
    if (!zip) {
      const variables = {
        input: {
          placeId: placeId,
        },
      };
      return googlePlacesZip({ variables }).then(
        ({
          data: {
            googlePlacesZip: { zip },
          },
        }) => {
          setFieldValue('zip', zip || '');
          handleSelectChange(
            'displayAddress',
            formatAddress({ addressLineOne, city, zip: zip || '' }),
          );
        },
      );
    }
    handleSelectChange(
      'displayAddress',
      formatAddress({ addressLineOne, city, zip }),
    );
  };

  const handleSelectChange = (field: string, selectedItem: string) => {
    closeMenu(field);
    setFieldValue(field, selectedItem);
    setFieldTouched(field, true, false);
  };

  const handleCustomNonProfitSelected = (selectedItem: NonProfitIRSNode) => {
    closeMenu('customNonProfit');
    const { name, nonProfitId, street, city, state, zip } = selectedItem;
    setFieldValue('customNonProfit', name);
    setFieldValue('nonProfitTaxId', nonProfitId);
    setFieldValue('addressLineOne', street);
    setFieldValue('city', city);
    setFieldValue('zip', zip);
    setFieldValue('displayAddress', `${street}, ${city}, ${zip}`);
    const validState = statesAbbrev.filter(
      approvedState => state === approvedState,
    );
    if (validState.length || state === '') {
      setFieldValue('state', state);
    }
  };

  const handleOuterClick = (field: string) => {
    setFieldValue(field, '');
    closeMenu(field);
  };

  const closeMenu = (field: string) => {
    switch (field) {
      case 'customNonProfit': {
        setIsNonProfitMenuOpen(false);
        break;
      }
      case 'displayAddress': {
        setIsAddressMenuOpen(false);
        break;
      }
    }
  };

  const noop = () => {};

  const handleCustomNonProfitChange = async (
    event: React.ChangeEvent<{ value: string }>,
  ) => {
    event.preventDefault();
    change('nonProfitName', event);
    const search = event.target.value;
    if (search && search !== '') {
      setIsNonProfitMenuOpen(true);
      await getIRSNonProfits({ search }).then(results => {
        if (get(results, 'data.viewer')) {
          setNonProfitsIRS(formatIRSNonProfits(results.data));
        }
      });
    } else {
      setIsNonProfitMenuOpen(false);
    }
  };

  const handleSetValue = (field: string, selectedItem: string) => {
    setFieldValue(field, selectedItem);
    setFieldTouched(field, true, false);
  };

  const handleAddressChange = async (
    event: React.ChangeEvent<{ value: string }>,
  ) => {
    event.preventDefault();
    change('displayAddress', event);
    const searchTerm = event.target.value;
    if (!searchTerm) {
      handleSetValue('addressLineOne', '');
      handleSetValue('city', '');
      handleSetValue('zip', '');
    }
    const variables = {
      input: {
        query: searchTerm,
      },
    };
    await addressAutoComplete({
      variables,
    }).then((res: any) => {
      const { data } = res;
      setIsAddressMenuOpen(true);
      const predictions =
        data &&
        data.addressAutoComplete &&
        data.addressAutoComplete.addressPredictions;
      let allowedAddresses;
      if (predictions) {
        allowedAddresses = predictions.filter((address: Address) => {
          return allowedStates.includes(address.state);
        });
      }
      const listData = allowedAddresses || [];
      const truthyList = listData.filter((address: Address) => {
        return !!address;
      }) as Address[];
      setAddressPredictions(truthyList);
    });
  };

  const textChangeValid = (name: string, event: React.SyntheticEvent) => {
    return name === 'charityQuote'
      ? event.target.value.length <= 600
      : event.target.value.length <= 40;
  };

  const toggleShowDefault = (event: React.SyntheticEvent) => {
    if (!event.target.value) return setShowDefaultTrue();
    return setShowDefaultFalse();
  };

  const onCancel = () => {
    handleReset();
    return setIsNotEditing();
  };

  function toggleAddManualAddress() {
    handleSelectChange(
      'addressLineOne',
      addressLineOne || displayAddress || '',
    );
    handleSelectChange('displayAddress', '');
    setManualAddress(!manualAddress);
  }

  function toggleAddManualNonProfit() {
    setManualNonProfit(!manualNonProfit);
  }

  const toggleCheckbox = (name: any, e: React.SyntheticEvent) => {
    e.persist();

    setFieldValue(name, e.target.checked?.toString());
    setFieldTouched(name, true, false);

    if (name === 'showDonationGoal') {
      setFieldValue('charityDonationGoal', '');
    }
  };

  const { nonProfits } = props;
  const disableBookEvent = charityType === '';
  const qualified = donation?.ordersSubTotal > 500;
  const preApprovedNotSelected = !donation?.nonProfit?.isPreApproved;
  const notAcceptingOrders =
    event?.trinityPartyStatus === EventStatusDictionary.CLOSED;
  const editable = editing
    ? event?.status === EventStatusDictionary.OPEN
    : true;
  const selectCausePartnerRequired =
    !qualified &&
    preApprovedNotSelected &&
    notAcceptingOrders &&
    editable &&
    !no500DonationThreshold;
  const isOHWCausePartner =
    charityType === CharityTypeDictionary.OHW_PARTNER ||
    selectCausePartnerRequired;
  const isCustomNonProfit =
    charityType === CharityTypeDictionary.CUSTOM && !selectCausePartnerRequired;
  const isSkip = charityType === CharityTypeDictionary.SKIP;
  const charityDetails =
    ((isOHWCausePartner || isCustomNonProfit) && !selectCausePartnerRequired) ||
    isSkip;
  const hostQuotePlaceholder =
    'Enter a message from your host that will appear on their event’s landing page. Ask them “Why you are raising funds for this cause?” A strong why yields stronger support.';
  const lobMemoPlaceholder = 'Write a quick note for your donation check';
  const taxIdToolTipText =
    'A nine-digit tax number that IRS assigns to nonprofits, charities, organizations, and businesses.';
  const showOptionalSteps =
    isCustomNonProfit || (isOHWCausePartner && preApprovedNonProfit) || isSkip;
  const showDonationGoalInput = showDonationGoal === 'true';

  const disableSave =
    charityType === '' ||
    (charityType === 'OHW-PARTNER' && !preApprovedNonProfit) ||
    (!qualified && isEmpty(touched));

  return (
    <form onSubmit={handleSubmit}>
      {!editing && (
        <s.LoginHeader css>
          Enter the charity details{' '}
          <span role="img" aria-labelledby="red-heart">
            ❤️
          </span>
        </s.LoginHeader>
      )}
      {!selectCausePartnerRequired && editable && (
        <>
          <ToggleLabel>Select a charity to support</ToggleLabel>
          <RadioButtonGroup
            id="charityGroup"
            label=""
            value={charityType}
            error={errors.charityType}
            touched={touched.charityType}
          >
            <FastField
              component={RadioButton}
              name="charityType"
              id="OHW-PARTNER"
              label="ONEHOPE cause partner"
            />
            <FastField
              component={RadioButton}
              name="charityType"
              id="CUSTOM"
              label="Custom 501(c)3"
            />
            {!editing && (
              <FastField
                component={RadioButton}
                name="charityType"
                id="SKIP"
                label="Skip for now"
              />
            )}
          </RadioButtonGroup>
        </>
      )}
      {editable && (
        <>
          {isOHWCausePartner && (
            <CausePartnerDropDown
              showDefault={showDefault}
              onChange={(e: React.SyntheticEvent) => {
                toggleShowDefault(e);
                change('preApprovedNonProfit', e);
              }}
              value={preApprovedNonProfit}
              id="preApprovedNonProfit"
              nonProfits={nonProfits}
              error={
                touched.preApprovedNonProfit &&
                Boolean(errors.preApprovedNonProfit)
              }
            />
          )}
          {isCustomNonProfit && (
            <CustomNonProfitWrapper>
              {manualNonProfit ? (
                <>
                  <LongInput
                    label="Nonprofit"
                    placeholder="Enter 501(c)3 name"
                    id="customNonProfit"
                    name="customNonProfit"
                    value={customNonProfit}
                    fullWidth
                    validate
                    hasError={
                      !isSubmitting &&
                      touched.customNonProfit &&
                      Boolean(errors.customNonProfit)
                    }
                    hasSuccess={
                      editing
                        ? !isSubmitting &&
                          ((isEmpty(touched) && isEmpty(errors)) ||
                            !Boolean(errors.customNonProfit))
                        : !isSubmitting && !Boolean(errors.customNonProfit)
                    }
                    error={
                      touched.customNonProfit && Boolean(errors.customNonProfit)
                    }
                    helperText={
                      errors.customNonProfit &&
                      touched.customNonProfit &&
                      errors.customNonProfit
                    }
                    onChange={(e: React.SyntheticEvent) => {
                      if (textChangeValid('customNonProfit', e)) {
                        change('customNonProfit', e);
                      }
                    }}
                  />
                  <SearchLink onClick={toggleAddManualNonProfit}>
                    Use nonprofit search
                  </SearchLink>
                </>
              ) : (
                <AutoComplete
                  id="customNonProfitDropDown"
                  inputId="customNonProfit"
                  label="Nonprofit name"
                  placeholder="Search for 501(c)3 nonprofit"
                  value={values.customNonProfit}
                  error={errors.customNonProfit}
                  touched={touched.customNonProfit}
                  isSubmitting={isSubmitting}
                  isMenuOpen={isNonProfitMenuOpen}
                  validate={isCustomNonProfit}
                  handleCustomNonProfitSelected={handleCustomNonProfitSelected}
                  toggleAddManualNonProfit={toggleAddManualNonProfit}
                  handleInputChange={handleCustomNonProfitChange}
                  handleOuterClick={handleOuterClick}
                  objectPredictions={nonProfitsIRS}
                  editing={editing}
                />
              )}
              <LongInput
                label="Attention to (optional):"
                placeholder="First and last name"
                id="attentionToName"
                name="attentionToName"
                value={attentionToName}
                fullWidth
                validate
                hasError={
                  !isSubmitting &&
                  touched.attentionToName &&
                  Boolean(errors.attentionToName)
                }
                hasSuccess={
                  editing
                    ? !isSubmitting &&
                      ((isEmpty(touched) && isEmpty(errors)) ||
                        !Boolean(errors.attentionToName))
                    : !isSubmitting && !Boolean(errors.attentionToName)
                }
                error={
                  touched.attentionToName && Boolean(errors.attentionToName)
                }
                helperText={
                  errors.attentionToName &&
                  touched.attentionToName &&
                  errors.attentionToName
                }
                onChange={(e: React.SyntheticEvent) => {
                  if (textChangeValid('attentionToName', e)) {
                    change('attentionToName', e);
                  }
                }}
              />
              {manualAddress ? (
                <>
                  <LongInput
                    label="Address 1"
                    placeholder="Enter address"
                    id="addressLineOne"
                    name="addressLineOne"
                    value={addressLineOne}
                    fullWidth
                    hasError={
                      !isSubmitting &&
                      touched.addressLineOne &&
                      Boolean(errors.addressLineOne)
                    }
                    error={
                      touched.addressLineOne && Boolean(errors.addressLineOne)
                    }
                    helperText={
                      errors.addressLineOne &&
                      touched.addressLineOne &&
                      errors.addressLineOne
                    }
                    hasSuccess={
                      editing
                        ? !isSubmitting &&
                          ((isEmpty(touched) && isEmpty(errors)) ||
                            ((touched.addressLineOne ||
                              !Boolean(touched.addressLineOne)) &&
                              !Boolean(errors.addressLineOne)))
                        : !isSubmitting &&
                          touched.addressLineOne &&
                          !Boolean(errors.addressLineOne)
                    }
                    onChange={(e: React.SyntheticEvent) => {
                      if (textChangeValid('addressLineOne', e)) {
                        change('addressLineOne', e);
                      }
                    }}
                  />
                  <SearchLink onClick={toggleAddManualAddress}>
                    Back to address search
                  </SearchLink>
                  <LongInput
                    label="Address 2"
                    placeholder="Building, suite or apartment number"
                    id="addressLineTwo"
                    name="addressLineTwo"
                    value={addressLineTwo}
                    fullWidth
                    hasError={
                      !isSubmitting &&
                      touched.addressLineTwo &&
                      Boolean(errors.addressLineTwo)
                    }
                    error={
                      touched.addressLineTwo && Boolean(errors.addressLineTwo)
                    }
                    helperText={
                      errors.addressLineTwo &&
                      touched.addressLineTwo &&
                      errors.addressLineTwo
                    }
                    hasSuccess={
                      editing
                        ? !isSubmitting &&
                          ((isEmpty(touched) && isEmpty(errors)) ||
                            ((touched.addressLineTwo ||
                              !Boolean(touched.addressLineTwo)) &&
                              !Boolean(errors.addressLineTwo)))
                        : !isSubmitting &&
                          touched.addressLineTwo &&
                          !Boolean(errors.addressLineTwo)
                    }
                    onChange={(e: React.SyntheticEvent) => {
                      if (textChangeValid('addressLineTwo', e)) {
                        change('addressLineTwo', e);
                      }
                    }}
                  />
                  <LongInput
                    label="City"
                    placeholder="Manhattan Beach"
                    id="city"
                    name="city"
                    value={city}
                    fullWidth
                    hasError={
                      !isSubmitting && touched.city && Boolean(errors.city)
                    }
                    error={touched.city && Boolean(errors.city)}
                    helperText={errors.city && touched.city && errors.city}
                    hasSuccess={
                      editing
                        ? !isSubmitting &&
                          ((isEmpty(touched) && isEmpty(errors)) ||
                            ((touched.city || !Boolean(touched.city)) &&
                              !Boolean(errors.city)))
                        : !isSubmitting && touched.city && !Boolean(errors.city)
                    }
                    onChange={(e: React.SyntheticEvent) => change('city', e)}
                  />
                  <WrapContainer>
                    <Col>
                      <MiniSelect
                        IconComponent={ArrowDropDown}
                        input={
                          <SelectInput label="State" id="state" width="164" />
                        }
                        value={state}
                        displayEmpty
                        onChange={(e: React.SyntheticEvent) =>
                          change('state', e)
                        }
                        error={touched.state && Boolean(errors.state)}
                        inputProps={{
                          name: 'state',
                          id: 'state',
                          MenuProps: MenuProps,
                        }}
                      >
                        >
                        <MenuItem component="li" value="">
                          <DefaultOption>Select one</DefaultOption>
                        </MenuItem>
                        {statesAbbrev.map(state => (
                          <MenuItem
                            button={true}
                            component="li"
                            key={state}
                            value={state}
                          >
                            <OptionLabel>{state}</OptionLabel>
                          </MenuItem>
                        ))}
                      </MiniSelect>
                    </Col>
                    <Col>
                      <MedInput
                        label="Zipcode"
                        placeholder="90210"
                        id="zip"
                        name="zip"
                        value={zip}
                        fullWidth
                        hasError={
                          !isSubmitting && touched.zip && Boolean(errors.zip)
                        }
                        error={touched.zip && Boolean(errors.zip)}
                        helperText={errors.zip && touched.zip && errors.zip}
                        hasSuccess={
                          editing
                            ? !isSubmitting &&
                              ((isEmpty(touched) && isEmpty(errors)) ||
                                ((touched.zip || !Boolean(touched.zip)) &&
                                  !Boolean(errors.zip)))
                            : !isSubmitting &&
                              touched.zip &&
                              !Boolean(errors.zip)
                        }
                        onChange={(e: React.SyntheticEvent) => change('zip', e)}
                      />
                    </Col>
                  </WrapContainer>
                </>
              ) : (
                <>
                  <AutoComplete
                    id="displayAddressDropDown"
                    inputId="displayAddress"
                    label="Address"
                    placeholder="Start typing to search"
                    value={displayAddress}
                    error={errors.addressLineOne ? 'Required' : null}
                    touched={touched.displayAddress}
                    validate={isCustomNonProfit}
                    isSubmitting={isSubmitting}
                    isMenuOpen={isAddressMenuOpen}
                    toggleAddManualAddress={toggleAddManualAddress}
                    handleAddressSelected={handleNonProfitAddressManual}
                    handleInputChange={handleAddressChange}
                    handleOuterClick={handleOuterClick}
                    objectPredictions={addressPredictions}
                    editing={editing}
                  />
                  <StateContainer>
                    <MiniSelect
                      IconComponent={ArrowDropDown}
                      input={
                        <SelectInput
                          width="144"
                          label="State"
                          id="state"
                          hasError={touched.state && Boolean(errors.state)}
                        />
                      }
                      value={state}
                      displayEmpty
                      onChange={(e: React.SyntheticEvent) => change('state', e)}
                      error={touched.state && Boolean(errors.state)}
                      helperText={errors.state && touched.state && errors.state}
                      inputProps={{
                        name: 'state',
                        id: 'state',
                        MenuProps: MenuProps,
                      }}
                    >
                      >
                      <MenuItem component="li" value="">
                        <DefaultOption>Select one</DefaultOption>
                      </MenuItem>
                      {statesAbbrev.map(state => (
                        <MenuItem
                          button={true}
                          component="li"
                          key={state}
                          value={state}
                        >
                          <OptionLabel>{state}</OptionLabel>
                        </MenuItem>
                      ))}
                    </MiniSelect>
                  </StateContainer>
                </>
              )}
              <MedInput
                label="Tax ID #"
                placeholder="e.g. 12-3456789"
                id="nonProfitTaxId"
                name="nonProfitTaxId"
                value={nonProfitTaxId}
                fullWidth
                validate
                hasError={
                  !isSubmitting &&
                  touched.nonProfitTaxId &&
                  Boolean(errors.nonProfitTaxId)
                }
                hasSuccess={
                  editing
                    ? !isSubmitting &&
                      ((isEmpty(touched) && isEmpty(errors)) ||
                        !Boolean(errors.nonProfitTaxId))
                    : !isSubmitting && !Boolean(errors.nonProfitTaxId)
                }
                error={touched.nonProfitTaxId && Boolean(errors.nonProfitTaxId)}
                helperText={
                  errors.nonProfitTaxId &&
                  touched.nonProfitTaxId &&
                  errors.nonProfitTaxId
                }
                onChange={(e: React.SyntheticEvent) =>
                  change('nonProfitTaxId', e)
                }
                toolTipText={taxIdToolTipText}
              />
            </CustomNonProfitWrapper>
          )}
          {charityDetails && showOptionalSteps && (
            <Details>
              {!notAcceptingOrders && (
                <div>
                  <DonationContainer>
                    <div>
                      <Title>Donation goal (optional)</Title>

                      <FormControlLabel
                        label={
                          <StyledTypography
                            disabled={false}
                            fontSize={14}
                            lineHeight={21}
                          >
                            Set & show a donation goal on the event page?
                          </StyledTypography>
                        }
                        disabled={false}
                        control={
                          <CheckBoxIcon
                            editing={false}
                            checked={showDonationGoal === 'true'}
                            onChange={(e: React.SyntheticEvent) =>
                              toggleCheckbox('showDonationGoal', e)
                            }
                            value="showDonationGoal"
                          />
                        }
                      />
                    </div>
                    {showDonationGoalInput && (
                      <div>
                        <LongInput
                          label="How much money do you want to raise?"
                          placeholder={showStartAdornment ? '' : '$50 minimum'}
                          id="charityDonationGoal"
                          name="charityDonationGoal"
                          value={charityDonationGoal}
                          fullWidth
                          validate
                          mask={CurrencyMask}
                          onFocus={setShowStartAdornmentTrue}
                          onBlur={
                            charityDonationGoal
                              ? noop
                              : setShowStartAdornmentFalse
                          }
                          startAdornment={showStartAdornment ? '$' : null}
                          hasError={
                            !isSubmitting &&
                            touched.charityDonationGoal &&
                            Boolean(errors.charityDonationGoal)
                          }
                          hasSuccess={
                            editing
                              ? !isSubmitting &&
                                ((isEmpty(touched) && isEmpty(errors)) ||
                                  !Boolean(errors.charityDonationGoal))
                              : !isSubmitting &&
                                !Boolean(errors.charityDonationGoal)
                          }
                          error={
                            touched.charityDonationGoal &&
                            Boolean(errors.charityDonationGoal)
                          }
                          helperText={
                            errors.charityDonationGoal &&
                            touched.charityDonationGoal &&
                            errors.charityDonationGoal
                          }
                          onChange={(e: React.SyntheticEvent) =>
                            change('charityDonationGoal', e)
                          }
                        />
                      </div>
                    )}
                  </DonationContainer>
                  <MuiInputAutosize
                    label="Message from your Host (optional)"
                    rows={6}
                    rowsMax={16}
                    maxCount="600"
                    placeholder={hostQuotePlaceholder}
                    value={charityQuote}
                    name="charityQuote"
                    id="charityQuote"
                    validInput={charityQuote.length > 0}
                    onChange={(e: React.SyntheticEvent) => {
                      if (textChangeValid('charityQuote', e)) {
                        change('charityQuote', e);
                      }
                    }}
                  />
                </div>
              )}
              <MuiInputAutosize
                label="Check memo (optional)"
                rows={1}
                rowsMax={16}
                maxCount="40"
                placeholder={lobMemoPlaceholder}
                value={lobMemo}
                name="lobMemo"
                id="lobMemo"
                validInput={lobMemo.length > 0}
                onChange={(e: React.SyntheticEvent) => {
                  if (textChangeValid('lobMemo', e)) {
                    change('lobMemo', e);
                  }
                }}
              />
            </Details>
          )}
          {editing ? (
            <SaveButtons
              cancelButtonText="CANCEL"
              saveButtonText="SAVE"
              handleCancel={onCancel}
              handleSave={mutationLoading ? () => {} : handleSubmit}
              disableSave={disableSave}
            />
          ) : (
            <FooterButtons
              prevStepButtonText="BACK"
              nextStepButtonText={hostKitsV2 ? 'NEXT' : 'BOOK EVENT'}
              handleNextStepChange={handleSubmit}
              handlePrevStepChange={handlePreviousStepChange}
              disableNext={disableBookEvent}
            />
          )}
        </>
      )}
    </form>
  );
}
