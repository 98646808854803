import { ApolloClient } from 'apollo-client';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
// import { BatchHttpLink } from 'apollo-link-batch-http';
import { RetryLink } from 'apollo-link-retry';
import fetch from 'isomorphic-fetch';

import introspectionQueryResultData from '../../../../tools/generate-graphql-typescript/filteredSchema.generated.json';
const { GATSBY_GRAPHQL_SERVER_URL } = process.env;

// @ts-ignore: types don't match up
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export const client = new ApolloClient({
  queryDeduplication: true,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        max: 5,
        retryIf: (error, _operation) => !!error,
      },
    }),
    new HttpLink({
      uri: `${GATSBY_GRAPHQL_SERVER_URL}/graphql`,
      fetch,
      credentials: 'include',
      // credentials: 'same-origin'
    }),
  ]),
  cache: new InMemoryCache({ fragmentMatcher }),
});
