import gql from 'graphql-tag';

export const NONPROFIT_AUTOCOMPLETE_QUERY = gql`
  query CEDash_NonProfitIRSQuery($search: String) {
    viewer {
      id
      nonProfitsIRS(search: $search) {
        edges {
          node {
            nonProfitId
            name
            street
            city
            state
            zip
          }
        }
      }
    }
  }
`;

export default NONPROFIT_AUTOCOMPLETE_QUERY;
