/* eslint-disable react/jsx-no-bind */
import * as React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SortIcon from '@material-ui/icons/ImportExport';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';

import Columns from '../../Columns';

const { cssConstants } = s;
const TableCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HeaderLabel = styled.div`
  font-family: ${cssConstants.font};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
`;

const SortIconWrapper = styled(SortIcon)`
    && {
      :hover {
        cursor: pointer;
      }
    } 
`;
interface TableHeaderContainerProps {
  handleSortEvents: (sortBy: string) => void;
}

export default function TableHeaderContainer(props: TableHeaderContainerProps) {
  const { handleSortEvents } = props;
  return (
    <TableHead>
      <TableRow>
        {Columns.map(column => {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              style={{
                  height: 60,
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
              }}>
              <TableCellContainer>
                <HeaderLabel>
                  {column.label}
                </HeaderLabel>
                {column.id !== 'menu' &&
                  column.id !== 'orderAdd' &&
                  <SortIconWrapper
                    onClick={() => handleSortEvents(column.id)}
                  />}
              </TableCellContainer>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
