import gql from 'graphql-tag';

const GET_CUSTOMER_ORDERS_QUERY = gql`
  query CEDash_CustomerOrdersQuery {
    viewer {
      id
      user {
        role
        id
        userId
        firstName
        lastName
        email
        customerOrders(first: 800, sortBy: "any") {
          edges {
            node {
              id
              email
              orderId
              isAutoShipOrder
              isPreferredCustomerOrder
              orderStatus
              orderNumber
              orderTotal
              createDate
              accountFullName
              shippingInfo {
                tracking
                carrier
                expectedDeliveryDate
              }
              trinityTransmissionStatus
              trinityOrderId
              trinityPartyId
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;

export default GET_CUSTOMER_ORDERS_QUERY;
