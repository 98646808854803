import gql from 'graphql-tag';

const GOOGLE_PLACES_ZIP_MUTATION = gql`
  mutation GooglePlacesZipMutation($input: GooglePlacesZipInput!) {
    googlePlacesZip(input: $input) {
      zip
    }
  }
`;

export default GOOGLE_PLACES_ZIP_MUTATION;
