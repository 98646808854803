import React, { Dispatch, SetStateAction } from 'react';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import { styles as s } from '@onehope/design-system';
import get from 'lodash/get';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import TableHeader from '../../TableComponents/TableListView/TableHeader';
import Columns from '../../Events/EventsTable/Columns';
import { EventStatusDictionary } from '../../../utils/enums';
import { isSatelliteEvent } from '../../Events/EventsTable/helpers';

import {
  CEDash_EventsQuery_viewer_user_events_edges as EventEdge,
  CEDash_EventsQuery_viewer_user_events_edges_node as EventType,
} from '../../../queries/generatedTypes/CEDash_EventsQuery';

const { cssConstants } = s;

const TableWrapper = styled.div`
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  overflow: auto;
  background-color: white;
  margin-bottom: 24px;
`;

const TableRow = styled(MuiTableRow)`
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

const TableCell = styled(MuiTableCell)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
  }
`;

const eventIsClosed = (e: EventType | null | undefined) =>
  e?.status !== EventStatusDictionary.OPEN;

const eventIsAcceptingOrders = (e: EventType | null | undefined) =>
  e?.trinityPartyStatus === EventStatusDictionary.OPEN;

const noSortByList = {
  orderAdd: true,
  menu: true,
};

interface EventsListViewProps {
  events: (EventEdge | null)[] | null;
  contactId?: string | null | undefined;
  handleSortEvents: (sortBy: string) => void;
  setSelectedEventId: Dispatch<SetStateAction<string>>;
  handleOrderAdd: (eventId: string | null | undefined) => void;
  toggleEventCopyUrlDialog: (
    eventId: string | null | undefined,
    trinityPartyId: string | null | undefined,
  ) => void;
}

export default function EventsListViewTable(props: EventsListViewProps) {
  const {
    handleSortEvents,
    setSelectedEventId,
    handleOrderAdd,
    events,
    contactId,
    toggleEventCopyUrlDialog,
  } = props;

  const handleRowClick = (
    event: EventEdge | null,
    contactId?: string | null | undefined,
  ) => {
    return () => {
      let link = !event?.node?.donationInfo?.nonProfit
        ? `/event?eventId=${event?.node?.eventId}&tab=2`
        : `/event?eventId=${event?.node?.eventId}&tab=0`;
      if (contactId) {
        link = `${link}&contactId=${contactId}`;
      }
      return navigate(link);
    };
  };

  return (
    <TableWrapper>
      <Table stickyHeader aria-label="events table">
        <TableHeader
          handleSort={handleSortEvents}
          columns={Columns}
          noSortByList={noSortByList}
        />
        <TableBody>
          {events?.map((event: EventEdge | null) => {
            const onRowClick = handleRowClick(event, contactId);
            return (
              <TableRow hover role="checkbox" key={event?.node?.id}>
                {Columns.map(column => {
                  const value = get(event?.node, `${column.id}`);
                  const donationInfo = event?.node?.donationInfo;
                  const eventId = event?.node?.eventId;
                  const trinityPartyId = event?.node?.trinityPartyId;
                  const eventClosed = eventIsClosed(event?.node);
                  const hostNonProfitName = event?.node?.hostNonProfitName;
                  const hostFullName = event?.node?.hostFullName;
                  const timeZone = event?.node?.timeZone || 'America/Chicago'; // Fall back for trinity events
                  const isAcceptingOrders = eventIsAcceptingOrders(event?.node);
                  const onCellClick = !column.render ? onRowClick : () => {};
                  const satelliteEvent = isSatelliteEvent(event?.node);
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      onClick={onCellClick}
                    >
                      {column.format &&
                        column.format(
                          value,
                          event?.node?.eventId,
                          eventClosed,
                          donationInfo,
                          hostNonProfitName,
                          hostFullName,
                          timeZone,
                          satelliteEvent,
                        )}
                      {column.render &&
                        column.render({
                          eventId,
                          setSelectedEventId,
                          handleOrderAdd,
                          toggleEventCopyUrlDialog,
                          isAcceptingOrders,
                          trinityPartyId,
                        })}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}
