import { css } from '@emotion/core';

export default css`
  height: 70px;
  background: #fff;
  padding: 0;
  position: relative;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  .trigger {
    font-size: 18px;
    line-height: 70px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
`;

export const menuStyles = css`
  display: flex;
  padding-left: 16px;
  height: 70px;
  align-items: center;
  .anticon {
    padding: 0;
    svg {
      float: left;
    }
  }

  span {
    padding: 6px;
    line-height: 40px;
    user-select: none;
  }
`;
