import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiMoreVertIcon from '@material-ui/icons/MoreVert';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';

import CreateConciergeServiceMutation from '../../mutations/Account/CreateConciergeServiceMutation';
import { segmentEvents } from '../../utils/segment/constants';
import { useTrackingContextValue } from '../../contexts/TrackingContext';
import { SegmentEventPropsV2 } from '../../utils/segment/SegmentTrackingV2';
const { cssConstants } = s;

const options = ['View Order', 'Cancel'];

const Container = styled.div<{ isCardView: boolean }>`
  position: ${({ isCardView }) => (isCardView ? 'absolute' : 'relative')};
  right: 0;
  top: ${({ isCardView }) => (isCardView ? '8px' : '0px')};
`;

const MenuItem = styled(MuiMenuItem)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
  }
`;

const MoreVertIcon = styled(MuiMoreVertIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

interface IconMenuProps {
  orderId: string | null;
  isCardView: boolean;
  tracking: (string | null)[] | null | undefined;
  carrier?: string | null | undefined;
  email: string | null;
}

interface OrderForCustomerProps {
  createConciergeService: any;
  email: string | null;
  productCatalogPageV2: boolean;
  trackEventV2: (params: SegmentEventPropsV2) => void;
}
const handleOrderForCustomer = ({
  email,
  createConciergeService,
  productCatalogPageV2,
  trackEventV2,
}: OrderForCustomerProps) => {
  return () => {
    const variables = {
      input: {
        customerEmail: email,
      },
    };
    return createConciergeService({ variables })
      .then(res => {
        const conciergeService = res?.data?.createConciergeService;
        if (!conciergeService) return;

        // segment tracking
        const isNewCustomer = conciergeService.newCustomer?.ordersCount === 0;
        const customerId = conciergeService.user?.conciergeCustomerAccountId;
        trackEventV2({
          event: segmentEvents.conciergeModeOrderStarted,
          properties: {
            eventId: null,
            shoppingForId: customerId,
            isNewCustomer,
          },
        });

        if (productCatalogPageV2) {
          window.location.href = `${process.env.GATSBY_PRODUCT_CATALOG_PAGE_URL}`;
        } else {
          window.location.href = `${process.env.GATSBY_HOPECOMMERCE_URL}/shop/wine/wine`;
        }

        return;
      })
      .catch(console.error);
  };
};

export default function IconMenu(props: IconMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { GATSBY_HOPECOMMERCE_URL } = process.env;
  const { tracking, carrier, email } = props;

  const { productCatalogPageV2 } = useFlags();

  const [createConciergeService, { loading: mutationLoading }] = useMutation(
    CreateConciergeServiceMutation,
  );

  const { trackEventV2 } = useTrackingContextValue();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewOrder = () => {
    return window.open(
      `${GATSBY_HOPECOMMERCE_URL}/order/${props.orderId}`,
      '_blank',
    );
  };

  const onHandleTrackOrder = () => {
    const trackingUrl =
      carrier === 'FedEx'
        ? `https://www.fedex.com/apps/fedextrack/?tracknumbers`
        : `https://wwwapps.ups.com/tracking/tracking.cgi?tracknum`;
    if (tracking) {
      tracking.forEach(trackingNumber => {
        return window.open(`${trackingUrl}=${trackingNumber}`, '_blank');
      });
    }
    return null;
  };

  const dispatchHandleOrderForCustomer = handleOrderForCustomer({
    email,
    createConciergeService,
    productCatalogPageV2,
    trackEventV2,
  });

  return (
    <Container isCardView={props.isCardView}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ horizontal: -75, vertical: 'top' }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem key="View Order" onClick={handleViewOrder}>
          View order
        </MenuItem>
        <MenuItem
          disabled={!Array.isArray(tracking) || tracking.length === 0}
          key="Track Order"
          onClick={onHandleTrackOrder}
        >
          Track order
        </MenuItem>
        <MenuItem key="New Order" onClick={dispatchHandleOrderForCustomer}>
          New Order
        </MenuItem>
      </Menu>
    </Container>
  );
}
