import React, { useState } from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import UserLogoutMutation from '../../mutations/Authentication/LogoutMutation';

function logoutSuccess(data: any) {
  console.log('logout successful', data);
}

function submitLogout(logoutMutation: MutationFn<any, any>) {
  return () => {
    logoutMutation({ variables: { input: {} } }).then(() => {
      if (typeof window !== 'undefined' && typeof analytics !== 'undefined') {
        analytics.reset();
      }
    });
  };
}

const LogoutButton = () => {
  return (
    <Mutation mutation={UserLogoutMutation} onCompleted={logoutSuccess}>
      {(logout, { data }) => {
        return <button onClick={submitLogout(logout)}>Logout</button>;
      }}
    </Mutation>
  );
};

export default LogoutButton;
