import styled from '@emotion/styled';
import { MuiInput } from '@onehope/design-system-ohw';

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 24px;
  @media all and (min-width: 968px) {
    flex-direction: row;
    padding-bottom: 0px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled(MuiInput)<{ width: string }>`
  && {
    display: flex;
    flex-direction: column;
    width: ${({ width }) => (width ? `${width}px` : 'inherit')};
    padding-bottom: 24px;
  }
`;
