import gql from 'graphql-tag';
import { HomePage_viewer } from '../../queries/HomePage';

export type LoginMutationVariables = {
  input: {
    id: string;
    facebookToken: boolean;
    googleToken: boolean;
    isPasswordReset: boolean;
    productId: string;
    imageUrl: string;
    email: string;
    password: string;
  };
};

const mutation = gql`
  mutation CeDashboardUserLoginMutation($input: LoginInput!) {
    login(input: $input) {
      viewer {
        ...HomePage_viewer
      }
    }
  }
  ${HomePage_viewer}
`;

export default mutation;
