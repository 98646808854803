import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { Interval, DateTime, Duration } from 'luxon';
import { styles as s } from '@onehope/design-system';

import NoteDeleteMutation from '../../../../mutations/Note/NoteDeleteMutation';
import { Customer_customer_notes as NoteType } from '../../../../queries/generatedTypes/Customer_customer';
import Loading from '../../../../common/Loading';
import EditNoteForm from './EditNoteForm';

interface NoteProps {
  note: NoteType | null;
  noteBeingEdited: string;
  setNoteBeingEdited: (noteId: string) => void;
}

const { cssConstants } = s;

const NoteContainer = styled.div`
  margin-bottom: 40px;
`;

const NoteContent = styled.div<{ disable: boolean }>`
  color: ${props => (props.disable ? '#cccccc' : cssConstants.textColor)};
`;

const Text = styled.div`
  /* font-family: ProximaNova; */
  font-size: 16px;
  line-height: 1.38;
  color: inherit;
  margin-bottom: 8px;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Time = styled.div<{ disable: boolean }>`
  height: 18px;
  /* font-family: GothamHTF; */
  font-size: 11px;
  font-weight: 500;
  line-height: 1.64;
  color: ${props =>
    props.disable ? '#e6e6e6' : cssConstants.secondaryTextColor};
  margin-right: 16px;
`;

const Action = styled.div`
  height: 16px;
  /* font-family: ProximaNova; */
  font-size: 11px;
  font-weight: bold;
  line-height: 1.45;
  color: inherit;
  margin-right: 16px;
  text-decoration: underline;
  cursor: pointer;
`;

function calculateInterval(startDate: Date, endDate: Date): Duration {
  return Interval.fromDateTimes(startDate, endDate).toDuration([
    'hours',
    'minutes',
  ]);
}

const getTimeAgo = (date: string) => {
  const noteUpdateDate = new Date(date);
  const beginDay = new Date();
  const time = calculateInterval(noteUpdateDate, beginDay);
  const { minutes, hours } = time;

  if (hours < 24) {
    if (hours > 1) return `${Math.ceil(hours)} hours ago`;
    if (minutes < 1) return 'now';
    if (minutes < 2) return '1 minute ago';
    if (minutes > 2 && minutes < 60) return `${Math.ceil(minutes)} minutes ago`;
  }

  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
};

function Note(props: NoteProps) {
  const { note, noteBeingEdited } = props;
  const [editing, setEditing] = useState(false);
  const [noteDelete, { loading: mutationNoteDeleteLoading }] = useMutation(
    NoteDeleteMutation,
  );

  if (mutationNoteDeleteLoading) return <Loading />;

  const handleDeleteNote = async () => {
    await noteDelete({
      variables: {
        input: {
          form: {
            noteId: note?.noteId,
            contactAccountId: note?.subjectId,
          },
        },
      },
    })
      .then((data: any) => {
        console.log(data);
      })
      .catch(async (errors: any) => {
        console.log(errors);
      });
  };

  const handleEditNote = async () => {
    setEditing(true);
    props.setNoteBeingEdited(note.noteId || '');
  };

  const onSave = () => {
    setEditing(false);
    props.setNoteBeingEdited('');
  };
  const disable = noteBeingEdited ? noteBeingEdited !== note.noteId : false;
  return (
    <NoteContainer>
      {editing ? (
        <EditNoteForm
          onSave={onSave}
          noteId={note?.noteId}
          defaultValues={{
            text: note?.text,
          }}
        />
      ) : (
        <NoteContent disable={disable}>
          <Text>{note?.text}</Text>
          <SubContainer>
            <Time disable={disable}>{getTimeAgo(note?.dateUpdated)}</Time>
            <Action onClick={handleEditNote}>Edit</Action>
            <Action onClick={handleDeleteNote}>Delete</Action>
          </SubContainer>
        </NoteContent>
      )}
    </NoteContainer>
  );
}

export default Note;
