import React from 'react';
import styled from '@emotion/styled';
import get from 'lodash/get';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { DateTime } from 'luxon';
import { MuiButton } from '@onehope/design-system-ohw';
import CircularProgress from '@material-ui/core/CircularProgress';
import ORDER_WINE_SUMMARY_QUERY from '../../../../queries/Order';
import OrderCancelMutation from '../../../../mutations/Orders/OrderCancelMutation';
import {
  DetailsHeader,
  DetailsTitle,
} from '../../../Contact/TabContent/index.styles';
import { StraightLine, TabContentContainer } from '../index.styles';
import SummaryLine from '../../../Contact/TabContent/SummaryLine';
import PriceTotalSummary from './PriceTotalSummary';
import ShoppingCart from '../../../../common/ShoppingCart';
import {
  getOrderStatusIcon,
  isStatusValidForCancel,
} from '../../../../common/Orders/helpers';
import useToggle from '../../../../utils/useToggleHook';
import GetSupportDialog from '../../../Dialog/GetSupportDialog';
import Loading from '../../../../common/Loading';
import StepSummary from './StepSummary';
import Notification from '../../../Notifcations/DefaultNotifcation';

interface OrderSummaryProps {
  orderId: string;
}

const ButtonContainer = styled.div`
  margin-top: 24px;
`;

const Button = styled(MuiButton)`
  && {
    margin-bottom: 8px;
  }
`;

const OrderStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OrderStatusIcon = styled.div`
  height: 28px;
  margin-right: 8px;
`;

const handleCancelOrder = async ({
  orderId,
  orderCancel,
  setNotificationOpen,
}: any) => {
  await orderCancel({
    variables: { input: { orderId } },
  })
    .then((data: any) => {})
    .catch((errors: any) => {
      console.log('errors', errors);
      return setNotificationOpen();
    });
};

const OrderSummary = ({ orderId }: OrderSummaryProps) => {
  const { data, loading, error } = useQuery(ORDER_WINE_SUMMARY_QUERY, {
    variables: { orderId },
  });
  const [orderCancel, { loading: mutationLoading }] = useMutation(
    OrderCancelMutation,
  );

  const {
    value: dialogOpen,
    setTrue: setDialogOpen,
    setFalse: setDialogClosed,
  } = useToggle();

  const {
    value: notificationOpen,
    setTrue: setNotificationOpen,
    setFalse: setNotificationClosed,
  } = useToggle();

  if (loading) return <Loading />;

  const order = get(data, 'viewer.order');
  const { billingAddress, shippingAddress } = order;
  const shippingName = `${shippingAddress?.firstName} ${shippingAddress?.lastName}`;
  const billingName =
    billingAddress?.firstName && billingAddress?.lastName
      ? `${billingAddress?.firstName} ${billingAddress?.lastName}`
      : shippingName;

  const orderStatus = getOrderStatusIcon(order.orderStatus);
  const canCancel = isStatusValidForCancel(order.orderStatus);

  const handlePrint = () => {
    return window.print();
  };

  const handleViewOrder = () => {
    return window.open(
      `${process.env.GATSBY_HOPECOMMERCE_URL}/order/${order.orderId}`,
      '_blank',
    );
  };

  const ERROR_MESSAGE = `Unable to cancel. Please refresh your page.`;
  return (
    <div>
      <Notification
        variant="error"
        open={notificationOpen}
        message={ERROR_MESSAGE}
        handleClose={setNotificationClosed}
      />
      <TabContentContainer>
        <DetailsHeader>
          <DetailsTitle>Order Details</DetailsTitle>
          {/* <SvgPrint onClick={handlePrint} cursor="pointer" /> */}
        </DetailsHeader>
        <StraightLine />
        <SummaryLine title="Purchased by" value={billingName} />
        <SummaryLine title="Shipping to" value={shippingName} />
        <SummaryLine
          title="Order date"
          value={DateTime.fromISO(order.createDate).toLocaleString(
            DateTime.DATE_SHORT,
          )}
        />
        <SummaryLine title="Total" value={`$${order.orderTotal}`} />
        <SummaryLine title="Order #" value={order.orderNumber} />
        <SummaryLine
          title="Status"
          value={
            <OrderStatusContainer>
              <OrderStatusIcon>{orderStatus.icon}</OrderStatusIcon>
              <div>{orderStatus.status}</div>
            </OrderStatusContainer>
          }
        />
        <ButtonContainer>
          <Button
            type="PRIMARY"
            fullWidth
            disableRipple
            onClick={handleViewOrder}
          >
            view order
          </Button>
          <Button
            type="SECONDARY"
            fullWidth
            disableRipple
            disabled={!canCancel}
            onClick={
              mutationLoading
                ? () => {}
                : () =>
                    handleCancelOrder({
                      orderId,
                      orderCancel,
                      setNotificationOpen,
                    })
            }
          >
            {mutationLoading && (
              <CircularProgress
                size={24}
                style={{ marginRight: 6, color: 'black' }}
              />
            )}
            cancel order
          </Button>
          <Button
            type="SECONDARY"
            fullWidth
            disableRipple
            onClick={setDialogOpen}
          >
            get support
          </Button>
        </ButtonContainer>
        <GetSupportDialog
          dialogOpen={dialogOpen}
          setDialogClosed={setDialogClosed}
        />
        <ShoppingCart orderItems={order?.orderItems} />
        <StepSummary stepName="shipping" order={order} />
        <StepSummary stepName="payment" order={order} />
        <PriceTotalSummary order={order} />
      </TabContentContainer>
    </div>
  );
};

export default OrderSummary;
