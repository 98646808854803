/* eslint-disable react/no-multi-comp */
import React from 'react';
import styled from '@emotion/styled';
import get from 'lodash/get';
import { styles as s } from '@onehope/design-system';
import Table from '@material-ui/core/Table';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Columns from './Columns';

import TableHeader from '../../../../TableComponents/TableListView/TableHeader';

const { cssConstants } = s;

const TableWrapper = styled.div`
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  overflow: auto;
  padding: 0 48px;
  background-color: white;
  margin-bottom: 24px;
`;

const TableRow = styled(MuiTableRow)`
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

const TableCell = styled(MuiTableCell)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
  }
`;

interface TestOrderType {
  node: {
    id: string;
    accountId: string;
    orderId: string;
    accountFullName: string;
    timeZone: string;
    name: string;
    createDate: string;
    orderNumber: string;
    orderDate: string;
    promoCode: string;
    promoCodeExp: string;
    availableTastingFlightCode: {
      code: string;
      expiresOn: string;
    };
    shippingInfo: {
      carrier: string;
      tracking: string[];
    };
  };
}

interface TastingKitOrdersListViewProps {
  page: number;
  orders: TestOrderType[] | null;
  rowsPerPage: number;
  handleTrackOrder: (shipping: { carrier: string; tracking: string[] }) => void;
}

export default function TastingKitOrdersListView(
  props: TastingKitOrdersListViewProps,
) {
  const { page, rowsPerPage, orders, handleTrackOrder } = props;

  return (
    <TableWrapper>
      <Table stickyHeader aria-label="tasting-kit-guest-orders">
        <TableHeader
          noSortByList={{
            trackOrder: true,
            orderMenu: true,
          }}
          handleSort={() => null}
          columns={Columns}
        />
        <TableBody>
          {orders
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((order: TestOrderType | null) => {
              if (order?.node?.availableTastingFlightCode?.code) {
                return (
                  <TableRow hover role="checkbox" key={order?.node?.id}>
                    {Columns.map(column => {
                      const value = get(order?.node, `${column.id}`);
                      const orderId = order?.node?.orderId;
                      const timeZone =
                        order?.node?.timeZone || 'America/Chicago'; // Fall back for trinity events
                      const accountId = order?.node?.accountId;
                      const shipping = order?.node?.shippingInfo;
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && column.format(value, timeZone)}
                          {column.render &&
                            column.render({
                              orderId,
                              handleTrackOrder,
                              accountId,
                              shipping,
                            })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
              return null;
            })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}
