import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import React from 'react';

const GET_USER_QUERY = gql`
  query CEDash_UserQueryV3 {
    viewer {
      user {
        id
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
      }
    }
  }
`;

function LoginRedirectWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Query query={GET_USER_QUERY}>
      {({ loading, error, data }) => {
        if (loading) return null;
        let isLoggedInAsCeo = false;
        isLoggedInAsCeo = data?.viewer?.user
          ? data.viewer.user.permissions.oneCommerce.ceo.access
          : false;
        if (isLoggedInAsCeo) {
          window.location.href = '/dashboard';
          return null;
        }
        return <React.Fragment>{children}</React.Fragment>;
      }}
    </Query>
  );
}

export default LoginRedirectWrapper;
