import gql from 'graphql-tag';
import { HostRewardsFragment } from './HostRewards';

export const EventFragment = gql`
  fragment Event_event on Event {
    id
    accountId
    createDate
    status
    eventId
    eventDate
    ceoFirstName
    ceoEmail
    ceoFullName
    hostLastName
    hostFirstName
    hostFullName
    hostEmail
    hostPhone
    hostAccountId
    hostNonProfitName
    ordersCount
    trinityPartyId
    trinityPartyType
    trinityPartyStatus
    trinityHostRewardsOrderId
    hostRewardsOrderIds
    hostRewardsQualifiedSubTotal
    hostKitOrderNumbers
    hostKitQuantity
    hostKitCredits
    starterKitCredits
    hostKitPurchaser
    isPurchasingTastingFlights
    wineTastingOrderIds
    hostKitOrderLinkId
    lastOrderLinkInfo {
      id
      recipient
      dateSent
    }
    timeZone
    hostQuote
    hostUser {
      id
      role
      userId
      firstName
      lastName
      fullName
      organizationName
      mobilePhone
      email
    }
    eventAddress {
      addressLineOne
      addressLineTwo
      city
      state
      zip
      phone
    }
    closeDate
    manualCloseDate
    donationId
    donationInfo {
      id
      ordersSubTotal
      donationAmount
      donationGoal
      hostKitDonation
      orderDonationAmount
      status
      lobMemo
      nonProfit {
        id
        nonProfitName
        nonProfitTaxId
        nonProfitId
        nonProfitDescription
        nonProfitPayeeName
        isPreApproved
        nonProfitAddress {
          optionalName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          phoneNumber
        }
      }
    }
    parentEventId
    groupFundraiserType
    groupFundraiserSlug
    title
    displayPreferences {
      id
      donationGoal
      supporters
    }
    images {
      id
      imageId
      groupName
      fileName
    }
  }
`;

const EVENT_DETAILS_QUERY = gql`
  query CEDash_EventDetailsPage($eventId: String) {
    viewer {
      id
      user {
        id
        userId
        email
        trinityDealerId
        permissions {
          id
          oneCommerce {
            admin {
              access
            }
          }
        }
        numberOfAvailableCredits
        availableStarterKitCredits
      }
      event(eventId: $eventId) {
        ...Event_event
        ...HostRewards_event
      }
    }
  }
  ${EventFragment}
  ${HostRewardsFragment}
`;

export default EVENT_DETAILS_QUERY;
