import * as React from 'react';
import ProductV2 from '../types/ProductV2';
import styled from '@emotion/styled';
import { styles, Currency } from '@onehope/design-system';

const { cssConstants: { textColor, tertiaryFont } } = styles;

const PriceContainer = styled.div`
  margin: 10px 0;
  font-weight: bold;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${textColor};
  font-family: ${tertiaryFont};
`;

const PriceSale = styled.span`
  font-weight: 300;
  text-decoration: line-through;
  padding-right: 10px;
`;

export default function PriceTag({
  product,
  priceBought,
}: {
  product: ProductV2;
  priceBought: number;
}) {
  const { price } = product;

  return (
    <PriceContainer>
      <div>
        <Currency rawValue={priceBought} />
      </div>
    </PriceContainer>
  );
}
