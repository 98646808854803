import { ActivityProps } from '../../components/Dashboard/ActionEngine/Cards';

export const getCardTrackingProps = (activity: ActivityProps) => {
  const {
    id,
    cardTemplateId,
    category,
    buttonText,
    image,
    title,
    muxVideo,
    dynamicDataParams,
  } = activity;

  const getCardTemplate = () => {
    if (muxVideo || image) {
      return muxVideo ? 'video' : 'image';
    }
    return null;
  };
  const properties = {
    streamActionId: id || null,
    contentfulEntryId: cardTemplateId || null,
    cardName: title || null,
    cardTemplate: getCardTemplate(),
    cardCategory: category,
    cardButton: buttonText || null,
    subjectUserId: dynamicDataParams?.subjectUserId || null,
  };
  return properties;
};
