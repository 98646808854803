import React from 'react';
import { DateTime } from 'luxon';
import { Currency } from '@onehope/design-system';

import {
  DetailsContainer,
  SummaryTitle,
  SummaryValue,
} from '../../Event/TabContent/index.styles';
import SummaryLine from '../../Contact/TabContent/SummaryLine';
import { Address } from '../EventDetailsForm/EventDetailsForm';
import { getCharityType } from '../../Event/TabContent/Charity/helpers';

import {
  CEDash_EventDetailsPage_viewer_event as EventType,
  CEDash_EventDetailsPage_viewer_event_donationInfo as DonationInfoType,
} from '../../../queries/generatedTypes/CEDash_EventDetailsPage';

interface CharitySummaryProps {
  event: EventType;
  donation: DonationInfoType;
  openEvent: boolean;
}

export default function CharityDetailsSummary(props: CharitySummaryProps) {
  const { donation, event, openEvent } = props;

  const nonProfitAddress = donation?.nonProfit?.nonProfitAddress
    ? donation?.nonProfit?.nonProfitAddress
    : null;
  const charityType = getCharityType(donation, false);
  const defaultGoal = '--';
  const showDonationGoal = event?.displayPreferences?.donationGoal;

  const formatCharityType = () => {
    switch (charityType) {
      case 'OHW-PARTNER':
        return 'ONEHOPE Cause Partner';
      case 'CUSTOM':
        return 'Custom 501(c)3';
      case 'SKIP':
        return 'No selection';
      default:
        return 'No selection';
    }
  };
  return (
    <DetailsContainer>
      <div>
        {openEvent ? (
          <div>
            <SummaryLine title="Type of charity" value={formatCharityType()} />
            <SummaryLine
              title="Name of 501(c)3"
              value={donation?.nonProfit?.nonProfitName || 'No selection'}
            />
            <SummaryLine
              title="Donation goal"
              value={showDonationGoal ? 'Show' : 'Hide'}
            />
            <SummaryLine
              title="Goal"
              value={
                donation?.donationGoal ? (
                  <Currency rawValue={donation.donationGoal} />
                ) : (
                  defaultGoal
                )
              }
            />
          </div>
        ) : (
          <div>
            <SummaryLine
              title="Submission date"
              value={DateTime.fromISO(event?.eventDate)
                .toUTC()
                .toLocaleString(DateTime.DATE_SHORT)}
            />
            <SummaryLine
              title="Submission status"
              value={donation?.status.replace(/^\w/, (c: string) =>
                c.toUpperCase(),
              )}
            />
            <SummaryLine
              title="Name of 501(c)3"
              value={donation?.nonProfit?.nonProfitName || 'No selection'}
            />
            <SummaryLine
              title="Tax ID #"
              value={donation?.nonProfit?.nonProfitTaxId}
            />
            {nonProfitAddress?.addressLineOne && (
              <SummaryLine
                title="Address"
                address={
                  nonProfitAddress
                    ? ({
                        addressLineOne: nonProfitAddress.addressLineOne,
                        addressLineTwo: nonProfitAddress.addressLineTwo,
                        city: nonProfitAddress.city,
                        state: nonProfitAddress.state,
                        zip: nonProfitAddress.zip,
                      } as Address)
                    : undefined
                }
              />
            )}
          </div>
        )}
        {event?.hostQuote ? (
          <div>
            <SummaryTitle>Message from Host:</SummaryTitle>
            <SummaryValue>{event.hostQuote}</SummaryValue>
          </div>
        ) : (
          <SummaryLine title="Message from Host" value="No entry" />
        )}
        {donation?.lobMemo ? (
          <div>
            <SummaryTitle>Check Memo:</SummaryTitle>
            <SummaryValue>{donation.lobMemo}</SummaryValue>
          </div>
        ) : (
          <SummaryLine title="Check memo" value="No entry" />
        )}
      </div>
    </DetailsContainer>
  );
}
