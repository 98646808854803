import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { Grid } from '@onehope/design-system-v2';

import styled from '@emotion/styled';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { NotificationBadge } from '../../Event/Notifications/index.styles';
import Loading from '../../../common/Loading';

import GET_MASTER_EVENTS_QUERY from '../../../queries/MasterEvents';
import { CEDash_MasterEventsQuery_viewer_masterEvents_edges as EventEdgeType } from '../../../queries/generatedTypes/CEDash_MasterEventsQuery';
import {
  SatelliteContainer,
  SatellitePromoContainer,
  SatellitePromoNotificationContainer,
} from '../../../common/Snackbar/index.styles';
import IOSAnnouncementBanner from './iOSAnnounecmentBanner';
import NotificationAlertBlack from '../../../assets/notificationAlertBlack';

const IconContainer = styled.div`
  margin: 0 11px;
`;

const Action = styled.a``;

type EventType = {
  hostNonProfitName: string;
  groupFundraiserSlug: string;
  openDate: string;
};

const formatEvents = (data: any) => {
  const events =
    get(data, 'viewer.masterEvents.edges') &&
    get(data, 'viewer.masterEvents.edges').map((event: EventEdgeType) => {
      return {
        hostNonProfitName: event?.node ? event?.node?.hostNonProfitName : '',
        groupFundraiserSlug: event?.node
          ? event?.node?.groupFundraiserSlug
          : '',
        openDate: event?.node ? event?.node?.openDate : '',
      };
    });
  return events.filter((event: EventType) => {
    return event?.openDate ? new Date() >= new Date(event?.openDate) : false;
  });
};

export default function SatelliteEventsSnackBar({
  isMobile,
}: {
  isMobile?: boolean;
}) {
  const [index, setIndex] = useState(0);
  const [showAnnouncementBanner, setShowAnnouncementBanner] = useState(true);
  const { data, loading, error } = useQuery(GET_MASTER_EVENTS_QUERY);
  if (loading) return <div />;
  if (error) {
    console.log({ error });
    return <div />;
  }

  if (get(data.viewer, 'user.id')) {
    const trinityDealerId = get(data.viewer, 'user.trinityDealerId');
    const events = formatEvents(data);
    events.sort(function(a, b) {
      return new Date(b.openDate) - new Date(a.openDate);
    });
    const showPrevious = index !== 0;
    const showNext = index !== events.length - 1;

    const goToNext = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
      e.persist();
      if (showAnnouncementBanner) {
        setShowAnnouncementBanner(false);
      } else {
        setIndex(index + 1);
      }
    };

    const goToPrevious = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
      e.persist();
      if (index === 1) {
        setShowAnnouncementBanner(true);
      } else {
        setIndex(index - 1);
      }
    };

    const onClickNotification = (e: React.SyntheticEvent<HTMLSpanElement>) => {
      e.persist();
      if (e?.target?.localName === 'a') {
        e.preventDefault();
        return;
      }
      return window.open(
        `${process.env.GATSBY_HOPECOMMERCE_URL}/${events[index].groupFundraiserSlug}?dealer=${trinityDealerId}`,
        '_blank',
      );
    };

    const pathname = window?.location?.pathname;

    return (
      <>
        {events.length > 0 && (
          <>
            {!isMobile ? (
              <SatelliteContainer>
                {showAnnouncementBanner ? (
                  <IOSAnnouncementBanner goToNext={goToNext} />
                ) : (
                  <SatellitePromoContainer onClick={onClickNotification}>
                    <IconContainer>
                      <NotificationBadge variant="dot">
                        <NotificationsIcon />
                      </NotificationBadge>
                    </IconContainer>
                    {events.length > 1 && (
                      <span>
                        ({index + 1}/{events.length}){' '}
                      </span>
                    )}{' '}
                    Your new online event to support the{' '}
                    {events[index].hostNonProfitName} has been created.
                    {events.length > 1 && (
                      <span>
                        {showPrevious && (
                          <Action onClick={goToPrevious}>Previous</Action>
                        )}
                        {showNext && <Action onClick={goToNext}>Next</Action>}
                      </span>
                    )}
                  </SatellitePromoContainer>
                )}
              </SatelliteContainer>
            ) : (
              <>
                {showAnnouncementBanner && (
                  <IOSAnnouncementBanner
                    goToNext={goToNext}
                    isMobile={isMobile}
                  />
                )}
                {events.map((event, i) => (
                  <SatellitePromoNotificationContainer
                    onClick={onClickNotification}
                  >
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <NotificationAlertBlack />
                      </Grid>
                      <Grid item xs={10}>
                        Your new online event to support the{' '}
                        {event.hostNonProfitName} has been created.
                      </Grid>
                    </Grid>
                  </SatellitePromoNotificationContainer>
                ))}
              </>
            )}
          </>
        )}
        {events.length < 0 && pathname !== '/events' && (
          <IOSAnnouncementBanner />
        )}
      </>
    );
  }
  return null;
}
