import React, { useState } from 'react';
import styled from '@emotion/styled';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  Dialog,
  Grid,
  makeStyles,
  ThemeType,
  Text,
  Container,
  Image,
} from '@onehope/design-system-v2';

import { NotificationBadge } from '../../Event/Notifications/index.styles';
import {
  SatelliteContainer,
  SatellitePromoContainer,
  SatellitePromoNotificationContainer,
} from '../../../common/Snackbar/index.styles';
import NotificationAlertBlack from '../../../assets/notificationAlertBlack';

const assetsUrl = process.env.GATSBY_S3_ASSETS;

const IconContainer = styled.div`
  margin: 0 11px;
`;

const Action = styled.a``;
const GoToAppStore = styled.a``;

const styles = makeStyles((theme: ThemeType) => ({
  dialogContainer: {
    padding: '40px',
  },
  dialogTitle: {
    fontWeight: 400,
    fontSize: '44px',
    fontFamily: theme.headerFontFamily,
  },
  dialogText: {
    fontSize: '12px',
  },
}));

export default function IOSAnnouncementBanner({
  goToNext,
  isMobile,
}: {
  goToNext?: () => void;
  isMobile?: boolean;
}) {
  const classes = styles();
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const isIOSDevice =
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <Container className={classes.dialogContainer}>
          <Text className={classes.dialogTitle}>Download our App!</Text>
          <Text className={classes.dialogText}>
            Scan this QR code from your iPhone or iPad to download our app!
            Available in the Apple App Store.
          </Text>
          <Image
            src={'./iOSQRCode.jpg'}
            altText="QR code"
            fullWidth
            src={`${assetsUrl}/iOSQRCode.png`}
          />
        </Container>
      </Dialog>
      {isMobile ? (
        <SatellitePromoNotificationContainer>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <NotificationAlertBlack />
            </Grid>
            <Grid item xs={10}>
              Sip, sip hooray! We’ve launched a ONEHOPE iOS app!
              {isIOSDevice && (
                <GoToAppStore href="https://apps.apple.com/us/app/onehope-wine/id1589287759?ls=1&mt=8">
                  Download it today in the Apple App Store.
                </GoToAppStore>
              )}
            </Grid>
          </Grid>
        </SatellitePromoNotificationContainer>
      ) : (
        <SatelliteContainer>
          <SatellitePromoContainer onClick={handleOpenDialog}>
            <IconContainer>
              <NotificationBadge variant="dot">
                <NotificationsIcon />
              </NotificationBadge>
            </IconContainer>
            <div>
              Sip, sip hooray! We’ve launched a ONEHOPE iOS app! Download it
              today in the Apple App Store.
              {!isMobile && (
                <span>
                  <Action onClick={goToNext}>Next</Action>
                </span>
              )}
            </div>
          </SatellitePromoContainer>
        </SatelliteContainer>
      )}
    </>
  );
}
