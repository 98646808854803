import React from 'react';

function Success() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30">
      <defs>
        <path
          id="zb"
          d="M15 0C6.72 0 0 6.72 0 15c0 8.28 6.72 15 15 15 8.28 0 15-6.72 15-15 0-8.28-6.72-15-15-15zm-2.357 20.786L6.75 15.13l1.662-1.594 4.23 4.049L21.589 9l1.662 1.606-10.607 10.18z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="xt" fill="#fff">
          <use xlinkHref="#zb" />
        </mask>
        <use fill="#757575" fillRule="nonzero" xlinkHref="#zb" />
        <g fill="#13C2C2" mask="url(#xt)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </svg>
  );
}

export default Success;
