import gql from 'graphql-tag';

export default gql`
  query CEDash_SubscriptionsQuery($accountId: String, $csPortal: Boolean!) {
    viewer {
      id
      userInfo(accountId: $accountId, csPortal: $csPortal) {
        role
        id
        userId
        firstName
        lastName
      }
    }
  }
`;
