import { DateTime } from 'luxon';
import { MyFormValues } from './FormTypes';
import { CEDash_CustomerDetailsPage_viewer_customer as ContactType } from '../../../../queries/generatedTypes/CEDash_CustomerDetailsPage';

export function getContactDetailsToSubmit(
  values: MyFormValues,
  contact: ContactType,
): any {
  const {
    contactType,
    firstName,
    lastName,
    organizationName,
    email,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zip,
    mobilePhone,
    dateOfBirth,
  } = values;
  if (contactType === 'PERSON') {
    return {
      firstName,
      lastName,
      organizationName: null,
      email,
      shippingAddress: addressLineOne
        ? {
            addressId: contact?.shippingAddress?.addressId,
            firstName,
            lastName,
            phoneNumber: mobilePhone ? mobilePhone.replace(/[-() ]/g, '') : '',
            addressLineOne,
            addressLineTwo,
            city,
            state,
            zip,
          }
        : null,
      mobilePhone: mobilePhone ? mobilePhone.replace(/[-() ]/g, '') : null,
      dateOfBirth: dateOfBirth ? dateOfBirth : null,
      contactType,
    };
  }
  return {
    organizationName,
    firstName,
    lastName,
    email,
    shippingAddress: addressLineOne
      ? {
          addressId: contact?.shippingAddress?.addressId,
          firstName,
          lastName,
          companyName: organizationName,
          phoneNumber: mobilePhone ? mobilePhone.replace(/[-() ]/g, '') : '',
          addressLineOne,
          addressLineTwo,
          city,
          state,
          zip,
        }
      : null,
    mobilePhone: mobilePhone ? mobilePhone.replace(/[-() ]/g, '') : null,
    dateOfBirth: dateOfBirth ? dateOfBirth : null,
    contactType,
  };
}

export const getDefaultValues = (contact: ContactType) => {
  const preferredCustomerJoinDate = contact?.preferredCustomerData
    ?.preferredCustomerJoinDate
    ? DateTime.fromISO(
        contact?.preferredCustomerData?.preferredCustomerJoinDate,
      )
        .toUTC()
        .toLocaleString(DateTime.DATE_SHORT)
    : '';
  return {
    contactType: contact?.contactType ?? 'PERSON',
    firstName: contact?.firstName ?? '',
    lastName: contact?.lastName ?? '',
    organizationName: contact?.organizationName ?? '',
    mobilePhone: contact?.mobilePhone ?? '',
    email: contact?.email ?? '',
    createDate: contact?.createDate
      ? DateTime.fromISO(contact.createDate)
          .toUTC()
          .toLocaleString(DateTime.DATE_SHORT)
      : '',
    addressLineOne: contact?.shippingAddress?.addressLineOne ?? '',
    addressLineTwo: contact?.shippingAddress?.addressLineTwo ?? '',
    city: contact?.shippingAddress?.city ?? '',
    state: contact?.shippingAddress?.state ?? '',
    zip: contact?.shippingAddress?.zip ?? '',
    dateOfBirth: contact?.dateOfBirth ?? '',
    preferredCustomerJoinDate,
    lifeTimeSpend: contact?.lifeTimeSpend ?? 0,
    impactTotal: contact?.impactTotal ?? 0,
    lastOrderDate: contact?.lastOrderDate
      ? DateTime.fromISO(contact.lastOrderDate)
          .toUTC()
          .toLocaleString(DateTime.DATE_SHORT)
      : '',
    joinWineClub:
      contact?.contactPreferences?.interestedIn?.joinWineClub ?? false,
    hostWineTasting:
      contact?.contactPreferences?.interestedIn?.hostWineTasting ?? false,
    becomePC: contact?.contactPreferences?.interestedIn?.becomePC ?? false,
    joinAsCE: contact?.contactPreferences?.interestedIn?.joinAsCE ?? false,
    corporateGifting:
      contact?.contactPreferences?.interestedIn?.corporateGifting ?? false,
  };
};
