import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';

import GET_CUSTOMER_ORDERS_QUERY from '../../queries/CustomerOrders';
import { useTrackingContextValue } from '../../contexts/TrackingContext';
import OrdersPageWrapper from './OdersWrapper';
import Loading from '../../common/Loading';

function OrdersPage() {
  const { data, loading, error } = useQuery(GET_CUSTOMER_ORDERS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  const { trackPageView } = useTrackingContextValue();

  useEffect(() => {
    trackPageView({ page: 'CUSTOMER ORDERS' });
  }, []);

  if (error) return null;
  if (get(data, 'viewer.user.customerOrders')) {
    const { customerOrders } = data.viewer.user;

    return <OrdersPageWrapper orders={customerOrders} />;
  }
  return <Loading />;
}

export default OrdersPage;
