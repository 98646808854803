import styled from '@emotion/styled';
import { Badge } from '@material-ui/core';
import { styles as s } from '@onehope/design-system-ohw';
const { cssConstants } = s;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 12px;
`;

export const NotificationBadge = styled(Badge)`
  .MuiBadge-anchorOriginTopRightRectangle {
    transform: scale(1) translate(-30%, 50%);
    background-color: #f4564e;
  }
  .MuiBadge-dot {
    height: 8px;
    min-width: 8px;
    border: 2px solid white;
  }
`;

export type NotificationProps = {
  causePartnerRequired?: boolean;
};

export const Notification = styled.div<NotificationProps>`
  height: 16px;
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
  color: ${cssConstants.primaryColor};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: ${({ causePartnerRequired }: NotificationProps) =>
    causePartnerRequired ? '40px' : '0'};
  @media all and (min-width: 768px) {
    flex-direction: row;
    padding-bottom: 0;
  }
`;

export const TextContainer = styled.span`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  :last-of-type {
    padding-bottom: 32px;
  }
  @media all and (min-width: 768px) {
    margin-bottom: 0;
    /* flex-direction: row; */
  }
`;

export const Link = styled.span`
  color: ${cssConstants.linkColor};
  margin-left: 3px;
  margin-right: 19px;
  cursor: pointer;
`;

export const Spacer = styled.div`
  padding-right: 5px;
`;

export const ClosedEvent = styled.div`
  /* font-family: ProximaNova-It; */
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.5px;
  font-style: italic;
  margin-bottom: 10px;
`;
