import React from 'react';
import styled from '@emotion/styled';
import { MuiButton } from '@onehope/design-system-ohw';
import { styles as s } from '@onehope/design-system';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import DialogRoot from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  CancelContainerItems,
  UnderlineText,
  BoldText,
  ButtonContainer,
} from './index.styles';

const { cssConstants } = s;

const Dialog = styled(DialogRoot)`
  &.MuiDialog-root {
    z-index: 9000 !important;
  }
`;

const Content = styled(DialogContent)`
  &.MuiDialogContent-root {
    font-family: ${cssConstants.font};
    padding: 40px 16px 16px 16px;
    @media all and (min-width: 768px) {
      padding: 60px 80px;
      margin: 0 auto;
      min-width: 600px;
    }
  }
  &.MuiDialogContent-root:first-of-type {
    padding-top: 60px;
  }
`;

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    // @ts-ignore: Type 'string' is not assignable to type 'ReactElement<any, any>'
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

const Header = styled.div`
  font-size: 32px;
  font-family: ${cssConstants.font};
  font-weight: bold;
  margin-bottom: 25px;
`;

const Link = styled.a`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${cssConstants.linkColor};
`;

interface GetSupportDialogProps {
  dialogOpen: boolean;
  setDialogClosed: () => void;
}

/* eslint-disable react/no-multi-comp */
const GetSupportDialog = ({
  dialogOpen,
  setDialogClosed,
}: GetSupportDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={dialogOpen}
      scroll="paper"
      fullScreen={fullScreen}
      fullWidth
      onClose={setDialogClosed}
      TransitionComponent={Transition}
    >
      <Content>
        <Header>Get Support</Header>
        <CancelContainerItems>
          <UnderlineText>Phone</UnderlineText>
          <BoldText>(949) 329-5576</BoldText>
          <br />
          <BoldText>Monday - Friday</BoldText>
          <div>5 am to 5 pm PST</div>
          <div>6 am to 6 pm MST</div>
          <div>7 am to 7 pm CST</div>
          <div>8 am to 8 pm EST</div>
          <UnderlineText>Email</UnderlineText>
          <Link
            href={`mailto:support@onehopewine.com`}
            target="_blank"
            rel="noopener noreferrer"
          >
            support@onehopewine.com
          </Link>
        </CancelContainerItems>
        <ButtonContainer>
          <MuiButton type="PRIMARY" fullWidth onClick={setDialogClosed}>
            CLOSE
          </MuiButton>
        </ButtonContainer>
      </Content>
    </Dialog>
  );
};

export default GetSupportDialog;
