import gql from 'graphql-tag';

export default gql`
  query CEDash_AccountInfoQuery($accountId: String, $csPortal: Boolean!) {
    viewer {
      id
      userInfo(accountId: $accountId, csPortal: $csPortal) {
        id
        role
        userId
        firstName
        lastName
        email
        role
        password
        hasSSOAccount
        hasGoogleLogin
        hasFacebookLogin
        preferredCustomerCeoAccountId
        shippingAddressIdDefault
        profile {
          id
          image
          city
          state
          zip
          summary
        }
        shippingAddressDefault {
          id
          lastName
          firstName
          street
          addressLineOne
          addressLineTwo
          city
          state
          addressId
          zip
        }
        paymentDefault {
          id
          name
          expMonth
          expYear
          last4
          paymentId
        }
      }
    }
  }
`;
