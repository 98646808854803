import React, { useEffect, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../common/Loading';
import EventsPageWrapper from './EventsWrapper';
import GET_EVENTS_QUERY from '../../queries/Events';
import get from 'lodash/get';
import { useTrackingContextValue } from '../../contexts/TrackingContext';

interface EventsProps {}

function EventsPage(props: EventsProps) {
  const { data, loading, error } = useQuery(GET_EVENTS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const { trackPageView } = useTrackingContextValue();

  useEffect(() => {
    trackPageView({ page: 'EVENTS' });
  }, []);

  if (error) return null;
  if (get(data, 'viewer.user.events.edges')) {
    const { events } = data.viewer.user;
    return <EventsPageWrapper events={events} user={data.viewer.user} />;
  }
  return <Loading />;
}

export default EventsPage;
