import React from 'react';

const SvgPhone = (props: any) => (
  <svg width="12px" height="20px" viewBox="0 0 15 20" {...props}>
    <defs>
      <path
        d="M24.5 9h-8a2.5 2.5 0 0 0-2.5 2.5v17a2.5 2.5 0 0 0 2.5 2.5h8a2.5 2.5 0 0 0 2.5-2.5v-17A2.5 2.5 0 0 0 24.5 9zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4h-9V12h9v14z"
        id="phone_svg__a"
      />
    </defs>
    <g transform="translate(-12 -10)" fill="none" fillRule="evenodd">
      <mask id="phone_svg__b" fill="#fff">
        <use xlinkHref="#phone_svg__a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#phone_svg__a" />
      <g mask="url(#phone_svg__b)" fill="#1A1A1A">
        <path d="M0 0h39v40H0z" />
      </g>
    </g>
  </svg>
);

export default SvgPhone;
