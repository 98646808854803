import React, { Component, ReactNode } from 'react';
import gql from 'graphql-tag';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
// import { navigate } from 'gatsby';

import Layout from 'antd/lib/layout';
import { debounce } from 'lodash';

import SiderNav from '../sider/sider';
import Header from '../header/header';
import Content from '../content/content';

import './layout.css';
import { Query } from 'react-apollo';
import _get from 'lodash/get';
import LoginV2Redirect from '../loginRedirect/loginV2Redirect';

interface AppLayoutProps {
  children: ReactNode;
  selectedUserId: string;
  location: any;
}

interface AppLayoutState {
  collapsed: boolean;
  innerWidth: number;
  resizeListener: () => void;
}

const GET_USER_QUERY = gql`
  query CEDash_UserQueryV2 {
    viewer {
      id
      user {
        id
        firstName
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
      }
    }
  }
`;

class AppLayout extends Component<AppLayoutProps, AppLayoutState> {
  state = {
    collapsed: typeof window === 'undefined' ? true : window.innerWidth < 1200,
    innerWidth: typeof window !== 'undefined' ? window.innerWidth : 375,
    resizeListener: () => {},
  };

  componentDidMount() {
    setTimeout(() => {
      if (
        typeof window !== 'undefined' &&
        typeof window.addEventListener === 'function'
      ) {
        const resizeListener = debounce(() => {
          this.setState({
            innerWidth: window.innerWidth,
            collapsed: window.innerWidth < 1200,
          });
        }, 500);

        window.addEventListener('resize', resizeListener);
        this.setState({
          resizeListener,
          collapsed:
            typeof window === 'undefined' ? true : window.innerWidth < 1200,
          innerWidth: typeof window !== 'undefined' ? window.innerWidth : 375,
        });
      }
    }, 750);
  }

  componentWillUnmount() {
    if (
      typeof window !== 'undefined' &&
      typeof window.removeEventListener === 'function'
    ) {
      window.removeEventListener('resize', this.state.resizeListener);
    }
    this.setState({ resizeListener: () => {} });
  }

  toggle = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  getSelectedMenuItem = () => {
    if (typeof window === 'undefined') return ['0'];
    const { pathname } = window.location;
    switch (true) {
      case pathname === '/':
        return ['0'];
      case pathname.includes('/dashboard'):
        return ['1'];
      case pathname.includes('/event'):
        return ['2'];
      // case pathname.includes('/customers'):
      //   return ['3'];
      case pathname.includes('/credits'):
        return ['3'];
      case pathname.includes('/contact'):
        return ['4'];
      case pathname.includes('/customer-orders'):
        return ['5'];
      default:
        return ['0'];
    }
  };

  getOpenMenuItems = () => {
    if (typeof window === 'undefined') return;
    const { pathname } = window.location;
    switch (true) {
      case pathname.includes('/customer-orders'):
      case pathname.includes('/personal-orders'):
        return ['sub1'];
      default:
        return ['sub1'];
    }
  };

  render() {
    const { children, location } = this.props;
    const { collapsed, innerWidth } = this.state;
    const defaultSelectedKeys = this.getSelectedMenuItem();
    // const defaultOpenKeys = this.getOpenMenuItems();
    const { selectedUserId } = this.props;
    const onClick = innerWidth < 600 && !collapsed ? this.toggle : () => {};

    return (
      <Query query={GET_USER_QUERY}>
        {({ loading, error, data }: any) => {
          if (error) return <div>`Error! ${error.message}`</div>;
          let isLoggedInAsCeo = false;
          if (loading) return null;
          isLoggedInAsCeo =
            !data || !data.viewer || !data.viewer.user
              ? false
              : _get(data.viewer.user, 'permissions.oneCommerce.ceo.access');
          if (!isLoggedInAsCeo) {
            return <LoginV2Redirect />;
          }
          return (
            <Layout>
              <SiderNav
                innerWidth={innerWidth}
                collapsed={collapsed}
                selectedUserId={selectedUserId}
                // defaultOpenKeys={defaultOpenKeys}
                defaultSelectedKeys={defaultSelectedKeys}
                toggle={this.toggle}
              />
              <Layout onClick={onClick}>
                <Header
                  collapsed={collapsed}
                  toggle={this.toggle}
                  firstName={data.viewer.user.firstName}
                  location={location}
                />
                <Content
                  siderOpen={!collapsed}
                  innerWidth={innerWidth}
                  children={children}
                  selectedUserId={selectedUserId}
                />
              </Layout>
            </Layout>
          );
        }}
      </Query>
    );
  }
}

export default withLDProvider({
  clientSideID: process.env.GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: {
    anonymous: true,
  },
})(AppLayout);
