import gql from 'graphql-tag';
import { CUSTOMERPAGE_USER } from '../../queries/CustomersPage';

export default gql`
  mutation ShopWithCustomerAddMutation($input: ShopWithCustomerAddInput!) {
    shopWithCustomerAdd(input: $input) {
      user {
        ...CustomerPage_User
      }
      newCustomer {
        id
        createDate
        dateOfBirth
        isSubscriber
        impactTotal
        isPreferredCustomer
        fullName
        firstName
        lastName
        accountId
        email
        mobilePhone
        lifeTimeSpend
        lastOrderDate
        contactType
        ordersCount
      }
    }
  }
  ${CUSTOMERPAGE_USER}
`;
