import React from 'react';

const SvgEnvelope = (props: any) => (
  <svg width="20px" height="15px" viewBox="0 0 15 20" {...props}>
    <defs>
      <path
        d="M28 12H12c-1.1 0-1.99.9-1.99 2L10 26c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V14c0-1.1-.9-2-2-2zm0 14H12V16l8 5 8-5v10zm-8-7l-8-5h16l-8 5z"
        id="envelope_svg__a"
      />
    </defs>
    <g transform="translate(-12 -10)" fill="none" fillRule="evenodd">
      <mask id="envelope_svg__b" fill="#fff">
        <use xlinkHref="#envelope_svg__a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#envelope_svg__a" />
      <g mask="url(#envelope_svg__b)" fill="#1A1A1A">
        <path d="M0 0h39v40H0z" />
      </g>
    </g>
  </svg>
);

export default SvgEnvelope;
