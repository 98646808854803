import gql from 'graphql-tag';

const mutation = gql`
  mutation StripePaymentDeleteMutation($input: StripePaymentDeleteInput!) {
    stripePaymentDelete(input: $input) {
      clientMutationId
      deletedPaymentId
      user {
        paymentIdDefault
        paymentDefault {
          last4
        }
        cart {
          billingAddress {
            firstName
            id
            lastName
            firstName
            street
            addressLineOne
            addressLineTwo
            city
            state
            zip
            addressId
            country
            phoneNumber
            emailAddress
          }
          paymentInfo {
            id
            name
            expMonth
            expYear
            stripeCardId
            stripeTokenId
            paymentId
            last4
          }
        }
        stripePaymentCollection {
          id
          totalNumberOfStripePayment
          totalNumberOfStripePayments
          entries(first: 1000) {
            edges {
              cursor
              node {
                id
                stripePayment {
                  id
                  name
                  last4
                  expYear
                  expMonth
                  billingAddressUuid
                  paymentId
                  object
                  brand
                  dynamicLast4
                  funding
                  tokenizationMethod
                  billingAddress {
                    id
                    firstName
                    lastName
                    street
                    addressLineOne
                    addressLineTwo
                    city
                    state
                    zip
                    recipientBirthDate
                    addressId
                    country
                    phoneNumber
                    emailAddress
                    specialInstructions
                    nickname
                    companyName
                  }
                  nickname
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default mutation;
