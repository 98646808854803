import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import Highlighter from 'react-highlight-words';
import {
  Table,
  Input,
  Button,
  InputNumber,
  Popconfirm,
  Form,
  Icon,
  Spin,
} from 'antd';
const { TextArea } = Input;

import InputValidations from './InputValidations';
import AddressBookEditMutation from '../../../mutations/Addresses/AddressBookEdit';

const FormItem = Form.Item;
const EditableContext = React.createContext({});

interface EditableCellProps {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: string;
  index: number;
  record: any;
}

class EditableCell extends React.Component<EditableCellProps, {}> {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    } else if (this.props.inputType === 'textArea') {
      return <TextArea rows={4} />;
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      index,
      ...restProps
    } = this.props;
    const record = this.props.record
      ? this.props.record.node.address
      : undefined;
    const dataKey = dataIndex ? dataIndex.split('.')[2] : null;
    const rules = InputValidations[dataIndex]
      ? InputValidations[dataIndex].rules
      : undefined;
    return (
      <EditableContext.Consumer>
        {(form: any) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules,
                    initialValue: record[dataKey],
                  })(this.getInput())}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

interface AddressesTableProps {
  form: any;
  addresses: any;
  selectedUserId: string;
}

interface AddressesTableState {
  isUpdating: boolean;
  editingKey: string;
  data: Array<any>;
  record: any;
  row: any;
  searchText: string;
}

class AddressesTable extends Component<
  AddressesTableProps,
  AddressesTableState
> {
  private columns: Array<any>;
  private searchInput: any;
  constructor(props: AddressesTableProps) {
    super(props);
    this.state = {
      data: props.addresses,
      editingKey: '',
      isUpdating: false,
      record: null,
      row: null,
      searchText: '',
    };
    this.columns = [
      {
        title: 'First Name',
        dataIndex: 'node.address.firstName',
        editable: false,
        key: 'firstName',
        sorter: (a: any, b: any) =>
          a.node.address.firstName.localeCompare(b.node.address.firstName),
        ...this.getColumnSearchProps('node.address.firstName'),
      },
      {
        title: 'Last Name',
        dataIndex: 'node.address.lastName',
        editable: true,
        key: 'lastName',
        sorter: (a: any, b: any) =>
          a.node.address.lastName.localeCompare(b.node.address.lastName),
        ...this.getColumnSearchProps('node.address.lastName'),
      },
      {
        title: 'Company',
        dataIndex: 'node.address.companyName',
        key: 'company',
        editable: true,
        sorter: (a: any, b: any) =>
          a.node.address.companyName.localeCompare(b.node.address.companyName),
        ...this.getColumnSearchProps('node.address.companyName'),
      },
      {
        title: 'Address Line 1',
        dataIndex: 'node.address.addressLineOne',
        key: 'addressLineOne',
        editable: true,
        sorter: (a: any, b: any) =>
          a.node.address.addressLineOne.localeCompare(
            b.node.address.addressLineOne,
          ),
        ...this.getColumnSearchProps('node.address.addressLineOne'),
      },
      {
        title: 'Address Line 2',
        dataIndex: 'node.address.addressLineTwo',
        key: 'addressLineTwo',
        editable: true,
        sorter: (a: any, b: any) =>
          a.node.address.addressLineTwo.localeCompare(
            b.node.address.addressLineTwo,
          ),
      },
      {
        title: 'City',
        dataIndex: 'node.address.city',
        key: 'city',
        editable: true,
        sorter: (a: any, b: any) =>
          a.node.address.city.localeCompare(b.node.address.city),
        ...this.getColumnSearchProps('node.address.city'),
      },
      {
        title: 'State',
        dataIndex: 'node.address.state',
        key: 'state',
        editable: true,
        sorter: (a: any, b: any) =>
          a.node.address.state.localeCompare(b.node.address.state),
      },
      {
        title: 'Zip',
        dataIndex: 'node.address.zip',
        key: 'zip',
        editable: true,
        sorter: (a: any, b: any) =>
          a.node.address.zip.localeCompare(b.node.address.zip),
      },
      {
        title: 'Phone',
        dataIndex: 'node.address.phoneNumber',
        key: 'phoneNumber',
        editable: true,
        sorter: (a: any, b: any) =>
          a.node.address.phoneNumber.localeCompare(b.node.address.phoneNumber),
        ...this.getColumnSearchProps('node.address.phoneNumber'),
      },
      {
        dataIndex: 'operation',
        key: 'operation',
        width: '125px',
        fixed: 'right',
        render: (text: string, record: any) => {
          const editable = this.isEditing(record);
          const { editingKey } = this.state;
          return (
            <div>
              <Mutation
                mutation={AddressBookEditMutation}
                onCompleted={this.onAddressUpdated}
              >
                {(editAddress, { data }) => (
                  <div>
                    {editable ? (
                      <span>
                        <EditableContext.Consumer>
                          {form => (
                            <Popconfirm
                              title="Sure to save?"
                              // onConfirm={() => this.save(form, record)}>
                              onConfirm={() =>
                                this.save(form, record, editAddress)
                              }
                            >
                              <Button
                                htmlType="button"
                                type="primary"
                                shape="circle"
                                icon="save"
                                size="small"
                                style={{ marginRight: 8 }}
                              />
                            </Popconfirm>
                          )}
                        </EditableContext.Consumer>
                        <React.Fragment>
                          <Button
                            onClick={this.cancel}
                            htmlType="button"
                            type="default"
                            shape="circle"
                            icon="close"
                            size="small"
                          />
                          {this.state.isUpdating && this.isEditing(record) && (
                            <Spin style={{ marginLeft: 8 }} size="small" />
                          )}
                        </React.Fragment>
                      </span>
                    ) : (
                      <Button
                        onClick={() => this.edit(record)}
                        htmlType="button"
                        type="primary"
                        shape="circle"
                        icon="edit"
                        size="small"
                        disabled={editingKey !== ''}
                      />
                    )}
                  </div>
                )}
              </Mutation>
            </div>
          );
        },
      },
    ];
  }

  getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex.split('.')[2]}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record.node.address[dataIndex.split('.')[2]]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text: string) => (
      <Highlighter
        autoEscape
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys: Array<string>, confirm: () => void) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters: () => void) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  isEditing = (record: any) => {
    return record.node.id === this.state.editingKey;
  };

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  onAddressUpdated = () => {
    return this.setState({ editingKey: '', isUpdating: false });
  };

  save(form: any, record: any, editAddress: (input: object) => void) {
    if (!form || this.state.isUpdating) return null;
    form.validateFields((error: Object, row: any) => {
      if (error) {
        return;
      }

      const { selectedUserId } = this.props;
      this.setState({ record, row, isUpdating: true });
      const result = {
        ...record.node.address,
        ...row.node.address,
      };
      delete result.__typename;
      editAddress({
        variables: { input: { selectedUserId, address: result } },
      });
    });
  }

  edit(key: any) {
    if (this.state.isUpdating) return null;
    this.setState({ editingKey: key.node.id });
  }

  // onPageChange = (page: number, pageSize: number) => {
  //   const { onLoadMore, hasNextPage } = this.props;
  //   if (hasNextPage) {
  //     onLoadMore();
  //   }
  // };

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          key: col.key,
          title: col.title,
          inputType: col.key === 'text',
          dataIndex: col.dataIndex,
          editing: this.isEditing(record),
        }),
      };
    });

    const { addresses, form } = this.props;
    const scroll = { x: 1600 };
    return (
      <EditableContext.Provider value={form}>
        <Table
          bordered
          scroll={scroll}
          columns={columns}
          dataSource={addresses}
          components={components}
          rowKey={(row: any) => row.node.id}
          rowClassName="editable-row"
        />
      </EditableContext.Provider>
    );
  }
}
const EditableFormTable = Form.create()(AddressesTable);
export default EditableFormTable;
