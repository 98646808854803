import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../../common/Loading';
import get from 'lodash/get';
import GET_GUEST_ORDERS_QUERY from '../../../../queries/GuestOrdersByEventId';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';
import OrdersPageWrapper from './OrdersWrapper';
import { useTrackingContextValue } from '../../../../contexts/TrackingContext';

interface GuestOrdersProps {
  event: EventType;
}

function GuestOrdersPage(props: GuestOrdersProps) {
  const { data, loading, error } = useQuery(GET_GUEST_ORDERS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      byEventId: props.event.eventId,
    },
  });

  const { trackPageView } = useTrackingContextValue();

  useEffect(() => {
    trackPageView({ page: 'GUEST ORDERS' });
  }, []);

  if (error) return null;
  if (get(data, 'viewer.user.orders')) {
    const { orders } = data.viewer.user;
    return <OrdersPageWrapper orders={orders} event={props.event} />;
  }
  return <Loading />;
}

export default GuestOrdersPage;
