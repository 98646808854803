import * as React from 'react';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import Tooltip from '@material-ui/core/Tooltip'
import { styles as s } from '@onehope/design-system';
import IconMenu from './IconMenu';
import PCCustomerSvg from '../../assets/pcCustomer';
import SubscriberSvg from '../../assets/subscriber';
import OrderErrorSvg from '../../assets/orderError';
import OrderShippedSvg from '../../assets/orderShipped';
import OrderProcessingSvg from '../../assets/orderProcessing';
import OrderSuccessSvg from '../../assets/orderSuccess';

import { evaluateOrderStatus } from '../../utils/utils';
import { CEDash_GuestOrdersQuery_viewer_user_orders_edges_node as OrderType } from '../../queries/generatedTypes/CEDash_GuestOrdersQuery';
const { cssConstants } = s;

const GridItem =
  styled.div <
  { orderCount: number } >
  `
  position: relative;
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 16px;
  max-width: 100%;
  @media all and (min-width: 768px) {
    padding: 24px;
    max-width: ${({ orderCount }) => (orderCount < 3 ? '356px' : '100%')};
  } 
   :hover {
    cursor: pointer;
    background-color: ${cssConstants.neutral2};
  }
`;

const HostContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HostName = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  padding-bottom: 8px;
`;

const PreferredCustomer = styled.span`
  svg {
    height: 30px;
    width: 30px;
  }
`;
const Subscriber = styled.span`
  svg {
    height: 30px;
    width: 30px;
  }
`;

const OrderStatusIconContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 28px;
    width: 28px;
    margin-right: 8px;
  }
`;

const GridBlock = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  padding-bottom: 8px;
`;

const GridText = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
`;

const GridContainer = styled.div``;

interface OrderCardProps {
  order: OrderType | null;
  orderCount: number;
}

function getOrderStatusIcon(status: string | null) {
  if (status) {
    switch (evaluateOrderStatus(status)) {
      case 'processing':
        return { icon: <OrderProcessingSvg />, status: 'Processing' };
      case 'inTransit':
        return { icon: <OrderShippedSvg />, status: 'In Transit' };
      case 'cancelled':
        return { icon: <OrderErrorSvg />, status: 'Cancelled' };
      case 'exception':
        return { icon: <OrderErrorSvg />, status };
      case 'complete':
        return { icon: <OrderSuccessSvg />, status: 'Delivered' };
      default:
        return { icon: <OrderProcessingSvg />, status: 'Processing' };
    }
  }
  return { icon: <OrderErrorSvg />, status: '' };
}

const handleCardClick = (order: OrderType | null) => {
  const { GATSBY_HOPECOMMERCE_URL } = process.env;
  return () => {
    return window.open(
      `${GATSBY_HOPECOMMERCE_URL}/order/${order?.orderId}`,
      '_blank',
    );
  };
};

export default function OrderCard(props: OrderCardProps) {
  const { order, orderCount } = props;
  if (order) {
    const onCardClick = handleCardClick(order);
    const { icon, status } = getOrderStatusIcon(order.orderStatus);
    return (
      <GridItem orderCount={orderCount}>
        <IconMenu
            email={order.email}
            orderId={order.orderId}
            isCardView={true}
            carrier={order.shippingInfo?.carrier}
            tracking={order.shippingInfo?.tracking} />
        <GridContainer onClick={onCardClick}>
          <HostContainer>
            <HostName>
              {order.accountFullName}
            </HostName>
            {order.isAutoShipOrder
                ? <Tooltip title="Subscriber" placement="top">
                  <Subscriber>
                    <SubscriberSvg />
                  </Subscriber>
                </Tooltip>
                : order.isPreferredCustomerOrder &&
                <Tooltip title="Preferred Customer" placement="top">
                  <PreferredCustomer>
                    <PCCustomerSvg />
                  </PreferredCustomer>
                </Tooltip>}
          </HostContainer>
          <GridBlock>
            Ordered:{' '}
            {order.createDate &&
              <GridText>
                {DateTime.fromISO(order.createDate)
                  .toUTC()
                  .toLocaleString(DateTime.DATE_FULL)}
              </GridText>}
          </GridBlock>
          <GridBlock>
            Total: <GridText>${order.orderTotal}</GridText>
          </GridBlock>
          {/*<GridBlock>*/}
          {/*  Quantity: <GridText>{order.orderItemsQty}</GridText>*/}
          {/*</GridBlock>*/}
          <GridBlock>
            Order #: <GridText>{order.orderNumber}</GridText>
          </GridBlock>
          <GridBlock>
            <GridText>
              <OrderStatusIconContainer>
                {icon}
                {status}
              </OrderStatusIconContainer>
            </GridText>
          </GridBlock>
        </GridContainer>
      </GridItem>
    );
  }
  return null;
}
