import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/react-hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { styles as s } from '@onehope/design-system';
import { MuiButton } from '@onehope/design-system-ohw';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import AddContactDialog from '../AddContactDialog/AddContactDialog';
import ImportContacts from '../ImportContacts';
import ExportContacts from '../ExportContacts';
import ContactsCSVExportMutation from '../../../mutations/Customer/ContactsCSVExportMutation';

const { cssConstants } = s;

const Button = styled(MuiButton)`
  && {
    width: 288px;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 16px 16px 16px;
  flex-direction: column;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  @media all and (min-width: 768px) {
    padding: 40px;
    flex-direction: row;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  margin-bottom: 16px;
  @media all and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const ContactActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: -10px;
  @media all and (min-width: 768px) {
    justify-content: flex-end;
  }
`;

const HeaderTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: -0.5px;
`;

const NotificationText = styled.div`
  padding-top: 8px;
  font-size: 14px;
  width: 288px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  padding-top: 16px;
  justify-content: center;
`;

interface HeaderProps {
  resetContacts: (input: any) => void;
}

const handleContactsCSVExport = ({
  contactsCSVExport,
  loading,
  setCSVUploaded,
}: any) => {
  return () => {
    if (!loading) {
      setCSVUploaded(false);
      return contactsCSVExport({
        variables: {
          input: {},
        },
      })
        .then((res: any) => {
          setCSVUploaded(res.data.contactsCSVExport.csvUploaded);
        })
        .catch(async (errors: any) => {
          console.error(errors);
        });
    }
  };
};

export default function Header(props: HeaderProps) {
  const { resetContacts } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [contactsCSVExport, { loading }] = useMutation(
    ContactsCSVExportMutation,
  );

  const { cloudSpongeUploadContacts } = useFlags();

  const [csvUploaded, setCSVUploaded] = useState(false);

  const dispatchHandleContactsCSVExport = handleContactsCSVExport({
    setCSVUploaded,
    contactsCSVExport,
    loading,
  });
  return (
    <React.Fragment>
      <HeaderContainer>
        <HeaderTitle>Contacts</HeaderTitle>
        <ActionsWrapper>
          <ButtonsWrapper>
            <AddContactDialog />
          </ButtonsWrapper>
          <ContactActionsContainer>
            {cloudSpongeUploadContacts && (
              <ImportContacts resetContacts={resetContacts} />
            )}
            <ExportContacts
              handleContactsCSVExport={dispatchHandleContactsCSVExport}
            />
          </ContactActionsContainer>
          {csvUploaded && (
            <NotificationText>
              Your export is in progress. We will email you when it's complete
            </NotificationText>
          )}
          {loading && (
            <LoadingWrapper>
              <CircularProgress size={24} style={{ color: 'black' }} />
            </LoadingWrapper>
          )}
        </ActionsWrapper>
      </HeaderContainer>
    </React.Fragment>
  );
}
