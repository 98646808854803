export const config = (hideTastingKit: boolean) => {
  const configObject = [
    {
      label: 'Details',
      value: 0,
      cypressData: 'event-details-tab-button',
    },
    {
      label: hideTastingKit ? 'Wine' : 'Tasting Flight',
      value: 1,
      cypressData: 'event-wine-tab-button',
    },
    {
      label: 'Charity',
      value: 2,
      cypressData: 'event-charity-tab-button',
    },
    {
      label: 'Host Rewards',
      value: 3,
      cypressData: 'event-host-rewards-tab-button',
    },
    {
      label: 'Guest Orders',
      value: 4,
      cypressData: 'event-guest-orders-tab-button',
    },
  ];

  return configObject;
};

export const satelliteEventConfig = [
  {
    label: 'Details',
    value: 0,
    cypressData: 'event-details-tab-button',
  },
  {
    label: 'Charity',
    value: 2,
    cypressData: 'event-charity-tab-button',
  },
  {
    label: 'Host Rewards',
    value: 3,
    cypressData: 'event-host-rewards-tab-button',
  },
  {
    label: 'Guest Orders',
    value: 4,
    cypressData: 'event-guest-orders-tab-button',
  },
];

export const oldConfig = [
  {
    label: 'Details',
    value: 0,
    cypressData: 'event-details-tab-button',
  },
  {
    label: 'Host Kit',
    value: 1,
    cypressData: 'event-host-kit-tab-button',
  },
  {
    label: 'Charity',
    value: 2,
    cypressData: 'event-charity-tab-button',
  },
  {
    label: 'Host Rewards',
    value: 3,
    cypressData: 'event-host-rewards-tab-button',
  },
  {
    label: 'Guest Orders',
    value: 4,
    cypressData: 'event-guest-orders-tab-button',
  },
];
