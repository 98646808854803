import gql from 'graphql-tag';

const mutation = gql`
  mutation CardButtonClickedMutation($input: CardButtonClickedMutationInput!) {
    cardButtonClicked(input: $input) {
      clientMutationId
    }
  }
`;

export default mutation;
