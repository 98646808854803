import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';

const {
  cssConstants: { font, textColor },
} = s;

export const containerStyle = `
  font-family: ${font};
  color: ${textColor};
  max-width: 432px;
  margin: 40px auto 40px auto;
  padding: 0 16px;
`;

export const Container = styled.div`
  ${containerStyle}
  display: flex;
  justify-content: center;
  height: 100vh;
`;

export const ContainerBlock = styled.div`
  ${containerStyle}
  display: block;
`;

export const TabContentContainer = styled.div`
  max-width: 432px;
  width: 100%;
  margin: auto;
  padding: 40px 16px 156px 16px;
  @media all and (min-width: 768px) {
    padding: 40px 0 156px 0;
  }
`;

export const BaseContainer = styled.div`
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 0;
  @media all and (min-width: 768px) {
    padding: 0 16px;
  }
`;

export const FieldContainer = styled.div`
  margin-bottom: 24px;
`;

export const DetailsContainer = styled.div`
  margin-bottom: 40px;
`;

export const DetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DetailsTitle = styled.div`
  /* font-family: ProximaNova; */
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

export const StraightLine = styled.div`
  max-width: 432px;
  height: 1px;
  background-color: #dedede;
  margin: 12px 0 16px 0;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 432px;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  @media all and (min-width: 768px) {
    justify-content: flex-start;
    text-align: left;
  }
`;

export const Key = styled.div`
  /* font-family: ProximaNova; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: normal;
  margin-right: 8px;
  text-align: left;
`;

export const Value = styled.div`
  /* font-family: ProximaNova; */
  font-size: 14px;
  line-height: 19px;
  word-break: break-word;
  margin-right: 0;
  text-align: right;
  width: 432px;
  @media all and (min-width: 768px) {
    text-align: left;
    width: initial;
  }
`;

export const ToggleLabel = styled.div`
  font-size: 16px;
  line-height: 22px;
  margin: 24px 0 8px 0;
`;

export const SummaryTitle = styled.div`
  /* font-family: ProximaNova; */
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  max-width: 432px;
  margin: 8px 0;
`;

export const SummaryValue = styled.div`
  /* font-family: ProximaNova; */
  font-size: 14px;
  word-break: break-word;
  text-align: left;
  max-width: 432px;
  margin-bottom: 16px;
`;
