import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { MuiButton } from '@onehope/design-system-ohw';
import EVENT_UPDATE_MUTATION from '../../../mutations/Event/EventUpdateMutation';
import EventCopyUrlDialog from '../../Dialog/EventCopyUrlDialog';
import Notifications from '../Notifications';
import CancelEventDialog from '../../Dialog/AlertDialog';
import {
  CEDash_EventDetailsPage_viewer_event,
  CEDash_EventDetailsPage_viewer_user,
} from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { isSatelliteEvent } from '../../Events/EventsTable/helpers';
import { EventStatusDictionary } from '../../../utils/enums';
import { getTastingFlights } from '../../../utils/utils';

const Button = styled(MuiButton)`
  && {
    width: 100%;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  flex-direction: column;
  @media all and (min-width: 768px) {
    padding: 0 40px 0 40px;
    flex-direction: row;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  width: 100%;
  @media all and (min-width: 768px) {
    padding: 0;
    max-width: 288px;
    flex-direction: column;
    align-items: flex-end;
  }
`;

const ButtonLeftContainer = styled.div`
  padding-right: 8px;
  width: 138px;
  @media all and (min-width: 768px) {
    padding: 0;
    margin-bottom: 8px;
  }
  @media all and (min-width: 1280px) {
    width: 100%;
  }
`;
const ButtonRightContainer = styled.div`
  width: 138px;
  @media all and (min-width: 1280px) {
    width: 100%;
  }
`;

const HostName = styled.div`
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: -0.5px;
  margin-bottom: 12px;
`;

const DIALOG_TEXT = 'Are you sure you would like to close this event?';

const isBool = (value: boolean | null) => value === true || value === false;

const handleCloseEvent = ({
  event,
  eventUpdateMutation,
  handleSetCancelDialog,
}: {
  event: CEDash_EventDetailsPage_viewer_event;
  eventUpdateMutation: any;
  handleSetCancelDialog: () => void;
}) => () => {
  const { eventId } = event;
  const input = {
    form: {
      eventId,
      status: EventStatusDictionary.CLOSED,
      trinityPartyStatus: EventStatusDictionary.CLOSED,
    },
  };
  return eventUpdateMutation({
    variables: { input: input },
  })
    .then(() => {
      handleSetCancelDialog();
    })
    .catch((errors: any) => {
      console.log('errors', errors);
      return;
    });
};

interface HeaderProps {
  event: CEDash_EventDetailsPage_viewer_event;
  user: CEDash_EventDetailsPage_viewer_user;
}

export default function Header(props: HeaderProps) {
  const { event, user } = props;

  const closedEvent = event?.status === EventStatusDictionary.CLOSED;
  const hasOrders = !!event?.ordersCount;
  const satelliteEvent = isSatelliteEvent(event);
  const [isCopyUrlDialogOpen, setIsCopyUrlDialogOpen] = React.useState(false);
  const [isCancelDialogOpen, setIsCancelDialogClose] = React.useState(false);
  const [eventUpdateMutation, { loading }] = useMutation(EVENT_UPDATE_MUTATION);

  const hasCharitySelected = event?.donationInfo?.nonProfit;

  const showTastingFlights = getTastingFlights(event);

  // either host kit or tasting kit
  const wineInfoProvided = showTastingFlights
    ? isBool(event?.isPurchasingTastingFlights)
    : Number.isInteger(event?.hostKitQuantity);

  // show close event button if event is not closed, has orders, has charity selected, has provided hostkit info
  const showCloseEventButton =
    hasOrders &&
    !closedEvent &&
    hasCharitySelected &&
    wineInfoProvided &&
    !satelliteEvent;

  const handleSetCancelDialog = () => {
    setIsCancelDialogClose(!isCancelDialogOpen);
  };

  const dispatchCloseEvent = handleCloseEvent({
    event,
    eventUpdateMutation,
    handleSetCancelDialog,
  });

  function handleViewEvent() {
    return setIsCopyUrlDialogOpen(true);
  }
  return (
    <HeaderContainer>
      <CancelEventDialog
        dialogText={DIALOG_TEXT}
        open={isCancelDialogOpen}
        setClose={handleSetCancelDialog}
        saveChange={dispatchCloseEvent}
        mutationLoading={loading}
      />
      <EventCopyUrlDialog
        isOpen={isCopyUrlDialogOpen}
        selectedTrinityPartyId={event.trinityPartyId?.toString() || ''}
        selectedEventId={event.eventId}
        setIsCopyUrlDialogOpen={setIsCopyUrlDialogOpen}
        isSatellite={satelliteEvent}
        groupFundraiserSlug={event.groupFundraiserSlug || ''}
        trinityDealerId={user.trinityDealerId || ''}
      />
      <TitleContainer>
        <HostName>
          {event.title ||
            event.hostNonProfitName ||
            event.hostFullName ||
            event.ceoFullName}
          🍷
        </HostName>
        <Notifications event={event} />
      </TitleContainer>
      <ButtonsWrapper>
        <ButtonLeftContainer>
          <Button type="PRIMARY" onClick={handleViewEvent}>
            Event Page
          </Button>
        </ButtonLeftContainer>
        <ButtonRightContainer>
          {showCloseEventButton && (
            <Button type="SECONDARY" onClick={handleSetCancelDialog}>
              Close Event
            </Button>
          )}
        </ButtonRightContainer>
      </ButtonsWrapper>
    </HeaderContainer>
  );
}
