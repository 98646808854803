/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState } from 'react';
import { makeStyles } from '@onehope/design-system-v2';
// Use old styles to match rest of event creation flow
import { styles } from '@onehope/design-system';

import useToggle from '../../../utils/useToggleHook';

import ImageUploadDialog from '../../Dialog/ImageUploadDialog';
import FooterButtons from '../../Dialog/EventCreateDialog/FooterButtons';

import UpdateImage from './UpdateImage';
import CropImage from './CropImage';
import DragAndDrop from './DragAndDrop';

const { styled: s } = styles;

const textConfig = {
  upload: {
    header: 'Upload a photo',
    body:
      'Add an image that tells the story of your event. If you choose to skip, a default photo will be added.',
  },
  crop: {
    header: 'Crop photo',
    body: 'Please use the cropping tool to crop your photo.',
  },
};

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%',
    textAlign: 'left',
  },
  tabContent: {
    maxWidth: '432px',
    width: '100%',
    margin: 'auto',
    padding: '0 0 156px 0',
    ['@media (min-width: 768px)']: {
      padding: '40px 0 156px 0',
    },
  },
  subtext: {
    padding: '18px 0 24px 0',
  },
  centeredText: { textAlign: 'center' },
}));

export default function UploadImageForm(props: any) {
  const { handlePreviousStepChange, editing, event, loading } = props;

  const [image, setImage] = useState('');
  const [mimeType, setMimeType] = useState('');
  const [error, setError] = useState('');
  const [canvas, setCanvas] = useState('');

  const classes = useStyles();

  const {
    value: imageUploadOpen,
    setTrue: setImageUploadOpen,
    setFalse: setImageUploadClosed,
  } = useToggle();

  const onCancel = () => {
    setImage('');
    setCanvas('');
    setMimeType('');
  };

  const onFileDrop = useCallback(
    (acceptedFiles: any, fileRejections: any) => {
      if (acceptedFiles?.[0]) {
        const imageFile = acceptedFiles[0];
        const fileType = imageFile?.type?.split('/')[1];
        setMimeType(fileType);
        setImage(imageFile);
        return setImageUploadOpen();
      }

      if (fileRejections?.[0]) {
        return setError(fileRejections?.[0]?.errors?.[0]?.message);
      }

      setError('Something went wrong, please try again');
    },
    [setImageUploadOpen],
  );

  const handleNextStepChange = () =>
    props.handleNextStepChange({ imageCanvas: canvas, mimeType });

  const onUpload = (imageCanvas: string) =>
    props.onUpload({ imageCanvas, mimeType });

  return (
    <div>
      <ImageUploadDialog
        open={imageUploadOpen}
        onClose={setImageUploadClosed}
        image={image}
        mimeType={mimeType}
        setImage={setImage}
        setCanvas={setCanvas}
        onUpload={editing ? onUpload : undefined}
      />
      {editing ? (
        <UpdateImage onFileDrop={onFileDrop} event={event} loading={loading} />
      ) : (
        <div className={classes.container}>
          <div className={classes.tabContent}>
            <s.LoginHeader css>
              {canvas ? textConfig.crop.header : textConfig.upload.header}
            </s.LoginHeader>
            <div className={classes.subtext}>
              {canvas ? textConfig.crop.body : textConfig.upload.body}
            </div>
            <div className={classes.centeredText}>
              {canvas ? (
                <CropImage
                  canvas={canvas}
                  onCancel={onCancel}
                  setImageUploadOpen={setImageUploadOpen}
                />
              ) : (
                <DragAndDrop
                  onFileDrop={onFileDrop}
                  handleNextStepChange={handleNextStepChange}
                />
              )}
            </div>
            {error && <div>{error}</div>}
            <FooterButtons
              prevStepButtonText="BACK"
              nextStepButtonText="NEXT"
              handleNextStepChange={handleNextStepChange}
              handlePrevStepChange={handlePreviousStepChange}
              disableNext={!!image && !canvas}
            />
          </div>
        </div>
      )}
    </div>
  );
}
