import React from 'react';
import { ThemeProvider, theme } from '@onehope/design-system-v2';

import {
  BaseContainer,
  TabContentContainer,
} from '../../../Event/TabContent/index.styles';
import UploadImageForm from '../../../Events/UploadImageForm';

export type ImageValuesType = {
  imageCanvas: string;
  mimeType: string;
};

interface UploadImageProps {
  form: any;
  handleNextStepChange: (
    key: string,
    imageValues: ImageValuesType,
  ) => Promise<void> | undefined;
  handlePreviousStepChange: () => void;
}

export default function UploadImage(props: UploadImageProps) {
  const { handlePreviousStepChange } = props;

  return (
    <ThemeProvider theme={theme}>
      <BaseContainer>
        <TabContentContainer>
          <UploadImageForm
            {...props}
            editing={false}
            handlePreviousStepChange={handlePreviousStepChange}
            /* eslint-disable react/jsx-no-bind */
            handleNextStepChange={async (imageValues: ImageValuesType) => {
              props.handleNextStepChange('imageUpload', imageValues);
            }}
          />
        </TabContentContainer>
      </BaseContainer>
    </ThemeProvider>
  );
}
