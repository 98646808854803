import React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { Form, Icon } from 'antd';
import InputFloatingLabel from '../../common/InputFloatingLabel';
import { css } from '@emotion/core';
import UserLoginMutation from '../../mutations/Authentication/LoginMutation';
import { Button } from '@onehope/design-system';

const LoginFormContainer = css`
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin: 100px auto 0 auto;
  width: 300px;
  .ant-form-item {
    margin-bottom: 0;
  }
  button {
    margin: 16px 0;
  }
`;

const LoginButtonContainer = css`
  button {
    margin: 16px 0;
  }
`;

const IconStyles = css`
  position: absolute;
  margin-top: 24px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.25);
`;

const dFlex = css`
  display: flex;
`;

type LoginMutationVariables = {
  input: {
    id: string;
    facebookToken: boolean;
    googleToken: boolean;
    isPasswordReset: boolean;
    productId: string;
    imageUrl: string;
    email: string;
    password: string;
  };
};

interface LoginFormProps {
  form: any;
}

class LoginForm extends React.Component<LoginFormProps, {}> {
  submitLogin = (
    event: React.ChangeEvent<any>,
    loginMutation: MutationFn<any, LoginMutationVariables>,
  ) => {
    event.preventDefault();
    const { form } = this.props;
    form.validateFields((err: any, values: any) => {
      if (err) {
        return;
      }
      const { email, password } = values;
      const variables: LoginMutationVariables = {
        input: {
          id: '',
          facebookToken: false,
          googleToken: false,
          isPasswordReset: false,
          productId: '',
          imageUrl: '',
          email,
          password,
        },
      };
      return loginMutation({ variables });
    });
  };

  loginSuccess = (data: any) => {
    console.log('login successful', data);
  };

  loginFailure = (data: any) => {
    const { form } = this.props;
    form.setFields({
      password: {
        value: form.getFieldValue('password'),
        errors: [new Error(data.graphQLErrors[0].message)],
      },
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Mutation
        mutation={UserLoginMutation}
        onError={this.loginFailure}
        onCompleted={this.loginSuccess}
      >
        {(login, { data }) => {
          return (
            <Form
              onSubmit={e => this.submitLogin(e, login)}
              css={LoginFormContainer}
            >
              <div css={dFlex}>
                <Form.Item>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        type: 'email',
                        message: 'Please enter a valid email.',
                      },
                      { required: true, message: 'Please input your email.' },
                    ],
                  })(
                    <InputFloatingLabel
                      name="email"
                      label="Email"
                      placeholder="Email"
                      margin="none"
                    />,
                  )}
                </Form.Item>
                <Icon type="mail" css={IconStyles} />
              </div>
              <div css={dFlex}>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your password.',
                      },
                    ],
                  })(
                    <InputFloatingLabel
                      name="password"
                      type="password"
                      label="Password"
                      placeholder="Password"
                      margin="none"
                    />,
                  )}
                </Form.Item>
                <Icon type="lock" css={IconStyles} />
              </div>
              <Form.Item css={LoginButtonContainer}>
                <Button block type="primary-regular" htmlType="submit">
                  Log In
                </Button>
              </Form.Item>
            </Form>
          );
        }}
      </Mutation>
    );
  }
}

const WrappedLoginForm = Form.create({ name: 'login_form' })(LoginForm);

export default WrappedLoginForm;
