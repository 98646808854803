import React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { MiniProgress } from 'ant-design-pro/lib/Charts';
import { DateTime } from 'luxon';
import { Button } from '@onehope/design-system';
import { DashboardQuery_viewer_user } from '../../../queries/generatedTypes/DashboardQuery';
import { getMonthName, validInput } from '../../../utils/utils';
import AntButton from 'antd/lib/button';
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import Spin from 'antd/lib/spin';

import GET_CE_IMPACT_GOALS_QUERY from '../../../queries/CE/CEImpactGoals';

import CeUpdateMutation, {
  CeUpdateMutationVariables,
} from '../../../mutations/CE/CeUpdateMutation';

import myImpactProgressStyles from './MyImpactProgressStyles';

function toCurrency(value: any): any {
  let num = 0;
  if (value !== '') {
    num = parseFloat(value);
  }
  return num.toFixed(2).toString();
}

interface ImpactDataMonth {
  month: string;
  impactTotal: string;
}

interface Month {
  __typename: string;
  month: string;
  impactGoal: string;
}

interface Year {
  __typename: string;
  year: string;
  months: Array<Month>;
}

interface ImpactDataYear {
  year: string;
  months: Array<ImpactDataMonth>;
}

interface MyImpactProgressProps {
  ceImpactGoals: {
    goals: Array<Year>;
  };
  ceImpactData: {
    years: Array<ImpactDataYear>;
    lifetime: string;
  };
  ceId: string | null;
  user: DashboardQuery_viewer_user;
}

interface MyImpactProgressState {
  isEditing: boolean;
  currentImpact: string;
  impactGoal: string;
  newImpactGoal: string;
  isUpdating: boolean;
}

const date = DateTime.local();
const { year, month } = date;

const defaultCeImpactGoals = {
  goals: [
    {
      year: year.toString(),
      months: [
        {
          month: getMonthName(month),
          impactGoal: '100.00',
        },
      ],
    },
  ],
};

const defaultCeImpactData = {
  years: [
    {
      year: year.toString(),
      months: [
        {
          month: getMonthName(month),
          impactTotal: '0.00',
        },
      ],
    },
  ],
  lifetime: '0.00',
};

class MyImpactProgress extends React.Component<
  MyImpactProgressProps,
  MyImpactProgressState
> {
  constructor(props: MyImpactProgressProps) {
    super(props);

    this.state = {
      isUpdating: false,
      isEditing: true,
      currentImpact: this.calculateCurrentImpact(year, month),
      impactGoal: this.calculateImpactGoal(year, month),
      newImpactGoal: '',
    };
  }

  getYearData = (data: any, year: number) => {
    return data.find((goalData: Year) => {
      return goalData.year === year.toString();
    });
  };

  getMonthData = (data: any, month: number) => {
    return data.find((monthData: Month) => {
      return monthData.month === getMonthName(month);
    });
  };

  calculateImpactGoal = (year: number, month: number) => {
    const ceImpactGoals = this.props.ceImpactGoals
      ? this.props.ceImpactGoals
      : defaultCeImpactGoals;
    const impactGoalYearData = this.getYearData(ceImpactGoals.goals, year);
    const impactGoalMonthData =
      impactGoalYearData && this.getMonthData(impactGoalYearData.months, month);
    return (impactGoalMonthData && impactGoalMonthData.impactGoal) || '0';
  };

  calculateCurrentImpact = (year: number, month: number) => {
    const ceImpactData = this.props.ceImpactData
      ? this.props.ceImpactData
      : defaultCeImpactData;
    const currentImpactYearData = this.getYearData(ceImpactData.years, year);
    const currentImpactMonthData =
      currentImpactYearData &&
      this.getMonthData(currentImpactYearData.months, month);
    return (
      (currentImpactMonthData && currentImpactMonthData.impactTotal) || '0'
    );
  };

  calculatePercentage = () => {
    const { impactGoal, currentImpact } = this.state;
    const progress = (parseFloat(currentImpact) / parseFloat(impactGoal)) * 100;
    return Math.min(progress, 100);
  };

  toggleEdit = () => {
    this.setState({
      isEditing: !this.state.isEditing,
    });
  };

  resetGoal = () => {
    this.toggleEdit();
    this.setState({ newImpactGoal: this.state.impactGoal });
  };

  prepareMutationData = () => {
    const today = DateTime.local();
    const month = today.month;
    const { newImpactGoal } = this.state;

    const ceImpactGoals = this.props.ceImpactGoals
      ? this.props.ceImpactGoals
      : defaultCeImpactGoals;

    return ceImpactGoals.goals.map((goal: any) => {
      const { __typename, ...restGoal } = goal;
      restGoal.months = restGoal.months.map((monthData: Month) => {
        const { __typename, ...restMonthData } = monthData;
        if (restMonthData.month === getMonthName(month)) {
          restMonthData.impactGoal = newImpactGoal;
        }
        return restMonthData;
      });
      return restGoal;
    });
  };

  saveNewImpactGoal = (
    ceUpdate: MutationFn<any, CeUpdateMutationVariables>,
  ) => {
    const { newImpactGoal, impactGoal } = this.state;

    if (newImpactGoal !== '' && newImpactGoal !== impactGoal) {
      this.setState({ isUpdating: true });
      const updateGoals = this.prepareMutationData();
      const ceContent = {
        ceId: this.props.ceId ? this.props.ceId : this.props.user.userId,
        ceImpactGoals: {
          goals: updateGoals,
        },
      };

      const variables: CeUpdateMutationVariables = {
        input: {
          ce: ceContent,
        },
      };
      return ceUpdate({ variables });
    } else {
      this.toggleEdit();
    }
  };

  updateGoal = (event: any) => {
    if (validInput(event.target.value)) {
      this.setState({ newImpactGoal: toCurrency(event.target.value) });
    }
  };

  getDaysToEndOfMonth = () => {
    const today = DateTime.local();
    const day = today.day;
    const daysInMonth = today.daysInMonth;
    return daysInMonth - day;
  };

  onMutationCompleted = () => {
    const date = DateTime.local();
    const { year, month } = date;
    const impactGoal = this.calculateImpactGoal(year, month);
    this.setState({ impactGoal, isUpdating: false }, () => this.toggleEdit());
  };

  validateCurrency = (
    rule: any,
    value: string,
    callback: (input?: string) => void,
  ) => {
    let valid_dollar_amt_regex = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
    if (valid_dollar_amt_regex.test(value)) {
      callback();
      return;
    }
    callback('Goal must be valid input');
  };

  updateCeAndTrack = async (ceUpdate: any) => {
    await this.saveNewImpactGoal(ceUpdate);
    analytics.track('CE Feature Used - Monthly Impact Goal', {});
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const percentage = this.calculatePercentage();
    const daysToEndOfMonth = this.getDaysToEndOfMonth();
    const { impactGoal, currentImpact, isEditing, isUpdating } = this.state;
    if (isEditing) {
      return (
        <div css={myImpactProgressStyles}>
          <header>
            <span className="title">Monthly Impact Goal</span>
            <AntButton className="edit" onClick={this.toggleEdit}>
              Edit
            </AntButton>
          </header>
          <div className="my-goal-content">
            <div className="my-goal-progress">
              <div className="impact-status-amnt">${impactGoal}</div>
              <div className="my-goal-progress-container">
                <MiniProgress
                  target={percentage}
                  targetLabel=""
                  percent={percentage}
                  strokeWidth={8}
                />
              </div>
              <div className="progress-info">
                <span className="progress-cash-goal">
                  ${currentImpact}/{impactGoal}
                </span>
                <span className="progress-time-left">
                  {daysToEndOfMonth} days left
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div css={myImpactProgressStyles}>
          <header>
            <span className="title">Monthly Impact Goal</span>
            <AntButton className="edit" onClick={this.resetGoal}>
              Cancel
            </AntButton>
          </header>
          <div className="my-goal-content">
            <div className="my-goal-progress">
              <div className="input-label">Impact goal</div>
              <Form layout="vertical" onSubmit={this.updateGoal}>
                <Form.Item>
                  {getFieldDecorator('impactGoal', {
                    initialValue: impactGoal,
                    rules: [
                      {
                        max: 15,
                        message: `Goal must be valid`,
                      },
                      {
                        validator: this.validateCurrency,
                      },
                    ],
                  })(<Input prefix="$" onChange={this.updateGoal} />)}
                </Form.Item>
                <Form.Item>
                  <div className="button-container">
                    <Mutation
                      onCompleted={this.onMutationCompleted}
                      mutation={CeUpdateMutation}
                      awaitRefetchQueries={true}
                      refetchQueries={[
                        {
                          query: GET_CE_IMPACT_GOALS_QUERY,
                        },
                      ]}
                    >
                      {(ceUpdate: MutationFn) => (
                        <Button
                          block
                          type="primary-regular"
                          onClick={() => this.updateCeAndTrack(ceUpdate)}
                        >
                          {isUpdating ? <Spin /> : 'Save'}
                        </Button>
                      )}
                    </Mutation>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      );
    }
  }
}

const MyImpactProgressForm = Form.create({ name: 'impact_form' })(
  MyImpactProgress,
);
export default MyImpactProgressForm;
