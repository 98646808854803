import gql from 'graphql-tag';
import { EventsPage_user } from '../../queries/Events';

const mutation = gql`
  mutation EventCancelMutation($input: EventCancelInput!) {
    eventCancel(input: $input) {
      user {
        ...EventsPage_user
      }
    }
  }
  ${EventsPage_user}
`;

export default mutation;
