import React from 'react';

// types
import ActivityEntry from '../types/ActivityEntry';
import sendToTrackEventV2Fn from '../types/sendToTrackEventV2';
import OpenVideoDialogFn from '../types/openVideoDialog';
import OpenButtonActionDialogFn from '../types/OpenButtonActionDialog';
import OnCardButtonClickedFn from '../types/OnCardButtonClicked';

// components
import CardContent from './CardContent';

interface Props {
  activity: ActivityEntry;
  openVideoDialog?: OpenVideoDialogFn;
  openButtonActionDialog?: OpenButtonActionDialogFn;
  openDialog?: boolean;
  client?: any;
  isContentfulSideBar?: boolean;
  sendToTrackEventV2?: sendToTrackEventV2Fn;
  onCardButtonClicked?: OnCardButtonClickedFn;
  archiveCardMutation?: (ledgerId: string, isArchived: boolean) => Promise<any>;
  removeArchivedCard?: (ledgerId: string) => void;
}

export default function ActionEngineCard({
  activity,
  openVideoDialog,
  openButtonActionDialog,
  openDialog,
  sendToTrackEventV2,
  onCardButtonClicked,
  isContentfulSideBar = false,
  archiveCardMutation,
  removeArchivedCard,
}: Props) {
  return (
    <CardContent
      activity={activity}
      openButtonActionDialog={openButtonActionDialog}
      openVideoDialog={openVideoDialog}
      openDialog={openDialog}
      sendToTrackEventV2={sendToTrackEventV2}
      isContentfulSideBar={isContentfulSideBar}
      onCardButtonClicked={onCardButtonClicked}
      archiveCardMutation={archiveCardMutation}
      removeArchivedCard={removeArchivedCard}
    />
  );
}
