import React from 'react';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash';
import { MuiButton, MuiInputAutosize } from '@onehope/design-system-ohw';
import { styles } from '@onehope/design-system';
import Close from '@material-ui/icons/Close';
import { FormikProps } from 'formik';
import { MyFormValues } from './FormTypes';

const {
  cssConstants: { textColor },
} = styles;

interface NoteFormProps {
  values: MyFormValues;
  editing: boolean;
  mutationLoading: boolean;
  onClose: () => void;
}

const Form = styled.div<{ editing: boolean }>`
  margin: 0 auto;
  padding: ${props => (props.editing ? '0px' : '16px')};
  @media all and (min-width: 968px) {
    padding: ${props => (props.editing ? '0px' : '32px')};
    width: 432px;
  }
`;

const Title = styled.div`
  height: 42px;
  /* font-family: PlayfairDisplay; */
  font-size: 32px;
  font-weight: bold;
  line-height: 1.31;
  letter-spacing: -0.5px;
  color: ${textColor};
  margin-bottom: 24px;
`;

const InputContainer = styled.div`
  margin: 0 auto;
`;

const Button = styled(MuiButton)`
  width: 100%;
`;

const Spacer = styled.div`
  padding-right: 20px;
`;

const CloseDialog = styled.div`
  width: 48px;
  height: 48px;
  position: absolute;
  padding-top: 21.4px;
  right: 0;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 35px;
`;

export default function NoteForm(
  props: FormikProps<MyFormValues> & NoteFormProps,
) {
  const {
    values,
    errors,
    touched,
    onClose,
    editing,
    handleChange,
    handleSubmit,
    setFieldTouched,
    mutationLoading,
  } = props;
  const { text } = values;

  const change = (name: any, e: React.SyntheticEvent) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const textChangeValid = (event: React.SyntheticEvent) => {
    return event.target.value.length <= 140;
  };

  const disableSubmit = mutationLoading || !isEmpty(errors) || isEmpty(touched);
  return (
    <form onSubmit={handleSubmit}>
      {!editing && (
        <CloseDialog onClick={onClose}>
          <Close />
        </CloseDialog>
      )}
      <Form editing={editing}>
        {!editing && <Title>Add new note</Title>}
        <>
          <InputContainer>
            <MuiInputAutosize
              label={editing ? 'Edit note' : 'Write a new note'}
              rows={1}
              rowsMax={16}
              maxCount="140"
              placeholder={'Enter note here'}
              value={text}
              name="text"
              id="text"
              validInput={text.length > 0}
              onChange={(e: React.SyntheticEvent) => {
                if (textChangeValid(e)) {
                  change('text', e);
                }
              }}
            />
          </InputContainer>
          <ButtonContainer>
            <Button type="SECONDARY" disableRipple onClick={onClose}>
              cancel
            </Button>
            <Spacer />
            <Button
              type="PRIMARY"
              disableRipple
              disabled={disableSubmit}
              onClick={handleSubmit}
            >
              save
            </Button>
          </ButtonContainer>
        </>
      </Form>
    </form>
  );
}
