import * as React from 'react';
import styled from '@emotion/styled';
import SvgWarning from '../../../../assets/warning';
import SvgTimes from '../../../../assets/times';

const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 12px;
  margin-bottom: 24px;
  border: solid 1px #faad14;
  background-color: #fffaeb;
  border-radius: 4px;
  padding: 16px;
`;

const WarningSvgContainer = styled.div`
  margin-right: 8px;
`;

const CloseSvgContainer = styled.span`
  cursor: pointer;
  margin-top: -10px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

type WarningCardProps = {
  message: string;
  setClose: () => void;
};

export default function WarningCard(props: WarningCardProps) {
  return (
    <WarningContainer>
      <WarningSvgContainer>
        <SvgWarning />
      </WarningSvgContainer>
      <div>
        <BoldText>Warning: </BoldText>
        {props.message}
      </div>
      <CloseSvgContainer onClick={props.setClose}>
        {/* <Close width="30" height="30" /> */}
        <SvgTimes />
      </CloseSvgContainer>
    </WarningContainer>
  );
}
