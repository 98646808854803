/* eslint-disable react/jsx-no-bind */
import * as React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SortIcon from '@material-ui/icons/ImportExport';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';

const { cssConstants } = s;
const TableCellContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const HeaderLabel = styled.div`
  font-family: ${cssConstants.font};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
`;

const SortIconWrapper = styled(SortIcon)`
  margin-left: 10px;
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

type listType = {
  [key: string]: boolean
}

interface TableHeaderContainerProps {
  handleSort: (sortBy: string) => void;
  columns: any[];
  noSortByList?: listType;
}

export default function TableHeaderContainer(props: TableHeaderContainerProps) {
  const { handleSort, columns, noSortByList } = props;
  const noSortOn = { ...noSortByList } as listType;
  return (
    <TableHead>
      <TableRow>
        {columns.map(column => {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              style={{
                height: 60,
                minWidth: column.minWidth,
                maxWidth: column.maxWidth,
              }}
            >
              <TableCellContainer>
                <HeaderLabel>{column.label}</HeaderLabel>
                {!noSortOn[column.id] && (
                  <SortIconWrapper onClick={() => handleSort(column.id)} />
                )}
              </TableCellContainer>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
