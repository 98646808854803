import React from 'react';
import AddressesTable from './AddressesTable/AddressesTable';

interface AddressesProps {
  addresses: any;
  selectedUserId: string;
}

const Addresses: React.FunctionComponent<AddressesProps> = ({
  addresses,
  selectedUserId,
}) => <AddressesTable selectedUserId={selectedUserId} addresses={addresses} />;
export default Addresses;
