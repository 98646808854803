import * as React from 'react';
import { styles as s } from '@onehope/design-system';
import Details from './Details';
import Notes from './Notes';
import Orders from './Orders';
import Events from './Events';

import { CEDash_CustomerDetailsPage_viewer_customer as ContactType } from '../../../queries/generatedTypes/CEDash_CustomerDetailsPage';

const { cssConstants } = s;

interface TabContentProps {
  tabValue: number;
  contact: ContactType;
}
const getPage = (tab: number) => {
  switch (tab) {
    case 1:
      return 'CONTACT NOTES';
    case 2:
      return 'CONTACT ORDERS';
    case 3:
      return 'CONTACT EVENTS';
    default:
      return 'CONTACT DETAILS';
  }
};
export default function TabContent(props: TabContentProps) {
  const { tabValue, contact } = props;
  // const { trackPageView } = useTrackingContextValue();
  // const page = getPage(tabValue);
  // React.useEffect(() => {
  //   //console.log(page);
  //   trackPageView({ page });
  // }, [page]);

  switch (tabValue) {
    case 1:
      return <Notes contact={contact} />;
    case 2:
      return <Orders contact={contact} />;
    case 3:
      return <Events contact={contact} />;
    default:
      return <Details contact={contact} />;
  }
}
