import React from 'react';
import { navigate } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../common/Loading';
import ContactDetailsPage from './ContactDetailsPage';
import CUSTOMER_DETAILS_QUERY from '../../queries/Customer';
import get from 'lodash/get';

interface EventPageProps {
  contactId: string | string[] | null | undefined;
  tab: string | string[];
}

function goBack() {
  navigate('/contacts');
  return null;
}

function ContactPage(props: EventPageProps) {
  const { data, loading, error } = useQuery(CUSTOMER_DETAILS_QUERY, {
    variables: { accountId: props.contactId },
  });
  if (loading) return <Loading />;
  if (error) return goBack();
  if (get(data.viewer, 'customer.id')) {
    const { customer: contact } = data.viewer;
    return <ContactDetailsPage contact={contact} tab={props.tab} />;
  }
  return null;
}

export default ContactPage;
