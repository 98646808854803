import React, { useState } from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import down from './assets/down.svg';

const HowItWorksContainer = styled.div`
  padding-left: 40px;
  padding-bottom: 40px;
  padding-right: 40px;
  padding-top: 50px;
  width: 75%;
  margin-bottom: 30px;
  background-color: #fafafa;
  /* max-width: 1500px; */
`;

const mobileContainer = css`
  width: 100% !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
`;

const ComponentTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2.5px;
`;

const MobileComponentTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2.5px;
  margin-left: -10px;
`;

const BulletPointWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  font-size: 16px;
  margin-top: 20px;
`;

const CircleNumber1 = styled.div`
  border: 1px solid black;
  height: 25px;
  min-width: 25px;
  padding-left: 7.5px;
  margin-top: 3px;
  border-radius: 15px;
  margin-right: 18px;
`;

const CircleNumber = styled.div`
  height: 26px;
  min-width: 26px;
  padding-left: 7px;
  border: 1px solid black;
  border-radius: 15px;
  margin-top: 8px;
  margin-right: 17px;
`;

const AccordianHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const downArrowStyle = css`
  margin-top: -13px;
  height: 48px;
`;

export default function HowItWorks() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [openAccordian, setOpenAccordian] = useState(false);

  const toggleAccordian = () => {
    setOpenAccordian(!openAccordian);
  };

  const howItWorksContent = (
    <>
      <BulletPointWrapper>
        <CircleNumber1>1</CircleNumber1>
        <div>
          Apply 1 or 2 event kit credits towards 6 or 12 bottles of wine when
          creating a new event. *
        </div>
      </BulletPointWrapper>
      <BulletPointWrapper>
        <CircleNumber>2</CircleNumber>
        <div>
          Once your event has been created, either you or the Host can select
          the wine for the tasting by using the event’s unique ordering link.
        </div>
      </BulletPointWrapper>
      <BulletPointWrapper>
        <CircleNumber>3</CircleNumber>
        <div>
          Don’t have any event kit credits in the bank? No worries, wine can
          still be purchased at a discount starting at $99 for 6 bottles.
        </div>
      </BulletPointWrapper>
      <div>
        * Please note your Event Kit Credit will expire after 30 days of being
        issued.
      </div>
    </>
  );

  return (
    <HowItWorksContainer css={isMobile && mobileContainer}>
      {isMobile ? (
        <>
          <AccordianHeaderContainer>
            <MobileComponentTitle>HOW IT WORKS</MobileComponentTitle>
            <img
              src={down}
              css={downArrowStyle}
              onClick={toggleAccordian}
            ></img>
          </AccordianHeaderContainer>
          {openAccordian && howItWorksContent}
        </>
      ) : (
        <>
          <ComponentTitle>HOW IT WORKS</ComponentTitle>
          {howItWorksContent}
        </>
      )}
    </HowItWorksContainer>
  );
}
