import React, { useCallback } from 'react';
import getRelativeTime from './helpers/getRelativeTime';
import ActivityEntry from './types/ActivityEntry';

interface CreateContextProps {
  createContext?: ActivityEntry['createContext'];
  time: ActivityEntry['dateCreated'];
}

export const CreateContext = ({ createContext, time }: CreateContextProps) => {
  const getAudienceVerb = useCallback((audienceDirection?: string) => {
    if (!audienceDirection) {
      return null;
    }

    if (audienceDirection === 'audience_enter') {
      return 'entering';
    }
    return 'exiting';
  }, []);

  // Published {card-name} to {user-name} upon {entering/exiting} {audience-name}
  if (createContext) {
    switch (createContext.triggerType) {
      default:
        return null;

      case 'cardRule':
        return (
          <div className="change-context">
            <p>
              Published <b>{createContext.templateName}</b> to{' '}
              <b>{createContext.userName}</b> upon{' '}
              {getAudienceVerb(
                createContext.cardRuleContext?.audienceDirection,
              )}{' '}
              audience <b>{createContext.cardRuleContext?.audienceName}</b>{' '}
              &mdash; {getRelativeTime(time)}
            </p>
          </div>
        );

      case 'broadcast':
        return (
          <div className="change-context">
            <p>
              Published <b>{createContext.templateName}</b> to{' '}
              <b>{createContext.userName}</b> upon <b>broadcast</b> &mdash;{' '}
              {getRelativeTime(time)}
            </p>
          </div>
        );
    }
  }

  return null;
};
