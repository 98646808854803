import React from 'react';
import { Formik } from 'formik';
/* Components */
/* Helpers / Functions */

/* Types */
type FormContainerProps = {
  enableReinitialize?: boolean;
  formToUse: any;
  initialValues: any;
  validationSchema: any;
  validateOnChange?: boolean;
  validateOnBlur?: boolean;
  onSubmit: (values: any, { setErrors }: any) => Promise<void>;
};

function FormContainer(props: FormContainerProps) {
  const {
    enableReinitialize,
    initialValues,
    onSubmit,
    formToUse,
    validationSchema,
    validateOnBlur,
    validateOnChange,
  } = props;
  return (
    <Formik
      enableReinitialize={!!enableReinitialize}
      validateOnChange={!!validateOnChange}
      validateOnBlur={!!validateOnBlur}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {formToUse}
    </Formik>
  );
}

export default FormContainer;
