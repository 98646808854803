import React from 'react';
import { Query } from 'react-apollo';

import GET_SUBSCRIPTIONS_QUERY from '../../queries/Subscriptions';
import { getCurrentUserId } from '../../utils/utils';

import Loading from '../../common/Loading';

interface SubscriptionProps {
  selectedUserId: string;
}

export default class Subscriptions extends React.Component<SubscriptionProps> {
  render() {
    const { selectedUserId } = this.props;
    return (
      <Query
        query={GET_SUBSCRIPTIONS_QUERY}
        variables={{
          accountId: selectedUserId,
          csPortal: true,
        }}
      >
        {({ loading, error, data }: any) => {
          if (loading) return <Loading />;
          if (error) return <div>Error! ${error.message}</div>;
          return (
            <div>
              {`${data.viewer.userInfo.firstName} ${data.viewer.userInfo.lastName}`}
            </div>
          );
        }}
      </Query>
    );
  }
}
