import React, { Component } from 'react';
import { Query } from 'react-apollo';

import Loading from '../common/Loading';
import Layout from '../components/MainLayout/layout/layout';
import { getCurrentUserId } from '../utils/utils';
import Orders from '../components/Orders';
import GET_ORDERS_QUERY from './../queries/Orders';

interface PersonalOrdersPageProps {
  location: any;
}

class PersonalOrdersPage extends Component<PersonalOrdersPageProps, {}> {
  render() {
    const { location } = this.props;
    const currentUser = getCurrentUserId(location);

    return (
      <Layout selectedUserId={currentUser}>
        {/*<Query*/}
        {/*  query={GET_ORDERS_QUERY}*/}
        {/*  variables={{*/}
        {/*    accountId: currentUser,*/}
        {/*    filterBy: 'any',*/}
        {/*    sortBy: 'data',*/}
        {/*    csPortal: true,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {({ loading, error, data, fetchMore }) => {*/}
        {/*    if (loading) return <Loading />;*/}
        {/*    if (error) return `Error! ${error.message}`;*/}
        {/*    const onLoadMore = () =>*/}
        {/*      fetchMore({*/}
        {/*        variables: {*/}
        {/*          cursor: data.viewer.userInfo.orders.pageInfo.endCursor,*/}
        {/*        },*/}
        {/*        updateQuery: (previousResult, { fetchMoreResult }) => {*/}
        {/*          const newEdges = fetchMoreResult.viewer.userInfo.orders.edges;*/}
        {/*          const pageInfo =*/}
        {/*            fetchMoreResult.viewer.userInfo.orders.pageInfo;*/}
        {/*          return newEdges.length*/}
        {/*            ? {*/}
        {/*                // Put the new comments at the end of the list and update `pageInfo`*/}
        {/*                // so we have the new `endCursor` and `hasNextPage` values*/}
        {/*                viewer: {*/}
        {/*                  ...previousResult.viewer,*/}
        {/*                  userInfo: {*/}
        {/*                    ...previousResult.viewer.userInfo,*/}
        {/*                    orders: {*/}
        {/*                      __typename:*/}
        {/*                        previousResult.viewer.userInfo.orders*/}
        {/*                          .__typename,*/}
        {/*                      edges: [*/}
        {/*                        ...previousResult.viewer.userInfo.orders.edges,*/}
        {/*                        ...newEdges,*/}
        {/*                      ],*/}
        {/*                      pageInfo,*/}
        {/*                    },*/}
        {/*                  },*/}
        {/*                },*/}
        {/*              }*/}
        {/*            : previousResult;*/}
        {/*        },*/}
        {/*      });*/}
        {/*    const {*/}
        {/*      viewer: {*/}
        {/*        userInfo: { firstName, lastName, orders },*/}
        {/*      },*/}
        {/*    } = data;*/}
        {/*    return (*/}
        {/*      <React.Fragment>*/}
        {/*        <h2>{`${firstName} ${lastName}'s Personal Orders`}</h2>*/}
        {/*        <Orders*/}
        {/*          orders={orders.edges}*/}
        {/*          onLoadMore={onLoadMore}*/}
        {/*          selectedUserId={currentUser}*/}
        {/*          hasNextPage={orders.pageInfo.hasNextPage}*/}
        {/*        />*/}
        {/*      </React.Fragment>*/}
        {/*    );*/}
        {/*  }}*/}
        {/*</Query>*/}
      </Layout>
    );
  }
}

export default PersonalOrdersPage;
