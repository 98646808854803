import React from 'react';
import { DateTime } from 'luxon';
import { Menu } from 'antd';
import { MutedHorizontalRule } from '@onehope/design-system';

import { getMonthName } from '../../../utils/utils';
import myImpactGoalStyles, { margin } from './ImpactToDateStyles';
import Up from '../../../assets/up';
import Down from '../../../assets/down';

function checkPercentage(value: string): JSX.Element {
  if (parseFloat(value) >= 0) {
    return <Up />;
  } else {
    return <Down />;
  }
}

interface Month {
  month: string;
  impactTotal: string;
}
interface Year {
  year: string;
  months: Array<Month>;
}

interface MyImpactProps {
  ceImpactData: {
    years: Array<Year>;
    lifetime: string;
  };
}

interface MyImpactState {
  current: string;
  selectedKey: string;
  impactProgress: string;
}

type calculateMonthlyImpactParams = {
  thisMonthImpact: string;
  lastMonthImpact: string;
};

export default class MyImpact extends React.Component<
  MyImpactProps,
  MyImpactState
> {
  constructor(props: MyImpactProps) {
    super(props);
    const { ceImpactData } = this.props;
    this.state = {
      impactProgress: this.getCurrMonthProgress(),
      selectedKey: '1',
      current: this.getCurrMonthImpact(),
    };
  }

  setCurrentTotal = (value: string) => {
    this.setState({
      current: value,
    });
  };

  getCurrMonthImpact = () => {
    if (this.props.ceImpactData) {
      const date = DateTime.local();
      return this.calculateMonthlyImpact(date);
    }
    return '0';
  };

  getCurrMonthProgress = () => {
    if (this.props.ceImpactData) {
      const date = DateTime.local();
      const lastMonth = date.minus({ months: 1 });
      const thisMonthImpact = this.calculateMonthlyImpact(date);
      const lastMonthImpact = this.calculateMonthlyImpact(lastMonth);
      return this.calculateMonthlyGrowth({
        thisMonthImpact,
        lastMonthImpact,
      });
    }
    return '0';
  };

  calculateMonthlyImpact = (date: DateTime) => {
    if (this.props.ceImpactData) {
      const {
        ceImpactData: { years },
      } = this.props;
      const currentYear = date.year.toString();
      const month = date.monthLong.toLowerCase();
      const currentYearData = years.find(
        (yearData: Year) => yearData.year === currentYear,
      );
      const monthData =
        currentYearData &&
        currentYearData.months.find(
          (monthData: Month) => monthData.month.toLowerCase() === month,
        );
      return monthData ? monthData.impactTotal : '0';
    }
    return '0';
  };

  getLastMonthImpact = () => {
    if (this.props.ceImpactData) {
      const date = DateTime.local();
      const lastMonth = date.minus({ months: 1 });
      const twoMonthsAgo = lastMonth.minus({ months: 1 });
      const lastMonthImpact = this.calculateMonthlyImpact(lastMonth);
      const twoMonthsAgoImpact = this.calculateMonthlyImpact(twoMonthsAgo);
      const impactProgress = this.calculateMonthlyGrowth({
        thisMonthImpact: lastMonthImpact,
        lastMonthImpact: twoMonthsAgoImpact,
      });
      return this.setState({ current: lastMonthImpact, impactProgress });
    }
    return '0';
  };

  calculateMonthlyGrowth = ({
    thisMonthImpact,
    lastMonthImpact,
  }: calculateMonthlyImpactParams) => {
    return lastMonthImpact !== '0'
      ? (
          ((parseFloat(thisMonthImpact) - parseFloat(lastMonthImpact)) /
            parseFloat(lastMonthImpact)) *
          100
        ).toFixed(2)
      : '0';
  };

  getThisMonthImpact = () => {
    const thisMonthImpact = this.getCurrMonthImpact();
    const impactProgress = this.getCurrMonthProgress();
    return this.setState({ current: thisMonthImpact, impactProgress });
  };

  handleClick = (e: { key: any }) => {
    const { key } = e;
    this.setState({ selectedKey: key });
    const { ceImpactData } = this.props;
    switch (key) {
      case '1':
        return this.getThisMonthImpact();
      case '2':
        return this.getLastMonthImpact();
      case '3':
        if (this.props.ceImpactData && this.props.ceImpactData.lifetime) {
          return this.setCurrentTotal(ceImpactData.lifetime);
        } else {
          return '0';
        }
      default:
        return '0';
    }
  };

  render() {
    const current = parseFloat(this.state.current);
    const { selectedKey, impactProgress } = this.state;
    const impactAmount = current.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); //calculateImpact(current);
    const svg = checkPercentage(impactProgress);
    const showGrowth = selectedKey !== '3';
    return (
      <div css={myImpactGoalStyles}>
        <header>
          <span className="title">Impact to Date</span>
        </header>
        <div className="my-goal-content">
          <div className="my-goal-amount">
            <Menu
              onClick={this.handleClick}
              mode="horizontal"
              defaultSelectedKeys={['1']}
              className="my-goal-range-picker"
            >
              <Menu.Item
                key={1}
                style={{
                  borderBottomLeftRadius: '4px',
                  borderTopLeftRadius: '4px',
                }}
              >
                This month
              </Menu.Item>
              <Menu.Item key={2}>Last month</Menu.Item>
              <Menu.Item
                key={3}
                style={{
                  borderBottomRightRadius: '4px',
                  borderTopRightRadius: '4px',
                }}
              >
                All time
              </Menu.Item>
            </Menu>
          </div>
        </div>
        <div className="impact-amnt">${impactAmount}</div>
        <div css={margin}>
          <MutedHorizontalRule style={{ margin: 0 }} />
        </div>
        <div className="progress-info">
          {showGrowth && (
            <span>
              <span className="up-svg">{svg}</span>
              <span className="progress-percentage">{impactProgress}%</span>
            </span>
          )}
        </div>
      </div>
    );
  }
}
