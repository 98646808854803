import gql from 'graphql-tag';

const GET_ORDERS_BY_ACCOUNT_ID_QUERY = gql`
    query CEDash_OrderByAccountQuery($byContactId: String!) {
        viewer {
            id
            user {
                id
                orders(first: 10000, byContactId: $byContactId)
                @connection(key: "Orders_orders_account", filter: []) {
                    edges {
                        __typename
                        node {
                            id
                            orderId
                            orderNumber
                            accountFullName
                            createDate
                            orderStatus
                            orderTotal
                            orderTax
                            orderSubTotal
                            email
                            giftReceipt
                            isProcessing
                            giftCards
                            giftCardSavings
                            promoCodes
                            promoCodeSavings
                            isAutoShipOrder
                            isPreferredCustomerOrder
                            trinityTransmissionStatus
                            trinityOrderId
                            trinityPartyId
                            shippingChosen
                            shippingInfo {
                                carrier
                                tracking
                                expectedDeliveryDate
                            }
                            shippingAddress {
                                firstName
                                lastName
                                specialInstructions
                                street
                                addressLineTwo
                                city
                                state
                                zip
                                phone
                            }
                        }
                    }
                    pageInfo {
                        endCursor
                        hasNextPage
                        hasPreviousPage
                    }
                }
            }
        }
    }
`;

export default GET_ORDERS_BY_ACCOUNT_ID_QUERY ;
