import * as React from 'react';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

const SlideTransition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    // @ts-ignore: Type 'string' is not assignable to type 'ReactElement<any, any>'
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

export default SlideTransition;
