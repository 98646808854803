import * as React from 'react';
import { MuiButton } from '@onehope/design-system-ohw';
import { DateTime } from 'luxon';
import IconMenu from './IconMenu';

interface RenderProps {
  orderId: string | null | undefined;
  handleTrackOrder: (shipping: any) => void;
  accountId: string | null | undefined;
  shipping:
    | {
        tracking: string[];
        carrier: string;
      }
    | null
    | undefined;
}

interface Column {
  id:
    | 'menu'
    | 'accountFullName'
    | 'orderNumber'
    | 'createDate'
    | 'availableTastingFlightCode.code'
    | 'availableTastingFlightCode.expiresOn'
    | 'trackOrder'
    | 'orderMenu';
  label?: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'right';
  format?: (value: string, timeZone?: string | undefined) => void;
  render?: (renderProps: RenderProps) => any;
}

const columns: Column[] = [
  {
    id: 'accountFullName',
    label: 'Name',
    minWidth: 170,
    align: 'left',
    format: value => (value ? value : ''),
  },
  {
    id: 'orderNumber',
    label: 'Order #',
    minWidth: 140,
    align: 'left',
    format: value => (value ? value : ''),
  },
  {
    id: 'createDate',
    label: 'Order Date',
    minWidth: 70,
    align: 'left',
    format: (value, timeZone: string | undefined) =>
      DateTime.fromISO(value, { zone: timeZone }).toLocaleString(
        DateTime.DATE_SHORT,
      ),
  },
  {
    id: 'availableTastingFlightCode.code',
    label: 'Promo Code',
    minWidth: 70,
    align: 'left',
    format: value => (value ? value : ''),
  },
  {
    id: 'availableTastingFlightCode.expiresOn',
    label: 'Promo Code Exp',
    minWidth: 70,
    align: 'left',
    format: (value, timeZone: string | undefined) =>
      DateTime.fromISO(value, { zone: timeZone }).toLocaleString(
        DateTime.DATE_SHORT,
      ),
  },
  {
    id: 'trackOrder',
    minWidth: 0,
    align: 'left',
    render: ({ handleTrackOrder, shipping }: RenderProps) => (
      <MuiButton type="ROUND" onClick={() => handleTrackOrder(shipping)}>
        Track Order
      </MuiButton>
    ),
  },
  {
    id: 'orderMenu',
    minWidth: 0,
    align: 'left',
    render: ({ orderId, accountId }: RenderProps) => (
      <IconMenu accountId={accountId} orderId={orderId} />
    ),
  },
];

export default columns;
