import * as React from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import Table from '@material-ui/core/Table';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { CEDash_CustomersQuery_viewer_user_customers_edges as ContactsEdges } from '../../../queries/generatedTypes/CEDash_CustomersQuery';

const { cssConstants } = s;

const TableWrapper = styled.div`
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  //max-height: 600px;
  overflow: auto;
  background-color: white;
  margin-bottom: 24px;
`;

interface ContactsListViewProps {
  contacts: (ContactsEdges | null)[] | null;
  page: number;
  rowsPerPage: number;
  handleSortContacts: (sortBy: string) => void;
  handleOrderAdd: (contactId: string) => void;
}

export default function ContactsListViewTable(props: ContactsListViewProps) {
  const {
    handleSortContacts,
    contacts,
    page,
    rowsPerPage,
    handleOrderAdd,
  } = props;
  return (
    <TableWrapper>
      <Table stickyHeader aria-label="events table">
        <TableHeader
          page={page}
          handleSortContacts={handleSortContacts}
          contacts={contacts}
          rowsPerPage={rowsPerPage}
        />
        <TableBody
          page={page}
          contacts={contacts}
          rowsPerPage={rowsPerPage}
          handleOrderAdd={handleOrderAdd}
        />
      </Table>
    </TableWrapper>
  );
}
