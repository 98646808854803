import React from 'react';
import { makeStyles, ThemeProvider, theme } from '@onehope/design-system-v2';
import ActionEngineCard from './ActionEngineCard';
import ActivityEntry from '../types/ActivityEntry';
import { CreateContext } from '../CreateContext';
import { DeleteContext } from '../DeleteContext';
import sendToTrackEventV2Fn from '../types/sendToTrackEventV2';
import OpenVideoDialogFn from '../types/openVideoDialog';
import OpenButtonActionDialogFn from '../types/OpenButtonActionDialog';
import OnCardButtonClickedFn from '../types/OnCardButtonClicked';

const styles = makeStyles(() => ({
  wrapper: {
    border: (props: { admin: boolean }) =>
      props.admin ? '1px dotted black' : '',
  },
}));

interface Props {
  activity: ActivityEntry;
  admin: boolean;
  openDialog?: boolean;
  openVideoDialog?: OpenVideoDialogFn;
  openButtonActionDialog?: OpenButtonActionDialogFn;
  sendToTrackEventV2?: sendToTrackEventV2Fn;
  onCardButtonClicked?: OnCardButtonClickedFn;
  archiveCardMutation?: (ledgerId: string, isArchived: boolean) => Promise<any>;
  removeArchivedCard?: (ledgerId: string) => void;
}

const Activity = ({
  activity,
  admin = false,
  sendToTrackEventV2,
  openDialog,
  openVideoDialog,
  openButtonActionDialog,
  onCardButtonClicked,
  archiveCardMutation,
  removeArchivedCard,
}: Props) => {
  const { wrapper } = styles({ admin });
  return (
    <ThemeProvider theme={theme}>
      <div className={wrapper}>
        {admin === true && (
          <>
            {activity.deleteContext && (
              <DeleteContext
                deleteContext={activity.deleteContext}
                time={activity.dateDeleted}
              />
            )}
            {activity.createContext && (
              <CreateContext
                createContext={activity.createContext}
                time={activity.dateCreated}
              />
            )}
          </>
        )}
        <ActionEngineCard
          activity={activity}
          openDialog={openDialog}
          openVideoDialog={openVideoDialog}
          openButtonActionDialog={openButtonActionDialog}
          sendToTrackEventV2={sendToTrackEventV2}
          onCardButtonClicked={onCardButtonClicked}
          archiveCardMutation={archiveCardMutation}
          removeArchivedCard={removeArchivedCard}
        />
      </div>
    </ThemeProvider>
  );
};

export default Activity;
