import gql from 'graphql-tag';

const mutation = gql`
    mutation ContactsCSVExportMutation($input: ContactsCSVExportInput!) {
        contactsCSVExport(input: $input) {
            csvUploaded
        }
    }
`;
export default mutation;
