import React from 'react';
import { navigate } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../common/Loading';
import EventDetailsPage from './EventDetailsPage';
import EVENT_DETAILS_QUERY from '../../queries/Event';
import get from 'lodash/get';

interface EventPageProps {
  eventId: string | string[] | null | undefined;
  tab: string | string[];
  contactId?: string | string[] | null | undefined;
}

function goBack() {
  navigate('/events');
  return null;
}

function EventPage(props: EventPageProps) {
  const { data, loading, error } = useQuery(EVENT_DETAILS_QUERY, {
    variables: { eventId: props.eventId },
    pollInterval: 10000,
  });
  if (loading) return <Loading />;
  if (error) return goBack();
  if (get(data.viewer, 'event.id')) {
    const { tab, contactId } = props;
    const { event, user } = data.viewer;
    const isLoggedInAsAdmin =
      user?.permissions?.oneCommerce?.admin?.access ?? false;
    if (!isLoggedInAsAdmin && user.userId !== event.accountId) {
      return goBack();
    }
    return (
      <EventDetailsPage
        event={event}
        tab={tab}
        contactId={contactId}
        user={user}
      />
    );
  }
  return null;
}

export default EventPage;
