import React from 'react';
import CreditsTable from './CreditsTable';
import { CreditsPage_user_credits as CreditsType } from '../../queries/generatedTypes/CreditsPage_user';

interface CreditsProps {
  credits: (CreditsType | null)[] | null;
  pageNumber: number;
  numberOfTotalCredits: number;
  filterBy: string;
  setPageNumber: (page: number) => void;
  setFilterBy: () => void;
  handleSortCredits: () => void;
}

function TablePageWrapper(props: CreditsProps) {
  const {
    credits,
    pageNumber,
    setPageNumber,
    numberOfTotalCredits,
    setFilterBy,
    filterBy,
    handleSortCredits,
  } = props;
  const creditEdges = credits?.edges;

  return (
    <CreditsTable
      credits={creditEdges}
      page={pageNumber}
      setPageNumber={setPageNumber}
      setFilterBy={setFilterBy}
      filterBy={filterBy}
      handleSortCredits={handleSortCredits}
      numberOfTotalCredits={numberOfTotalCredits}
    />
  );
}

export default TablePageWrapper;
