import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import EventsPageWrapper from './EventsWrapper';

import GET_EVENTS_BY_HOST_ACCOUNT_QUERY from '../../../../queries/EventsByHostAccount';
import { CEDash_CustomerDetailsPage_viewer_customer as ContactType } from '../../../../queries/generatedTypes/CEDash_CustomerDetailsPage';
import { useTrackingContextValue } from '../../../../contexts/TrackingContext';

import Loading from '../../../../common/Loading';

interface ContactEventsPageProps {
  contact: ContactType;
}

function ContactEventsPage(props: ContactEventsPageProps) {
  const { contact } = props;
  const { data, loading, error } = useQuery(GET_EVENTS_BY_HOST_ACCOUNT_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      byHostAccountId: contact.accountId,
    },
  });

  const { trackPageView } = useTrackingContextValue();

  useEffect(() => {
    trackPageView({ page: 'CONTACT EVENTS' });
  }, []);

  if (error) return null;
  if (get(data, 'viewer.user.events.edges')) {
    const { events } = data.viewer.user;
    return <EventsPageWrapper contact={contact} events={events} />;
  }
  return <Loading />;
}

export default ContactEventsPage;
