import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Query } from 'react-apollo';
import { DateTime } from 'luxon';

import Loading from '../common/Loading';
import Layout from '../components/MainLayout/layout/layout';
import { getCurrentUserId } from '../utils/utils';
import { getPageTitle } from '../utils/site';
import GET_DASHBOARD_QUERY from '../queries/Dashboard';
import getFallBackImageForCategory from '../components/Dashboard/Blog/fallbackImages';

interface DashboardPageProps {
  location: any;
}

interface DashboardPageState {
  noMorePolling: boolean;
  Dashboard: any;
}

const BLOG_FEATURED_TAG_ID = 12;

class DashboardPage extends Component<DashboardPageProps, DashboardPageState> {
  state = {
    noMorePolling: false,
    Dashboard: null,
  };

  componentDidMount() {
    import('../components/Dashboard').then(({ default: Dashboard }) =>
      this.setState({ Dashboard }),
    );
    setTimeout(() => this.setState({ noMorePolling: true }), 3000);
  }

  pageInfo = () => (
    <Helmet>
      <title>{getPageTitle('Home')}</title>
    </Helmet>
  );

  render() {
    const { location } = this.props;
    const { Dashboard } = this.state;
    const currentUser = getCurrentUserId(location);
    const pageInfo = this.pageInfo();

    if (!Dashboard) return <Loading />;

    return (
      <Layout selectedUserId={currentUser} location={location}>
        <Query
          query={GET_DASHBOARD_QUERY}
          pollInterval={500}
          notifyOnNetworkStatusChange
        >
          {({ loading, error, data, stopPolling }: any) => {
            if (loading) return <Loading />;
            if (error) return <div>Error! ${error.message}</div>;

            const {
              user,
              workRampInfo,
              ceImpactData,
              ceImpactGoals,
              ceId,
              tasks,
              noCoursesInProgress,
              fastStartCheck,
              mentorInfo,
              customUrl,
              fastStartEndDate,
              mostRecentPosts,
              featuredPosts,
            } = getCeDashboardInfo(data);

            if (
              this.state.noMorePolling ||
              (mentorInfo && customUrl && fastStartEndDate)
            ) {
              stopPolling();
            } else {
              return <Loading />;
            }
            return (
              <React.Fragment>
                {pageInfo}
                <Dashboard
                  user={user}
                  workRampInfo={workRampInfo}
                  ceImpactData={ceImpactData}
                  ceImpactGoals={ceImpactGoals}
                  ceId={ceId}
                  tasks={tasks}
                  noCoursesInProgress={noCoursesInProgress}
                  fastStartCheck={fastStartCheck}
                  mentorInfo={mentorInfo}
                  customUrl={customUrl}
                  fastStartEndDate={fastStartEndDate}
                  mostRecentPosts={mostRecentPosts}
                  featuredPosts={featuredPosts}
                  location={location}
                />
              </React.Fragment>
            );
          }}
        </Query>
      </Layout>
    );
  }
}
export default DashboardPage;

function getCeDashboardInfo(data: any) {
  const { user, blog: blogData } = data.viewer;
  const { ce = {}, customUrl, fastStartEndDate } = user;
  const {
    workRampInfo = null,
    ceImpactData,
    ceImpactGoals: ceImpactGoalsData,
    ceTaskList,
    ceId,
    mentorInfo,
  } = ce;

  const ceImpactGoals = {
    ...ceImpactGoalsData,
    goals: formatImpactGoalData(ceImpactGoalsData.goals),
  };

  const tasks = ceTaskList && ceTaskList.tasks ? ceTaskList.tasks : [];

  const noCoursesInProgress = !workRampInfo;
  let fastStartCheck = checkValidDates(
    user.fastStartEndDate,
    user.trinityEnrollDate,
  );

  const blog = blogData && Array.isArray(blogData.edges) ? blogData.edges : [];
  const { mostRecentPosts, featuredPosts } = formatBlogPosts(blog);

  return {
    user,
    workRampInfo,
    ceImpactData,
    ceImpactGoals,
    ceId,
    tasks,
    noCoursesInProgress,
    fastStartCheck,
    mentorInfo,
    customUrl,
    fastStartEndDate,
    mostRecentPosts,
    featuredPosts,
  };
}

function checkOldDate(date: Date, now = new Date()) {
  return date.getTime() > now.getTime();
}

function checkValidDates(
  fastStartDate: string,
  trinityDate: string,
  fastStartEndDate = new Date(fastStartDate),
) {
  if (fastStartDate && trinityDate) {
    return checkOldDate(fastStartEndDate);
  }
  return false;
}

function formatBlogPosts(posts: Object[]) {
  const formattedPosts = posts.reduce(
    (acc: any, edge: any) => {
      const { mostRecentPosts, featuredPosts } = acc;
      const { node: post } = edge;
      const badPost =
        !post ||
        !post.header ||
        !post.slug ||
        !post.datePublishedFull ||
        !post.images ||
        (!post.images.preview &&
          !getFallBackImageForCategory(decodeHtml(post.category)));

      if (badPost) {
        return acc;
      }

      const date = DateTime.fromISO(post.datePublishedFull, { zone: 'UTC-0' });
      const publishedDate = new Date(date.toLocal().toString());

      const formattedPost = {
        title: decodeHtml(post.header),
        publishedDate,
        postHref: `${process.env.GATSBY_VINE_URL}${post.slug}/`,
        previewImgHref:
          post.images.preview || getFallBackImageForCategory(post.category),
      };

      if (Array.isArray(post.tags) && post.tags.includes(BLOG_FEATURED_TAG_ID))
        featuredPosts.push(formattedPost);
      mostRecentPosts.push(formattedPost);

      return acc;
    },
    { mostRecentPosts: [], featuredPosts: [] },
  );
  return {
    mostRecentPosts: formattedPosts.mostRecentPosts.sort((a, b) =>
      a.publishedDate >= b.publishedDate ? -1 : 1,
    ),
    featuredPosts: formattedPosts.featuredPosts.sort((a, b) =>
      a.publishedDate >= b.publishedDate ? -1 : 1,
    ),
  };
}

// may need this for removing html tags from rendered wordpress blog content
function decodeHtml(html) {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

function formatImpactGoalData(goalData = []) {
  const now = new Date();
  const month = now.toLocaleString('default', { month: 'long' }).toLowerCase();
  const year = now.toLocaleString('default', { year: 'numeric' });
  const monthlyDataTemplate = { month, impactGoal: '50.00' };

  const yearlyGoalData = goalData.find(
    data => data.year === year && Array.isArray(data.months),
  );
  // goal data is missing year, add data for this month
  if (!yearlyGoalData) {
    goalData.push({ year, months: [monthlyDataTemplate] });
    return goalData;
  }

  const monthlyGoalData = yearlyGoalData.months.find(
    data => data.month === month,
  );
  if (!monthlyGoalData) {
    yearlyGoalData.months.push(monthlyDataTemplate);
  }
  return goalData;
}
