import React from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system-ohw';
import { Currency, MutedHorizontalRule } from '@onehope/design-system';

import {
  SummaryHeader,
  BreakdownTable,
  MutedText,
  TableRowText,
  TableRowBigText,
} from './components/styledElements';

import { CEDash_OrderWineSummaryPage_viewer_order as OrderType } from '../../../../queries/generatedTypes/CEDash_OrderWineSummaryPage';

const { cssConstants } = s;

const Container = styled.div`font-family: ${cssConstants.tertiaryFont};`;


type PriceTotalSummaryProps = {
  order: OrderType;
};

export default function PriceTotalSummary(props: PriceTotalSummaryProps) {
  const { order } = props;
  const {
    orderTotal,
    orderTax,
    orderSubTotal,
    orderShipping,
    shippingChosen,
  } = order;

  const summaryValues = {
    subtotal: orderSubTotal || 0,
    total: orderTotal || 0,
    taxTotal: orderTax || 0,
  };
  return (
    <Container>
      <SummaryHeader>Summary</SummaryHeader>
      <MutedHorizontalRule />
      <BreakdownTable>
        <TableRowText>Subtotal</TableRowText>
        <TableRowText>
          <Currency rawValue={Number(summaryValues.subtotal)} />
        </TableRowText>
      </BreakdownTable>
      <BreakdownTable>
        <TableRowText>
          Estimated Tax:
        </TableRowText>
        <TableRowText>
          <Currency rawValue={Number(summaryValues.taxTotal)} />
        </TableRowText>
      </BreakdownTable>
      <BreakdownTable>
        <TableRowText>
          Shipping: {shippingChosen}
        </TableRowText>
        <TableRowText>
          <Currency rawValue={Number(orderShipping)} />
        </TableRowText>
      </BreakdownTable>
      <MutedHorizontalRule />
      <BreakdownTable>
        <TableRowBigText>TOTAL</TableRowBigText>
        <TableRowBigText>
          <Currency rawValue={Number(summaryValues.total)} />
        </TableRowBigText>
      </BreakdownTable>
      <MutedHorizontalRule />
    </Container>
  );
}
