import React from 'react';
import Activity from './ActionEngineCard/Activity';
import { makeStyles, ThemeType } from '@onehope/design-system-v2';
import { ActivityEntry } from '.';

const styles = makeStyles((theme: ThemeType) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    [theme.breakpoints.only('md')]: {
      width: '80%',
      minWidth: '288px',
      marginBottom: '0px',
      height: '515px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: '288px',
      height: '500px',
    },
  },
}));

const emptyStateCardFeedItem: ActivityEntry = {
  id: '',
  object: {},
  dateCreated: new Date().toISOString(),
  dateArchived: null,
  dateDeleted: null,
  dateSaved: null,
  dateSucceeded: null,
  title: 'Want More Tips & Tricks?',
  message: [
    "We're here to grow your ONEHOPE business. View our training events today. The more you do, the more tips we'll provide!",
  ],
  buttonAction: {
    action: 'website',
    url: 'https://help.onehopewine.com/what-is-the-playbook',
    name: '',
    phoneNumber: '',
    body: '',
    emailAddress: '',
    subject: '',
    firstName: '',
    lastName: '',
  },
  buttonText: 'Learn More',
  image: {
    src: require('../src/asset/empty_state.jpg'),
    alt: 'empty_state_image',
  },
};

const EmptyStateCard = () => {
  const classes = styles();
  return (
    <div className={classes.wrapper}>
      <Activity
        activity={emptyStateCardFeedItem}
        admin={false}
        key={'emptyCard'}
      />
    </div>
  );
};

export default EmptyStateCard;
