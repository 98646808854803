import React, { useState } from 'react';
import styled from '@emotion/styled';
import CartHeader from './components/CartHeader';
import CartItem from './components/CartItem';
import { styles as s } from '@onehope/design-system-ohw';

const { cssConstants } = s;

const CartItems = styled.div`
`;

const CartContainer = styled.div`
  margin: 24px 0;
`;

const MutedHorizontalRule = styled.div`
  width: 100%;
  border-bottom: 1px solid ${cssConstants.neutral13};
  margin-bottom: 8px;
`;

interface ShoppingCartProps {
    orderItems: any;
}
export default function ShoppingCart(props: ShoppingCartProps) {
  const [shouldShow, setShouldShow] = useState(true);
  const { orderItems } = props;
  const itemComponents = orderItems?.map(({ product, priceBought, quantity }, index) =>
    <React.Fragment key={`item-${index}`}>
      <CartItem product={product} quantity={quantity} priceBought={priceBought} />
      {index < orderItems.length - 1
        ? <MutedHorizontalRule key={`hr-${index}`} />
        : null}
    </React.Fragment>,
  );

  return (
    <CartContainer>
      <CartHeader
        items={orderItems}
        shouldShow={shouldShow}
        setShouldShow={setShouldShow}
      />
      <CartItems>
        {shouldShow ? itemComponents : null}
      </CartItems>
    </CartContainer>
  );
}
