import React from 'react';

const NotificationAlert = (props: any) => (
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_2185_34461" fill="white">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.88 9V14.82L18 16.94V18H0V16.94L2.12 14.82V9C2.12 5.75 4.37 3.03 7.41 2.31V1.59C7.41 0.71 8.12 0 9 0C9.88 0 10.59 0.71 10.59 1.59V2.31C13.63 3.03 15.88 5.75 15.88 9ZM9 21C7.9 21 7.01 20.11 7.01 19.01H10.99C10.99 20.11 10.1 21 9 21Z"
      />
    </mask>
    <path
      d="M15.88 14.82H14.88V15.2342L15.1729 15.5271L15.88 14.82ZM18 16.94H19V16.5258L18.7071 16.2329L18 16.94ZM18 18V19H19V18H18ZM0 18H-1V19H0V18ZM0 16.94L-0.707107 16.2329L-1 16.5258V16.94H0ZM2.12 14.82L2.82711 15.5271L3.12 15.2342V14.82H2.12ZM7.41 2.31L7.64047 3.28308L8.41 3.10082V2.31H7.41ZM10.59 2.31H9.59V3.10082L10.3595 3.28308L10.59 2.31ZM7.01 19.01V18.01H6.01V19.01H7.01ZM10.99 19.01H11.99V18.01H10.99V19.01ZM16.88 14.82V9H14.88V14.82H16.88ZM18.7071 16.2329L16.5871 14.1129L15.1729 15.5271L17.2929 17.6471L18.7071 16.2329ZM19 18V16.94H17V18H19ZM0 19H18V17H0V19ZM-1 16.94V18H1V16.94H-1ZM1.41289 14.1129L-0.707107 16.2329L0.707107 17.6471L2.82711 15.5271L1.41289 14.1129ZM1.12 9V14.82H3.12V9H1.12ZM7.17953 1.33692C3.69913 2.16122 1.12 5.27566 1.12 9H3.12C3.12 6.22434 5.04087 3.89878 7.64047 3.28308L7.17953 1.33692ZM6.41 1.59V2.31H8.41V1.59H6.41ZM9 -1C7.56772 -1 6.41 0.157715 6.41 1.59H8.41C8.41 1.26228 8.67228 1 9 1V-1ZM11.59 1.59C11.59 0.157715 10.4323 -1 9 -1V1C9.32772 1 9.59 1.26228 9.59 1.59H11.59ZM11.59 2.31V1.59H9.59V2.31H11.59ZM16.88 9C16.88 5.27566 14.3009 2.16122 10.8205 1.33692L10.3595 3.28308C12.9591 3.89878 14.88 6.22434 14.88 9H16.88ZM6.01 19.01C6.01 20.6623 7.34772 22 9 22V20C8.45228 20 8.01 19.5577 8.01 19.01H6.01ZM10.99 18.01H7.01V20.01H10.99V18.01ZM9 22C10.6523 22 11.99 20.6623 11.99 19.01H9.99C9.99 19.5577 9.54772 20 9 20V22Z"
      fill="#373634"
      mask="url(#path-1-inside-1_2185_34461)"
    />
    <path
      d="M11 6C11 3.79086 12.7909 2 15 2C17.2091 2 19 3.79086 19 6C19 8.20914 17.2091 10 15 10C12.7909 10 11 8.20914 11 6Z"
      fill="#DB4437"
    />
  </svg>
);

export default NotificationAlert;
