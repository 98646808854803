import { css } from '@emotion/core';
import { styles } from '@onehope/design-system';
const { cssConstants } = styles;

export default css`
  height: 230px;
  background-color: white;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);

  header {
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 24px;
    padding-top: 16px;
    padding-bottom: 15px;
    padding-right: 24px;
    span {
      height: 23px;
      width: 85px;
      color: ${cssConstants.textColor};
      font-family: ${cssConstants.font};
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
    }
  }

  .my-goal-range-picker {
    border: none;
  }

  .my-goal-content {
    display: flex;
    width: 100%;
  }

  .impact-amnt {
    width: 90%;
    height: 42px;
    margin-left: 24px;
    margin-bottom: 20px;
    color: ${cssConstants.textColor};
    font-family: ${cssConstants.font};
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: -0.5px;
  }

  .my-goal-amount {
    padding-top: 16px;
    padding-left: 24px;
    padding-bottom: 50px;
    padding-right: 133px;
    .anticon svg {
      transform: scale(0.68);
    }
  }

  .antd-pro-charts-mini-progress-progressWrap {
    height: 5px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 1px 2px 17px 0 rgba(0, 0, 0, 0.05);
  }

  .antd-pro-charts-mini-progress-progress {
    height: 5px;
    border-radius: 2px;
    box-shadow: 1px 2px 17px 0 rgba(0, 0, 0, 0.05);
  }

  .progress-info {
    width: 90%;
    padding-top: 9px;
    margin-bottom: 16px;
    margin-left: 24px;

    .progress-percentage {
      padding-top: 100px !important;
      height: 22px;
      color: ${cssConstants.textColor};
      font-family: ${cssConstants.font};
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      padding-left: 10px;
    }

    .progress-cash-goal,
    .progress-time-left {
      height: 16px;
      float: right;
      color: ${cssConstants.textColor};
      font-family: ${cssConstants.font};
      font-size: 10px;
      font-style: italic;
      font-weight: 300;
      letter-spacing: 0.5px;
      line-height: 16px;
    }
    .progress-cash-goal {
      float: left;
    }
    .progress-time-left {
      float: right;
      text-align: right;
    }
  }

  .ant-menu {
    display: flex;
    padding-top: 5px;
    margin-bottom: -30px;
  }

  .ant-menu
    .ant-menu-change
    .ant-menu-light
    .ant-menu-root
    .ant-menu-horizontal
    .ant-menu-change:hover {
    border-bottom: none !important;
    color: inherit !important;
  }

  .ant-menu-item {
    height: 28px;
    width: 96px;
    border: solid 1px #d9d9d9;
    background-color: #ffffff;
    font-family: ${cssConstants.font};
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: center;
    color: ${cssConstants.neutral34};
    padding: 5px 2px;
  }

  .ant-menu-item-selected {
    background-color: #ffffff;
    border: solid 1px ${cssConstants.primaryColor};
    font-weight: bold;
    color: ${cssConstants.primaryColor};
  }

  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu {
    border: solid 1px ${cssConstants.primaryColor} !important;
    color: inherit !important;
    font-weight: bold;
  }

  .ant-dropdown-trigger {
    height: 56px;
    width: 317px;
    border: 1px solid ${cssConstants.primaryColor};
    border-radius: 4px;
    background-color: #ffffff;
    display: flex;
    margin-top: 10px;
    span {
      padding-left: 16px;
      padding-top: 18px;
      padding-bottom: 17px;
      margin-right: auto;
      height: 21px;
      width: 250.35px;
      color: ${cssConstants.textColor};
      font-family: ${cssConstants.font};
      font-size: 14px;
      font-weight: bold;
      line-height: 21px;
    }
    .ant-btn-circle {
      background: ${cssConstants.textColor};
      height: 20px;
      width: 20px;
      margin-top: 18px;
      margin-right: 16px;
    }
    .anticon-caret-down {
      color: #ffffff;
    }
  }
`;

export const margin = css`
  margin: 0 24px;
  @media only screen and (max-width: 600px) {
    margin: 0 16px;
  }
`;
