import gql from 'graphql-tag';

export default gql`
  query CEDash_DashboardQuery {
    viewer {
      id
      user {
        id
        userId
        firstName
        lastName
        email
        customUrl
        trinityDealerId
        trinityUplineDealerId
        trinityCurrentRank
        kpi {
          currentPeriodPCV
          currentPeriodOV
        }
        fastStart {
          fastStartSalesToDate
        }
        ce {
          id
          ceId
          workRampInfo {
            pathName
            pathDescription
            lastAccessed
            url
          }
          mentorInfo {
            fullName
            mentorEmail
            phoneNumber
            trinityEnrollDate
            phoneNumber
            trinityCurrentRank
            trinityUplineDealerId
            mentorProfile {
              image
              city
              state
            }
          }
          ceTaskList {
            tasks {
              description
              isCompleted
            }
          }
          ceImpactData {
            years {
              year
              months {
                month
                impactTotal
              }
            }
            lifetime
          }
          ceImpactGoals {
            goals {
              year
              months {
                month
                impactGoal
              }
            }
          }
        }
        profile {
          city
          state
          image
        }
        profileImages {
          id
          thumb
        }
        customUrl
        fastStartEndDate
        trinityEnrollDate
        accountRole {
          id
          accountRoleTitle
        }
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
      }
      blog(pageNumber: 1, postsPerPage: 100, vine: true) {
        edges {
          node {
            id
            datePublishedFull
            category
            slug
            images {
              preview
              hero
            }
            excerpt
            isValidPage
            header
            sticky
            tags
          }
        }
      }
    }
  }
`;
