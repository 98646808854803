import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useQuery } from '@apollo/react-hooks';
import ContactsWrapper from './ContactsWrapper';

import GET_CONTACTS_V2_QUERY from '../../queries/CustomersPaginated';
import { useTrackingContextValue } from '../../contexts/TrackingContext';

function Contacts() {
  let { page } = queryString.parse(window.location.search);
  if (!page) page = 1;
  const [pageNumber, setPageNumber] = useState(page);
  const [filterBy, setFilterBy] = useState(null);
  const [sort, setSort] = useState({ sortBy: 'dateCreated', desc: true });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState(null);
  const { data, loading, error, refetch } = useQuery(GET_CONTACTS_V2_QUERY, {
    variables: {
      page: Number(pageNumber),
      filterBy,
      sortBy: sort.sortBy,
      desc: sort.desc,
      rowsPerPage,
      searchBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleSortContacts = (sortBy: string) => {
    setSort({
      sortBy,
      desc: !sort.desc,
    });
  };

  const { trackPageView } = useTrackingContextValue();

  useEffect(() => {
    trackPageView({ page: 'CONTACTS' });
  }, []);

  if (error) return null;
  if (data?.viewer?.user?.customersV2) {
    const { customersV2, customersV2Count } = data.viewer.user;
    return (
      <ContactsWrapper
        contacts={customersV2}
        pageNumber={Number(pageNumber)}
        filterBy={filterBy ? filterBy : 'all'}
        setPageNumber={setPageNumber}
        setFilterBy={setFilterBy}
        handleSortContacts={handleSortContacts}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={customersV2Count}
        setSearchBy={setSearchBy}
      />
    );
  }
  return null;
}

export default Contacts;
