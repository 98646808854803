import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Loading from '../../common/Loading';
import ContactsWrapper from './ContactsWrapper';

import get from 'lodash/get';
import GET_CONTACTS_QUERY from '../../queries/Customers';
import { useTrackingContextValue } from '../../contexts/TrackingContext';

function Contacts() {
  const { data, loading, error, refetch } = useQuery(GET_CONTACTS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const { trackPageView } = useTrackingContextValue();

  useEffect(() => {
    trackPageView({ page: 'CONTACTS' });
  }, []);

  // if (loading) return <Loading />;
  if (error) return null;
  if (get(data, 'viewer.user.customers')) {
    const { customers } = data.viewer.user;
    return <ContactsWrapper contacts={customers} />;
  }
  return <Loading />;
}

export default Contacts;
