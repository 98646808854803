import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { useMutation } from '@apollo/react-hooks';
import { get } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

/* Components */
import FormContainer from '../../FormContainer';
import Notification from '../../../Notifcations/DefaultNotifcation';
import EventDetailsForm from '../../../Events/EventDetailsForm';
import EventSummary from '../../../Events/EventDetailsForm/EventSummary';
import OrderLinkSentDialog from '../../../Dialog/OrderLinkSentDialog';
import SummaryLine from '../../../Contact/TabContent/SummaryLine';

/* Mutations */
import EVENT_UPDATE_MUTATION from '../../../../mutations/Event/EventUpdateMutation';

/* Types */
import { MyFormValues } from './FormTypes';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';

/* Helpers & Functions */
import useToggle from '../../../../utils/useToggleHook';
import { getDefaultValues } from './helpers';
import { EventDetailsFormSchema } from './validationSchema';
import {
  getEventDetailsToSubmit,
  getDisplayPreferences,
} from '../../../Events/EventDetailsForm/submitHelpers';
import {
  isSatelliteEvent,
  eventIsClosed,
} from '../../../Events/EventsTable/helpers';
import { useTrackingContextValue } from '../../../../contexts/TrackingContext';
import { getDays } from '../../Notifications';

/* Styles */
import {
  TabContentContainer,
  BaseContainer,
  DetailsHeader,
} from '../index.styles';
import {
  DetailsTitle,
  StraightLine,
} from '../../../Contact/TabContent/index.styles';
import { SvgPencil } from '../../../../assets/pencil';

const HeaderContainer = styled.div`
  padding-bottom: 29px;
`;

const handleUpdateEvent = async ({
  event,
  trackEventsV2,
  eventId,
  values,
  setTouched,
  eventUpdateMutation,
  setNotificationOpen,
  setIsNotEditing,
  setDialogOpen,
  hostKitsV2,
}: any) => {
  const eventDetails = getEventDetailsToSubmit({
    values,
    ceAccountId: event?.accountId,
  });
  const displayPreferences = getDisplayPreferences({
    event,
    values,
  });

  const input = {
    form: {
      eventId,
      ...eventDetails,
      displayPreferences,
    },
  };

  await eventUpdateMutation({
    variables: { input: input },
  })
    .then(data => {
      const newEvent = get(data, 'data.eventUpdate.event');
      if (newEvent?.eventDate !== event?.eventDate) {
        trackEventsV2({
          eventName: 'Event Date Time Updated',
          newEvent,
          oldEvent: event,
        });
      }
      setTouched({});
      setIsNotEditing();
      if (
        hostKitsV2 &&
        newEvent?.hostKitPurchaser === 'HOST' &&
        event?.hostAccountId !== newEvent.hostAccountId &&
        (!newEvent?.wineTastingOrderIds ||
          !newEvent?.wineTastingOrderIds.length)
      ) {
        setDialogOpen();
      }
      return setNotificationOpen();
    })
    .catch((errors: any) => {
      console.log('errors', errors);
      return;
    });
};

interface DetailsProps {
  event: EventType;
}

const Details = ({ event }: DetailsProps) => {
  const { trackEventsV2 } = useTrackingContextValue();
  const { hostKitsV2 } = useFlags();

  const newEvent = JSON.parse(localStorage.getItem('eventCreated') || 'false');
  useEffect(() => {
    if (newEvent) {
      const timer = setTimeout(() => {
        localStorage.setItem('eventCreated', 'false');
        return setNewlyCreateEvent(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
    return;
  }, []);

  const [newlyCreatedEvent, setNewlyCreateEvent] = useState(newEvent);
  const {
    value: notificationOpen,
    setTrue: setNotificationOpen,
    setFalse: setNotificationClosed,
  } = useToggle();
  const {
    value: editing,
    setTrue: setIsEditing,
    setFalse: setIsNotEditing,
  } = useToggle(false);
  const {
    value: dialogOpen,
    setTrue: setDialogOpen,
    setFalse: setDialogClosed,
  } = useToggle();

  const [eventUpdateMutation, { loading: mutationLoading }] = useMutation(
    EVENT_UPDATE_MUTATION,
  );

  const hostDetails = get(event, 'hostUser');
  const defaultValues = getDefaultValues(event, hostDetails);
  const SUCCESS_MESSAGE = newEvent
    ? `This event has been created`
    : `This event has been updated`;

  const closedEvent = eventIsClosed(event);
  const satelliteEvent = isSatelliteEvent(event);
  const daysUntilClose = getDays(event.closeDate);
  const editable = !editing && !satelliteEvent && !closedEvent;
  const ordersPlaced = event?.ordersCount > 0;

  const getDetailsTitle = () => {
    if (closedEvent) return 'Summary';
    if (editing) return 'Edit Event Details';
    return 'Event Details';
  };

  const handleNotificationClose = () => {
    localStorage.setItem('eventCreated', 'false');
    setNewlyCreateEvent(false);
    setNotificationClosed();
  };

  const handleNotificationOpen = () => {
    setNewlyCreateEvent(false);
    setNotificationOpen();
  };

  return (
    <BaseContainer>
      <TabContentContainer>
        <Notification
          variant="success"
          open={newlyCreatedEvent || notificationOpen}
          message={SUCCESS_MESSAGE}
          handleClose={handleNotificationClose}
        />
        <OrderLinkSentDialog open={dialogOpen} setClose={setDialogClosed} />
        {ordersPlaced && !closedEvent && !satelliteEvent && (
          <HeaderContainer>
            <SummaryLine
              title={
                daysUntilClose > 0
                  ? 'Accepting ordering until'
                  : 'Stopped accepting orders on'
              }
              value={DateTime.fromISO(event?.closeDate, {
                zone: defaultValues?.timeZone,
              }).toLocaleString(DateTime.DATE_FULL)}
            />
          </HeaderContainer>
        )}
        <DetailsHeader>
          <DetailsTitle>{getDetailsTitle()}</DetailsTitle>
          {editable && <SvgPencil onClick={setIsEditing} cursor="pointer" />}
        </DetailsHeader>
        <StraightLine />
        {editing ? (
          <FormContainer
            enableReinitialize
            validationSchema={EventDetailsFormSchema}
            initialValues={defaultValues}
            /* eslint-disable react/jsx-no-bind */
            formToUse={(props: any) => (
              <EventDetailsForm
                {...props}
                editing={editing}
                event={event}
                mutationLoading={mutationLoading}
                setIsNotEditing={setIsNotEditing}
              />
            )}
            onSubmit={async (
              values: MyFormValues,
              { setErrors, setTouched }: any,
            ) => {
              await handleUpdateEvent({
                event,
                trackEventsV2,
                values,
                setErrors,
                setTouched,
                eventId: event.eventId,
                errorField: 'hostFullName',
                eventUpdateMutation,
                setNotificationClosed: handleNotificationClose,
                setNotificationOpen: handleNotificationOpen,
                setIsNotEditing,
                setDialogOpen,
                hostKitsV2,
              });
            }}
          />
        ) : (
          <EventSummary
            event={event}
            values={defaultValues}
            satelliteEvent={satelliteEvent}
            editing={editing}
            setIsEditing={setIsEditing}
          />
        )}
      </TabContentContainer>
    </BaseContainer>
  );
};

export default Details;
