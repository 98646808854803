import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash';
import { MuiInput, MuiButton } from '@onehope/design-system-ohw';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import Close from '@material-ui/icons/Close';
import { FormikProps } from 'formik';
import { MyFormValues } from './MyFormTypes';
import { CustomerCard, CardInfo, CardLine, Icon } from './index.styles';
import { PhoneMask } from '../../Contacts/AddContactDialog/PhoneMask';

interface AddCustomerProps {
  formTitle: string;
  values: MyFormValues;
  onClose: () => void;
  existingCustomer: any;
  existingCustomerSelected: boolean;
  addCustomerButtonText: string;
  existingCustomerButtonText: string;
  setExistingCustomer: any;
  setExistingCustomerSelected: (bool: boolean) => void;
  errorNotification: string | null;
  setErrorNotification: (error: string) => void;
  mutationLoading: boolean;
}

interface CustomerNode {
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface Customer {
  node: CustomerNode;
}

interface CustomerData {
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  accountId: string;
}

const Form = styled.div`
  margin: 0 auto;
  width: 100%;
  background-color: #ffffff;
  padding: 16px;
  @media all and (min-width: 968px) {
    padding: 32px;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    max-width: 432px;
    max-height: 725px;
  }
`;

const Title = styled.div`
  height: 42px;
  /* font-family: PlayfairDisplay; */
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.5px;
  color: #000000;
  margin-bottom: 24px;
`;

const InputContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  @media all and (min-width: 968px) {
    max-width: 368px;
  }
`;

const ShortInput = styled(MuiInput)`
  && {
    padding-bottom: 24px;
  }
`;

const Spacer = styled.div`
  padding-bottom: 16px;
`;

const CloseDialog = styled.div`
  width: 48px;
  height: 48px;
  position: absolute;
  padding-top: 21.4px;
  right: 0;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const Text = styled.div`
  /* font-family: ProximaNova; */
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 24px;
`;

const ErrorNotification = styled.div`
  width: 100%;
  /* font-family: ProximaNova; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  border-radius: 4px;
  border: solid 1px #ff4d4f;
  background-color: #fff5f5;
  padding: 14px 22px;
  margin-bottom: 40px;
`;

export default function AddCustomer(
  props: FormikProps<MyFormValues> & AddCustomerProps,
) {
  const {
    values,
    errors,
    touched,
    onClose,
    formTitle,
    setErrors,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldTouched,
    setTouched,
    mutationLoading,
    existingCustomer,
    setExistingCustomer,
    existingCustomerSelected,
    setExistingCustomerSelected,
    addCustomerButtonText,
    existingCustomerButtonText,
    errorNotification,
    setErrorNotification,
    initialValues,
  } = props;
  const { firstName, lastName, email, phone } = values;

  useEffect(() => {
    if (errors) {
      setTouched({
        firstName: !!initialValues.firstName,
        lastName: !!initialValues.lastName,
        email: !!initialValues.email,
        phone: !!initialValues.phone,
      });
    }
  }, [initialValues]);

  const handleCheckCustomer = () => {
    existingCustomerSelected
      ? setErrors({ email: 'A customer with this email already exists' })
      : setErrors({});
    setExistingCustomerSelected(!existingCustomerSelected);
  };

  const change = (name: any, e: React.SyntheticEvent) => {
    if (name === 'email') {
      setExistingCustomer('');
      setErrorNotification(null);
      setExistingCustomerSelected(false);
    }
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const disableSubmit =
    mutationLoading ||
    !isEmpty(errors) ||
    isEmpty(touched) ||
    !!(existingCustomer && !existingCustomerSelected);
  const buttonText =
    existingCustomerSelected && !disableSubmit && existingCustomerButtonText
      ? existingCustomerButtonText
      : addCustomerButtonText;

  return (
    <form onSubmit={handleSubmit}>
      <CloseDialog onClick={onClose}>
        <Close />
      </CloseDialog>
      <Form>
        <Title>{formTitle}</Title>
        <>
          <InputContainer>
            <ShortInput
              label="First name"
              placeholder="Jane"
              id="firstName"
              name="firstName"
              value={firstName}
              fullWidth
              validate
              hasError={
                !isSubmitting && touched.firstName && Boolean(errors.firstName)
              }
              hasSuccess={
                !isSubmitting && touched.firstName && !Boolean(errors.firstName)
              }
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={
                errors.firstName && touched.firstName && errors.firstName
              }
              onChange={(e: React.SyntheticEvent) => change('firstName', e)}
            />
            <ShortInput
              label="Last name"
              placeholder="Austen"
              id="lastName"
              name="lastName"
              value={lastName}
              fullWidth
              validate
              hasError={
                !isSubmitting && touched.lastName && Boolean(errors.lastName)
              }
              hasSuccess={
                !isSubmitting && touched.lastName && !Boolean(errors.lastName)
              }
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={
                errors.lastName && touched.lastName && errors.lastName
              }
              onChange={(e: React.SyntheticEvent) => change('lastName', e)}
            />
            <ShortInput
              label="Email address"
              placeholder="jane@gmail.com"
              id="email"
              name="email"
              value={email}
              fullWidth
              validate
              hasError={!isSubmitting && touched.email && Boolean(errors.email)}
              hasSuccess={
                !isSubmitting && touched.email && !Boolean(errors.email)
              }
              error={touched.email && Boolean(errors.email)}
              helperText={errors.email && touched.email && errors.email}
              onChange={(e: React.SyntheticEvent) => change('email', e)}
            />
            <ShortInput
              label="Mobile phone # (optional)"
              placeholder="(555) 123 - 4567"
              id="phone"
              name="phone"
              value={phone}
              fullWidth
              validate
              hasError={!isSubmitting && touched.phone && Boolean(errors.phone)}
              hasSuccess={
                !isSubmitting && touched.phone && !Boolean(errors.phone)
              }
              mask={PhoneMask}
              error={touched.phone && Boolean(errors.phone)}
              helperText={errors.phone && touched.phone && errors.phone}
              onChange={(e: React.SyntheticEvent) => change('phone', e)}
            />
          </InputContainer>
          {existingCustomer && (
            <div>
              <Text>This contact already exists!</Text>
              <CustomerCard selected={existingCustomerSelected}>
                <CardInfo>
                  <Icon onClick={handleCheckCustomer}>
                    {existingCustomerSelected ? (
                      <CheckCircleIcon />
                    ) : (
                      <RadioButtonUncheckedOutlinedIcon />
                    )}
                  </Icon>
                  <CardLine>
                    {`${existingCustomer.firstName} ${existingCustomer.lastName}` ||
                      ''}
                  </CardLine>
                  <CardLine>{existingCustomer.email || ''}</CardLine>
                  {existingCustomer && (
                    <CardLine>{existingCustomer.phone || ''}</CardLine>
                  )}
                </CardInfo>
              </CustomerCard>
            </div>
          )}
          {errorNotification && (
            <ErrorNotification>{errorNotification}</ErrorNotification>
          )}
          <ButtonContainer>
            <MuiButton
              type="PRIMARY"
              disableRipple
              disabled={disableSubmit}
              onClick={handleSubmit}
            >
              {buttonText}
            </MuiButton>
            <Spacer />
            <MuiButton type="TERTIARY" disableRipple onClick={onClose}>
              cancel
            </MuiButton>
          </ButtonContainer>
        </>
      </Form>
    </form>
  );
}
