import { DateTime } from 'luxon';
import { formatAddress } from '../Charity/helpers';

const getHostType = (event: any) => {
  if (event?.hostNonProfitName && event?.hostFullName) {
    return 'NONPROFIT';
  } else if (event?.hostFullName && event?.hostAccountId !== event?.accountId) {
    return 'CUSTOMER';
  } else {
    return 'SELFHOST';
  }
};

const getValue = (value: string) => {
  return value && value !== 'null' ? value : '';
};

const getHostInformation = (event: any, hostDetails: any) => {
  // hostDetails will always be null for imported trinity events
  if (hostDetails?.userId) {
    return {
      hostFullName:
        (getValue(hostDetails?.organizationName) ||
          getValue(hostDetails?.fullName)) ??
        '',
      hostFirstName: getValue(hostDetails?.firstName) ?? '',
      hostLastName: getValue(hostDetails?.lastName) ?? '',
      hostAccountId: hostDetails?.userId ?? '',
      hostEmail: hostDetails?.email ?? '',
      hostPhone: hostDetails?.mobilePhone ?? '',
    };
  }
  return {
    hostFullName:
      (getValue(hostDetails?.fullName) ||
        getValue(hostDetails?.organizationName)) ??
      '',
    hostFirstName: getValue(hostDetails?.firstName) ?? '',
    hostLastName: getValue(hostDetails?.lastName) ?? '',
    hostAccountId: event?.hostAccountId ?? '',
    hostEmail: event?.hostEmail ?? '',
    hostPhone: event?.hostPhone ?? '',
  };
};

const getTrinityPartyType = (trinityPartyType: string) => {
  if (trinityPartyType === 'EPARTY') return 'EPARTY';
  return 'EVENT'; // if partyType === "EVENT" or "INHOME - SHIPTOGUEST"
};

const getAddress = (event: any) => {
  return {
    addressLineOne: event?.eventAddress?.addressLineOne || '',
    city: event?.eventAddress?.city || '',
    state: event?.eventAddress?.state || '',
    zip: event?.eventAddress?.zip || '',
  };
};

export const getDefaultValues = (event: any, hostDetails: any) => {
  const addressParsed = getAddress(event);
  const timeZone = event?.timeZone ? event.timeZone : 'America/Chicago'; // Fall back for trinity events
  const eventTimeFormatted = event?.eventDate
    ? DateTime.fromISO(event.eventDate, { zone: timeZone })
        .toLocaleString(DateTime.TIME_SIMPLE)
        .replace(/\s/g, '')
        .padStart(7, 0)
    : '';
  const eventDate = event?.eventDate
    ? DateTime.fromISO(event.eventDate, { zone: timeZone }).toLocaleString(
        DateTime.DATE_SHORT,
      )
    : '';
  const hostInfo = getHostInformation(event, hostDetails);
  return {
    ...addressParsed,
    ...hostInfo,
    hostNonProfitName: event?.hostNonProfitName ? event.hostNonProfitName : '',
    hostType: getHostType(event),
    hostCustomerType: '',
    displayAddress: addressParsed ? formatAddress(addressParsed) : '',
    addressLineTwo: event?.eventAddress?.addressLineTwo
      ? event?.eventAddress?.addressLineTwo
      : '',
    eventDate,
    eventTime: eventTimeFormatted ? eventTimeFormatted.substring(0, 5) : '',
    eventTimeLocale: eventTimeFormatted
      ? eventTimeFormatted.substring(5, 7)
      : '',
    trinityPartyType: event?.trinityPartyType
      ? getTrinityPartyType(event.trinityPartyType)
      : 'EVENT',
    timeZone,
    title: event?.title ?? '',
    showSupporters:
      event?.displayPreferences?.supporters?.toString() ?? 'false',
  };
};

export function formatZone(zone: string | undefined) {
  switch (zone) {
    case 'America/Anchorage': {
      return 'AKST';
    }
    case 'America/New_York': {
      return 'EST';
    }
    case 'America/Chicago': {
      return 'CST';
    }
    case 'America/Denver': {
      return 'MST';
    }
    case 'America/Los_Angeles': {
      return 'PST';
    }
    default: {
      return 'CST';
    }
  }
}

export function formatShortZoneToLong(zone: string) {
  switch (zone) {
    case 'AKST':
      return 'America/Anchorage';
    case 'AKDT':
      return 'America/Anchorage';
    case 'EST':
      return 'America/New_York';
    case 'EDT':
      return 'America/New_York';
    case 'CST':
      return 'America/Chicago';
    case 'CDT':
      return 'America/Chicago';
    case 'MST':
      return 'America/Denver';
    case 'MDT':
      return 'America/Denver';
    case 'PST':
      return 'America/Los_Angeles';
    case 'PDT':
      return 'America/Los_Angeles';
    default:
      return 'America/Chicago';
  }
}

export const getInitialValues = () => {
  const timeZone = formatShortZoneToLong(DateTime.local().offsetNameShort);
  return {
    hostFullName: localStorage.getItem('hostFullName') || '',
    hostFirstName: localStorage.getItem('hostFirstName') || '',
    hostLastName: localStorage.getItem('hostLastName') || '',
    hostAccountId: localStorage.getItem('hostAccountId') || '',
    hostEmail: localStorage.getItem('hostEmail') || '',
    hostPhone: localStorage.getItem('hostPhone') || '',
    hostNonProfitName: localStorage.getItem('hostNonProfitName') || '',
    hostType: localStorage.getItem('hostType') || '',
    hostCustomerType: localStorage.getItem('hostCustomerType') || '',
    displayAddress: localStorage.getItem('displayAddress') || '',
    addressLineOne: localStorage.getItem('addressLineOne') || '',
    addressLineTwo: localStorage.getItem('addressLineTwo') || '',
    city: localStorage.getItem('city') || '',
    state: localStorage.getItem('state') || '',
    zip: localStorage.getItem('zip') || '',
    eventDate: localStorage.getItem('eventDate') || '',
    eventTime: localStorage.getItem('eventTime') || '',
    eventTimeLocale: localStorage.getItem('eventTimeFormat') || 'PM',
    trinityPartyType: localStorage.getItem('trinityPartyType') || '',
    timeZone,
    title: localStorage.getItem('title') || '',
    showSupporters: localStorage.getItem('showSupporters') || 'true',
  };
};
