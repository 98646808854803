import styled from '@emotion/styled';
import { styles } from '@onehope/design-system';
const {
  styled: s,
  cssConstants: { textColor },
} = styles;

export const BalanceContainer = styled.div<{ editing: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: ${props => (props.editing ? '24px' : '40px')};
  margin-bottom: 40px;
  @media all and (min-width: 768px) {
    align-items: center;
  }
`;

export const Balance = styled.div`
  /* font-family: ProximaNova; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${textColor};
`;

export const BalanceHeader = styled(Balance)`
  font-weight: bold;
  margin-left: 10px;
  margin-right: 4px;
`;
