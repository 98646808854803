import * as React from 'react';
import { PageRendererProps } from 'gatsby';
import styled from '@emotion/styled';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';

import Layout from '../components/MainLayout/layout/layout';
import Events from '../components/Events';
import EventsV2 from '../components/EventsV2';

import { getCurrentUserId } from '../utils/utils';

const Container = styled.div`
  background-color: white;
  height: 100vh;
`;

function EventsPage(props: PageRendererProps) {
  const { location } = props;
  const currentUser = getCurrentUserId(location);

  const { eventsPaginationV2 } = useFlags();

  return (
    <Layout selectedUserId={currentUser}>
      <Container>{eventsPaginationV2 ? <EventsV2 /> : <Events />}</Container>
    </Layout>
  );
}

export default withLDProvider({
  clientSideID: process.env.GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: {
    anonymous: true,
  },
})(EventsPage);
