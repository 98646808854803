/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import { navigate } from 'gatsby';
import { DateTime } from 'luxon';
import { MuiButton } from '@onehope/design-system-ohw';
import { Link } from 'gatsby';

interface Column {
  id:
  | 'status'
  | 'event.hostFullName'
  | 'createDate'
  | 'expirationDate'
  | 'earnedFromEventId'
  | 'eventDetails';
  label?: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'right';
  format?: (
    value: string,
    timeZone?: string | undefined,
    earnedFromEventId?: string | null | undefined,
  ) => void;
  render?: ({ eventId }) => any;
}

const columns: Column[] = [
  {
    id: 'status',
    label: 'Status',
    minWidth: 130,
    align: 'left',
    format: value => value.charAt(0).toUpperCase() + value.slice(1),
  },
  {
    id: 'dateCreated',
    label: 'Issued',
    minWidth: 130,
    align: 'left',
    format: (value, timeZone: string | undefined) =>
      DateTime.fromISO(value, { zone: timeZone }).toLocaleString(
        DateTime.DATE_SHORT,
      ),
  },
  {
    id: 'earnedFromEventId',
    label: 'Earned From Event ID',
    minWidth: 80,
    align: 'left',
    format: (value, timeZone, eventId: string | undefined) => (
      <Link to={`/event?eventId=${eventId}`}>{value}</Link>
    ),
  },
  {
    id: 'event.hostFullName',
    label: 'Redeemed for',
    minWidth: 150,
    align: 'left',
    format: value => value || '',
  },
  {
    id: 'expirationDate',
    label: 'Expires',
    minWidth: 150,
    align: 'left',
    format: (value, timeZone: string | undefined) =>
      value &&
      DateTime.fromISO(value, { zone: timeZone }).toLocaleString(
        DateTime.DATE_SHORT,
      ),
  },
  {
    id: 'eventDetails',
    minWidth: 100,
    align: 'left',
    render: ({ eventId }) => (
      <MuiButton
        disabled={!eventId}
        type="ROUND"
        onClick={() => navigate(`/event?eventId=${eventId}&tab=1`)}
      >
        Details
      </MuiButton>
    ),
  },
];

export default columns;
