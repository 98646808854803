import gql from 'graphql-tag';

export default gql`
  query CEImpactGoalsQuery {
    viewer {
      id
      user {
        id
        ce {
          id
          ceId
          ceImpactGoals {
            goals {
              year
              months {
                month
                impactGoal
              }
            }
          }
        }
      }
    }
  }
`;
