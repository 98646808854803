import React, { useEffect } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../../common/Loading';

import GET_ORDERS_BY_ACCOUNT_ID_QUERY from '../../../../queries/OrdersByAccountId';
import { useTrackingContextValue } from '../../../../contexts/TrackingContext';
import { CEDash_CustomerDetailsPage_viewer_customer as ContactType } from '../../../../queries/generatedTypes/CEDash_CustomerDetailsPage';
import OrdersPageWrapper from './OrdersWrapper';

interface OrdersPageProps {
  contact: ContactType;
}

function OrdersPage(props: OrdersPageProps) {
  const { data, loading, error } = useQuery(GET_ORDERS_BY_ACCOUNT_ID_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      byContactId: props.contact.accountId,
    },
  });

  const { trackPageView } = useTrackingContextValue();

  useEffect(() => {
    trackPageView({ page: 'CONTACT ORDERS' });
  }, []);

  if (error) return null;
  if (get(data, 'viewer.user.orders')) {
    const { orders } = data.viewer.user;
    return <OrdersPageWrapper orders={orders} contact={props.contact} />;
  }
  return <Loading />;
}

export default OrdersPage;
