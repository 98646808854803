/* eslint-disable react/jsx-no-bind */
import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SortIcon from '@material-ui/icons/ImportExport';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import { CEDash_CustomersQuery_viewer_user_customers_edges as ContactsEdges } from '../../../../queries/generatedTypes/CEDash_CustomersQuery';
import { useCheckBoxToggleContext } from '../../CheckboxToggleContext';
import { useArchiveContactsContext } from '../../ArchiveContactsContext';
import { SvgArchive } from '../../../../assets/archive';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Columns from '../../Columns';

const useStyles = {
  backgroundColor: '#ddd',
};

const { cssConstants } = s;
const TableCellContainer = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  justify-content: flex-start;
  width: 100%;
`;

const ArchiveContact = styled.span`
  :hover {
    cursor: pointer;
  }
`;

const ArchiveButton = styled.span`
  /* width: 44px; */
  height: 18px;
  font-family: ${cssConstants.font};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #0091ea;
  padding-left: 12px;
  :hover {
    cursor: pointer;
  }
`;

const HeaderLabel = styled.div`
  font-family: ${cssConstants.font};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  padding-right: 14px;
`;

const ArchiveLabel = styled.div`
  font-family: ${cssConstants.font};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  padding-right: 12px;
`;

const SortIconWrapper = styled(SortIcon)`
  && {
    :hover {
      cursor: pointer;
    }
  }
`;
interface TableHeaderContainerProps {
  handleSortContacts: (sortBy: string) => void;
  contacts: (ContactsEdges | null)[];
  rowsPerPage: number;
  page: number;
}

export default function TableHeaderContainer(props: TableHeaderContainerProps) {
  const { page, handleSortContacts, contacts, rowsPerPage } = props;
  const { isCheckBoxActivated } = useCheckBoxToggleContext();
  const {
    handleContacts,
    selectedContacts,
    handleArchiveContact,
    handleArchiveAllContacts,
    setAllSelectedFalse,
    setAllSelectedTrue,
    allSelected,
  } = useArchiveContactsContext();
  const contactIds = contacts
    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map(contact => contact?.node?.accountId);
  const columnLength = Columns.length;
  const contactsCount = contacts?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  ).length;
  const allContacts = props.contacts.length;
  const archiveWarningMsg = `All ${
    allSelected ? allContacts : contactsCount
  } contacts ${allSelected ? '' : 'on this page'} are selected`;
  const archiveAllMsg = `Select all ${allContacts} contacts`;
  const clearSelected = `Clear selection`;
  const zeroSelectedMsg = `0 contacts have been selected`;
  const ArchiveWarningSpan = styled.span`
    font-family: ${cssConstants.font};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
  `;

  const isAllSelectedOnPage =
    selectedContacts.length === contactsCount ? true : false;

  return (
    <TableHead>
      <TableRow>
        {isCheckBoxActivated && (
          <TableCell>
            <TableCellContainer>
              <HeaderLabel>
                <Checkbox
                  checked={isAllSelectedOnPage && contacts.length > 0}
                  onClick={() =>
                    handleContacts(contactIds, isAllSelectedOnPage)
                  }
                />
              </HeaderLabel>
            </TableCellContainer>
          </TableCell>
        )}
        {selectedContacts.length === 0 ? (
          Columns.map(column => {
            return (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  height: 60,
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                }}
              >
                <TableCellContainer>
                  <HeaderLabel>{column.label}</HeaderLabel>
                  {column.id !== 'menu' && column.id !== 'orderAdd' && (
                    <SortIconWrapper
                      onClick={() => handleSortContacts(column.id)}
                    />
                  )}
                </TableCellContainer>
              </TableCell>
            );
          })
        ) : (
          <TableCell
            colSpan={columnLength}
            style={{
              height: 60,
            }}
          >
            <TableCellContainer>
              <ArchiveLabel>{`Selected (${
                allSelected ? contacts.length : selectedContacts.length
              })`}</ArchiveLabel>
              <ArchiveContact>
                <SvgArchive
                  onClick={
                    allSelected
                      ? () => handleArchiveAllContacts(contactIds, allContacts)
                      : () =>
                          handleArchiveContact(selectedContacts, allContacts)
                  }
                />
              </ArchiveContact>
              <ArchiveButton
                onClick={
                  allSelected
                    ? () => handleArchiveAllContacts(contactIds, allContacts)
                    : () => handleArchiveContact(selectedContacts, allContacts)
                }
              >
                Archive selected
              </ArchiveButton>
            </TableCellContainer>
          </TableCell>
        )}
      </TableRow>
      {isCheckBoxActivated && isAllSelectedOnPage && (
        <TableRow>
          <TableCell
            colSpan={columnLength + 1}
            align={'center'}
            style={{
              height: 30,
              padding: 7.5,
              backgroundColor: '#ddd',
            }}
          >
            <ArchiveWarningSpan>
              {contactsCount === 0 ? zeroSelectedMsg : archiveWarningMsg}
            </ArchiveWarningSpan>

            {contacts.length > contactsCount ? (
              !allSelected ? (
                <ArchiveButton onClick={setAllSelectedTrue}>
                  {archiveAllMsg}
                </ArchiveButton>
              ) : (
                <ArchiveButton onClick={setAllSelectedFalse}>
                  {clearSelected}
                </ArchiveButton>
              )
            ) : (
              ''
            )}
          </TableCell>
        </TableRow>
      )}
    </TableHead>
  );
}
