import React from 'react';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import { styles as s } from '@onehope/design-system';
import Header from './Header';
import TabNavigation from './TabNavigation/TabNavigation';
import BreadCrumbs from '../../common/Breadcrumbs';
import TabContent from './TabContent';
import { CEDash_CustomerDetailsPage_viewer_customer as Contact } from '../../queries/generatedTypes/CEDash_CustomerDetailsPage';
import { ArchiveContactsContextProvider } from '../Contacts/ArchiveContactsContext';

const { cssConstants } = s;

const Container = styled.div`
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  background: white;
  padding: 24px 0 0 0;
  @media only screen and (min-width: 768px) {
    padding: 40px 0 0 0;
  }
`;

interface ContactDetailsPageProps {
  contact: Contact;
  tab: string | string[];
}

function ContactDetailsPage(props: ContactDetailsPageProps) {
  const { contact, tab } = props;
  const handleChange = (e: React.FormEvent<EventTarget>, value: string) => {
    e.preventDefault();
    return navigate(`/contact?contactId=${contact.accountId}&tab=${value}`);
  };
  const tabValue = parseInt(tab);
  return (
    <Container>
      <BreadCrumbs location="contact" />
      <ArchiveContactsContextProvider>
        <Header contact={contact} />
      </ArchiveContactsContextProvider>
      <TabNavigation tabValue={tabValue} handleChange={handleChange} />
      <TabContent tabValue={tabValue} contact={contact} />
    </Container>
  );
}

export default ContactDetailsPage;
