import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { FormValues } from './FormTypes';
import { LaunchDates } from '../../../../utils/constants';
import { isVirtual, getTastingFlights } from '../../../../utils/utils';

interface CreditOption {
  id: string;
  key: string;
  label: string;
}

export const getCreditOptions = ({
  numberOfAvailableCredits,
  numCreditsUsed,
  hostKitQuantity,
}: {
  numberOfAvailableCredits: number;
  numCreditsUsed: number;
  hostKitQuantity: string;
}): CreditOption[] | null => {
  if (
    !Number.isInteger(numberOfAvailableCredits) ||
    !Number.isInteger(numCreditsUsed) ||
    !hostKitQuantity
  ) {
    return null;
  }
  const creditsAvailableForEvent = numberOfAvailableCredits + numCreditsUsed;
  if (creditsAvailableForEvent === 1) {
    return [
      {
        id: '1',
        key: '1',
        label: `1 credit ${
          hostKitQuantity === '6'
            ? '(tasting is free!)'
            : '(price starts at $99)'
        }`,
      },
      {
        id: '0',
        key: '0',
        label: `None (price starts at $${
          hostKitQuantity === '6' ? '99' : '198'
        })`,
      },
    ];
  }
  if (creditsAvailableForEvent > 1) {
    if (hostKitQuantity === '6') {
      return [
        { id: '1', key: '1', label: '1 credit (tasting is free!)' },
        { id: '0', key: '0', label: 'None (price starts at $99)' },
      ];
    }
    return [
      { id: '1', key: '1', label: '1 credit (price starts at $99)' },
      { id: '2', key: '2', label: '2 credits (tasting is free!)' },
      { id: '0', key: '0', label: 'None (price starts at $198)' },
    ];
  }
  return null;
};

export const getCreditText = (
  creditOptions: CreditOption[] | null,
  hostKitCredits: string,
): string => {
  if (!hostKitCredits || !creditOptions) return 'None';
  const option = creditOptions.filter(option => option.id === hostKitCredits);
  return option.length ? option[0].label : '';
};

export function getWineDetailsToSubmit(
  values: FormValues,
  trinityPartyType: string,
  createDate: string | null,
): any {
  const event = { trinityPartyType, createDate } as EventType;
  const showTastingFlights = getTastingFlights(event);

  const { wineNeeded } = values;

  if (showTastingFlights) {
    let isPurchasingTastingFlights;
    switch (wineNeeded) {
      case 'YES':
        isPurchasingTastingFlights = true;
        break;
      case 'NO':
        isPurchasingTastingFlights = false;
        break;
      default:
        isPurchasingTastingFlights = null;
        break;
    }
    return {
      isPurchasingTastingFlights,
      hostKitQuantity: null,
      hostKitCredits: null,
      hostKitPurchaser: null,
      starterKitCredits: null,
    };
  }

  if (wineNeeded === 'YES') {
    return {
      hostKitQuantity: isNaN(parseInt(values?.hostKitQuantity))
        ? 0
        : parseInt(values.hostKitQuantity) / 6,
      hostKitCredits: isNaN(parseInt(values?.hostKitCredits))
        ? 0
        : parseInt(values.hostKitCredits),
      hostKitPurchaser: values?.hostKitPurchaser
        ? values.hostKitPurchaser
        : 'CE',
      starterKitCredits: 0,
      isPurchasingTastingFlights: null,
    };
  }
  if (wineNeeded === 'NO') {
    return {
      hostKitQuantity: 0,
      hostKitCredits: 0,
      hostKitPurchaser: null,
      starterKitCredits: 0,
      isPurchasingTastingFlights: null,
    };
  }
  if (wineNeeded === 'STARTER-KIT') {
    return {
      hostKitQuantity: 0,
      hostKitCredits: 0,
      hostKitPurchaser: null,
      starterKitCredits: 1,
      isPurchasingTastingFlights: null,
    };
  }
  return {
    hostKitQuantity: null,
    hostKitCredits: null,
    hostKitPurchaser: null,
    starterKitCredits: null,
    isPurchasingTastingFlights: null,
  };
}

export const getWineNeeded = (
  hostKitQuantity: number | null,
  starterKitCredits: number | null,
  hostKitPurchaser: string | null,
  isPurchasingTastingFlights: boolean | null,
  showTastingFlights: boolean,
) => {
  if (showTastingFlights) {
    switch (isPurchasingTastingFlights) {
      case true:
        return 'YES';
      case false:
        return 'NO';
      default:
        return 'SKIP';
    }
  }
  // If they entered any hostKitQuantity before launch,
  // hostKitPurchaser would be null and we'd show
  // "already ordered" screen. After launch, if hostKitQuantity
  // is entered, hostKitPurchaser would never be null.
  if (hostKitQuantity && !hostKitPurchaser) {
    return 'ALREADY-ORDERED';
  }
  switch (hostKitQuantity) {
    case 0:
      if (starterKitCredits === 1) {
        return 'STARTER-KIT';
      }
      return 'NO';
    case 1:
      return 'YES';
    case 2:
      return 'YES';
    default:
      return 'SKIP';
  }
};

const getHostKitQuantity = (
  hostKitQuantity: number | null,
  hostKitPurchaser: string | null,
) => {
  // Clear the hostKitQuantity if they entered any hostKitQuantity
  // before launch date for when they edit Wine details
  if (!hostKitQuantity || (hostKitQuantity && !hostKitPurchaser)) return '';
  return (hostKitQuantity * 6).toString();
};

const getHostKitPurchaser = () => {
  if (localStorage.getItem('hostType') === 'SELFHOST') return 'CE';
  return localStorage.getItem('hostKitPurchaser') || '';
};

export const getDefaultValues = (event: EventType) => {
  const showTastingFlights = getTastingFlights(event);
  return {
    wineNeeded: getWineNeeded(
      event.hostKitQuantity,
      event.starterKitCredits,
      event.hostKitPurchaser,
      event.isPurchasingTastingFlights,
      showTastingFlights,
    ),
    hostKitQuantity: getHostKitQuantity(
      event.hostKitQuantity,
      event.hostKitPurchaser,
    ),
    hostKitCredits:
      event?.hostKitCredits || event?.hostKitCredits === 0
        ? event.hostKitCredits?.toString()
        : '',
    hostKitPurchaser: event?.hostKitPurchaser ?? '',
  };
};

export const getInitialValues = () => {
  return {
    wineNeeded: localStorage.getItem('wineNeeded') || '',
    hostKitQuantity: localStorage.getItem('hostKitQuantity') || '',
    hostKitCredits: localStorage.getItem('hostKitCredits') || '',
    hostKitPurchaser: getHostKitPurchaser(),
  };
};
