import React, { useCallback, useMemo } from 'react';
import { MiniProgress } from 'ant-design-pro/lib/Charts';
import copy from 'copy-to-clipboard';
import {
  Button,
  ThemeType,
  Grid,
  Text,
  useTheme,
  useMediaQuery,
  Icons,
  makeStyles,
} from '@onehope/design-system-v2';
import { ceProfileImage } from '../../../utils/utils';

type GreetingProps = {
  user: any;
};

const useStyles = makeStyles((theme: ThemeType) => ({
  daysLeft: {
    fontFamily: theme.bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',

    alignItems: 'center',
    textAlign: 'right',
    letterSpacing: '0.01em',

    color: theme.palette.primary.main,
    float: 'right',
    display: 'inline-block',

    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      marginBottom: '24px',
    },
  },
  daysPassed: {
    fontFamily: theme.bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.01em',
    color: theme.palette.primary.main,
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      marginBottom: '24px',
    },
  },
  daysWrapper: {
    height: '40px',
    marginTop: '9px',
    width: '100%',
    [theme.breakpoints.only('md')]: {
      height: '21px',
      marginTop: '6px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '20px',
      marginTop: '7px',
    },
  },
  copyBtn: {
    fontFamily: theme.bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    textDecorationLine: 'underline',
    color: theme.palette.mud.main,
    backgroundColor: 'Transparent',
    border: '0px',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      padding: '0px',
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  fastStartTracker: {
    '& .antd-pro-charts-mini-progress-miniProgress': {
      padding: '0px !important',
    },
    '& .antd-pro-charts-mini-progress-progress': {
      backgroundColor: `${theme.palette.blue.main} !important`,
    },
    '& .antd-pro-charts-mini-progress-progressWrap': {
      backgroundColor: `${theme.palette.greyMedium.main} !important`,
    },
  },
  fastStartTrackerBtn: {
    padding: '16px 24px',
    fontFamily: theme.bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '0.1em',
    textDecorationLine: 'none',
    [theme.breakpoints.down('md')]: {
      marginTop: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
    },
  },
  fastStartTrackerTitle: {
    fontFamily: theme.bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.01em',
    color: theme.palette.primary.main,
    marginBottom: '9px',
    [theme.breakpoints.only('md')]: {
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: '6px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      marginBottom: '7px',
    },
  },
  fastStartTrackerWrapper: {
    height: '81px',
    width: '100%',
    marginTop: '24px',

    [theme.breakpoints.only('md')]: {
      marginTop: '14px',
    },

    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
    },
  },
  rank: {
    fontFamily: theme.bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.01em',
    color: theme.palette.primary.main,
    marginBottom: '8px',
    [theme.breakpoints.only('md')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  rankWrapper: {
    height: '144px',
    marginTop: '24px',
    [theme.breakpoints.down('md')]: {
      marginTop: '16px',
      height: '120px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '14px',
      height: '108px',
    },
  },
  link: {
    alignItems: 'center',
    display: 'inline-block',
    marginRight: '24px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0px',
    },
  },
  clickableLink: {
    fontFamily: theme.bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '0.01em',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  linkWrapper: {
    height: '30px',
    marginTop: '8px',
    [theme.breakpoints.down('sm')]: {
      height: '46px',
    },
  },
  dealerId: {
    fontFamily: theme.bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    marginBottom: '0px',
    marginTop: '8px',
    [theme.breakpoints.only('md')]: {
      fontSize: '14px',
      lineHeight: '21px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  greetingTitle: {
    fontFamily: theme.headerFontFamily,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '32px',
    lineHeight: '44px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.03em',
    color: theme.palette.primary.main,
    marginTop: '8px',
    marginBottom: '0px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  greetingWrapper: ({ isFastStart }) => ({
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.veryLightGrey.main,
    height: isFastStart ? '605px' : '544px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: isFastStart ? '482px' : '420px',
      backgroundColor: theme.palette.neutral0.main,
      boxShadow: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: isFastStart ? '486px' : '420px',
      backgroundColor: theme.palette.neutral0.main,
    },
  }),
  greetingContentWrapper: {
    height: 'auto',
    width: '100%',
    padding: '29px',
    [theme.breakpoints.only('md')]: {
      padding: '16px 24px 24px 24px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 16px 24px 16px',
    },
  },
  greetingHeader: {
    width: '100%',
    height: '40px',
    padding: '0 16px',
    backgroundColor: theme.palette.neutral0,
    borderBottom: `1px solid ${theme.palette.veryLightGrey.main}`,
  },
  personalInfo: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      fontFamily: theme.bodyFontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '21px',
      letterSpacing: '0.01em',
      color: theme.palette.primary.main,
    },
  },
  avatar: props => ({
    height: '80px',
    width: '80px',
    borderRadius: '50%',
    backgroundImage: `url(${
      props.user?.profileImages?.thumb
        ? ceProfileImage(props.user?.profileImages?.thumb)
        : ''
    })`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    [theme.breakpoints.down('md')]: {
      height: '48px',
      width: '48px',
    },
  }),
}));

const Greeting = ({ user }: GreetingProps) => {
  const fastStartResourceClicked = useCallback(() => {
    window.open(
      'https://training.onehopewine.com/training/a7107ed2-3dc2-11eb-b208-067360dfb065/overview',
      '_blank',
    );
  }, []);

  const formatNumber = (number: number) => {
    return Intl.NumberFormat('en-US').format(number);
  };

  const getProgressPercentage = () => {
    const { trinityEnrollDate, fastStartEndDate } = user;
    if (!trinityEnrollDate || !fastStartEndDate) {
      return {
        percentage: -1,
        daysPassed: -1,
        daysLeft: -1,
      };
    }
    const currentDate = new Date();
    const startDate = new Date(trinityEnrollDate);
    const endDate = new Date(fastStartEndDate);
    const totalDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const totalDays = Math.ceil(totalDiff / (1000 * 60 * 60 * 24));
    const currentDiff = Math.abs(currentDate.getTime() - startDate.getTime());
    const currentDays = Math.ceil(currentDiff / (1000 * 60 * 60 * 24));
    if (endDate < startDate || endDate < currentDate) {
      return {
        percentage: -1,
        daysPassed: -1,
        daysLeft: -1,
      };
    }
    return {
      percentage: (currentDays / totalDays) * 100,
      daysPassed: currentDays,
      daysLeft: totalDays - currentDays,
    };
  };

  const getGreeting = (firstName: string | null): string => {
    let date = new Date();
    let now = date.getHours();
    if (now >= 4 && now < 12) {
      return `Good morning, ${firstName || ''}!`;
    } else if (now >= 12 && now < 18) {
      return `Good afternoon, ${firstName || ''}!`;
    } else {
      return `Good evening, ${firstName || ''}!`;
    }
  };

  const {
    trinityDealerId,
    firstName,
    customUrl,
    trinityCurrentRank,
    kpi: { currentPeriodPCV, currentPeriodOV },
    fastStart: { fastStartSalesToDate },
  } = user;
  const pcvConverted = parseFloat(currentPeriodPCV);
  const ovConverted = parseFloat(currentPeriodOV);
  const userLink = `${process.env.GATSBY_HOPECOMMERCE_URL}/myshop/${customUrl}`;
  const { percentage, daysPassed, daysLeft } = getProgressPercentage();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles({ user, isFastStart: percentage >= 0 });
  const avatarSize = isDesktop ? '80px' : '48px';

  const formatUserLink = useMemo(() => {
    if (userLink.startsWith('https://')) {
      return userLink.substr(8);
    } else if (userLink.startsWith('http://')) {
      return userLink.substr(7);
    }

    return userLink;
  }, [userLink]);

  const copyLink = useCallback(() => {
    copy(userLink);
  }, [userLink]);

  return (
    <Grid className={classes.greetingWrapper}>
      {!isDesktop ? (
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.greetingHeader}
        >
          <Grid className={classes.personalInfo}>Personal Information</Grid>
        </Grid>
      ) : null}
      <Grid className={classes.greetingContentWrapper}>
        {!!user?.profile?.image ? (
          <Grid className={classes.avatar} />
        ) : (
          <Icons.EmptyProfile width={avatarSize} height={avatarSize} />
        )}
        <Text className={classes.greetingTitle}>{getGreeting(firstName)}</Text>
        <Text className={classes.dealerId}>Dealer ID: {trinityDealerId}</Text>
        <Grid className={classes.linkWrapper}>
          <Text className={classes.link}>
            <a
              href={userLink}
              target={'_blank'}
              className={classes.clickableLink}
            >
              {formatUserLink}
            </a>
          </Text>
          <button
            type={'button'}
            className={classes.copyBtn}
            onClick={copyLink}
          >
            Copy
          </button>
        </Grid>
        <Grid className={classes.rankWrapper}>
          <Text className={classes.rank}>
            Current Rank: {trinityCurrentRank}
          </Text>
          {percentage >= 0 ? (
            <Text className={classes.rank}>
              Retail Sales: {formatNumber(fastStartSalesToDate)}
            </Text>
          ) : null}
          <Text className={classes.rank}>
            Current PCV: {formatNumber(pcvConverted)}
          </Text>
          <Text className={classes.rank} style={{ marginBottom: '0px' }}>
            Current OV: {formatNumber(ovConverted)}
          </Text>
          {percentage >= 0 ? (
            <Grid className={classes.fastStartTrackerWrapper}>
              <Text className={classes.fastStartTrackerTitle}>
                Fast Start Tracker
              </Text>
              <Grid className={classes.fastStartTracker}>
                <MiniProgress
                  target={100}
                  targetLabel=""
                  percent={percentage}
                  strokeWidth={16}
                />
              </Grid>
              <Grid className={classes.daysWrapper}>
                <Text className={classes.daysPassed}>
                  {daysPassed} days passed
                </Text>
                <Text className={classes.daysLeft}>{daysLeft} days left</Text>
              </Grid>
              <Button
                className={classes.fastStartTrackerBtn}
                onClick={fastStartResourceClicked}
              >
                Fast Start Resources
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Greeting;
