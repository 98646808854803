import React, { useCallback } from 'react';
import { Button, makeStyles, ThemeType } from '@onehope/design-system-v2';
import ActivityEntry from '../types/ActivityEntry';
import { isMobileOnly, isMobile } from 'react-device-detect';
import OpenButtonActionDialogFn from '../types/OpenButtonActionDialog';
import OnCardButtonClickedFn from '../types/OnCardButtonClicked';
import smsLink from 'sms-link';

interface CardButtonProps {
  activity: ActivityEntry;
  onVideoClickPreview: () => void;
  openButtonActionDialog: OpenButtonActionDialogFn | undefined;
  onCardButtonClicked?: OnCardButtonClickedFn;
  watchNow: () => void;
}

const useStyles = makeStyles((theme: ThemeType) => ({
  button: {
    display: 'block',
    position: 'absolute',
    width: '310px',
    borderRadius: '12px',
    margin: '0px 16px 0px',
    [theme.breakpoints.down('sm')]: {
      width: '256px',
    },
  },
}));

const convertEmailBreakpoints = (body: string) =>
  body.replace(/<br(\s\/)?>/gi, '\n');

const CardButton = ({
  activity,
  onVideoClickPreview,
  openButtonActionDialog,
  onCardButtonClicked,
  watchNow,
}: CardButtonProps) => {
  const classes = useStyles();

  const openUrl = useCallback((url: string | undefined) => {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }, []);

  const getUrl = (
    buttonAction: ActivityEntry['buttonAction'] | undefined,
    buttonText: string | undefined,
  ): any => {
    // action: website
    // fields: name url

    // action: phoneCall
    // fields: name phoneNumber

    // action: email
    // fields: name emailAddress body subject

    // action: textMessage
    // fields: name phoneNumber body
    if (buttonAction) {
      switch (buttonAction.action) {
        case 'website':
          return buttonAction.url;
        case 'textMessage':
          return smsLink({
            phone: buttonAction.phoneNumber,
            body: buttonAction.body,
          });
        case 'email':
          // left unchecked, this will render "null" in the user's email client
          const emailTo = buttonAction.emailAddress || '';
          return `mailto:${emailTo}?subject=${encodeURI(
            buttonAction.subject,
          )}&body=${encodeURI(convertEmailBreakpoints(buttonAction.body))}`;
        case 'phoneCall':
          return `tel:${buttonAction.phoneNumber}`;
        default:
          console.log(`${buttonText} is not a valid action for card button`);
      }
    }
  };

  const buttonTriggered = () => {
    const { buttonText, buttonAction } = activity;
    if (buttonAction) {
      openUrl(getUrl(buttonAction, buttonText));
    } else {
      switch (buttonText) {
        case 'Watch Now':
          isMobile ? watchNow() : onVideoClickPreview();
          break;
        case 'Mark Read':
          // no op... it's handled in onCardButtonClicked
          break;
        default:
          throw new Error('Invalid button received!');
      }
    }
  };

  const buttonClicked = () => {
    const { buttonAction } = activity;
    const isTextOrCallButtonAction =
      buttonAction &&
      (buttonAction.action === 'phoneCall' ||
        buttonAction.action === 'textMessage');
    if (isTextOrCallButtonAction && !isMobileOnly) {
      if (typeof openButtonActionDialog !== 'undefined') {
        openButtonActionDialog(activity.buttonAction);
      }
    } else {
      buttonTriggered();
    }
    // Success triggers, mark read, card clicked segment event
    onCardButtonClicked && onCardButtonClicked(activity.id);
  };

  return (
    <Button onClick={buttonClicked} className={classes.button}>
      {activity.buttonText}
    </Button>
  );
};

export default CardButton;
