import React, { useEffect, useState } from 'react';
import { MuiButton } from '@onehope/design-system-ohw';
import DialogRoot from '@material-ui/core/Dialog';
import styled from '@emotion/styled';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import AddContactForm from './AddContactForm';

const NewContactDialog = styled(DialogRoot)`
  &.MuiDialog-root {
    /* z-index: 9000 !important; */
  }
`;

const CreateContactButton = styled(MuiButton)`
  && {
    width: 288px;
  }
`;

export default function AddContactDialog(
  props: any,
  // props: FormProps & FormikProps<MyFormValues> & AddContactDialogProps,
) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isContactCreateOpen, setIsContactCreateOpen] = useState(false);

  const handleEventContactToggle = () => {
    setIsContactCreateOpen(!isContactCreateOpen);
  };

  return (
    <div>
      <CreateContactButton
        fullWidth
        type="PRIMARY"
        onClick={handleEventContactToggle}
      >
        create new contact
      </CreateContactButton>
      <NewContactDialog
        fullScreen={fullScreen}
        disableEnforceFocus
        open={isContactCreateOpen}
        onClose={handleEventContactToggle}
      >
        <AddContactForm
          onClose={handleEventContactToggle}
          defaultValues={{
            contactType: 'PERSON',
            firstName: '',
            lastName: '',
            organizationName: '',
            email: '',
            shippingAddress: '',
            addressLineOne: '',
            addressLineTwo: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            dateOfBirth: '',
            note: '',
            hostWineTasting: false,
            joinWineClub: false,
            becomePC: false,
            joinAsCE: false,
            corporateGifting: false,
          }}
        />
      </NewContactDialog>
    </div>
  );
}
