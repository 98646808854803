import { DateTime } from 'luxon';
import { MyFormValues } from './MyFormTypes';

export const dateInputFormat = (date: string) => {
  if (date.match(/^\d{2}$/) !== null || date.match(/^\d{2}\/\d{2}$/)) {
    return date + '/';
  }
  return date;
};

export const cannotBeAdded = (error: string) => {
  return (
    error.includes('To shop for yourself, simply click the event link') ||
    error.includes(
      'Sorry, this user is a CE and cannot be added as your customer',
    ) ||
    error.includes(
      'Customer is already shopping with a different Cause Entrepreneur. Please contact support@onehopewine.com for assistance',
    )
  );
};

export function getContactDetailsToSubmit(values: MyFormValues): any {
  const {
    contactType,
    firstName,
    lastName,
    organizationName,
    email,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zip,
    phone,
    dateOfBirth,
    // note,
    hostWineTasting,
    joinWineClub,
    becomePC,
    joinAsCE,
    corporateGifting,
  } = values;
  if (contactType === 'PERSON') {
    return {
      firstName,
      lastName,
      organizationName: null,
      email,
      contactType,
      shippingAddress: addressLineOne
        ? {
            firstName,
            lastName,
            phoneNumber: phone ? phone.replace(/[-() ]/g, '') : '',
            addressLineOne: addressLineOne ?? null,
            addressLineTwo: addressLineTwo ?? null,
            city: city ?? null,
            state: state ?? null,
            zip: zip ?? null,
          }
        : null,
      mobilePhone: phone ? phone.replace(/[-() ]/g, '') : null,
      dateOfBirth: dateOfBirth ? dateOfBirth : null,
      interestedIn: {
        hostWineTasting,
        joinWineClub,
        becomePC,
        joinAsCE,
        corporateGifting,
      },
    };
  }
  return {
    organizationName,
    firstName,
    lastName,
    email,
    contactType,
    shippingAddress: addressLineOne
      ? {
          firstName,
          lastName,
          companyName: organizationName,
          phoneNumber: phone ? phone.replace(/[-() ]/g, '') : '',
          addressLineOne: addressLineOne ?? null,
          addressLineTwo: addressLineTwo ?? null,
          city: city ?? null,
          state: state ?? null,
          zip: zip ?? null,
        }
      : null,
    mobilePhone: phone ? phone.replace(/[-() ]/g, '') : null,
    dateOfBirth: dateOfBirth ? dateOfBirth : null,
    interestedIn: {
      hostWineTasting,
      joinWineClub,
      becomePC,
      joinAsCE,
      corporateGifting,
    },
  };
}
