import React from 'react';
import styled from '@emotion/styled';
import { styles } from '@onehope/design-system';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { MenuProps } from '../EventDetailsForm/index.styles';
import {
  CEDash_NonProfitsQuery_viewer_user_nonProfits as PreApprovedNonProfitsType,
  CEDash_NonProfitsQuery_viewer_user_nonProfits_edges_node as PreApprovedNonProfitNode,
} from '../../../queries/generatedTypes/CEDash_NonProfitsQuery';
import {
  Title,
  Select,
  Partner,
  Option,
  DefaultMenuItem,
  PartnerDefaultOption,
  NonProfitContainer,
  NonProfitTitle,
  NonProfitDescription,
} from './index.styles';
import { CharityNameDictionary } from '../../../utils/enums';

const { cssConstants } = styles;

const ArrowDropDownCircle = styled(ArrowDropDownCircleIcon)`
  && {
    fill: ${cssConstants.primaryColor};
    margin-right: 16px;
  }
`;

const CheckCircle = styled(CheckCircleIcon)`
  && {
    fill: ${cssConstants.primaryColor};
    margin-right: 16px;
  }
`;

export const formatNonProfits = (nonProfits: PreApprovedNonProfitsType) => {
  let onehopeFoundationPosition;
  const nonProfitsArray =
    nonProfits.map(
      ({ node: nonProfit }: PreApprovedNonProfitNode, i: number) => {
        const name = nonProfit && nonProfit.nonProfitName;
        const description = nonProfit && nonProfit.nonProfitDescription;
        const nonProfitId = nonProfit && nonProfit.nonProfitId;
        if (name.toLowerCase() === CharityNameDictionary.ONEHOPE_FOUNDATION)
          onehopeFoundationPosition = i;
        return { name, description, nonProfitId };
      },
    ) || [];
  // move onehope foundation to position 0
  if (onehopeFoundationPosition)
    nonProfitsArray.splice(
      0,
      0,
      nonProfitsArray.splice(onehopeFoundationPosition, 1)[0],
    );

  return nonProfitsArray;
};

interface ParsedPreApprovedNonProfit {
  name: string;
  description: string;
  nonProfitId: string;
}

interface CausePartnerDropDownProps {
  showDefault: boolean;
  value: string;
  id: string;
  error: boolean;
  nonProfits: NonProfitsType;
  onChange: () => void;
}

export default function CausePartnerDropDown(props: CausePartnerDropDownProps) {
  const { showDefault, value, id, error, nonProfits, onChange } = props;
  const preApprovedNonProfitArray = formatNonProfits(nonProfits);

  return (
    <div>
      <Title>Select a ONEHOPE Cause Partner:</Title>
      <Select
        showDefault={showDefault}
        IconComponent={showDefault ? ArrowDropDownCircle : CheckCircle}
        input={<Partner id={id} />}
        value={value}
        displayEmpty={true}
        onChange={onChange}
        error={error}
        inputProps={{
          name: id,
          id: id,
          MenuProps: MenuProps,
        }}
      >
        <DefaultMenuItem component="div" value="">
          <PartnerDefaultOption>Select from the dropdown</PartnerDefaultOption>
        </DefaultMenuItem>
        {preApprovedNonProfitArray.length &&
          preApprovedNonProfitArray.map(
            (nonProfit: ParsedPreApprovedNonProfit) => {
              const { name, description, nonProfitId } = nonProfit;
              return (
                <Option
                  button={true}
                  component="div"
                  key={nonProfitId}
                  value={nonProfitId}
                >
                  <NonProfitContainer>
                    <NonProfitTitle>{name}</NonProfitTitle>
                    <NonProfitDescription>{description}</NonProfitDescription>
                  </NonProfitContainer>
                </Option>
              );
            },
          )}
      </Select>
    </div>
  );
}
