import gql from 'graphql-tag';

export const EventsPage_user = gql`
  fragment EventsPage_HostAccountUser on User {
    events(first: 10000, sortBy: "any", byHostAccountId: $byHostAccountId) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          status
          trinityPartyStatus
          trinityPartyId
          eventId
          eventDate
          closeDate
          hostFirstName
          hostLastName
          hostFullName
          hostNonProfitName
          trinityPartyType
          hostKitOrderNumbers
          hostKitQuantity
          timeZone
          hostQuote
          eventAddress {
            city
            state
          }
          donationId
          donationInfo {
            id
            ordersSubTotal
            donationAmount
            hostKitDonation
            donationType
            donationGoal
            status
            lobMemo
            nonProfit {
              id
              nonProfitName
              nonProfitTaxId
              nonProfitId
              isPreApproved
              nonProfitAddress {
                optionalName
                addressLineOne
                addressLineTwo
                city
                state
                zip
                phoneNumber
              }
            }
          }
        }
      }
    }
  }
`;

const GET_EVENTS_QUERY = gql`
    query CEDash_EventsByHostAccountQuery($byHostAccountId: String!){
        viewer {
            id
            user {
                ...EventsPage_HostAccountUser
                id
                email
            }
        }
    }
    ${EventsPage_user}
`;

export default GET_EVENTS_QUERY;
