import graphql from 'graphql-tag';
type TaskListItem = {
  description: string;
  isCompleted: boolean;
};

interface Month {
  month: string;
  impactGoal: string;
}

interface Year {
  year: string;
  months: Array<Month>;
}
export type CeUpdateMutationVariables = {
  input: {
    ce: {
      ceId: string;
      ceTaskList?: {
        tasks: Array<TaskListItem>;
      };
      ceImpactGoals?: {
        goals: Array<Year>;
      };
    };
  };
};

const mutation = graphql`
  mutation CeUpdateMutation($input: CeUpdateMutationInput!) {
    ceUpdate(input: $input) {
      user {
        id
        ce {
          id
          ceId
          workRampInfo {
            pathName
            lastAccessed
            url
          }
          mentorInfo {
            fullName
            mentorEmail
            phoneNumber
            trinityEnrollDate
            phoneNumber
            trinityCurrentRank
            mentorProfile {
              image
              city
              state
            }
          }
          ceTaskList {
            tasks {
              description
              isCompleted
            }
          }
          ceImpactGoals {
            goals {
              year
              months {
                month
                impactGoal
              }
            }
          }
          ceImpactData {
            years {
              year
              months {
                month
                impactTotal
              }
            }
            lifetime
          }
        }
      }
    }
  }
`;

export default mutation;
