import * as React from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import NonContactsSvg from '../../assets/noOrders';
import { MuiButton } from '@onehope/design-system-ohw';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

const { cssConstants } = s;
const NonContactsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 16px;
  @media all and (min-width: 768px) {
    padding: 40px 16px;
  }
`;

const NonContactsText = styled.div`
  color: ${cssConstants.textColor};
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  padding: 24px 0;
`;

const NewOrderButton = styled.div`
  max-width: 432px;
  width: 100%;
  height: 100px;
  color: ${cssConstants.primaryColor};
  cursor: pointer;
`;

const NewOrderText = styled.div`
  /* font-family: ProximaNova; */
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 8px;
`;

const OrderTextContainer = styled.div`
  max-width: 432px;
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SvgContainer = styled.div``;

export default function NonContacts({
  openDialog,
  openForOrders,
}: {
  openDialog: (input: boolean) => void;
  openForOrders: boolean;
}) {

  const openOrderDialog = () => {
    openDialog(true);
  };

  return (
    <NonContactsContainer>
      <SvgContainer>
        <NonContactsSvg />
      </SvgContainer>
      <NonContactsText>There are no contacts yet.</NonContactsText>
      {openForOrders &&
        <NewOrderButton>
          <MuiButton type="DASHED" fullWidth disableRipple onClick={openOrderDialog}>
            <OrderTextContainer>
              <AddCircleOutline />
              <NewOrderText>add new order</NewOrderText>
            </OrderTextContainer>
          </MuiButton>
        </NewOrderButton>}
    </NonContactsContainer>
  );
}
