import styled from '@emotion/styled';
import { MuiButton } from '@onehope/design-system-ohw';

export const OrderContainer = styled.div`
  position: fixed;
  right: 24px;
  bottom: 16px;
  z-index: 1;
  @media only screen and (min-width: 768px) {
    bottom: 24px;
  }
`;

export const OrderButton = styled(MuiButton)`
  && {
    width: 114px;
    border-radius: 100px !important;
    height: 48px !important;
  }
  .MuiButton-label {
    padding: 0;
  }
`;

export const OrderText = styled.div`
  margin-left: 8px;
`;
