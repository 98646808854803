import * as React from 'react';
import styled from '@emotion/styled';
import { styles } from '@onehope/design-system-ohw';

const {
  cssConstants: { primaryColor, neutralBackgroundColor },
} = styles;

const MutedNumber = styled.span`
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  opacity: 0.3;
  border-radius: 4px;
  border: solid 1px ${primaryColor};
  background-color: ${neutralBackgroundColor};
  font-weight: bold;
  display: inline-block;
  text-align: center;
  padding-top: 10px;
`;

export default function ItemQuantity({ quantity }: { quantity: number }) {
  return <MutedNumber>{quantity}</MutedNumber>;
}
