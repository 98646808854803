import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import TextField from '@material-ui/core/TextField';

type InputProps = {
  error?: boolean;
};

const { cssConstants } = s;
export const SearchInput = styled(TextField)<InputProps>`
  & .MuiInputBase-input {
    font-family: ${cssConstants.font};
    height: 48px;
    padding: 0.15rem 16px;
    font-weight: normal;
    line-height: 1.57;
    font-size: 14px;
    border-radius: 100px;
    background-color: ${cssConstants.neutral2};
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${cssConstants.primaryColor} !important;
    border-radius: 100px;
  }

  & .MuiInputBase-input:focus {
    background-color: ${cssConstants.inputFocusColor};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ error }) =>
      error ? cssConstants.errorColor : cssConstants.primaryColor};
  }

  & .MuiFormHelperText-root {
    font-family: ${cssConstants.font};
    color: ${cssConstants.errorColor};
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    margin-top: 8px;
  }
  & .MuiInputAdornment-root {
    position: absolute;
    right: 16px;
  }
  & .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
`;
