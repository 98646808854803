import React from 'react';
import queryString from 'query-string';
import styled from '@emotion/styled';
import { PageRendererProps } from 'gatsby';

import Layout from '../components/MainLayout/layout/layout';
import { getCurrentUserId } from '../utils/utils';
import ContactDetailsPage from '../components/Contact';

const Container = styled.div`
  background-color: white;
  height: 100vh;
`;

function ContactPage(props: PageRendererProps) {
  const { location } = props;
  const currentUser = getCurrentUserId(location);
  const { contactId, tab } = queryString.parse(location.search);
  return (
    <Layout selectedUserId={currentUser}>
      <Container>
        <ContactDetailsPage contactId={contactId} tab={tab || '0'} />
      </Container>
    </Layout>
  );
}

export default ContactPage;
