import React from 'react';

export const SvgSM = (props: any) => (
  <svg width="40" height="40" viewBox="0 6 25 40" {...props}>
    <defs>
      <path d="M21 15v4h4v2h-4v4h-2v-4h-4v-2h4v-4h2z" id="sm_svg__a" />
    </defs>
    <g transform="rotate(45 10 10)" fill="none" fillRule="nonzero">
      <mask id="sm_svg__b" fill="#fff">
        <use xlinkHref="#sm_svg__a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#sm_svg__a" />
      <g mask="url(#sm_svg__b)" fill="#1A1A1A">
        <path d="M0 0h40v40H0z" />
      </g>
    </g>
  </svg>
);
