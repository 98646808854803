import React from 'react';

function Processing() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="28"
      height="28"
      viewBox="0 0 28 28">
      <defs>
        <path
          id="s"
          d="M13.5 8.864v2.318l3.25-3.091L13.5 5v2.318C9.909 7.318 7 10.085 7 13.5c0 1.213.374 2.341 1.008 3.292l1.186-1.128a4.361 4.361 0 01-.569-2.164c0-2.558 2.186-4.636 4.875-4.636zm5.492 1.344l-1.186 1.128a4.47 4.47 0 01.569 2.164c0 2.558-2.186 4.636-4.875 4.636v-2.318l-3.25 3.091L13.5 22v-2.318c3.591 0 6.5-2.767 6.5-6.182a5.912 5.912 0 00-1.008-3.292z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect width="28" height="28" fill="#FAAD14" rx="14" />
        <path d="M0 0h28v28H0z" />
        <mask id="rb" fill="#fff">
          <use xlinkHref="#s" />
        </mask>
        <g fill="#FFF" mask="url(#rb)">
          <rect width="28" height="28" rx="14" />
        </g>
      </g>
    </svg>
  );
}

export default Processing;
