import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import EventsTable from './EventsTable';
import EVENT_CANCEL_MUTATION from '../../mutations/Event/EventCancelMutation';
import { handleFilterByTab } from './EventsTable/helpers';
import {
  CEDash_EventsQuery_viewer_user_events as EventsType,
  CEDash_EventsQuery_viewer_user as UserType,
} from '../../queries/generatedTypes/CEDash_EventsQuery';

interface EventsProps {
  events: EventsType;
  user: UserType;
}

function EventsPageWrapper(props: EventsProps) {
  const [eventCancelMutation, { loading: eventCanceling }] = useMutation(
    EVENT_CANCEL_MUTATION,
  );
  const eventEdges = props.events.edges;
  const currentEventsTab = sessionStorage.getItem(`ceDashEventsAllTab`);
  const storedTabFilterState = sessionStorage.getItem(`ceDashEventsAllTab`);
  const filterBy = storedTabFilterState
    ? JSON.parse(storedTabFilterState)
    : 'open';
  const currentEvents = handleFilterByTab(
    eventEdges,
    currentEventsTab ? JSON.parse(currentEventsTab) : 'open',
  );
  const [events, setEvents] = useState(currentEvents);
  const [allEvents, setAllEvents] = useState(eventEdges);

  useEffect(
    () => {
      setEvents(currentEvents);
      setAllEvents(eventEdges);
    },
    [props.events],
  );

  return (
    <EventsTable
      filterBy={filterBy}
      showHeader={true}
      setEvents={setEvents}
      events={events}
      user={props.user}
      allEvents={allEvents}
      eventCanceling={eventCanceling}
      eventCancelMutation={eventCancelMutation}
    />
  );
}

export default EventsPageWrapper;
