import React from 'react';

function HostSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30">
      <defs>
        <path
          id="x"
          d="M17.805 9.488a.391.391 0 01-.551 0 .377.377 0 010-.543l1.218-1.212a.407.407 0 01.564 0 .377.377 0 010 .543l-1.231 1.212zm-4.64-1.263a.38.38 0 01-.193.518.4.4 0 01-.525-.19l-.73-1.54c-.09-.19 0-.43.192-.518a.407.407 0 01.525.19l.73 1.54zm2.525-.088a.394.394 0 01-.462.316c-.205-.038-.358-.24-.32-.442l.308-1.692a.409.409 0 01.448-.316.39.39 0 01.32.455l-.294 1.679zm-.282 3.46l6.37-1.692c.219-.05.436.075.488.277l.948 3.46a3.962 3.962 0 01-.41 3.018 4.052 4.052 0 01-2.076 1.73l1.153 4.217 1.628-.429a.386.386 0 01.474.278.372.372 0 01-.269.467l-4.025 1.06a.379.379 0 01-.474-.265.38.38 0 01.27-.48l1.64-.429-1.154-4.217a4.1 4.1 0 01-2.666-.48c-.897-.518-1.602-1.35-1.884-2.412l-.564-2.07-.27 1.01a3.985 3.985 0 01-1.884 2.399 4 4 0 01-2.679.48l-1.14 4.217 1.627.43a.409.409 0 01.282.48.41.41 0 01-.487.277l-4.012-1.06a.392.392 0 01-.282-.48c.051-.203.27-.329.487-.278l1.628.442 1.14-4.218a3.994 3.994 0 01-2.063-1.742 3.883 3.883 0 01-.41-3.005l.936-3.473a.398.398 0 01.487-.265L15.24 10.7a.37.37 0 01.282.467l-.115.43zm4.217 2.639c.603 0 1.141-.026 1.628-.152.397-.088.77-.24 1.128-.492l-.769-2.829-6.281 1.654.487 1.743c.949-.076 1.782-.025 2.564.025.436.025.846.05 1.243.05zm-5.435-1.137c-.961-.088-1.782-.038-2.576.013-.423.025-.846.05-1.244.05-.59 0-1.128-.025-1.615-.138a3.565 3.565 0 01-1.128-.493l.77-2.841 6.267 1.654-.474 1.755z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="r" fill="#fff">
          <use xlinkHref="#x" />
        </mask>
        <use fill="#000" xlinkHref="#x" />
        <g fill="#1A1A1A" mask="url(#r)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </svg>
  );
}

export default HostSvg;
