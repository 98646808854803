import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import NoteAddMutation from '../../../../mutations/Note/NoteAddMutation';
import { MyFormValues } from './FormTypes';
import { NoteFormSchema } from './validationSchema';
import NoteForm from './NoteForm';
import FormContainer from '../../../Event/FormContainer';

interface AddNoteFormProps {
  contactAccountId: string | null;
  onClose: () => void;
  defaultValues: MyFormValues;
}

const handleAddNote = async ({
  values,
  contactAccountId,
  onClose,
  noteAdd,
}: any) => {
  const { text } = values;
  await noteAdd({
    variables: {
      input: {
        text,
        contactAccountId,
      },
    },
  })
    .then((data: any) => {
      console.log(data);
      onClose();
    })
    .catch(async (errors: any) => {
      console.log(errors);
    });
};

export default function AddNoteForm(props: AddNoteFormProps) {
  const { onClose, contactAccountId, defaultValues } = props;
  const [noteAdd, { loading: mutationLoading }] = useMutation(NoteAddMutation);

  return (
    <div>
      <FormContainer
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={defaultValues}
        validationSchema={NoteFormSchema}
        formToUse={(props: any) => (
          <NoteForm
            {...props}
            editing={false}
            onClose={onClose}
            mutationLoading={mutationLoading}
          />
        )}
        onSubmit={async (values: MyFormValues) => {
          await handleAddNote({
            values,
            contactAccountId,
            onClose,
            noteAdd,
          });
        }}
      />
    </div>
  );
}
