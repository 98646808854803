import React from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system-ohw';
import { CEDash_OrderWineSummaryPage_viewer_order as OrderType } from '../../../../queries/generatedTypes/CEDash_OrderWineSummaryPage';

const { cssConstants } = s;

const SummarySection = styled.div`
  padding: 20px 0;
`;

const MutedHorizontalRule = styled.div`
  width: 100%;
  border-bottom: 1px solid ${cssConstants.mutedAccentColor};
  margin: 15px 0;
`;

const Header = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: black;
`;

const SummaryLine = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: black;
`;

export default function StepSummary({ order, stepName }: { stepName: string, order: OrderType  }) {
  const { shippingAddress, paymentInfo } = order;

  // These 3 variables will get set in the switch statement.
  let header = 'Summary' as string;
  let lines = [] as string[];

  switch (stepName) {
    case 'shipping':
      header = 'Address';
      const {
        firstName,
        lastName,
        addressLineOne,
        addressLineTwo,
        city,
        state,
        zip,
        phone,
      } = shippingAddress;
      lines = [
        `${firstName} ${lastName || ''}`,
        `${addressLineOne}, ${(addressLineTwo && addressLineTwo + ', ') ||
          ''} ${city}, ${state} ${zip}`,
        `${phone}`,
      ];
      break;
    // case 'host':
    //   header = 'Host Notifications';
    //   const { recipientEmail, recipientPhoneNumber } = giftRecipientInfo;
    //   lines = [recipientEmail, recipientPhoneNumber];
    //   break;
    case 'payment':
      header = 'Payment';
      const { brand, last4, expMonth, expYear } = paymentInfo;
      lines = [`${brand} ending ...${last4}`, `Exp ${expMonth}/${expYear}`];
      break;
  }

  return (
    <SummarySection>
      <Header>{header}</Header>
      <MutedHorizontalRule />
      {lines.map(line => (
        <SummaryLine key={line}>{line}</SummaryLine>
      ))}
    </SummarySection>
  );
}
